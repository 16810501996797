import React, { useState } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { Empty } from "antd5";
import isEqual from "lodash.isequal";
import useDeepCompareEffect from "use-deep-compare-effect";

import {
  convertDocumentFiltersToSearchRequest,
  DEFAULT_PAGINATION,
  DocumentColumns,
  DocumentFilters,
  documentTypeMap,
  isValidSortField,
} from "components/documents/utils";
import OrganisationsContainer from "components/record_details/OrganisationsPopover";
import { numberSort, stringSort } from "lib/columnSort";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { NewRelevanceScore } from "lib/core_components/NewRelevanceScore";
import { ColumnType, commonTableColumns } from "lib/core_components/Table/ColumnTypes";
import { Table } from "lib/core_components/Table/Table";
import { DocumentsDto, DocumentsSearchResponse, useDocuments } from "lib/hooks/api/useDocuments";
import DocumentIcon from "lib/icons/DocumentIcon";
import { EventNames, useTracking } from "lib/tracking";
import { SignalEntityType } from "lib/utils/signalUtils";
import DocumentDrawer from "../document_details/DocumentDrawer";

import css from "./DocumentTable.module.scss";

type SortState = Exclude<DocumentFilters["sort"], undefined>;

type DocumentResult = DocumentsSearchResponse["results"][0];

type Props = {
  filters: DocumentFilters;
  columnSettings: DocumentColumns[];
  selectedRows?: string[];
  onSortChange: (sort: SortState) => void;
  onSelectedRowsChange?: (selectedRowKeys: string[], selectedRows: DocumentsDto[]) => void;
};

function getSortOrder(key: string, sort?: SortState): "ascend" | "descend" | null {
  return sort?.field !== key ? null : sort.order === "ASC" ? "ascend" : "descend";
}

function getDocumentColumns({
  filters,
  columnSettings,
  onDocumentClick,
}: {
  filters: DocumentFilters;
  columnSettings: DocumentColumns[];
  onDocumentClick: (document: DocumentResult) => void;
}) {
  const sort = filters.sort;

  const title: ColumnType<DocumentsDto> = {
    title: "Document title",
    dataIndex: "title",
    key: "title",
    render: (_, d) => (
      <div className={css.titleContainer}>
        <DocumentIcon className={css.documentIcon} />
        <div className={css.title}>
          <EllipsisTooltipTextLink
            fullText={d.title ?? "-"}
            linkProps={{
              to: `/documents/${d.id}`,
              onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
                onDocumentClick(d);
              },
              className: css.titleText,
            }}
            linkText={d.title}
          />
        </div>
      </div>
    ),
    sorter: stringSort((d) => d.title),
    sortOrder: getSortOrder("title", sort),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
    sizeConfig: {
      small: 200,
      medium: 300,
      large: 400,
      xlarge: 500,
    },
  };

  const buyers: ColumnType<DocumentsDto> = {
    ...commonTableColumns.titleColumn,
    title: "Buyer(s)",
    dataIndex: "buyers",
    key: "buyers",
    render: (_, d) => <OrganisationsContainer orgs={d.buyers ?? []} orgType="buyers" />,
  };

  const signalScore: ColumnType<DocumentsDto> = {
    ...commonTableColumns.relevanceColumn,
    title: "Signal score",
    key: "signalScore",
    dataIndex: "signalScore",
    render: (_, d) => (
      <NewRelevanceScore
        relevanceScore={d.signalScore}
        popoverPlacement="bottomLeft"
        id={d.id}
        entityType={SignalEntityType.Document}
      />
    ),
    sorter: numberSort((d: DocumentsDto) => d.signalScore),
    sortOrder: getSortOrder("signalScore", sort),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: {
      title:
        "BETA: This score is calculated based on the strength of your signal settings against this document.",
    },
  };

  const category: ColumnType<DocumentsDto> = {
    title: "Type",
    dataIndex: "category",
    key: "category",
    render: (_, d) => <span>{d.category ? documentTypeMap[d.category] : "-"}</span>,
    sorter: stringSort((d) => d.category),
    sortOrder: getSortOrder("category", sort),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
  };

  const publishedAt: ColumnType<DocumentsDto> = {
    title: "Published",
    dataIndex: "publishedAt",
    key: "publishedAt",
    render: (_, d) => (d.publishedAt ? d.publishedAt.split("T")[0] : "-"),
    sorter: stringSort((d) => d.publishedAt),
    sortOrder: getSortOrder("publishedAt", sort),
    sortDirections: ["ascend", "descend", "ascend"],
    showSorterTooltip: false,
  };

  const columns = {
    title,
    buyers,
    signalScore,
    category,
    publishedAt,
  };

  return columnSettings.map((c) => columns[c]);
}

export function DocumentTable({ filters, columnSettings, onSortChange }: Props) {
  const { logEvent } = useTracking();
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const [selectedDocument, setSelectedDocument] = React.useState<DocumentResult | undefined>(
    undefined,
  );

  const { data, isLoading, isError } = useDocuments(
    convertDocumentFiltersToSearchRequest(filters, pagination),
  );

  const onDocumentClick = (document: DocumentResult) => setSelectedDocument(document);

  const columns = React.useMemo(() => {
    return getDocumentColumns({
      filters,
      columnSettings,
      onDocumentClick: onDocumentClick,
    });
  }, [filters, columnSettings]);

  useDeepCompareEffect(() => {
    setPagination(DEFAULT_PAGINATION);
  }, [filters]);

  return (
    <>
      <Table<DocumentsDto>
        ariaLabel="Document Table"
        dataSource={data?.results}
        loading={isLoading}
        columns={columns}
        showSorterTooltip={false}
        scroll={{ x: true }}
        rowKey="id"
        onRow={(document) => ({
          onClick: (e) => {
            e.stopPropagation();
            e.preventDefault();
            onDocumentClick(document);
          },
        })}
        pagination={{
          ...pagination,
          total: data?.totalResults || 0,
          onChange: (page, pageSize) => {
            setPagination({ current: page, pageSize });
            logEvent(EventNames.paginationSelected, { Value: page });
          },
          showSizeChanger: true,
        }}
        onChange={(_, __, sorter) => {
          if (Array.isArray(sorter)) {
            sorter = sorter[0];
          }
          // If the sort order is not defined, we don't want to trigger a sort without an order
          if (!filters.sort && !sorter.order) {
            return;
          }

          const sortField = isValidSortField(sorter.columnKey?.toString());
          if (sortField) {
            const newSortState: SortState = {
              order: sorter.order === "descend" ? "DESC" : "ASC",
              field: sortField,
            };

            if (!isEqual(filters.sort, newSortState)) {
              onSortChange?.(newSortState);
            }
          }
        }}
        locale={{
          emptyText: isError ? (
            <Empty
              image={<WarningOutlined style={{ fontSize: 100 }} />}
              description="Sorry, something has gone wrong"
            />
          ) : undefined,
        }}
      />
      <DocumentDrawer
        documentId={selectedDocument?.id}
        filters={filters}
        onClose={() => setSelectedDocument(undefined)}
      />
    </>
  );
}
