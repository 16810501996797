import React from "react";
import { CloseCircleFilled, CloseCircleOutlined } from "@ant-design/icons";

import Badge from "../../ui/badge/badge";

import css from "./CollapseFilterHeader.module.scss";

export interface CollapseFilterHeaderProps {
  title: string;
  count?: number;
  clear: () => void;
}

function CollapseFilterHeader({ title, count, clear }: CollapseFilterHeaderProps) {
  return (
    <div className={css.wrapper}>
      {title}
      {count
        ? count > 0 && (
            <Badge
              iconOnClick={clear}
              icon={
                <span className={css.closeIconWrapper}>
                  <CloseCircleOutlined className={css.closeIconDefault} />
                  <CloseCircleFilled className={css.closeIconHover} />
                </span>
              }
            >
              {count}
            </Badge>
          )
        : null}
    </div>
  );
}

export default CollapseFilterHeader;
