import React from "react";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Popover, Skeleton } from "antd5";

import { documentTypeMap } from "components/documents/utils";
import OrganisationsContainer from "components/record_details/OrganisationsPopover";
import { NewRelevanceScore } from "lib/core_components/NewRelevanceScore";
import { PropertyInline } from "lib/core_components/Property";
import { DOCUMENTS_SIGNAL_SCORE } from "lib/featureFlags";
import { SignalEntityType } from "lib/utils/signalUtils";

import css from "./DocumentDetails.module.scss";

type DocumentDetailsProps = {
  id: string;
  buyers: { id: string; name: string }[];
  signalScore?: number;
  type: string;
  publishDate: string;
  sources: string[];
  isLoading: boolean;
};

export default function DocumentDetails({
  id,
  buyers,
  signalScore,
  type,
  publishDate,
  sources,
  isLoading,
}: DocumentDetailsProps) {
  const isSignalScoreEnabled = useVariableValue(DOCUMENTS_SIGNAL_SCORE, false);

  return (
    <div className={css.infoContainer}>
      <h3 className={css.infoHeader}>Details</h3>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          {isSignalScoreEnabled && (
            <PropertyInline
              title={isSignalScoreEnabled ? "Signal score" : "Signals"}
              value={
                <NewRelevanceScore
                  relevanceScore={signalScore}
                  popoverPlacement="bottomLeft"
                  id={id}
                  entityType={SignalEntityType.Document}
                />
              }
            />
          )}
          <PropertyInline
            title="Buyer(s)"
            value={<OrganisationsContainer orgs={buyers} orgType="buyers" />}
          />
          <PropertyInline title="Type" value={documentTypeMap[type] ?? "--"} />
          <PropertyInline
            title="Published"
            value={publishDate ? publishDate.split("T")[0] : "--"}
          />
          <PropertyInline
            title="Source(s)"
            value={<Sources sources={sources} />}
            valueClassName={sources.length ? css.ellipsis : undefined}
          />
        </>
      )}
    </div>
  );
}

function Sources({ sources }: { sources: string[] }) {
  const sourcesTotal = sources.length;

  const popoverContent = (
    <div className={css.popoverContent}>
      {sources.map((source) => (
        <a href={source} target="_blank" className={(css.link, css.ellipsis)}>
          {source}
        </a>
      ))}
    </div>
  );

  if (sources.length === 0) {
    return <span>--</span>;
  }

  if (sources.length === 1) {
    return (
      <a href={sources[0]} target="_blank" className={css.link}>
        {sources[0]}
      </a>
    );
  }

  return (
    <Popover
      placement="bottom"
      title={<div>{`${sourcesTotal} sources`}</div>}
      content={popoverContent}
    >
      {<span>{`${sourcesTotal} sources`}</span>}
    </Popover>
  );
}
