import React, { ReactNode } from "react";

import { sysPrimaryDefault } from "../../themes/colors";

export interface UIconProps {
  svg: ReactNode;
  colour?: string;
  size?: number; // in px
  className?: string;
}

// untitled_ui icon generic component
function UIcon({ svg, colour = sysPrimaryDefault, size = 24, className }: UIconProps) {
  return (
    <div style={{ color: colour, width: size, height: size }} className={className}>
      {svg}
    </div>
  );
}

export default UIcon;
