import * as React from "react";
import { CloseCircleFilled } from "@ant-design/icons";
// eslint-disable-next-line no-restricted-imports
import { Button, Calendar, Popover, Select, Tabs } from "antd"; // dodgy global styles here
import { RenderHeader } from "antd/lib/calendar/Header";
import moment from "moment";

import { FilterPill } from "./FilterPill";

import css from "./RelativeDatePicker.module.scss";

const { TabPane } = Tabs;

type DateType = "relative" | "specific";
//https://www.notion.so/stotles/0022-Relative-dates-c1c9cf427844485cabceade99fc55df9#3bc88486058b4a738accb55deca71478

export const TODAY = "PT0S";

const PAST_OPTIONS: Record<string, string> = {
  "P-7D": "1 week ago",
  "P-1M": "1 month ago",
  "P-3M": "3 months ago",
  "P-6M": "6 months ago",
  "P-1Y": "1 year ago",
  "P-2Y": "2 years ago",
  "P-3Y": "3 years ago",
  "P-4Y": "4 years ago",
  "P-5Y": "5 years ago",
};

const FUTURE_OPTIONS: Record<string, string> = {
  P7D: "1 week from now",
  P1M: "1 month from now",
  P3M: "3 months from now",
  P6M: "6 months from now",
  P1Y: "1 year from now",
  P2Y: "2 years from now",
  P3Y: "3 years from now",
  P4Y: "4 years from now",
  P5Y: "5 years from now",
};

const RelativeOptions: Record<string, string> = {
  PT0S: "today",
  ...PAST_OPTIONS,
  ...FUTURE_OPTIONS,
};

/* Whole RelativeOptions but without "today", variable is just used to filter */
/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const { PT0S: _, ...optionsWithoutToday } = RelativeOptions;

type DatePickerPopoverProps = {
  value?: moment.Moment;
  relativeValue?: string;
  onSelect: (value?: moment.Moment, relativeValue?: string) => void;
  disabledDate?: (current: moment.Moment) => boolean;
  format?: string;
  disabled?: boolean;
  placeholder?: string;
  showFutureDates?: boolean;
};

export function DatePickerPopover({
  value,
  relativeValue,
  onSelect,
  disabledDate,
  disabled,
  format,
  placeholder,
  showFutureDates,
}: DatePickerPopoverProps): JSX.Element {
  const [activeTab, setActiveTab] = React.useState<DateType>("relative");
  const [visible, setVisible] = React.useState<boolean>(false);

  const onChange = React.useCallback(
    (value, relativeValue) => {
      onSelect(value, relativeValue);
    },
    [onSelect],
  );

  const clear = React.useCallback(
    (e: React.MouseEvent) => {
      onChange(undefined, undefined);
      e.stopPropagation();
    },
    [onChange],
  );

  return (
    <Popover
      content={
        <DatePicker
          value={value}
          relativeValue={relativeValue}
          onSelect={onChange}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          disabledDate={disabledDate}
          showFutureDates={showFutureDates}
        />
      }
      trigger={"click"}
      visible={visible}
      onVisibleChange={setVisible}
      overlayClassName={css.popoverOverlay}
      getPopupContainer={(trigger) => trigger.parentElement ?? document.body}
    >
      <Button disabled={disabled} className={css.trigger}>
        {getDateValue(value, relativeValue, format)}
        {value || relativeValue ? (
          <div onClick={clear}>
            <CloseCircleFilled />
          </div>
        ) : (
          <span className={css.placeholder}>{placeholder}</span>
        )}
      </Button>
    </Popover>
  );
}

type DatePickerProps = {
  activeTab: DateType;
  setActiveTab: (tab: DateType) => void;
} & DatePickerPopoverProps;

function DatePicker({
  value,
  relativeValue,
  onSelect,
  activeTab,
  setActiveTab,
  disabledDate,
  showFutureDates,
}: DatePickerProps) {
  if (relativeValue && !value) {
    value = moment().startOf("day").add(moment.duration(relativeValue));
  }

  function getRelativeOptions(options: Record<string, string>) {
    return Object.entries(options).map(([duration, name]) => {
      const disabled =
        disabledDate && disabledDate(moment().startOf("day").add(moment.duration(duration)));
      return (
        <FilterPill
          key={duration}
          onClick={() => onSelect(moment().startOf("day").add(duration), duration)}
          disabled={disabled}
          selected={relativeValue === duration}
        >
          {name}
        </FilterPill>
      );
    });
  }

  const pastOptions = getRelativeOptions(PAST_OPTIONS);
  const futureOptions = getRelativeOptions(FUTURE_OPTIONS);

  return (
    <>
      <Tabs
        className={css.popover}
        activeKey={activeTab}
        onChange={(t) => setActiveTab(t as DateType)}
      >
        <TabPane tab="Relative date" key="relative">
          <div className={css.relativeDatePicker}>
            <div className={css.now}>
              <span>Now</span>
              <FilterPill
                onClick={() => onSelect(moment().startOf("day"), TODAY)}
                disabled={disabledDate && disabledDate(moment().startOf("day"))}
                selected={relativeValue == TODAY}
              >
                Today
              </FilterPill>
            </div>
            <div className={css.pastAndFuture}>
              <div className={css.column}>
                <span>Past</span>
                {pastOptions}
              </div>
              {showFutureDates && (
                <div className={css.column}>
                  <span>Future</span>
                  {futureOptions}
                </div>
              )}
            </div>
          </div>
        </TabPane>
        <TabPane tab="Specific date" key="specific">
          <Calendar
            fullscreen={false}
            value={value || moment()}
            onSelect={onSelect}
            onChange={onSelect}
            disabledDate={disabledDate}
            headerRender={DatePickerHeader}
          />
        </TabPane>
      </Tabs>
    </>
  );
}

// Custom calendar header
function DatePickerHeader({ value, onChange }: RenderHeader) {
  const start = 0;
  const end = 12;
  const monthOptions = [];

  const current = value.clone();
  const localeData = value.localeData();
  const months = [];
  for (let i = 0; i < 12; i++) {
    current.month(i);
    months.push(localeData.monthsShort(current));
  }

  for (let index = start; index < end; index++) {
    monthOptions.push(
      <Select.Option className="month-item" key={`${index}`}>
        {months[index]}
      </Select.Option>,
    );
  }
  const month = value.month();

  const year = value.year();
  const options = [];
  for (let i = year - 10; i < year + 10; i += 1) {
    options.push(
      <Select.Option key={i} value={i} className="year-item">
        {i}
      </Select.Option>,
    );
  }
  return (
    <div className={css.datePickerHeader}>
      <Select
        size="small"
        dropdownMatchSelectWidth={false}
        onChange={(newYear: number) => {
          const now = value.clone().year(newYear);
          if (onChange) {
            onChange(now);
          }
        }}
        value={year}
      >
        {options}
      </Select>
      <Select
        size="small"
        dropdownMatchSelectWidth={false}
        value={String(month)}
        onChange={(selectedMonth: string) => {
          const newValue = value.clone();
          newValue.month(parseInt(selectedMonth, 10));
          if (onChange) {
            onChange(newValue);
          }
        }}
      >
        {monthOptions}
      </Select>
    </div>
  );
}

function getDateValue(
  value?: moment.Moment,
  relativeValue?: string,
  format = "YYYY-MM-DD",
): string | undefined {
  if (relativeValue && RelativeOptions[relativeValue]) {
    return RelativeOptions[relativeValue];
  }
  return value?.format(format);
}
