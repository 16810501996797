import * as React from "react";
import { SelectProps } from "antd/lib/select";

import {
  AutocompleteMultiselect,
  LabeledValue,
} from "components/form_components/AutocompleteMultiselect";
import { SearchSuppliersResponse } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";
import { SupplierSummary } from "lib/types/models";
import { pluralise } from "lib/utils";

type Props = {
  supplierIds: number[] | undefined;
  onSupplierIdsChange: (newIds: number[]) => void;
  countries?: string[];
  id?: string;
  optionLabelProp?: SelectProps["optionLabelProp"];
  renderOption?: (r: SupplierSummary | LabeledValue<number>) => React.ReactElement;
  customPlaceholder?: string;
  noMaxTagCount?: boolean;
};

function SupplierMultiSelect(props: Props): JSX.Element {
  const api = useStotlesApi();
  const { supplierIds, countries, id, customPlaceholder } = props;

  const searchByText = React.useCallback(
    (text: string, limit?: number): Promise<SearchSuppliersResponse> =>
      api.searchSuppliers({
        sort: "relevance",
        text,
        country: countries,
        limit,
      }),
    [api, countries],
  );

  const searchByIds = React.useCallback((ids: number[]) => api.searchSuppliers({ id: ids }), [api]);

  const onSupplierIdsChange = React.useCallback(
    (newIds: (number | string)[]) => {
      const ids = newIds.map((id) => (typeof id === "string" ? parseInt(id) : id));

      props.onSupplierIdsChange(ids);
    },
    [props],
  );

  return (
    <AutocompleteMultiselect<SupplierSummary, SearchSuppliersResponse, number>
      getIdentifier={(s) => s.id}
      itemIds={supplierIds || []}
      onItemsChange={onSupplierIdsChange}
      searchByText={searchByText}
      searchByIds={searchByIds}
      placeholder={customPlaceholder || "Search Suppliers..."}
      displayedAttr="name"
      narrowSearchLabel={(numMatching) =>
        `${pluralise(numMatching, "supplier")} found, narrow search to add all`
      }
      id={id}
      {...props}
    />
  );
}

export default SupplierMultiSelect;
