import React from "react";
import { hot } from "react-hot-loader/root";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsContent, DetailsHeader, DetailsPage } from "components/app_layout/DetailsLayout";
import { FilterableDocuments } from "components/documents/FilterableDocuments";
import { DEFAULT_DOCUMENT_FILTERS } from "components/documents/utils";
import { BetaIcon } from "lib/core_components/Labels";

import css from "./DocumentDataPage.module.scss";

export function DocumentDataPage() {
  return (
    <DetailsPage>
      <DetailsHeader>
        <h1 className={css.title}>
          Documents <BetaIcon />
        </h1>
      </DetailsHeader>
      <DetailsContent className={css.content}>
        <FilterableDocuments defaultFilters={{ ...DEFAULT_DOCUMENT_FILTERS }} />
      </DetailsContent>
    </DetailsPage>
  );
}

export default hot(
  withAppLayout(DocumentDataPage, {
    pageName: "Document Data",
  }),
);
