/* tslint:disable */
/* eslint-disable */
/**
 * Stotles Admin API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const DataSource = {
    TendersPreTenders: 'TENDERS|PRE-TENDERS',
    Awards: 'AWARDS',
    BuyersSuppliers: 'BUYERS|SUPPLIERS',
    News: 'NEWS',
    Contacts: 'CONTACTS',
    Frameworks: 'FRAMEWORKS'
} as const;
export type DataSource = typeof DataSource[keyof typeof DataSource];


export function DataSourceFromJSON(json: any): DataSource {
    return DataSourceFromJSONTyped(json, false);
}

export function DataSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): DataSource {
    return json as DataSource;
}

export function DataSourceToJSON(value?: DataSource | null): any {
    return value as any;
}

