import { DateTime } from "luxon";

import { Address } from "lib/generated/app-api";
import * as tracking from "lib/tracking";

const NUMBER_FORMAT = new Intl.NumberFormat(undefined);

const NUMBER_FORMAT_COMPACT = new Intl.NumberFormat(undefined, {
  notation: "compact",
  maximumFractionDigits: 1,
});

export const EM_DASH = "–";

export const EMPTY_ARRAY = [];

type RemoveUnderscoreFirstLetter<S extends string> = S extends `${infer FirstLetter}${infer U}`
  ? `${FirstLetter extends "_" ? U : `${FirstLetter}${U}`}`
  : S;

type CamelToSnakeCase<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? "_" : ""}${RemoveUnderscoreFirstLetter<
      Lowercase<T>
    >}${CamelToSnakeCase<U>}`
  : S;

export type KeysToSnakeCase<T extends Record<string, unknown>> = {
  [K in keyof T as CamelToSnakeCase<string & K>]: T[K] extends Array<infer U>
    ? U extends Record<string, unknown>
      ? Array<KeysToSnakeCase<U>>
      : Array<U>
    : T[K] extends Record<string, unknown>
      ? KeysToSnakeCase<T[K]>
      : T[K];
};

export function formatAmount(
  amount: number | string | null | undefined,
  currency?: string | null,
): string {
  if (amount === null || amount === undefined) return EM_DASH;
  if (typeof amount === "string") amount = parseInt(amount);

  const formatted = NUMBER_FORMAT.format(amount);

  if (!currency) {
    return `${formatted}`;
  } else if (isNaN(amount)) {
    return EM_DASH;
  }
  return `${formatted} ${currency}`;
}

/**
 * Formats date object to string by day, month name then full year
 * @param date
 * @returns
 */
export function formatDate(date: Date | null | undefined, locale = "en-GB"): string {
  if (date === null || date === undefined) return EM_DASH;

  return DateTime.fromJSDate(date).setLocale(locale).toFormat("d MMM, yyyy");
}

export function formatShortAmount(
  amount: number | string | null | undefined,
  currency?: string | null,
): string {
  if (amount === null || amount === undefined) return EM_DASH;
  if (typeof amount === "string") amount = parseInt(amount);

  const formatted = NUMBER_FORMAT_COMPACT.format(amount);

  if (isNaN(amount)) {
    return EM_DASH;
  } else if (!currency) {
    return formatted;
  }
  return `${formatted} ${currency}`;
}

export function getAddress(
  country?: string,
  address?: string,
  town?: string,
  postalCode?: string,
): string {
  const parts = [];
  if (address) {
    parts.push(address);
  }
  if (postalCode || town) {
    if (!postalCode) {
      parts.push(town);
    } else if (!town) {
      parts.push(postalCode);
    } else {
      parts.push(`${postalCode} ${town}`);
    }
  }
  if (country) {
    parts.push(country);
  }
  return parts.join(", ");
}

export function getCSRFToken(): string | undefined {
  const elems = document.getElementsByName("csrf-token");
  if (elems.length === 0) {
    return undefined;
  }
  const elem = elems.item(0);
  if (elem instanceof HTMLMetaElement) {
    return elem.content;
  }
  return undefined;
}

export function isDefined<T>(x: T): x is Exclude<T, undefined | null> {
  return x !== null && x !== undefined;
}

/**
 * Deeply checks not only if an object is defined, but if any of
 * its properties are defined as well
 * @param obj
 * @returns
 */
export function isEmptyDeep<T>(obj: T): boolean {
  // Check if the object itself is undefined or null
  if (isDefined(obj)) {
    return false;
  }

  // Check if it's an object (this includes arrays and excludes functions)
  if (typeof obj !== "object") {
    return false;
  }

  // Special case for empty arrays
  if (Array.isArray(obj) && obj.length === 0) {
    return true;
  }

  // Check if every property of the object is undefined or null
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (value !== undefined && value !== null) {
        // Special case for arrays - check if they are not empty
        if (Array.isArray(value) && value.length === 0) {
          continue;
        }
        return false;
      }
    }
  }

  // If we reached this point, the object is either empty or all properties are undefined or null
  return true;
}

export function pluralise(count: number, noun: string, formatNumber?: boolean): string {
  if (count != 1) {
    noun = `${noun}s`;
  }
  return `${formatNumber ? NUMBER_FORMAT.format(count) : count} ${noun}`;
}

export function irregularPlural(count: number, nounSingular: string, nounPlural: string): string {
  return count !== 1 ? nounPlural : nounSingular;
}

/**
 * With input @param date output the date in a yyyy-mm-dd format
 * @param date
 * @returns
 */
export function getFormattedDate(date: Date): string {
  return date.toISOString().split("T")[0];
}

/**
 * Resets a date to the start of day midnight. Essentially strips time from the JS date object
 * @param date
 * @returns
 */
export function startOfDay(date: Date): Date {
  date.setHours(0, 0, 0, 0); // Sets hours, minutes, seconds, and milliseconds to zero
  return date;
}

export function openIntercom(triggeredFrom: string): void {
  if (typeof Intercom === "undefined") {
    window.location.href = "https://stotles.com/#chat";
  }
  Intercom("show");
  tracking.logEvent(tracking.EventNames.clickedLetsChat, {
    "Chat triggered from": triggeredFrom,
  });
}

export function linkedinPeopleSearchURL(...keywords: (string | null)[]): string {
  const query = keywords.filter(isDefined).join(" ").replace(" ", "%20");
  return `https://www.linkedin.com/search/results/people/?keywords=${query}`;
}

export function recordDetailsUrl(guid: string): string {
  return `/records/${guid}`;
}

export function stopEventPropagation<E extends React.MouseEvent | React.TouchEvent>(
  handler?: (e: E) => void,
): (e: E) => void {
  return (e: E) => {
    e.stopPropagation();
    if (handler) handler(e);
  };
}

export const defaultStopEventPropagation = stopEventPropagation();

export function removeUndefined(r: Record<string, unknown>): void {
  for (const key of Object.keys(r)) {
    if (r[key] === undefined) {
      delete r[key];
    }
  }
}

export function downloadFile(blob: Blob, fileName: string): void {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  // Some browsers require the link to be added to the document
  document.body.append(link);
  link.click(); // this actually triggers the download
  link.remove();
  URL.revokeObjectURL(link.href);
}

/**
 * Provide a link to download the file. Avoids pesky issues with popup blocker
 * @param href
 * @param fileName
 */
export function downloadFileFromLink(href: string, fileName: string): void {
  const link = document.createElement("a");
  link.href = href;
  link.download = fileName;
  // Some browsers require the link to be added to the document
  document.body.append(link);
  link.click(); // this actually triggers the download
  link.remove();
  URL.revokeObjectURL(link.href);
}

/**
 * Creates a `setState`-like function that lets you set a specific field on a state object.
 * Use this when your state is an object which fields are often updated separately.
 * Takes same parameters as `setState` (value or update function) and doesn't trigger
 * an update if the value of the field didn't change.
 *
 * Example:
 *
 * const [state, setState] = React.useState<{ fieldA: number, fieldB: SomeType }>(...);
 * const updateFieldA = React.useMemo(() => createSetNestedState('fieldA', setState));
 *
 * // somewhere else in code
 * updateFieldA(5);
 *
 * @param field - the key of a field in `State`
 * @param setState - usually the `set*` function returned by `useState` hook
 */
export function createSetNestedState<State, Key extends keyof State>(
  field: Key,
  setState: React.Dispatch<React.SetStateAction<State>>,
): React.Dispatch<React.SetStateAction<State[Key]>> {
  return (valueOrUpdateFn) => {
    return setState((oldState) => {
      const oldFieldState = oldState[field];
      let newState: State[Key];
      if (typeof valueOrUpdateFn === "function") {
        // We can't fully assert that `value` in `valueOrUpdateFn` won't be a function
        // so TS can't 100% check that this is a correct call.
        newState = (valueOrUpdateFn as (oldState: State[Key]) => State[Key])(oldFieldState);
      } else {
        newState = valueOrUpdateFn;
      }
      if (newState === oldFieldState) {
        return oldState;
      } else {
        return {
          ...oldState,
          [field]: newState,
        };
      }
    });
  };
}

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const sentenceCaseWithExceptions = (s: string, exceptions: RegExp): string => {
  const sentenceArray = s.split(" ");
  const firstWord = sentenceArray.shift() as string;
  // shift permanently removes the first word in the array so now we have firstWord on it's own and
  // the rest of the sentence is in the array
  const formattedRest = sentenceArray.map((w) => (w.match(exceptions) ? w : w.toLowerCase()));
  const formattedFirstWord = firstWord.match(exceptions) ? firstWord : capitalize(firstWord);
  return [formattedFirstWord, ...formattedRest].join(" ");
};

export const sentenceCaseWithAcronyms = (s: string): string => {
  return sentenceCaseWithExceptions(s, /^[^a-z]+$/);
};

export function convertCamelCaseToSpaceSeparatedWords(field: string) {
  const words = field.replace(/([a-z])([A-Z])/g, "$1 $2");
  return words;
}

export function mapIterable<T, U>(iterable: IterableIterator<T>, fn: (item: T) => U): U[] {
  const result: U[] = [];
  for (const item of iterable) {
    result.push(fn(item));
  }
  return result;
}

export function filterIterable<T>(iterable: IterableIterator<T>, fn: (item: T) => boolean): T[] {
  return Array.from(iterable).filter(fn);
}

export function assertDefined<T>(value: T | null | undefined): asserts value is T {
  if (value == null) {
    throw new Error(`Fatal error: value ${value} must not be null/undefined.`);
  }
}

export function joinToEnglishList(words: string[], exclusiveList?: boolean): string {
  const connectiveWord = exclusiveList ? "or" : "and";
  switch (words.length) {
    case 0:
      return "";
    case 1:
      return words[0];
    case 2:
      return words.join(` ${connectiveWord} `);
    default:
      return words.slice(0, -1).join(", ") + ` ${connectiveWord} ${words[words.length - 1]}`;
  }
}

const ELLIPSIS = "…";

export function truncate(str: string, length: number): string {
  if (str.length > length) {
    return str.slice(0, length) + ELLIPSIS;
  } else {
    return str;
  }
}

export function assert<T>(value: T | null | undefined): asserts value is T {
  if (value === null || value === undefined) {
    throw new Error("Expected value to be defined.");
  }
}

export function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export function formatAddress(address: Address) {
  return [address.address, address.address2, address.address3, address.town, address.countryCode]
    .filter(isDefined)
    .join(", ");
}

export function* deduplicateByKey<T>(
  iterable: Iterable<T>,
  extractKey: (element: T) => string,
): IterableIterator<T> {
  const seenKeys = new Set<string>();

  for (const element of iterable) {
    const elementKey = extractKey(element);
    const isDuplicate = seenKeys.has(elementKey);

    if (!isDuplicate) {
      yield element;
    }

    seenKeys.add(elementKey);
  }
}

/**
 * Checks if all values in the given object are `undefined` or the object itself is undefined / null.
 *
 * @param {object | undefined | null} obj - The object to check.
 * @returns {boolean} `true` if all values in the object are `undefined`, or if the object itself is `undefined` / null; otherwise, `false`.
 */
export function isObjEmpty(obj: object | undefined | null): boolean {
  if (!obj || obj === null) return true;
  return Object.values(obj).every((value) => value === undefined);
}

/**
 * Checks if every element of array a is in array b
 * @param a
 * @param b
 * @returns {boolean} `true` if all elements of array a are in array b; otherwise, `false`.
 */
export function isArrayInArray(a: string[], b: string[]): boolean {
  const setB = new Set(b);
  return a.every((item) => setB.has(item));
}

/**
 * Converts a camelCase string to a sentence with spaces between words and capitalizes the first letter of first word
 *
 * @param camelCaseStr - The camelCase string to convert
 * @returns {string} The sentence with spaces between words and the first letter of the first word capitalized
 */
export function camelToSentence(camelCaseStr: string): string {
  // Split the camelCase string into words using a regular expression
  const words = camelCaseStr.match(/[A-Z]?[a-z]+|[A-Z]+(?=[A-Z]|$)/g);

  if (!words) {
    return camelCaseStr;
  }

  const sentence = words.join(" ").toLowerCase();
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

/**
 * In an object of keys -> string arrays, finds the key that contains the given string
 *
 * @param value - The string to find in one of the keys of the object
 * @param object - The object of keys with string arrays e.g { key1: ['value1', 'value2'], key2: ['value3'] }
 * @returns {string} The key that contains the given string
 */
export function findKeybyValue(value: string | undefined, object: Record<string, string[]>) {
  if (!value) return undefined;

  return Object.keys(object).find((key) => object[key].includes(value));
}
