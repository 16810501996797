import * as React from "react";
import { useMemo, useState } from "react";
import { Select, Tag } from "antd5";
import classnames from "classnames";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { DefaultOptionType } from "rc-select/lib/Select";

import { MatchCountMap } from "components/onboarding/SharedOnboarding";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { useDebouncedValue } from "lib/debounce";
import { useSupplierSearch } from "lib/hooks/api/suppliers/useSupplierSearch";
import { isDefined } from "lib/utils";

import css from "./SupplierSignalSelect.module.scss";

type Props = {
  countries?: string[];
  supplierCounts?: MatchCountMap;
  supplierType: "partner" | "competitor";
  supplierGuids: string[];
  onSupplierGuidsChange: (newIds: string[]) => void;
};

function SupplierSignalSelect({
  supplierCounts,
  supplierType,
  countries,
  supplierGuids,
  onSupplierGuidsChange,
}: Props): JSX.Element {
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText] = useDebouncedValue(searchText, 300);

  const showInitialSuppliers = supplierGuids && supplierGuids.length > 0 && !debouncedText;
  const { data: initialSuppliers, isLoading: isLoadingInitial } = useSupplierSearch(
    {
      guid: supplierGuids,
      country: countries,
    },
    {
      enabled: showInitialSuppliers,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  const { data, isLoading } = useSupplierSearch(
    {
      sort: "relevance",
      text: debouncedText || "a",
      limit: 100,
      country: countries,
    },
    { enabled: !showInitialSuppliers },
  );

  const options = useMemo((): DefaultOptionType[] | undefined => {
    const options: DefaultOptionType[] = [];

    if (showInitialSuppliers) {
      const optionsToAdd =
        initialSuppliers?.results.map((supplier) => ({
          label: supplier.name,
          value: supplier.guid,
        })) || [];

      options.push(...optionsToAdd);
    } else {
      const optionsToAdd =
        data?.results.map((supplier) => ({
          label: supplier.name,
          value: supplier.guid,
        })) || [];

      options.push(...optionsToAdd);
    }

    // This logic determines whether or not we add a select all matching label
    if (
      options.length < 100 &&
      !options.every((option) => supplierGuids.includes(option.value as string))
    ) {
      const selectAllOption: DefaultOptionType = {
        label: <span className={css.addAllSelectOption}>Add {options.length} matching</span>,
        value: "ALL",
      };

      return [selectAllOption, ...options];
    } else {
      return options;
    }
  }, [data?.results, initialSuppliers?.results, showInitialSuppliers, supplierGuids]);

  const loading =
    (isLoading && !showInitialSuppliers && !data) ||
    (isLoadingInitial && showInitialSuppliers && !initialSuppliers);

  const renderTag = React.useCallback(
    ({ label, value: supplierGuid }: CustomTagProps) => {
      const supplierName = label?.toString();

      const supplierCount = supplierName ? supplierCounts?.[supplierName] : undefined;

      return (
        <Tag
          key={supplierGuid}
          closable
          onClose={() => onSupplierGuidsChange(supplierGuids.filter((g) => g !== supplierGuid))}
          className={classnames(
            css.choice,
            supplierType === "partner" ? css.partner : css.competitor,
          )}
        >
          <EllipsisTooltipText
            fullText={`${supplierName} ${supplierCount === undefined ? "" : `(${supplierCount})`}`}
            containerClassname={css.selectedItem}
          />
        </Tag>
      );
    },
    [onSupplierGuidsChange, supplierCounts, supplierGuids, supplierType],
  );

  return (
    <div>
      <Select
        mode="multiple"
        loading={loading}
        onChange={(newVals) => {
          let value: string[] = newVals;
          if (newVals.includes("ALL") && options) {
            value = [...newVals.filter((v) => v !== "ALL")];
            const optionValues = options
              .map((option) => option.value?.toString())
              .filter(isDefined);
            value = [...value, ...optionValues];
            value = [...new Set(value)];
          }
          onSupplierGuidsChange(value);
        }}
        onSearch={setSearchText}
        value={supplierGuids}
        style={{ width: "100%" }}
        placeholder="Type organisation names (e.g. Google, Amazon)..."
        tokenSeparators={[",", "\n", ";"]}
        filterOption={(input, option) => {
          // Only filtering actual options, not the label "Add n matching"
          if (typeof option?.label === "string") {
            return option.label.toString().toLowerCase().startsWith(input.toLowerCase());
          } else {
            return true;
          }
        }}
        options={options}
        tagRender={renderTag}
      />
    </div>
  );
}

export default SupplierSignalSelect;
