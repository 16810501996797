import React from "react";
import { DoubleRightOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Drawer, Tabs } from "antd5";

import { DocumentFilters } from "components/documents/utils";
import { PdfViewerContainer } from "lib/core_components/PdfViewerContainer";
import { useDocument } from "lib/hooks/api/useDocument";
import { PdfViewerContextProvider } from "../../lib/core_components/PdfViewer";
import DocumentHeader from "./DocumentHeader";
import { DocumentViewerFilters } from "./FilterForm";
import { createTabItems, TabsConfig } from "./utils";

import css from "./DocumentDrawer.module.scss";
import tabCss from "./utils.module.scss";

type DocumentDrawerProps = {
  documentId?: string;
  filters: DocumentFilters;
  onClose: () => void;
};

type DocumentViewerProps = {
  documentId: string;
  filters: DocumentFilters;
};

export default function DocumentDrawer({ documentId, filters, onClose }: DocumentDrawerProps) {
  const { data, isError } = useDocument(documentId ?? "", {
    enabled: !!documentId,
  });

  return (
    <Drawer
      open={!!documentId}
      onClose={onClose}
      styles={{
        body: { padding: 0 },
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: this property does exist in ant but types are not right
      width={1200}
      closeIcon={null}
    >
      <div className={css.previewWrapper}>
        <div className={css.controls}>
          <Button
            title="Close"
            icon={<DoubleRightOutlined className={css.closeIcon} />}
            onClick={onClose}
            className={css.controlsBtn}
          />
        </div>
        {isError ? (
          <div className={css.error}>Sorry, something has gone wrong.</div>
        ) : (
          <div className={css.previewContent}>
            <DocumentHeader
              documentId={documentId ?? ""}
              title={data?.title ?? ""}
              downloadUrl={data?.downloadUrl ?? ""}
            />
            {documentId && <DocumentViewer documentId={documentId} filters={filters} />}
          </div>
        )}
      </div>
    </Drawer>
  );
}

function DocumentViewer({ documentId, filters }: DocumentViewerProps) {
  const { data, isLoading, isFetching } = useDocument(documentId);

  const defaultFilters: DocumentViewerFilters = {
    keywords: filters.keywords ?? [],
  };

  const isSearchDisabled = !isLoading && !data?.pdfDocumentUrl;

  const tabItems = createTabItems(documentId, isLoading, isSearchDisabled, defaultFilters, data);

  return (
    <PdfViewerContextProvider>
      <div className={css.container}>
        <div className={css.columns}>
          <PdfViewerContainer
            documentUrl={data?.pdfDocumentUrl ?? ""}
            downloadUrl={data?.downloadUrl ?? ""}
            isLoading={isFetching}
          />
          <ConfigProvider theme={TabsConfig}>
            <Tabs
              defaultActiveKey="search"
              size="large"
              items={tabItems}
              className={tabCss.toolbar}
            />
          </ConfigProvider>
        </div>
      </div>
    </PdfViewerContextProvider>
  );
}
