import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { NoticesQuery, NoticesSearchRequest } from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

export type NoticeSearchQueryKey = ["notices", NoticesSearchRequest];

const searchNoticesQuery = graphql(`
  query notices($request: NoticesSearchRequest!) {
    notices(NoticesSearchRequest: $request) {
      results {
        score
        guid
        procurementStage {
          id
          stage
        }
        country
        stage
        name
        entityType
        currency
        value
        publishDate
        closeDate
        awardDate
        expiryDate
        url
        signals {
          name
          category
        }
        buyers {
          id
          guid
          name
          categoryAssignments {
            buyerCategoryID
            source
          }
        }
        qualification
        suppliers {
          id
          name
          guid
          isSme
        }
        lists {
          id
          name
        }
        assignee {
          guid
          firstName
          lastName
        }
        commentCount
        seenByUsers {
          firstName
          guid
        }
        relatedFrameworkProcess {
          frameworkId
          processType
        }
      }
      totalResults
    }
  }
`);

type NoticesSearchResponse = NoticesQuery["notices"];
export type NoticesDto = NoticesSearchResponse["results"][0];

export function useNotices(
  searchNoticesRequest: NoticesSearchRequest,
  options?: UseQueryOptions<NoticesQuery, unknown, NoticesQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["notices", searchNoticesRequest],
    searchNoticesQuery,
    [{ request: searchNoticesRequest }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return {
    data: {
      ...data,
      results: data?.notices?.results ?? [],
      pagingInfo: { totalResults: data?.notices?.totalResults ?? 0 },
    },
    ...rest,
  };
}
