import React, { HTMLAttributes } from "react";

import css from "./badge.module.scss";

export interface BadgeProps extends HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  onClick?: () => void;
  icon?: React.ReactNode;
  iconOnClick?: () => void;
}

function Badge({ children, onClick, icon, iconOnClick, ...rest }: BadgeProps) {
  function stopPropagation(e: React.MouseEvent) {
    e.stopPropagation();
    iconOnClick?.();
  }

  return (
    <div className={css.wrapper} onClick={onClick} {...rest}>
      {children}
      {icon && (
        <div className={css.iconWrapper} onClick={(e) => stopPropagation(e)}>
          {icon}
        </div>
      )}
    </div>
  );
}

export default Badge;
