import React from "react";
import {
  CaretDownOutlined,
  CopyOutlined,
  DownOutlined,
  EditOutlined,
  PushpinOutlined,
  SaveOutlined,
} from "@ant-design/icons";
// TODO: need some css work on the antd5 menu to not look janky
import { Menu } from "antd"; // upgrade and verify
import { Button, message } from "antd5";
import isEqual from "lodash.isequal";

import { convertNoticeFiltersToView } from "components/notices/utils";
import DropdownMenu from "lib/core_components/DropdownMenu";
import { SavedViewType } from "lib/generated/app-api";
import { assert } from "lib/utils";
import { useUnpinView } from "../../../lib/hooks/api/notices/views/useUnpinView";
import { useUpdateView } from "../../../lib/hooks/api/notices/views/useUpdateView";
import { EMPTY_FILTERS, useMyFeedPageState } from "../useMyFeedPageState";
import { AlertButton } from "./AlertButton";
import { CreateViewModal } from "./CreateViewModal";
import { DuplicateViewModal } from "./DuplicateViewModal";
import { EditViewModal } from "./EditViewModal";

import css from "./ViewOptions.module.scss";

type ViewOptionsProps = {
  selectedView?: SavedViewType;
};

function ViewOptions({ selectedView }: ViewOptionsProps) {
  const [messageApi, contextHolder] = message.useMessage();
  const { filters, hasUserModifiedView, tableColumns, setSelectedView } = useMyFeedPageState();
  const { mutate: unpinViewMutation } = useUnpinView();
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = React.useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);

  const { mutate: mutateView } = useUpdateView("Update view dropdown", {
    onSuccess: async (_data, vars) => {
      await messageApi.success(`Successfully updated ${vars.view.name}`);
    },
    onError: async (_data, vars) => {
      await messageApi.error(`Failed to update ${vars.view.name}`);
    },
  });

  const moreMenu = selectedView && (
    <Menu>
      <Menu.Item
        onClick={() => {
          assert(selectedView.id);
          unpinViewMutation({ id: selectedView.id, name: selectedView.name });
        }}
      >
        <PushpinOutlined /> Unpin view
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setIsDuplicateModalOpen(true);
        }}
      >
        <CopyOutlined /> Duplicate view
      </Menu.Item>
      <Menu.Item
        onClick={() => {
          setIsEditModalOpen(true);
        }}
      >
        <EditOutlined /> Edit view
      </Menu.Item>
    </Menu>
  );

  const saveMenu = (
    <Menu>
      <Menu.Item
        disabled={!selectedView || selectedView.isStandardView || !hasUserModifiedView}
        onClick={() => {
          assert(selectedView);
          const convertedViewToFilter = convertNoticeFiltersToView(filters, tableColumns);
          mutateView({
            view: {
              ...selectedView,
              view: convertedViewToFilter,
            },
          });
        }}
      >
        Update current view
      </Menu.Item>
      <Menu.Item
        disabled={!hasUserModifiedView || isEqual(filters, EMPTY_FILTERS)}
        onClick={() => {
          setIsCreateModalOpen(true);
        }}
      >
        Save as new view
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={css.otherButtonContainer}>
      {selectedView && (
        <>
          <EditViewModal
            isOpen={isEditModalOpen}
            onClose={() => setIsEditModalOpen(false)}
            selectedView={selectedView}
          />
          <DuplicateViewModal
            isOpen={isDuplicateModalOpen}
            onClose={() => setIsDuplicateModalOpen(false)}
            selectedView={selectedView}
            onSuccess={(id) => setSelectedView(id, "", "Duplicate view modal")}
          />
          <DropdownMenu
            overlay={moreMenu}
            trigger={["hover"]}
            className={css.moreInfoDropdown}
            placement="bottomRight"
          >
            <Button onClick={(e) => e.stopPropagation()}>
              More <CaretDownOutlined className={css.dropdownArrow} />
            </Button>
          </DropdownMenu>
        </>
      )}
      {selectedView?.id && (
        <AlertButton selectedView={selectedView} hasUserModifiedView={hasUserModifiedView} />
      )}
      <DropdownMenu overlay={saveMenu} trigger={["hover"]} placement="bottomRight">
        <Button type="primary" onClick={(e) => e.stopPropagation()}>
          <SaveOutlined /> Save view <DownOutlined className={css.dropdownArrow} />
        </Button>
      </DropdownMenu>
      <CreateViewModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        selectedView={selectedView}
      />
      {contextHolder}
    </div>
  );
}

export default ViewOptions;
