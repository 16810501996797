import React from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Core } from "@pdftron/webviewer";
import { Skeleton } from "antd5";
import classNames from "classnames";

import { SimpleMatchHighlighter } from "lib/core_components/TextMatchHighlighter";

import css from "./DocumentSearchResults.module.scss";

import MAGNIFYING_GLASS from "../../../assets/images/icons/magnifying_glass_detailed.svg";

export type SearchResultSnippet = Core.Search.SearchResult & { originalIndex: number };

type DocumentSearchResults = {
  activeResultIndex: number;
  setActiveResultIndex: (index: number) => void;
  searchResults: SearchResultSnippet[];
  onJumpToSearchResult: (result: SearchResultSnippet) => void;
  isLoading?: boolean;
  filterCount: number;
};

export default function DocumentSearchResults({
  activeResultIndex,
  setActiveResultIndex,
  searchResults,
  onJumpToSearchResult,
  isLoading,
  filterCount,
}: DocumentSearchResults) {
  const handleSetActiveSearchResult = (index: number, result: SearchResultSnippet) => {
    setActiveResultIndex(index);
    onJumpToSearchResult(result);
  };

  const jumpToPreviousMatch = () => {
    const index = activeResultIndex - 1;

    if (index >= 0) {
      const searchResult = searchResults[index];
      handleSetActiveSearchResult(index, searchResult);
    }
  };

  const jumpToNextMatch = () => {
    const index = activeResultIndex + 1;

    if (index < searchResults.length) {
      const searchResult = searchResults[index];
      handleSetActiveSearchResult(index, searchResult);
    }
  };

  return (
    <div className={css.results}>
      <div className={css.header}>
        <h3 className={css.count}>{searchResults.length} Results</h3>
        <div className={css.arrows}>
          <UpOutlined
            onClick={jumpToPreviousMatch}
            className={classNames(css.arrow, {
              [css.disabled]: !searchResults.length || activeResultIndex === 0,
            })}
          />
          <DownOutlined
            onClick={jumpToNextMatch}
            className={classNames(css.arrow, {
              [css.disabled]:
                !searchResults.length || activeResultIndex === searchResults.length - 1,
            })}
          />
        </div>
      </div>
      {filterCount > 0 ? (
        <SearchResultsContainer
          isLoading={isLoading}
          searchResults={searchResults}
          handleSetActiveSearchResult={handleSetActiveSearchResult}
          activeResultIndex={activeResultIndex}
        />
      ) : (
        <div className={css.emptyState}>
          <img src={MAGNIFYING_GLASS} alt="Magnifying glass" />
          <h3>Search for some keywords to get started</h3>
          <p>Matches in this document will appear here.</p>
        </div>
      )}
    </div>
  );
}

function SearchResultsContainer({
  isLoading,
  searchResults,
  handleSetActiveSearchResult,
  activeResultIndex,
}: {
  isLoading?: boolean;
  searchResults: SearchResultSnippet[];
  handleSetActiveSearchResult: (index: number, result: SearchResultSnippet) => void;
  activeResultIndex: number;
}) {
  const searchResultsByPage = searchResults.reduce<Record<number, SearchResultSnippet[]>>(
    (acc, result) => {
      const pageNum = result.pageNum;
      if (!acc[pageNum]) {
        acc[pageNum] = [];
      }
      acc[pageNum].push(result);
      return acc;
    },
    {},
  );

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <>
      {Object.entries(searchResultsByPage).map(([pageNum, results], index) => (
        <div key={index} className={css.flexColumn}>
          <h3 className={css.label}>Page {pageNum}</h3>
          <div className={css.flexColumn}>
            {results.map((result) => (
              <div
                key={`${result.originalIndex}`}
                onClick={() => handleSetActiveSearchResult(result.originalIndex, result)}
                className={classNames(css.snippet, {
                  [css.activeSnippet]: activeResultIndex === result.originalIndex,
                })}
                data-testid="result-snippet"
              >
                <SimpleMatchHighlighter
                  text={result.ambientStr}
                  matches={[{ start: result.resultStrStart, end: result.resultStrEnd }]}
                  highlightClassName={css.highlight}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
}
