import React, { useEffect, useState } from "react";
import { Form, List, message, Modal, Radio, Select, Skeleton } from "antd"; // bad leaky global styles, upgrade message to new context

import UserInitials from "components/comments/UserInitials";
import { BuyerListDto } from "lib/generated/app-api";
import { useBuyerListPermissions } from "lib/hooks/api/buyer_lists/useBuyerListPermissions";
import {
  AssignedRole,
  useUpdateBuyerListPermissions,
} from "lib/hooks/api/buyer_lists/useUpdateBuyerListPermissions";
import { useUsers } from "lib/hooks/api/useUsers";

import css from "./BuyerListPermissionsModal.module.scss";

const roles = [
  { value: "buyer_list_viewer", label: "can view" },
  { value: "buyer_list_editor", label: "can edit" },
  { value: "buyer_list_admin", label: "admin" },
];

export type BuyerListPermissionsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  list: BuyerListDto;
};

function BuyerListPermissionsModal({ isOpen, onClose, list }: BuyerListPermissionsModalProps) {
  const { isLoading, mutate } = useUpdateBuyerListPermissions({
    onSuccess: () => {
      void message.success("Buyer list updated successfully!");
      onClose();
    },
    onError() {
      void message.error("Failed to update buyer list");
    },
  });
  const { data: permissions, isLoading: isLoadingPermissions } = useBuyerListPermissions(list.id);
  const { data: users } = useUsers();
  const [usersWithRoles, setUsersWithRoles] = useState<AssignedRole[]>([]);
  const [originalUserRoles, setOriginalUserRoles] = useState<AssignedRole[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);

  useEffect(() => {
    if (permissions && users) {
      const defaultSelectedUsers: number[] = [];
      const userRoles: AssignedRole[] = [];
      permissions.forEach((perm) => {
        if (perm.accessorType === "User") {
          const userIndex = users.findIndex((user) => user.guid === perm.accessorId);

          if (userIndex > -1) {
            defaultSelectedUsers.push(userIndex);
            userRoles.push({
              accessorId: perm.accessorId,
              roleName: perm.roleName,
              firstName: users[userIndex].firstName,
              lastName: users[userIndex].lastName,
              email: users[userIndex].email,
            });
          }
        }
      });
      setSelectedUsers(defaultSelectedUsers);
      setUsersWithRoles(userRoles);
      setOriginalUserRoles(userRoles);
    }
  }, [permissions, users]);

  const onSubmit = () => {
    mutate({
      permissionUpdate: usersWithRoles,
      buyerListGuid: list.id,
      previousPermissions: originalUserRoles,
    });
  };

  function onUserSelect(userIndex: number) {
    if (users && users[userIndex]) {
      const index = usersWithRoles.findIndex((perm) => perm.accessorId === users[userIndex].guid);
      const updated = [...usersWithRoles];
      if (index > -1) {
        updated[index].roleName = "buyer_list_viewer";
      } else {
        updated.push({
          roleName: "buyer_list_viewer",
          accessorId: users[userIndex].guid,
          firstName: users[userIndex].firstName,
          lastName: users[userIndex].lastName,
          email: users[userIndex].email,
        });
      }
      setUsersWithRoles(updated);
    }
  }

  function onUserDeselect(userIndex: number) {
    if (users && users[userIndex]) {
      const updated = usersWithRoles.map((item) => {
        if (item.accessorId === users[userIndex].guid) {
          return {
            ...item,
            roleName: null,
          };
        }
        return item;
      });

      setUsersWithRoles(updated);
    }
  }

  if (list.sharingType === "TEAM") {
    return (
      <Modal onOk={onClose} onCancel={onClose} title={`Share "${list.name}"`} visible={isOpen}>
        <Form layout="vertical" hideRequiredMark>
          <Form.Item label="Shared with" help="Everyone can view and edit this list">
            <Radio.Group disabled value={list.sharingType}>
              <Radio value="PRIVATE">Private</Radio>
              <Radio value="TEAM">Team</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  return (
    <Modal
      title={`Share "${list.name}"`}
      visible={isOpen}
      onCancel={onClose}
      okText={isLoading ? "Updating..." : "Update"}
      onOk={onSubmit}
      okButtonProps={{ disabled: isLoading || selectedUsers.length === 0 }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <h4>People with access</h4>
      {isLoadingPermissions ? (
        <Skeleton paragraph={false} />
      ) : (
        <>
          <Select
            showSearch
            showArrow
            mode="multiple"
            onSelect={(val: number) => onUserSelect(val)}
            onDeselect={(val: number) => onUserDeselect(val)}
            onChange={(val: number[]) => setSelectedUsers(val)}
            value={selectedUsers}
            className={css.userSelect}
          >
            {users?.map((user, index) => (
              <Select.Option
                key={user.guid}
                value={index}
                disabled={window.currentUser?.guid === user.guid}
              >{`${user.firstName} ${user.lastName}`}</Select.Option>
            ))}
          </Select>
          <List className={css.roleList}>
            {usersWithRoles.map(
              (user, index) =>
                user.roleName && (
                  <List.Item
                    key={user.accessorId}
                    actions={[
                      <Select
                        value={user.roleName}
                        onChange={(val: string) => {
                          const updated = [...usersWithRoles];
                          updated[index].roleName = val;
                          setUsersWithRoles(updated);
                        }}
                        size="small"
                        className={css.roleSelect}
                        disabled={window.currentUser?.guid === user.accessorId}
                      >
                        {roles.map((role) => (
                          <Select.Option key={role.value} value={role.value}>
                            {role.label}
                          </Select.Option>
                        ))}
                      </Select>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <UserInitials
                          type="small"
                          firstName={user.firstName}
                          lastName={user.lastName}
                        />
                      }
                      title={`${user.firstName} ${user.lastName}`}
                    />
                  </List.Item>
                ),
            )}
          </List>
        </>
      )}
    </Modal>
  );
}

export default BuyerListPermissionsModal;
