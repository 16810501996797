import * as React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Menu } from "antd5";
import classnames from "classnames";

import Badge from "lib/core_components/Badge";
import DropdownMenu from "lib/core_components/DropdownMenu";
import TreeSelect, { Options, SelectedOptions } from "./TreeSelect";

import css from "./TreeDropdownList.module.scss";

export type { Options, SelectedOptions };

/**
 * Tree select rendered inside a dropdown with a button trigger
 */

type Props = {
  title: string;
  searchable?: boolean;
  isLoading?: boolean;
  options: Options;
  selectedOptions: SelectedOptions;
  onSelectedChange: (opts: SelectedOptions) => void;
  trackingEvent?: (field: string, selected: boolean) => void;
  dropdownOverlayClassName?: string;
};

function TreeDropdownList({
  title,
  options,
  selectedOptions,
  onSelectedChange,
  searchable,
  trackingEvent,
  dropdownOverlayClassName,
  isLoading,
}: Props) {
  const [visible, setVisible] = React.useState(false);
  const overlay = React.useMemo(() => {
    return (
      <Menu>
        <div
          className={classnames(css.overlay, dropdownOverlayClassName)}
          onClick={(e) => e.stopPropagation()}
        >
          <TreeSelect
            options={options}
            selectedOptions={selectedOptions}
            onSelectedChange={onSelectedChange}
            searchable={searchable}
            trackingEvent={trackingEvent}
          />
        </div>
      </Menu>
    );
  }, [
    options,
    onSelectedChange,
    selectedOptions,
    searchable,
    trackingEvent,
    dropdownOverlayClassName,
  ]);

  // TODO: Discuss this functionality
  // It's difficult to tell how to count this in a reusable way.
  // If a parent node doesn't have children and is selected, does it count as 1?
  // If a parent and all children are selected does it count as children count + 1 for parent ?
  // In signals, clicking the category name doesn't add anything extra to the search beyond the children,
  // But in buyer types it does
  const [showAll, totalCount] = React.useMemo(() => {
    let allAreSelected = true;
    let selectedCount = 0;
    for (const [key, opt] of Object.entries(options)) {
      const childrenCount = opt.children?.length || 0;
      const selectedChildrenCount = selectedOptions[key]?.names?.length || 0;
      const isSelectAll = selectedOptions[key]?.includeAll;
      if (selectedChildrenCount < childrenCount && !isSelectAll) {
        allAreSelected = false;
      }
      if (isSelectAll) {
        selectedCount += childrenCount;
      } else {
        selectedCount += selectedChildrenCount;
      }
    }
    return [allAreSelected, selectedCount];
  }, [options, selectedOptions]);
  return (
    <DropdownMenu
      trigger={["click"]}
      overlay={overlay}
      visible={visible}
      onVisibleChange={(isVisible) => setVisible(isVisible)}
    >
      <Button className={css.btn} loading={isLoading}>
        {title} {!!totalCount && <Badge>{showAll ? "All" : totalCount}</Badge>}
        <DownOutlined />
      </Button>
    </DropdownMenu>
  );
}

export default TreeDropdownList;
