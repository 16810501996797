import React, { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Popover } from "antd5";
import { TooltipPlacement } from "antd5/lib/tooltip";

import RelevanceIcon from "lib/icons/Relevance";
import { RelevanceButton } from "lib/themes/RelevanceConfig";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { SignalEntityType } from "lib/utils/signalUtils";
import { NewRelevanceScoreContent } from "./NewRelevanceScoreContent";

// eslint-disable-next-line css-modules/no-unused-class
import css from "./RelevanceScore.module.scss";

type RelevanceScoreProps = {
  id: string;
  relevanceScore?: number | null;
  popoverPlacement?: TooltipPlacement;
  eventAttributes?: EventData;
  entityType: SignalEntityType;
};

function convertScoreToText(relevanceScore?: number | null) {
  switch (relevanceScore) {
    case 3:
      return "High Relevance";
    case 2:
      return "Medium Relevance";
    case 1:
      return "Low Relevance";
    case 0:
    default:
      return "No Score";
  }
}

export function NewRelevanceScore({
  popoverPlacement = "bottomLeft",
  id,
  relevanceScore,
  eventAttributes,
  entityType,
}: RelevanceScoreProps) {
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const { logEvent } = useTracking();

  const title = (
    <div className={css.popoverHeader}>
      <div className={css.popoverTitle}>
        <RelevanceIcon relevance={relevanceScore} darkMode={hover} />
        <span className={css.relevanceTitle}>{convertScoreToText(relevanceScore)}</span>
      </div>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setOpen(false);
        }}
        icon={<CloseOutlined />}
        className={css.borderLess}
      />
    </div>
  );

  return (
    <ConfigProvider theme={RelevanceButton}>
      <div onClick={(e) => e.stopPropagation()}>
        <Popover
          title={title}
          content={
            <NewRelevanceScoreContent
              entityId={id}
              entityType={entityType}
              eventAttributes={eventAttributes}
            />
          }
          arrow={false}
          onOpenChange={(open) => {
            setOpen(open);
            if (open) {
              logEvent(EventNames.relevanceScoreClicked, eventAttributes);
            }
          }}
          trigger="click"
          open={open}
          placement={popoverPlacement}
        >
          <Button
            type="text"
            ghost
            aria-label={"Relevance score"}
            className={css.relevanceScoreBtn}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <RelevanceIcon relevance={relevanceScore} darkMode={hover} />
            <span className={css.relevanceNumber}>{relevanceScore ?? 0}</span>
          </Button>
        </Popover>
      </div>
    </ConfigProvider>
  );
}
