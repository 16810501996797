import React, { useState } from "react";
import { Button, ConfigProvider, Popover } from "antd5";
import { TooltipPlacement } from "antd5/lib/tooltip";
import classnames from "classnames";

import { CpvCodesDto } from "lib/generated/app-api";
import { useRestrictedGuestAccess } from "lib/hooks/useRestrictedRowClick";
import { ExternalLink } from "lib/icons/ExternalLink";
import RelevanceIcon from "lib/icons/Relevance";
import { tagColourFromSignal } from "lib/tagUtils";
import { RelevanceButton } from "lib/themes/RelevanceConfig";
import { EventNames, useTracking } from "lib/tracking";
import { BasicSignal, getSignalTypes } from "lib/types/models";

// Disable linter - these are shared files!
// eslint-disable-next-line css-modules/no-unused-class
import tagHelperCss from "../../styles/tag_helpers.module.scss";
// eslint-disable-next-line css-modules/no-unused-class
import css from "./RelevanceScore.module.scss";

type RelevanceScoreProps = {
  recordGuid: string;
  recordStage: string;
  signals: BasicSignal[];
  relevanceScore?: number | null;
  popoverPlacement?: TooltipPlacement;
  cpvCodes?: CpvCodesDto[];
  btnClassName?: string;
};

function convertScoreToText(relevanceScore?: number | null) {
  if (relevanceScore === 0) {
    return "No Score";
  }
  if (relevanceScore === 1) {
    return "Low Relevance";
  }
  if (relevanceScore === 2) {
    return "Medium Relevance";
  }

  return "High Relevance";
}

export function RelevanceScorePopover({
  signals,
  relevanceScore,
  popoverPlacement,
  recordGuid,
  recordStage,
  cpvCodes,
  btnClassName,
}: RelevanceScoreProps) {
  const [hover, setHover] = useState(false);
  const { logEvent, trackingData } = useTracking();

  const relevanceScoreTrackingData = { ...trackingData, "CTA Actioned": "Update signal settings" };
  const openGuestAccessPassPaywall = useRestrictedGuestAccess(relevanceScoreTrackingData);

  const keywordsSignals = signals.filter((s) => s.category === "Keywords");
  const competitorSignals = signals.filter((s) => s.category === "Competitors");
  const partnerSignals = signals.filter((s) => s.category === "Partners");
  const buyerSignals = signals.filter((s) => s.category === "Buyers");
  const cpvSignals = signals.filter((s) => s.category === "CPV codes");

  const title = (
    <div className={css.oldPopoverTitle}>
      <RelevanceIcon relevance={relevanceScore} darkMode={hover} />
      <span className={css.relevanceTitle}>{convertScoreToText(relevanceScore)}</span>
    </div>
  );

  const signalsTypes = getSignalTypes(signals);
  const signalsCpvCodes = cpvCodes?.map((code) => `${code.code} - ${code.name}`);

  const eventAttributes = {
    "Record GUID": recordGuid,
    Stage: recordStage,
    "Signal score": relevanceScore,
    Signals: signalsTypes,
    "Signal types": signalsTypes ? Object.keys(signalsTypes) : [],
    "Signals.CPV codes": signalsCpvCodes || [],
  };

  const content = (
    <div className={css.container}>
      <p>
        Our relevance score is calculated based on this record's match with your signal settings.
      </p>
      {signals.length === 0 && <b>This record doesn't match any of your signals.</b>}
      {keywordsSignals.length > 0 && <SignalsBlock name="Keywords" signals={keywordsSignals} />}
      {competitorSignals.length > 0 && (
        <SignalsBlock name="Competitors" signals={competitorSignals} />
      )}
      {partnerSignals.length > 0 && <SignalsBlock name="Partners" signals={partnerSignals} />}
      {buyerSignals.length > 0 && <SignalsBlock name="Buyers" signals={buyerSignals} />}
      {cpvSignals.length > 0 && <SignalsBlock name="CPV codes" signals={cpvSignals} />}
      <Button
        type="default"
        className={css.settingsBtn}
        target="_blank"
        href="/account-management/feed-settings"
        onClick={(e) => {
          logEvent(EventNames.relevanceScoreUpdateSettings, eventAttributes);
          e.stopPropagation();
          if (window.guestUser) {
            e.preventDefault();
            openGuestAccessPassPaywall();
          }
        }}
      >
        <ExternalLink className={css.externalLink} />
        Update your signal settings
      </Button>
    </div>
  );

  return (
    <ConfigProvider theme={RelevanceButton}>
      <Popover
        title={title}
        content={content}
        arrow={false}
        onOpenChange={(open) => {
          if (open) {
            logEvent(EventNames.relevanceScoreClicked, eventAttributes);
          }
        }}
        trigger="click"
        placement={popoverPlacement}
      >
        <Button
          type="text"
          ghost
          className={classnames(css.relevanceScoreBtn, btnClassName)}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <RelevanceIcon relevance={relevanceScore} darkMode={hover} />
          <span className={css.relevanceNumber}>{relevanceScore}</span>
        </Button>
      </Popover>
    </ConfigProvider>
  );
}

function SignalsBlock({ name, signals }: { name: string; signals: BasicSignal[] }) {
  return (
    <div>
      <div className={css.signalNameTitle}>{name}:</div>
      <ul className={css.signalsContainer}>
        {signals.map((s) => (
          <li
            key={s.id}
            className={classnames(
              `tag-${tagColourFromSignal(s.category) || "default"}`,
              tagHelperCss.nonTagCellTag,
              css.tagPill,
            )}
          >
            {s.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
