import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  SearchFrameworksQuery,
  SearchFrameworksRequest,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const searchFrameworkQuery = graphql(`
  query searchFrameworks($request: SearchFrameworksRequest!) {
    frameworks(SearchFrameworksRequest: $request) {
      results {
        id
        title
        signalScore
        stage
        procedureType
        awardType
        validatedByProvider
        supplierCount
        lotCount
        buyerCount
        callOffCount
        callOff {
          count
          averageAmount {
            amount
            currency
          }
        }
        serviceProvider {
          id
          name
        }
        textSections {
          title
          content {
            content
            content_type
          }
          role
        }
        contractPeriod {
          startDate
          endDate
        }
        tenderPeriod {
          startDate
          endDate
        }
        value {
          amount
          currency
        }
      }
      totalResults
    }
  }
`);

// TODO: Maybe think about renaming this type

export type SearchFrameworksResponse = SearchFrameworksQuery["frameworks"];
export type FrameworkDto = SearchFrameworksResponse["results"][0];

export function useSearchFrameworks(
  searchFrameworksRequest: SearchFrameworksRequest,
  options?: UseQueryOptions<SearchFrameworksQuery, unknown, SearchFrameworksQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["frameworks", searchFrameworksRequest],
    searchFrameworkQuery,
    [{ request: searchFrameworksRequest }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return { data: data?.frameworks, ...rest };
}

// this function could have multiple different implementations depending on the graphql query passed
// to it
export type UseSearchFrameworks = typeof useSearchFrameworks;
