import React, { useEffect } from "react";
import { Button, Progress } from "antd5";

import { useUploadNoticeDocument } from "../../../lib/hooks/api/bid/useUploadNoticeDocument";

type FileUploadProps = {
  file: File;
  noticeId: string;
  onComplete: (fileName: string, isError: boolean) => void;
};

export function FileUpload({ file, noticeId, onComplete }: FileUploadProps) {
  const { status, mutate, uploadProgress, isError, error } = useUploadNoticeDocument({
    onSettled: (_data, error) => {
      onComplete(file.name, !!error);
    },
  });

  useEffect(() => {
    if (status === "idle") {
      mutate({ file, noticeId });
    }
  }, [file, noticeId, status, mutate]);

  return (
    <div>
      <h4>
        {file.name} {error && ` - ${error.message}`}
      </h4>
      <Progress percent={uploadProgress} status={isError ? "exception" : undefined} />
      {isError && <Button onClick={() => mutate({ file, noticeId })}>Retry</Button>}
    </div>
  );
}
