import { UseMutationOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  BidQualifyNoticeMutation,
  BidQualifyNoticeMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const bidQualifyNotice = graphql(`
  mutation bidQualifyNotice($input: QualifyNoticeRequest!) {
    qualifyNotice(QualifyNoticeRequest: $input) {
      result {
        answer
        question
      }
    }
  }
`);

export function useNoticeQualification(
  options?: UseMutationOptions<
    BidQualifyNoticeMutation,
    unknown,
    BidQualifyNoticeMutationVariables,
    unknown
  >,
) {
  const { data, ...rest } = useGraphQlMutation(bidQualifyNotice, {
    ...options,
    onSuccess: (data, vars, ctx) => {
      options?.onSuccess?.(data, vars, ctx);
    },
  });
  return { data: data?.qualifyNotice, ...rest };
}
