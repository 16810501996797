import React from "react";
import { Modal, Skeleton } from "antd"; // upgrade and verify

import { useSearchSpendData } from "lib/generated/spend-data-api/spendDataManagementAPI";
import { SpendDataFilters } from "lib/generated/spend-data-api/spendDataManagementAPI.schemas";
import ExportDataButton from "./ExportDataButton";
import TransactionsTable from "./TransactionsTable";

import css from "./TransactionsModal.module.scss";

type Props = { isOpen: boolean; onClose: () => void; filters: SpendDataFilters };

// TODO - this is a placeholder, need to know what data I can get from the chart i.e buyer / supplier names
function getFilterString(filters: SpendDataFilters) {
  const result: string[] = [];

  if (filters.buyerIds) {
    result.push(`Buyers`);
  }

  if (filters.supplierNames || filters.supplierGuids || filters.supplierNamesExact) {
    result.push(`Suppliers`);
  }

  if (filters.dateFrom || filters.dateTo) {
    const datefrom = filters.dateFrom ? new Date(filters.dateFrom) : undefined;
    const dateTo = filters.dateTo ? new Date(filters.dateTo) : undefined;
    result.push(`Dates ${datefrom?.toLocaleDateString()} - ${dateTo?.toLocaleDateString()}`);
  }

  if (result.length === 0) {
    return undefined;
  }

  return `Filtered by ${result.join(" • ")}`;
}

export function TransactionsModal({ isOpen, onClose, filters }: Props) {
  const { data, isLoading } = useSearchSpendData({
    filters,
    limit: 10,
    offset: 0,
  });
  const filterString = getFilterString(filters);
  return (
    <Modal onCancel={onClose} title={"Detailed View"} visible={isOpen} footer={null} width={1000}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <div className={css.modal}>
          <div className={css.modalHeader}>
            <h4>{data?.total} Transactions</h4>
            {filterString && <p>{filterString}</p>}
            <ExportDataButton className={css.export} filters={filters} totalRows={data?.total} />
          </div>
          <TransactionsTable filters={filters} className={css.table} />
        </div>
      )}
    </Modal>
  );
}
