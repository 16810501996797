import React, { useRef, useState } from "react";
import { Chart, getElementAtEvent } from "react-chartjs-2";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  TimeSeriesScale,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { isNumber, partition } from "lodash";
import { DateTime, Interval } from "luxon";

import "chartjs-adapter-luxon";

import CentredSpinner from "lib/core_components/CentredSpinner";
import { RecordDto } from "lib/generated/app-api";
import { useBuyerSummaryChart } from "lib/generated/spend-data-api/spendDataManagementAPI";
import { useSignalSettings } from "lib/hooks/api/teams/useSignalSettings";
import { useRecordSearch } from "lib/hooks/api/useRecordSearch";
import { useDialogManager } from "lib/providers/DialogManager";
import { BuyerDetails, BuyerSummary } from "lib/types/models";
import { NumberDataPointValue, SpendTooltip } from "./SpendTooltip";
import { TransactionsModal } from "./TransactionsModal";

type Props =
  | {
      supplierGuid: string;
      signalId?: never;
      buyer: BuyerDetails | BuyerSummary;
    }
  | {
      supplierGuid?: never;
      signalId: string;
      buyer: BuyerDetails | BuyerSummary;
    };

const startDate = DateTime.fromISO("2018-01-01");
const endDate = DateTime.now().startOf("quarter");
const interval = Interval.fromDateTimes(startDate, endDate);
// add 1 to include the current quarter
const numOfQuarters = interval.count("quarters") + 1;

function getQuarterlyData<K extends PropertyKey>(
  data: Record<K, unknown>[],
  dateKey: K,
  totalKey: K,
): (null | number)[] {
  const quarterlyData = new Array(numOfQuarters).fill(0);

  data.forEach((item) => {
    if (typeof item[dateKey] !== "string" || !isNumber(item[totalKey])) {
      return;
    }
    const date = DateTime.fromISO(item[dateKey] as string).plus({ hours: 1 });
    const interval = Interval.fromDateTimes(startDate, date);
    const quarterIndex = interval.count("quarters") - 1;
    const total = Math.round(item[totalKey] as number);
    if (quarterlyData[quarterIndex]) {
      quarterlyData[quarterIndex] += total;
    } else {
      quarterlyData[quarterIndex] = total;
    }
  });

  return quarterlyData;
}

function getTranscationPerQuarter(data: RecordDto[]): (null | number)[] {
  const quarterlyData = new Array(numOfQuarters).fill(null);

  data.forEach((item) => {
    const date = DateTime.fromFormat(item.publishDate, "yyyy-MM-dd");
    const interval = Interval.fromDateTimes(startDate, date);
    const quarterIndex = interval.count("quarters") - 1;

    if (quarterlyData[quarterIndex]) {
      quarterlyData[quarterIndex] += 1;
    } else {
      quarterlyData[quarterIndex] = 1;
    }
  });

  return quarterlyData;
}

function useChartData({ buyer, supplierGuid, signalId }: Props) {
  const { data: signals } = useSignalSettings();
  const signal = signalId ? signals?.signals.find((signal) => signal.id === signalId) : undefined;
  const { data: records, isLoading: isLoadingRecords } = useRecordSearch({
    buyers: { guid: [buyer.guid] },
    suppliers: supplierGuid ? { guid: [supplierGuid] } : undefined,
    anySignals: signalId ? { ids: [signalId] } : undefined,
  });

  const { data: spend, isLoading: isLoadingSpend } = useBuyerSummaryChart({
    filters: {
      dateFrom: startDate.toISO() || undefined,
      buyerIds: [buyer.guid],
      supplierNames: signal?.name ? [signal.name] : [],
      supplierGuids: supplierGuid ? [supplierGuid] : undefined,
    },
  });

  // As this page already shows a buyer - supplier relationship,
  // we ignore the partner & competitor signals and only focus on the notices with keyword signals
  const [relevantRecords, otherRecords] = partition(records?.results, (record) =>
    record.signals.some((signal) => signal.category === "Keywords"),
  );

  return {
    isLoading: isLoadingRecords || isLoadingSpend,
    data: {
      otherRecords: records && getTranscationPerQuarter(otherRecords),
      relevantRecords: records && getTranscationPerQuarter(relevantRecords),
      spend: spend?.data && getQuarterlyData(spend?.data, "date", "total"),
      allRecords: records?.results,
    },
  };
}

ChartJS.register(
  CategoryScale,
  TimeSeriesScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
);

const quarters = interval.splitBy({ quarters: 1 }).map((i) => i.start?.toString());

const chartData: ChartData<"bar" | "line", (number | null)[], unknown> = {
  labels: quarters,
  datasets: [
    {
      label: "Spend",
      type: "line",
      data: [],
      borderColor: "#FA5766",
      backgroundColor: "#FA5766",
      yAxisID: "y",
    },
    {
      label: "Relevant notices",
      type: "bar" as const,
      data: [],
      borderColor: "#4F86FA",
      backgroundColor: "#4F86FA",
      yAxisID: "y1",
    },
    {
      label: "Other notices",
      type: "bar" as const,
      data: [],
      borderColor: "#DCE7FE",
      backgroundColor: "#DCE7FE",
      yAxisID: "y1",
    },
  ],
};

const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
  },
  plugins: {
    tooltip: {
      enabled: false,
    },
  },
  hover: {
    mode: "point",
  },
  onHover: (event, activeElements) => {
    if (event.native?.target)
      (event.native.target as HTMLElement).style.cursor = activeElements?.length
        ? "pointer"
        : "default";
  },
  elements: {
    point: {
      radius: 0,
      hoverRadius: 5,
      hitRadius: 25,
    },
  },
  scales: {
    x: {
      type: "time" as const,
      time: {
        unit: "quarter",
        displayFormats: {
          quarter: "Qq yyyy",
        },
      },
      stacked: true,
      grid: {
        display: true,
        drawOnChartArea: false,
      },
      offset: false,
      ticks: {
        align: "center",
        autoSkip: true,
        crossAlign: "center",
      },
    },
    y: {
      type: "linear" as const,
      display: true,
      position: "left" as const,
      title: {
        text: "Transaction value GBP",
        display: true,
      },
      ticks: {
        autoSkip: true,
        precision: 0,
        format: {
          // style: "currency",
          // currency: "GBP",
          maximumFractionDigits: 2,
          notation: "compact",
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore - trailingZeroDisplay is not in the types but should be when ts is patched
          trailingZeroDisplay: "stripIfInteger",
        },
      },
      beginAtZero: true,
    },
    y1: {
      type: "linear" as const,
      display: true,
      position: "right" as const,
      grid: {
        drawOnChartArea: false,
      },
      title: {
        text: "Contract awards",
        display: true,
      },
      ticks: {
        precision: 0,
      },
      grace: 0,
      stacked: true,
    },
  },
};

export function BuyerSupplierChart(props: Props) {
  const [tooltipProps, setTooltipProps] = useState<{
    top: number;
    left: number;
    show: boolean;
    dataPoints?: TooltipItem<"line">[];
  }>({
    top: 0,
    left: 0,
    show: false,
  });
  const { isLoading, data } = useChartData(props);
  const chartRef = useRef<ChartJSOrUndefined<"bar" | "line">>(undefined);
  const { data: signals } = useSignalSettings();
  const signal = props.signalId
    ? signals?.signals.find((signal) => signal.id === props.signalId)
    : undefined;
  const dialogManager = useDialogManager();

  if (isLoading) {
    return <CentredSpinner />;
  }

  if (!data) {
    return null;
  }

  if (data.spend) {
    chartData.datasets[0].data = data.spend;
  }

  if (data.relevantRecords) {
    chartData.datasets[1].data = data.relevantRecords;
  }

  if (data.otherRecords) {
    chartData.datasets[2].data = data.otherRecords;
  }

  if (data.allRecords && data.allRecords.length > 0 && chartOptions.scales?.y1) {
    if (chartOptions.scales?.y1.type === "linear") {
      chartOptions.scales.y1.grace = 3;
    }
  }
  if (chartOptions.plugins?.tooltip) {
    chartOptions.plugins.tooltip.external = (context) => {
      if (
        tooltipProps.show === !!context.tooltip.opacity &&
        tooltipProps.top === context.tooltip.caretY / 2 + 25 &&
        tooltipProps.left === context.tooltip.caretX + 25
      ) {
        return;
      }

      setTooltipProps((o) => ({
        ...o,
        top: context.tooltip.caretY / 2 + 25,
        left: context.tooltip.caretX + 25,
        show: !!context.tooltip.opacity,
        dataPoints: context.tooltip.dataPoints,
      }));
    };
  }

  return (
    <>
      <Chart
        type="bar"
        data={chartData}
        options={chartOptions}
        onClick={(evt) => {
          const chart = chartRef.current;
          if (!chart) {
            return;
          }
          const dataset = getElementAtEvent(chart, evt);
          if (dataset.length > 0) {
            dataset[0].index;
            const quarter = startDate.plus({ quarters: dataset[0].index }).startOf("quarter");
            dialogManager.openDialog(TransactionsModal, {
              filters: {
                dateFrom: quarter.startOf("quarter").toISO() || undefined,
                dateTo: quarter.endOf("quarter").toISO() || undefined,
                buyerIds: [props.buyer.guid],
                supplierNames: signal?.name ? [signal.name] : [],
                supplierGuids: props.supplierGuid ? [props.supplierGuid] : undefined,
              },
            });
          }
        }}
        ref={chartRef}
      />
      <SpendTooltip
        {...tooltipProps}
        formatDataPointValue={(dataPoint: TooltipItem<"line" | "bar">) =>
          dataPoint.dataset.label === "Spend" ? (
            <NumberDataPointValue dataPoint={dataPoint} currency="GBP" />
          ) : (
            <NumberDataPointValue dataPoint={dataPoint} />
          )
        }
        clickMessage="Click to inspect"
        onClick={(dataPoints) => {
          if (dataPoints && dataPoints?.length > 0) {
            const quarter = startDate
              .plus({ quarters: dataPoints[0].dataIndex })
              .startOf("quarter");
            dialogManager.openDialog(TransactionsModal, {
              filters: {
                dateFrom: quarter.startOf("quarter").toISO() || undefined,
                dateTo: quarter.endOf("quarter").toISO() || undefined,
                buyerIds: [props.buyer.guid],
                supplierNames: signal?.name ? [signal.name] : [],
                supplierGuids: props.supplierGuid ? [props.supplierGuid] : undefined,
              },
            });
          }
        }}
      />
    </>
  );
}
