import * as React from "react";
import { hot } from "react-hot-loader/root";
import { Skeleton } from "antd5";
import { Route, Router, Switch } from "wouter";

import { withAppLayout } from "components/app_layout/AppLayout";
import { RelatedContactsPage } from "components/contacts/RelatedContactsPage";
import NoticeDetailsContent from "components/record_details/NoticeDetailsContent";
import NoticeHeader from "components/record_details/NoticeHeader";
import RecordDetailsContent from "components/record_details/RecordDetailsContent";
import RecordHeader from "components/record_details/RecordHeader";
import { assertCurrentUser } from "lib/currentUser";
import { NEW_NOTICES_SEARCH, useVariableValue } from "lib/featureFlags";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { RecordDto } from "lib/generated/app-api";
import { NoticeDetails, useNotice } from "lib/hooks/api/useNotice";
import { useRecord } from "lib/hooks/api/useRecord";
import useElementInViewport from "lib/hooks/useElementInViewport";
import { EventNames, TrackingProvider, useTracking } from "lib/tracking";
import { getRecordSignals, getSignalTypes, RecordDetails, recordFromDto } from "lib/types/models";
import { NoticeQualificationPage } from "../../components/bid_module/NoticeQualificationPage";

import css from "./NoticeDetailsPage.module.scss";

type Props = {
  notice: RecordDetails;
};

type NoticeDetailsProps = {
  notice: NoticeDetails;
};

type NoticeDetailsContainerProps = {
  noticeGuid: string;
};

type NoticeDetailsPageProps = {
  record: RecordDto;
};

function RecordDetailsPageWrapper({ notice }: Props) {
  const useNoticeDetailsWithGraphQL = FeatureToggles.isEnabled(Feature.NOTICE_DETAILS_GRAPHQL);
  const { logEvent } = useTracking();

  React.useEffect(() => {
    const user = assertCurrentUser();

    const urlSearchParams = new URLSearchParams(window.location.search);

    if (urlSearchParams.has("src") && urlSearchParams.get("src") === "email") {
      logEvent(EventNames.emailLinkClicked, {
        email: user.email,
        Link: window.location.href,
      });
    }
  }, [logEvent]);

  return (
    <Router base={`/records/${notice.guid}`}>
      <Switch>
        {/* related contacts page */}
        <Route path="/related_contacts">
          <RelatedContactsPage record={notice} />
        </Route>
        <Route path="/qualification">
          <NoticeQualificationPage noticeId={notice.guid} />
        </Route>
        <Route path={notice.canonical_url_path}>
          {useNoticeDetailsWithGraphQL ? (
            <NoticeDetailsContainer noticeGuid={notice.guid} />
          ) : (
            <RecordDetailsContainer noticeGuid={notice.guid} />
          )}
        </Route>
        {/* Default */}
        <Route>
          {useNoticeDetailsWithGraphQL ? (
            <NoticeDetailsContainer noticeGuid={notice.guid} />
          ) : (
            <RecordDetailsContainer noticeGuid={notice.guid} />
          )}
        </Route>
      </Switch>
    </Router>
  );
}

function RecordDetailsContainer({ noticeGuid }: NoticeDetailsContainerProps) {
  const { data, isLoading } = useRecord(noticeGuid);

  if (isLoading) {
    return (
      <div className={css.skeletonWrapper}>
        <Skeleton className={css.skeleton} />
      </div>
    );
  }

  if (!data) {
    return <div>No data found for this record.</div>;
  }

  return <RecordDetailsPage record={data} />;
}

function RecordDetailsPage({ record }: NoticeDetailsPageProps) {
  const [topOfPageRef, topOfPageIsVisible] = useElementInViewport<HTMLDivElement>();

  const getSignals = (record: RecordDto) => {
    const recordDetails = recordFromDto(record);
    return getRecordSignals(recordDetails);
  };

  const signals = getSignals(record);
  const signalTypes = getSignalTypes(signals);

  return (
    <TrackingProvider
      data={{
        "Record GUID": record.guid,
        "Signal score": record?.relevanceScore ?? "0",
        Stage: record.stage,
        "Signal types": signalTypes ? Object.keys(signalTypes) : [],
        Signals: signalTypes,
      }}
    >
      <div className={css.pageWrapper}>
        <RecordHeader
          record={record}
          trackingContextName="Title header"
          disableTitleLink
          headerClassName={css.header}
          scrollClassName={css.scroll}
          showStage={topOfPageIsVisible}
          isTopOfPageVisible={topOfPageIsVisible}
        />
        <div ref={topOfPageRef} />
        <div className={css.contentContainer}>
          <RecordDetailsContent
            record={record}
            trackingContextName="Title header"
            containerClassName={css.columnsContainer}
          />
        </div>
      </div>
    </TrackingProvider>
  );
}

function NoticeDetailsContainer({ noticeGuid }: NoticeDetailsContainerProps) {
  const { data: notice, isLoading } = useNotice(noticeGuid);
  // temporary workaround to fix the open search signals error
  const { data: legacyNotice } = useRecord(noticeGuid);
  const useOpenSearch = useVariableValue(NEW_NOTICES_SEARCH, false);

  if (isLoading) {
    return (
      <div className={css.skeletonWrapper}>
        <Skeleton className={css.skeleton} />
      </div>
    );
  }

  if (!notice) {
    return <div>No data found for this notice.</div>;
  }

  return (
    <NoticeDetailsPage
      notice={{
        ...notice,
        signals: useOpenSearch ? notice.signals : legacyNotice?.signals,
        score: useOpenSearch ? notice.score : notice.legacyRelevanceScore,
      }}
    />
  );
}

function NoticeDetailsPage({ notice }: NoticeDetailsProps) {
  const [topOfPageRef, topOfPageIsVisible] = useElementInViewport<HTMLDivElement>();

  const signalTypes = getSignalTypes(notice.signals ?? []);

  return (
    <TrackingProvider
      data={{
        "Record GUID": notice.guid,
        "Signal score": notice.score ?? 0,
        Stage: notice.stage ?? "Unknown",
        "Signal types": signalTypes ? Object.keys(signalTypes) : [],
        Signals: signalTypes,
      }}
    >
      <div className={css.pageWrapper}>
        <NoticeHeader
          notice={notice}
          trackingContextName="Title header"
          disableTitleLink
          headerClassName={css.header}
          scrollClassName={css.scroll}
          showStage={topOfPageIsVisible}
          isTopOfPageVisible={topOfPageIsVisible}
        />
        <div ref={topOfPageRef} />
        <div className={css.contentContainer}>
          <NoticeDetailsContent
            notice={notice}
            trackingContextName="Title header"
            containerClassName={css.columnsContainer}
          />
        </div>
      </div>
    </TrackingProvider>
  );
}

function getRecordSignalTypes(notice: RecordDetails) {
  const signalTypes = getSignalTypes(notice.signals);

  return { "Signal types": signalTypes ? Object.keys(signalTypes) : [], Signals: signalTypes };
}

export default hot(
  withAppLayout(RecordDetailsPageWrapper, (props: Props) => ({
    pageName: "Record Details",
    pageViewProps: {
      "Record GUID": props.notice.guid,
      "Signal score": props.notice.relevance_score ?? "0",
      Stage: props.notice.stage,
      ...getRecordSignalTypes(props.notice),
    },
  })),
);
