import React from "react";
import { hot } from "react-hot-loader/root";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsHeader, DetailsPage } from "components/app_layout/DetailsLayout";
import ContactsTable from "components/contacts/ContactsTable";

import css from "./ContactsPage.module.scss";

export function ContactsPage() {
  return (
    <DetailsPage>
      <DetailsHeader borderBottom={false}>
        <h1 className={css.header}>Buyer contacts</h1>
      </DetailsHeader>
      <ContactsTable
        contextSource="Buyer contacts table"
        pageSize={20}
        showAllFiltersDrawer={true}
        showDropdownFilters={true}
      />
    </DetailsPage>
  );
}

export default hot(withAppLayout(ContactsPage, {}));
