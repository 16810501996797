import { DocumentsSearchRequest } from "lib/generated/app-service-gql/graphql";
import { DocumentCategory, DocumentsSort, DocumentsSortOrder } from "lib/types/graphQLEnums";
import { ColumnSetting, CountryRegion } from "lib/types/models";

export type DocumentColumns = "title" | "buyers" | "signalScore" | "category" | "publishedAt";

export const ALL_DOCUMENT_COLUMNS: ColumnSetting<DocumentColumns>[] = [
  { title: "Document title", field: "title", disabled: true },
  { title: "Buyers", field: "buyers" },
  { title: "Type", field: "category" },
  { title: "Published", field: "publishedAt" },
];

export function createDocumentColumns({ excludedColumns }: { excludedColumns: DocumentColumns[] }) {
  return ALL_DOCUMENT_COLUMNS.filter((col) => !excludedColumns.includes(col.field));
}

export const SIGNAL_SCORE_COLUMN: ColumnSetting<DocumentColumns> = {
  title: "Signal score",
  field: "signalScore",
};

export const DEFAULT_PAGINATION = { current: 1, pageSize: 10 };

export type SortField = "title" | "category" | "publishedAt" | "signalScore";

const validSortFields: SortField[] = ["signalScore", "title", "category", "publishedAt"];

type DateFilter = { from?: string; relativeFrom?: string; to?: string; relativeTo?: string };

type Pagination = { pageSize: number; current: number };

type RelevanceScoreCategory = "None" | "Low" | "Medium" | "High";

export type DocumentFilters = {
  signalScore: RelevanceScoreCategory[];
  title: string;
  organisationIds?: string[];
  buyerTypes?: string[];
  buyerListIds?: string[];
  buyerCountryRegions?: CountryRegion;
  keywords?: string[];
  categories?: DocumentCategory[];
  publishDate?: { filter?: DateFilter };
  sort: {
    field: SortField;
    order: "ASC" | "DESC";
  };
};

export const DEFAULT_DOCUMENT_FILTERS: DocumentFilters = {
  title: "",
  keywords: [],
  signalScore: [],
  publishDate: {},
  categories: [],
  sort: {
    field: "publishedAt",
    order: "DESC",
  },
};

export function parseDocumentsUrlState(
  state: unknown,
  defaultFilters = DEFAULT_DOCUMENT_FILTERS,
): DocumentFilters {
  if (typeof state !== "object" || state === null) {
    return defaultFilters;
  }

  const filters = state as DocumentFilters;

  return {
    ...filters,
    signalScore: filters.signalScore ?? [],
  };
}

export function isValidSortField(field?: string): SortField | undefined {
  if (field && validSortFields.includes(field as SortField)) {
    return field as SortField;
  }
  return undefined;
}

export const documentTypes = [
  { label: "Report and accounts", value: DocumentCategory.ReportAndAccounts },
  { label: "Strategy", value: DocumentCategory.Strategy },
  { label: "Budget", value: DocumentCategory.Budget },
  { label: "FOI", value: DocumentCategory.Foi },
  {
    label: "Specific report e.g. public health, crime, etc.",
    value: DocumentCategory.SpecificReport,
  },
  {
    label: "Specific strategy e.g. housing, transport, etc.",
    value: DocumentCategory.SpecificStrategy,
  },
  { label: "Meeting minutes", value: DocumentCategory.MeetingMinutes },
  { label: "News and communication", value: DocumentCategory.NewsAndCommunication },
  { label: "Case study", value: DocumentCategory.CaseStudy },
  { label: "Guidance and regulation", value: DocumentCategory.GuidanceAndRegulation },
  { label: "Consultation and research", value: DocumentCategory.ConsultationAndResearch },
  { label: "Statistics", value: DocumentCategory.Statistics },
  { label: "Tribunal decisions", value: DocumentCategory.TribunalDecisions },
  { label: "Internal operations", value: DocumentCategory.InternalOperations },
  { label: "Other", value: DocumentCategory.Other },
];

export const documentTypeMap = documentTypes.reduce<{ [key: string]: string }>((map, item) => {
  map[item.value] = item.label;
  return map;
}, {});

const RELEVANCE_SCORE_MAP = { None: 0, Low: 1, Medium: 2, High: 3 };

function getMinScore(relevanceScoreCategory: RelevanceScoreCategory): number | undefined {
  const score = RELEVANCE_SCORE_MAP[relevanceScoreCategory];

  return score ?? undefined;
}

function mapToSortOrder(sort: { field: SortField; order: "ASC" | "DESC" }) {
  const order = sort.order === "ASC" ? DocumentsSortOrder.Asc : DocumentsSortOrder.Desc;
  const getField = (sortField: SortField) => {
    switch (sortField) {
      case "title":
        return DocumentsSort.Title;
      case "publishedAt":
        return DocumentsSort.PublishDate;
      case "signalScore":
        return DocumentsSort.Score;
      case "category":
        return DocumentsSort.Category;
      default:
        return DocumentsSort.Score;
    }
  };

  return {
    field: getField(sort.field),
    order: order,
  };
}

export function convertDocumentFiltersToSearchRequest(
  filters: DocumentFilters,
  pagination?: Pagination,
): DocumentsSearchRequest {
  const sortFields = mapToSortOrder(filters.sort);

  return {
    buyerFilters: {
      buyers: filters.organisationIds,
      buyerCategories: filters.buyerTypes,
      buyerLists: filters.buyerListIds,
      buyerCountryCodes: filters.buyerCountryRegions?.countries,
      buyerRegions: filters.buyerCountryRegions?.regions,
    },
    minScore: filters.signalScore.length ? getMinScore(filters.signalScore[0]) : undefined,
    publishDate: filters.publishDate ?? {},
    categories: filters.categories,
    keywords: filters.keywords ?? [],
    title: filters.title,
    limit: pagination?.pageSize ?? 10,
    page: pagination?.current ?? 1,
    sort: sortFields.field,
    sortOrder: sortFields.order,
  };
}

export type FilterSection = "buyers";
