import { captureException } from "@sentry/react";

import {
  ViewConfigurationFilterSettingsRelevanceScoreEnum,
  ViewConfigurationFilterSettingsSignals,
} from "lib/generated/app-api";
import { SignalDefinition } from "lib/generated/app-service-gql/graphql";

export enum SignalEntityType {
  Framework = "FRAMEWORK",
  ProcurementNotice = "PROCUREMENT_NOTICE",
  Document = "DOCUMENT",
}
type SignalResponse = {
  allSignals: SignalDefinition[];
  keywordSignals: SignalDefinition[];
  competitorSignals: SignalDefinition[];
  partnerSignals: SignalDefinition[];
  cpvSignals: SignalDefinition[];
};

function checkIfStringIsGuid(string: string): boolean {
  const guidRegex = new RegExp(
    "^[A-Fa-f0-9]{8}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{4}-[A-Fa-f0-9]{12}$",
    "i",
  );
  return guidRegex.test(string);
}

export const SIGNAL_SCORE_MAP = new Map<ViewConfigurationFilterSettingsRelevanceScoreEnum, number>([
  [ViewConfigurationFilterSettingsRelevanceScoreEnum.High, 3],
  [ViewConfigurationFilterSettingsRelevanceScoreEnum.Medium, 2],
  [ViewConfigurationFilterSettingsRelevanceScoreEnum.Low, 1],
  [ViewConfigurationFilterSettingsRelevanceScoreEnum.None, 0],
]);

export function mapSignalsToViewSignals(signals: string[]): ViewConfigurationFilterSettingsSignals {
  const viewSignals: ViewConfigurationFilterSettingsSignals = {
    ids: [],
    categories: [],
  };

  signals.forEach((signal) => {
    if (checkIfStringIsGuid(signal)) {
      viewSignals.ids?.push(signal);
    } else {
      viewSignals?.categories?.push(signal);
    }
  });

  return viewSignals;
}

/**
 * Simple util function which organizes signals by category
 * @param allSignals
 */
export function categorizeSignals(allSignals: SignalDefinition[]): SignalResponse {
  const keywordSignals: SignalDefinition[] = [];
  const competitorSignals: SignalDefinition[] = [];
  const partnerSignals: SignalDefinition[] = [];

  const cpvSignals: SignalDefinition[] = [];
  for (const signal of allSignals) {
    switch (signal.category) {
      case "Keywords":
        keywordSignals.push(signal);
        break;
      case "Competitors":
        competitorSignals.push(signal);
        break;
      case "Partners":
        partnerSignals.push(signal);
        break;
      case "CPV codes":
        cpvSignals.push(signal);
        break;
      default:
        captureException(`Unknown signal category: ${signal.category}`);
    }
  }

  return { allSignals, keywordSignals, competitorSignals, partnerSignals, cpvSignals };
}
