import * as React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Form } from "antd5";
import { CheckboxChangeEvent } from "antd5/es/checkbox";

import AdvancedSettings from "components/account_management/AdvancedSettings";
import { InfoButton } from "components/actions/InfoButton";
import { TextLink } from "components/actions/Links";
import CpvCodeSelector from "components/form_components/CpvCodeSelector";
import KeywordsField from "components/form_components/KeywordsField";
import { TextSearchTipsModal } from "components/modals/TextSearchTipsModal";
import { HELP_EDITING_YOUR_FEED_URL, MatchCountMap } from "components/onboarding/SharedOnboarding";
import { LAYER_CAKE_FILTERS, useVariableValue } from "lib/featureFlags";
import { useDialogManager } from "lib/providers/DialogManager";

import css from "./Keywords.module.scss";

type Props = {
  valid: boolean;
  values: string[];
  onValuesChange: (newVals: string[] | undefined) => void;
  feedPreviewLoading: boolean;
};

export default function KeywordsCollapseContent({
  valid,
  values,
  onValuesChange,
  keywordsCounts,
  feedPreviewLoading,
  ...advancedProps
}: Props & KeywordsAdvancedProps & { keywordsCounts: MatchCountMap | undefined }): JSX.Element {
  const layerCakeFiltersEnabled = useVariableValue(LAYER_CAKE_FILTERS, false);
  const dialogManager = useDialogManager();
  return (
    <>
      <Form.Item
        validateStatus={valid ? undefined : "error"}
        help={valid ? undefined : "Please choose at least one keyword"}
      >
        <KeywordsField
          feedPreviewLoading={feedPreviewLoading}
          showSuggestedKeywords
          values={values}
          keywordCounts={keywordsCounts}
          label="Your selected keywords"
          onChange={onValuesChange}
        />
      </Form.Item>
      <div>
        {layerCakeFiltersEnabled ? (
          <Button
            className={css.infoButton}
            type="primary"
            ghost
            onClick={(e) => {
              e.stopPropagation();
              dialogManager.openDialog(TextSearchTipsModal, {});
            }}
          >
            <InfoCircleOutlined />
            Advanced keyword search tips
          </Button>
        ) : (
          <KeywordsAdvancedSetings {...advancedProps} />
        )}
      </div>
    </>
  );
}

type KeywordsAdvancedProps = {
  exclude: string[] | undefined;
  cpvCodes: string[] | undefined;
  cpvCodesIncludeNull: boolean | undefined;
  onExcludeChange: (newWords: string[]) => void;
  onCpvCodesChange: (newCodes: string[]) => void;
  onCpvCodesIncludeNullChange: (newState: CheckboxChangeEvent) => void;
};

function KeywordsAdvancedSetings({
  exclude,
  cpvCodes,
  cpvCodesIncludeNull,
  onExcludeChange,
  onCpvCodesChange,
  onCpvCodesIncludeNullChange,
}: KeywordsAdvancedProps) {
  const dialogManager = useDialogManager();
  return (
    <AdvancedSettings
      collapseKey="keywords-advanced-settings"
      action={
        <Button
          className={css.infoButton}
          type="primary"
          ghost
          onClick={(e) => {
            e.stopPropagation();
            dialogManager.openDialog(TextSearchTipsModal, {});
          }}
        >
          <InfoCircleOutlined />
          Advanced keyword search tips
        </Button>
      }
    >
      <>
        <Form.Item>
          <KeywordsField
            label="Exclude notices that contain these keywords:"
            hintLabel={
              <span>
                This field also supports{" "}
                <TextLink to="#" onClick={() => dialogManager.openDialog(TextSearchTipsModal, {})}>
                  <b>advanced keyword search</b>
                </TextLink>
              </span>
            }
            hintTooltip={
              <span>
                Use this to exclude any results that have the keywords entered here. E.g., if you
                have architect as a keyword, you may want to exclude notices that contain data
                architect or system architect, if you're only looking for architecture relating to
                buildings.{" "}
                <TextLink to={HELP_EDITING_YOUR_FEED_URL} showIcon={false}>
                  Learn more
                </TextLink>
              </span>
            }
            onChange={onExcludeChange}
            values={exclude}
          />
        </Form.Item>
        <div className={css.cpvFilterContainer}>
          <label className={css.settingsLabel} htmlFor="partner-multiselect">
            Filter notices by these CPV codes:
            <InfoButton
              tooltipOverlayStyle={{ minWidth: 400 }}
              note={
                <span>
                  Use this to only return results that contain one of the keywords entered AND also
                  belong to one of the CPV codes included. TIP: Go a bit broader with the CPV code
                  in case buyers have mis-categorised the notices.{" "}
                  <TextLink to={HELP_EDITING_YOUR_FEED_URL} showIcon={false}>
                    Learn more
                  </TextLink>
                </span>
              }
            />
          </label>
          <Form.Item>
            <CpvCodeSelector
              selectedCodes={cpvCodes || []}
              onChange={onCpvCodesChange}
              placeholder="Search for CPV code or description"
            />
          </Form.Item>
          <Checkbox
            defaultChecked={true}
            disabled={!cpvCodes || cpvCodes.length < 1}
            className={css.keywordsNullCheckbox}
            checked={cpvCodesIncludeNull}
            onChange={onCpvCodesIncludeNullChange}
          >
            Include notices where no sector information exists
          </Checkbox>
        </div>
      </>
    </AdvancedSettings>
  );
}
