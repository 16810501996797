import React, { useMemo } from "react";

import SignalEmptyState from "components/form_components/SignalEmptyState";
import Signal from "lib/icons/Signal";
import ClassificationListSelect, { Option } from "../ClassificationListSelect";
import Dropdown from "../Dropdown";
import { CpvCode, findMatchingCpvCodes, getCpvPrefix } from "./cpv_code_utils";
import CpvTreeSelect from "./CpvTreeSelect";
import { CpvTreeNode } from "./useCpvTree";

import css from "./CpvDropdown.module.scss";

type Props = {
  cpvTree: CpvTreeNode[];
  allCpvCodes?: CpvCode[];
  signalCpvCodes?: CpvCode[];
  isLoading: boolean;
  isOpen: boolean;
  textSearch: string;
  value: string[];
  signalCodeIds: string[];
  onChange: (newValue: string[]) => void;
};

function CpvDropdown({
  isOpen,
  textSearch,
  value,
  signalCodeIds,
  onChange,
  isLoading,
  cpvTree,
  allCpvCodes = [],
  signalCpvCodes = [],
}: Props) {
  const signalCpvcodeOptions = signalCpvCodes.map((cpv) => ({
    label: `${cpv.code}: ${cpv.name}`,
    value: cpv.code,
    icon: <Signal className={css.signalIcon} aria-label="signalIcon" />,
  }));

  const filteredCpvCodes = useMemo(() => {
    if (textSearch.length === 0 || !cpvTree.length) {
      return [];
    }
    return findMatchingCpvCodes(cpvTree, textSearch, textSearch.match(/^\d+$/) !== null);
  }, [textSearch, cpvTree]);

  const categoryToLabel = {
    signalCpvCodes: (
      <label className={css.label}>
        <Signal className={css.signalIcon} />
        CPV Code signals{` (${signalCodeIds.length})`}
      </label>
    ),
    browseCpvCodes: "Browse CPV Codes",
  };

  const isChecked = (option: Option): boolean => {
    if (value.includes(option.value)) {
      return true;
    }

    return !!value.find((v) => {
      const cpvPrefix = getCpvPrefix(v);
      return option.value.startsWith(cpvPrefix);
    });
  };

  const categoryToContent = {
    signalCpvCodes: (
      <ClassificationListSelect
        classificationLabel={
          <>
            <Signal aria-label="signalIcon" />
            {`CPV Code signals (${signalCodeIds.length})`}
          </>
        }
        options={signalCpvcodeOptions}
        value={value}
        onChange={onChange}
        isChecked={isChecked}
        allCpvCodes={allCpvCodes}
        emptyState={
          <SignalEmptyState
            entity="CPV Code"
            message="Save CPV Codes via your signal settings to uncover more renewal opportunities and achieve relevant results."
          />
        }
      />
    ),

    browseCpvCodes: (
      <CpvTreeSelect
        value={value}
        onChange={onChange}
        signalCodeIds={signalCodeIds}
        cpvTree={cpvTree}
        isLoading={isLoading}
      />
    ),

    searchCpvCodes: (
      <CpvTreeSelect
        value={value}
        onChange={onChange}
        signalCodeIds={signalCodeIds}
        cpvTree={filteredCpvCodes}
        isLoading={isLoading}
        expand={true}
      />
    ),
  };

  return (
    <div>
      {isOpen ? (
        <Dropdown
          isLoading={isLoading}
          isOpen={isOpen}
          textSearch={textSearch}
          value={value}
          description="Search the CPV Codes you want to track"
          categoryToLabel={categoryToLabel}
          categoryToContent={categoryToContent}
          textSearchCategory="searchCpvCodes"
        />
      ) : null}
    </div>
  );
}

export default CpvDropdown;
