import * as React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Alert } from "antd"; // upgrade and verify
import { App, ConfigProvider } from "antd5";
import classNames from "classnames";

import { DefaultTheme } from "components/app_layout/DefaultTheme";
import { ReportSubscriptionContextProvider } from "components/reports/ReportSubscriptionProvider";
import { UserDataProvider } from "lib/data_providers/UserDataProvider";
import { IntegrationAPIProvider } from "lib/integrationApiContext";
import { OpenAPIProvider, STOTLES_OPEN_API } from "lib/openApiContext";
import { DialogManagerProvider } from "lib/providers/DialogManager";
import { RecordViewerProvider } from "lib/providers/RecordViewer";
import { AdminAPIProvider } from "lib/stotlesAdminApiContext";
import StotlesAPI from "lib/StotlesApi";
import { ApiContextProvider } from "lib/stotlesApiContext";
import {
  CreateStotlesDataProvidersContainer,
  StotlesDataContextProvider,
} from "../../lib/providers/StotlesData";

import css from "./AdminComponent.module.scss";

const REACT_QUERY_CLIENT = new QueryClient();
const STOTLES_API = new StotlesAPI();
const STOTLES_DATA_PROVIDERS_CONTAINER = CreateStotlesDataProvidersContainer(
  STOTLES_API,
  STOTLES_OPEN_API,
);

type AdminErrorBoundaryState = {
  error: Error | undefined;
};

type AdminErrorBoundaryProps = {
  children: React.ReactNode;
};
class AdminErrorBoundary extends React.Component<AdminErrorBoundaryProps, AdminErrorBoundaryState> {
  constructor(props: AdminErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  // We don't override `componentDidCatch` so that errors bubble up to window.onerror
  // and can be caught by sentry

  render() {
    if (this.state.error) {
      return (
        <Alert
          type="error"
          message="Error occurred"
          showIcon
          description={
            <div>
              <p>{this.state.error.message}</p>
              <pre>{this.state.error.stack}</pre>
            </div>
          }
        />
      );
    }
    return this.props.children;
  }
}

type AdminComponentOptions = {
  fullHeight?: boolean;
};

export function WrapAdminComponent<Props>(
  Component: React.ComponentType<Props>,
  options?: AdminComponentOptions,
) {
  return (props: Props): JSX.Element => (
    <ConfigProvider prefixCls="ant5" theme={DefaultTheme}>
      <App className={classNames({ [css.fullHeightAdminApp]: !!options?.fullHeight })}>
        <QueryClientProvider client={REACT_QUERY_CLIENT}>
          <ApiContextProvider api={STOTLES_API}>
            <OpenAPIProvider>
              <UserDataProvider>
                <StotlesDataContextProvider instance={STOTLES_DATA_PROVIDERS_CONTAINER}>
                  <AdminAPIProvider>
                    <IntegrationAPIProvider>
                      <AdminErrorBoundary>
                        <RecordViewerProvider>
                          {/** TODO: Remove this provider once we can inject mock Subscription/ProHelper providers */}
                          <ReportSubscriptionContextProvider>
                            <DialogManagerProvider>
                              <Component {...props} />
                            </DialogManagerProvider>
                          </ReportSubscriptionContextProvider>
                        </RecordViewerProvider>
                      </AdminErrorBoundary>
                    </IntegrationAPIProvider>
                  </AdminAPIProvider>
                </StotlesDataContextProvider>
              </UserDataProvider>
            </OpenAPIProvider>
          </ApiContextProvider>
        </QueryClientProvider>
      </App>
    </ConfigProvider>
  );
}
