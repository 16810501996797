import React, { useCallback } from "react";
import { CopyOutlined, DeleteOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Menu, Tooltip } from "antd"; //upgrade and verify

import {
  canEditList,
  DeleteListModal,
  DuplicateListModal,
  RenameListModal,
} from "components/user_lists/UserListsModals";
import DropdownMenu from "lib/core_components/DropdownMenu";
import { GetRecordListsResponseResult } from "lib/generated/app-api";
import { useDialogManager } from "lib/providers/DialogManager";
import { EventDataTypes, TrackingProvider, useTracking } from "lib/tracking";

import css from "./EditListMenu.module.scss";

function OverlayMenu(list: GetRecordListsResponseResult) {
  const dialogManager = useDialogManager();
  const { trackingData } = useTracking();

  const canEdit = canEditList(list);

  const onRename = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      if (!list || !canEdit) {
        return;
      }
      dialogManager.openDialog(RenameListModal, {
        list: list,
        trackingData,
      });
    },
    [list, canEdit, dialogManager, trackingData],
  );

  const onDuplicate = useCallback(
    (e: Event) => {
      e.stopPropagation();
      if (!list) {
        return;
      }
      dialogManager.openDialog(DuplicateListModal, {
        list: list,
        trackingData,
      });
    },
    [dialogManager, list, trackingData],
  );

  const onDelete = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      if (!list || !canEdit) {
        return;
      }
      dialogManager.openDialog(DeleteListModal, {
        list: list,
        trackingData,
      });
    },
    [canEdit, dialogManager, list, trackingData],
  );

  return (
    <Menu>
      <Menu.Item onClick={(e) => onDuplicate(e.domEvent)}>
        <CopyOutlined />
        Duplicate
      </Menu.Item>
      <Menu.Item disabled={!canEdit}>
        <Tooltip
          title={canEdit ? undefined : "You can’t rename this list because you are not the owner."}
        >
          <div className={css.menuItem} onClick={(e) => onRename(e)}>
            <EditOutlined />
            Rename
          </div>
        </Tooltip>
      </Menu.Item>

      <Menu.Item disabled={!canEdit}>
        <Tooltip
          title={canEdit ? undefined : "You can’t delete this list because you are not the owner."}
        >
          <div className={css.menuItem} onClick={(e) => onDelete(e)}>
            <DeleteOutlined />
            Delete
          </div>
        </Tooltip>
      </Menu.Item>
    </Menu>
  );
}

type UserListProps = {
  list: GetRecordListsResponseResult;
};

export function EditListMenu({ list }: UserListProps) {
  return (
    <TrackingProvider
      data={{ "List name": list.name, "List id": list.id, "Data type": EventDataTypes.recordList }}
    >
      <DropdownMenu overlay={OverlayMenu(list)} placement="bottomRight">
        <Button onClick={(e) => e.stopPropagation()}>
          Actions
          <DownOutlined />
        </Button>
      </DropdownMenu>
    </TrackingProvider>
  );
}
