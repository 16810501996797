import React from "react";
import type { TabsProps } from "antd5";
import { Tabs } from "antd5";
import { Route, Switch, useLocation } from "wouter";

import { SubRoute } from "lib/core_components/SubRoute";
import NotificationSettingsProvider from "lib/providers/NotificationSettings";
import { Index } from "../Notifications";
import IntegrationNotifications from "../notifications/IntegrationNotifications";
import { EmailNotifications } from "./EmailNotifications";

import css from "./NotificationsPage.module.scss";

const NOTIFICATION_TAB_KEYS = {
  email: "email",
  slack: "slack",
  teams: "teams",
};

const getActiveTabKey = (location: string) => {
  switch (location) {
    case "/":
      return NOTIFICATION_TAB_KEYS.email;
    case "/email":
      return NOTIFICATION_TAB_KEYS.email;
    case "/slack":
      return NOTIFICATION_TAB_KEYS.slack;
    case "/teams":
      return NOTIFICATION_TAB_KEYS.teams;
    // no explicit default case as it interferes with the current teams/slack routing
  }
};

export default function NotificationsPage() {
  const [location, setLocation] = useLocation();

  const items: TabsProps["items"] = [
    {
      key: NOTIFICATION_TAB_KEYS.email,
      label: "Email notifications",
      children: <EmailNotifications />,
    },
    {
      key: NOTIFICATION_TAB_KEYS.slack,
      label: "Slack notifications",
      children: (
        <NotificationSettingsProvider>
          <Switch>
            <Route path="/" component={Index} />
            <SubRoute path="/slack/:rest*">
              <IntegrationNotifications type="SLACK" />
            </SubRoute>
          </Switch>
        </NotificationSettingsProvider>
      ),
    },
    {
      key: NOTIFICATION_TAB_KEYS.teams,
      label: "Teams notifications",
      children: (
        <NotificationSettingsProvider>
          <Switch>
            <Route path="/" component={Index} />
            <SubRoute path="/teams/:rest*">
              <IntegrationNotifications type="TEAMS" />
            </SubRoute>
          </Switch>
        </NotificationSettingsProvider>
      ),
    },
  ];

  return (
    <div className={css.pageWrapper}>
      <div className={css.pageTitle}>Notifications</div>
      <div className={css.pageDescription}>
        Configure your notifications settings to decide what you want to be notified on.
      </div>
      <Tabs
        activeKey={getActiveTabKey(location)}
        items={items}
        className={css.tabs}
        onChange={(key) => setLocation(`/${key}`)}
      />
    </div>
  );
}
