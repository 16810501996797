import React from "react";
import { DownloadOutlined, DownOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd"; // could it be a ButtonLink?

import TextButton from "components/actions/TextButton";
import SignalsContainer from "components/tags/SignalsContainer";
import { Table } from "lib/core_components/Table";
import { Signal } from "lib/StotlesApi";
import { signalsToLeadSignals } from "lib/tagUtils";
import { useTracking } from "lib/tracking";
import * as tracking from "lib/tracking";
import { DocumentMatch } from "lib/types/models";
import { DocumentType, documentTypeDisplay } from "./documentUtils";

import css from "./DocumentGroup.module.scss";

type DocumentGroupProps = {
  name: DocumentType;
  signals: Record<string, Signal> | undefined;
  documents: DocumentMatch[];
  onDocumentClick: (documentMatch: DocumentMatch) => void;
};

function getGroupSubheading(docType: DocumentType): string {
  switch (docType) {
    case DocumentType["Key Corporate reports"]:
      return "Delivery plans, Budgets, Commercial pipelines";
    case DocumentType["Transparency"]:
      return "FOI Releases, Meeting minutes, Other transparency data";
    // TODO: fill out other headings when they are defined...
  }

  return "";
}

const GET_ROW_CLASS_NAME = () => css.tableDocRow;
const SUMMARY_DOC_COUNT = 5;

export function DocumentGroup({
  name,
  signals,
  documents,
  onDocumentClick,
}: DocumentGroupProps): JSX.Element {
  const [displayMode, setDisplayMode] = React.useState<"Hidden" | "Summary" | "All">(
    name == DocumentType["Key Corporate reports"] || name == DocumentType["Other Corporate reports"]
      ? "Summary"
      : "Hidden",
  );

  const { logEvent } = useTracking();

  const documentData = React.useMemo(() => {
    let docs = documents.sort(
      (a, b) =>
        Number(b.signal_ids.length > 0) - Number(a.signal_ids.length > 0) ||
        (b.document.published_at ?? "").localeCompare(a.document.published_at ?? ""),
    );
    const signalDocs = documents.filter((x) => x.signal_ids.length > 0);

    if (docs.length > SUMMARY_DOC_COUNT) {
      if (displayMode == "Summary") {
        if (signalDocs.length > 0) {
          docs = signalDocs.slice(0, SUMMARY_DOC_COUNT);
        } else {
          docs = docs.slice(0, SUMMARY_DOC_COUNT);
        }
      }
    }

    const groupSignals = [
      ...new Set(([] as string[]).concat(...documents.map((x) => x.signal_ids))),
    ];

    return { signalDocsCount: signalDocs.length, documents: docs, groupSignals };
  }, [displayMode, documents]);

  const columns = [
    {
      title: "Signals",
      dataIndex: "signal_ids" as const,
      key: "signal_ids",
      render: (_: string, documentMatch: DocumentMatch) => {
        return (
          signals && (
            <SignalsContainer
              contextSource="In-row"
              signals={signalsToLeadSignals(documentMatch.signal_ids, signals)}
              maxSignals={3}
            />
          )
        );
      },
    },
    {
      title: "Title",
      key: "title",
      render: (_: string, documentMatch: DocumentMatch) => {
        return (
          <span className={css.title}>
            {documentMatch.document.title ?? documentMatch.document.name}
          </span>
        );
      },
    },
    {
      title: "Type",
      key: "type",
      render: (_: string, documentMatch: DocumentMatch) => {
        return documentTypeDisplay(documentMatch.document.document_type);
      },
    },
    {
      title: "Published Date",
      key: "published_at",
      render: (_: string, documentMatch: DocumentMatch) => {
        return documentMatch.document.published_at?.slice(0, 7);
      },
    },
    {
      key: "source",
      render: (_: string, documentMatch: DocumentMatch) => {
        return (
          <span className={css.cardDetails}>
            <a
              href={documentMatch.document.source_url}
              onClick={(e) => {
                logEvent(tracking.EventNames.documentSourceViewed, {
                  "Document Type": DocumentType[name],
                  Name: documentMatch.document.title ?? documentMatch.document.name,
                });
                e.stopPropagation();
              }}
              target="_blank"
            >
              View source
            </a>
          </span>
        );
      },
    },
    {
      key: "download",
      render: (_: string, documentMatch: DocumentMatch) => {
        return (
          <Tooltip title="Download">
            <Button
              href={`/api/documents/download?id=${documentMatch.document.id}`}
              onClick={(e) => {
                logEvent(tracking.EventNames.documentDownloaded, {
                  "Document Type": DocumentType[name],
                  Name: documentMatch.document.title ?? documentMatch.document.name,
                });
                e.stopPropagation();
              }}
              download
            >
              <DownloadOutlined />
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  const handleViewMore = () => {
    setDisplayMode("All");
    logEvent(tracking.EventNames.documentViewMoreClicked, {
      "Document Type": DocumentType[name],
    });
  };

  const handleViewDocuments = () => {
    setDisplayMode("Summary");
    logEvent(tracking.EventNames.documentViewClicked, {
      "Document Type": DocumentType[name],
    });
  };

  return (
    <div>
      <h3 className={css.documentGroupTitle}>{DocumentType[name]}</h3>
      <h4 className={css.documentSubheading}>{getGroupSubheading(name)}</h4>
      <p>
        {documentData.signalDocsCount} matched documents, {documents.length} total
      </p>
      {displayMode === "Hidden" ? (
        signals && (
          <SignalsContainer signals={signalsToLeadSignals(documentData.groupSignals, signals)} />
        )
      ) : (
        <Table
          className={css.documentTable}
          dataSource={documentData.documents}
          rowKey={(record: DocumentMatch) => {
            return record.document.id;
          }}
          columns={columns}
          rowClassName={GET_ROW_CLASS_NAME}
          onRow={(record, _) => {
            return {
              onClick: () => {
                onDocumentClick(record);
                logEvent(tracking.EventNames.documentPreviewClicked, {
                  "Document Type": DocumentType[name],
                  Name: record.document.title ?? record.document.name,
                });
              },
            };
          }}
          size="small"
          pagination={false}
        />
      )}
      {displayMode === "Summary" && documents.length != documentData.documents.length && (
        <TextButton onClick={handleViewMore}>
          View more <DownOutlined />
        </TextButton>
      )}
      {displayMode === "Hidden" && (
        <TextButton className={css.viewDocumentsButton} onClick={handleViewDocuments}>
          View Documents
        </TextButton>
      )}
    </div>
  );
}
