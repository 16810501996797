import { DateTime } from "luxon";

import BuyerChart from "components/spend_data/BuyerChart";
import SupplierChart from "components/spend_data/SupplierChart";
import { TransactionChart } from "components/spend_data/TransactionChart";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { SpendDataDto } from "lib/generated/spend-data-api/spendDataManagementAPI.schemas";
import { SpendDataFilters } from "./hooks/useSpendPageState";
import { CompetitorChart, PartnerChart } from "./SupplierChartGrouped";

type ChartComponent =
  | typeof TransactionChart
  | typeof BuyerChart
  | typeof PartnerChart
  | typeof CompetitorChart
  | typeof SupplierChart;

type GroupByView = {
  title: string;
  components: {
    table: {
      title: string;
    };
    chart: {
      component: ChartComponent;
    } | null;
  };
  isDisabled: (filterValues: SpendDataFilters) => boolean;
  disabledText: string;
};

// setting the actual from and to values as these are required for the backend and the date range component doesn't set these from defaults
export const DEFAULT_DATE_RANGE = {
  relativeFrom: "P-3Y",
  from: DateTime.now().minus({ years: 3 }).startOf("day").toISO() || undefined,
  relativeTo: "PT0S",
  to: DateTime.now().startOf("day").toISO() || undefined,
};

export type GroupByKey = "buyers" | "competitors" | "partners" | "allSuppliers" | "allTransactions";

export const ALL_GROUP_BYS: Record<GroupByKey, GroupByView> = {
  buyers: {
    title: "Buyers",
    components: {
      chart: { component: BuyerChart },
      table: { title: "Buyer rankings" },
    },
    isDisabled: () => false,
    disabledText: "",
  },
  competitors: {
    title: "Competitors",
    components: {
      chart: {
        component: CompetitorChart,
      },
      table: {
        title: "Competitor rankings",
      },
    },
    isDisabled: (filters) => (filters.competitors ? filters.competitors.length < 1 : true),
    disabledText: "Apply competitor filters to view transactions grouped by competitor",
  },
  partners: {
    title: "Partners",
    components: {
      chart: {
        component: PartnerChart,
      },
      table: {
        title: "Partner rankings",
      },
    },
    isDisabled: (filters) => (filters.partners ? filters.partners.length < 1 : true),
    disabledText: "Apply partner filters to view transactions grouped by partner",
  },
  allSuppliers: {
    title: "All suppliers",
    components: {
      chart: {
        component: SupplierChart,
      },
      table: {
        title: "All suppliers",
      },
    },
    isDisabled: () => false,
    disabledText: "",
  },
  allTransactions: {
    title: "All transactions",
    components: {
      chart: FeatureToggles.isEnabled(Feature.SPEND_CHARTS)
        ? {
            component: TransactionChart,
          }
        : null,
      table: {
        title: "All transactions",
      },
    },
    isDisabled: () => false,
    disabledText: "",
  },
};

export type PageView = {
  title: string;
  description: string;
  filters: SpendDataFilters;
  groupBy: GroupByKey;
};

export type TransactionTableRow = SpendDataDto & { descriptiveColumns?: Record<string, string> };
