import React from "react";
import { Tooltip } from "antd5";

import BookmarkIcon from "components/actions/Bookmark";
import { SendNoticeToCRM } from "components/integrations/SendNoticeToCRMButton";
import { NoticeSeenBy } from "components/record_details/SeenBy";
import CommentIcon from "lib/core_components/CommentIcon";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { RecordDto } from "lib/generated/app-api";
import { useMarkAsSeen } from "lib/hooks/api/records/useMarkAsSeen";
import { useRestricedViewNotice } from "lib/hooks/useRestrictedRowClick";
import { ProHelperDataTypes } from "lib/providers/ProHelper";
import * as tracking from "lib/tracking";
import { useTracking } from "lib/tracking";
import { EntityType } from "lib/types/graphQLEnums";
import { getRecordSignals, getSignalTypes, recordFromDto } from "lib/types/models";
import { joinToEnglishList } from "lib/utils";
import BuyersContainer from "./BuyersContainer";

import css from "./TitleCell.module.scss";

export function TitleCell({
  record,
  requiredDataType,
}: {
  record: RecordDto;
  requiredDataType?: ProHelperDataTypes;
}) {
  const { trackingData } = useTracking();
  const { viewRecord } = useRestricedViewNotice("Title click", requiredDataType, trackingData);
  const { mutate: markAsSeen } = useMarkAsSeen();

  const signals = getRecordSignals(recordFromDto(record));
  const signalTypes = getSignalTypes(signals);

  return (
    <div className={css.titleContainer}>
      <div className={css.titleCell}>
        <EllipsisTooltipTextLink
          fullText={record.name}
          linkProps={{
            to: record.canonicalUrlPath,
            onClick: (e) => {
              e.stopPropagation();
              e.preventDefault();
              viewRecord(record.guid);
            },
            className: css.titleText,
          }}
          linkText={record.name}
          tooltipProps={{ overlayClassName: css.tooltip }}
        />
        <BuyersContainer
          handleClick={() => markAsSeen({ recordGuid: record.guid })}
          recordName={record.name}
          buyers={record.buyers.map((b) => ({ id: b.id, name: b.name }))}
          showAsLinks={false}
        />
      </div>
      <div className={css.recordStatus}>
        {record.lists.length > 0 && (
          <Tooltip title={`Saved to ${joinToEnglishList(record.lists.map((l) => l.name))}`}>
            <div>
              <BookmarkIcon filled colour="grey" />
            </div>
          </Tooltip>
        )}
        <CommentIcon
          commentCount={record.commentCount || 0}
          procurementStageId={record.procurementStage.id}
        />
        <NoticeSeenBy seenByUsers={record.seenByUsers} />
        {!window.guestUser && (
          <tracking.TrackingProvider
            data={{
              "Context source": "In-row",
              "Record GUID": record.guid,
              "Signal score": record.relevanceScore ?? "0",
              Stage: record.stage,
              "Signal types": signalTypes ? Object.keys(signalTypes) : [],
              Signals: signalTypes,
            }}
          >
            <SendNoticeToCRM
              notice={{
                guid: record.guid,
                name: record.name,
                value: record.valueSrc ?? undefined,
                url: record.url,
                closeDate: record.closeDate ?? "",
                type: record.type as EntityType,
              }}
              displayInRow
            />
          </tracking.TrackingProvider>
        )}
      </div>
    </div>
  );
}
