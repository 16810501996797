import { DefaultOptionType } from "antd5/es/select";
import _ from "lodash";

import {
  ViewConfigurationFilterSettingsRelevanceScoreEnum,
  ViewConfigurationFilterSettingsSignals,
} from "lib/generated/app-api";
import {
  SearchFrameworksRequest,
  SearchFrameworksRequest_StartDate as DatesFilter,
} from "lib/generated/app-service-gql/graphql";
import { parseBooleanValue, parseNumberValue } from "lib/hooks/useURLState";
import {
  AwardType as FrameworkAwardType,
  BuyerParticipantType,
  SearchRequestAwardType,
  SearchRequestProcedureType as ProcedureType,
  SearchRequestSortOrder,
  SearchRequestStages,
} from "lib/types/graphQLEnums";
import { CountryRegion } from "lib/types/models";
import { isDefined } from "lib/utils";
import { mapSignalsToViewSignals } from "lib/utils/signalUtils";

type SortField =
  | "signalScore"
  | "title"
  | "procedureType"
  | "stage"
  | "awardType"
  | "value"
  | "suppliers"
  | "startDate"
  | "endDate"
  | "closeDate"
  | "callOffs";

const validSortfields: SortField[] = [
  "signalScore",
  "title",
  "procedureType",
  "stage",
  "awardType",
  "value",
  "suppliers",
  "startDate",
  "endDate",
  "closeDate",
  "callOffs",
];

export function isValidSortField(field?: string): SortField | undefined {
  if (field && validSortfields.includes(field as SortField)) {
    return field as SortField;
  }
  return undefined;
}

export type FrameworkFilters = {
  signals: string[];
  signalScore: ViewConfigurationFilterSettingsRelevanceScoreEnum[];
  stage?: SearchRequestStages[];
  procedureType?: string[];
  languages?: string[];
  buyerParticipantType?: BuyerParticipantType;
  awardType?: string[];
  buyerIds?: string[];
  buyerListIds?: string[];
  buyerCategories?: string[];
  buyerCountryRegions?: CountryRegion;
  supplierIds?: string[];
  searchText: string;
  value: { from?: number; to?: number; hideNulls?: boolean };
  closeDate?: DatesFilter;
  endDate?: DatesFilter;
  startDate?: DatesFilter;
  cpvDimensions?: string[];
  cpvCodes?: string[];
  keywords?: string[];
  excludeKeywords?: string[];
  sort?: {
    field: SortField;
    order: "ASC" | "DESC";
  };
};

type Pagination = {
  pageSize: number;
  current: number;
};

function formatSignals(signals: string[]) {
  const signalViewConfig: ViewConfigurationFilterSettingsSignals = mapSignalsToViewSignals(signals);
  return {
    categories: signalViewConfig.categories ? signalViewConfig.categories : [],
    signalIds: signalViewConfig.ids ? signalViewConfig.ids : [],
  };
}

export function convertFrameworkFiltersToRequest(
  filters: FrameworkFilters,
  pagination?: Pagination,
): SearchFrameworksRequest {
  return {
    signals: !_.isEmpty(filters.signals) ? formatSignals(filters.signals) : undefined,
    searchText: filters.searchText,
    procedureTypes: filters.procedureType?.map((type) => type as ProcedureType).filter(isDefined),
    // TODO: From a frontend perspective, how do we get the supported languages - Or should this be entirely backend resolved?
    languages: filters.languages,
    // Tests fail without casting, empty string is passed in as a buyerParticipantType
    buyerParticipantType: filters.buyerParticipantType
      ? (filters.buyerParticipantType as BuyerParticipantType)
      : undefined,
    buyerListGuids: filters.buyerListIds,
    buyerCountryCodes: filters.buyerCountryRegions?.countries,
    buyerRegionGuids: filters.buyerCountryRegions?.regions,
    buyerCategoryGuids: filters.buyerCategories,
    stages: filters.stage,
    buyerGuids: filters.buyerIds,
    supplierGuids: filters.supplierIds,
    startDate: filters.startDate,
    endDate: filters.endDate,
    // TODO: Add UI for last updated date filtering
    lastUpdatedDate: undefined,
    awardTypes: filters.awardType?.map((type) => type as SearchRequestAwardType).filter(isDefined),
    closeDate: filters.closeDate,
    value: {
      from: filters.value?.from,
      to: filters.value?.to,
      hideNulls: filters.value?.hideNulls,
    },
    cpvCodes: filters.cpvCodes,
    keywords: filters.keywords,
    excludeKeywords: filters.excludeKeywords,
    sortOrder: (filters.sort?.order as SearchRequestSortOrder) || SearchRequestSortOrder.Asc,
    sort: filters.sort?.field || "name",
    limit: pagination ? pagination.pageSize : 10,
    page: pagination?.current ?? 1,
  };
}

export const DEFAULT_FILTERS: FrameworkFilters = {
  signals: [],
  signalScore: [],
  searchText: "",
  value: {},
  closeDate: {},
  startDate: {},
  endDate: {},
  sort: {
    field: "signalScore",
    order: "DESC",
  },
};

export const DEFAULT_PAGINATION = { current: 1, pageSize: 10 };

export const PROCEDURE_TYPE_LABEL: Record<ProcedureType, string> = {
  [ProcedureType.Framework]: "Framework",
  [ProcedureType.DynamicPurchasingSystem]: "DPS",
};

export const PROCEDURE_TYPE_OPTIONS: DefaultOptionType[] = [
  { label: "Framework", value: ProcedureType.Framework },
  {
    label: "Dynamic purchasing system (DPS)",
    value: ProcedureType.DynamicPurchasingSystem,
  },
];

export const AWARD_TYPE_LABEL: Record<FrameworkAwardType, string> = {
  [FrameworkAwardType.CompetitionOnly]: "Competition Only",
  [FrameworkAwardType.CompetitionAndDirect]: "Competition & Direct",
  [FrameworkAwardType.DirectAward]: "Direct Only",
  [FrameworkAwardType.Other]: "Other",
};

export const AWARD_TYPE_OPTIONS: DefaultOptionType[] = [
  //   { label: "Call off", value: FrameworkAwardType.CallOff },
  {
    label: AWARD_TYPE_LABEL[FrameworkAwardType.CompetitionAndDirect],
    value: FrameworkAwardType.CompetitionAndDirect,
  },
  {
    label: AWARD_TYPE_LABEL[FrameworkAwardType.CompetitionOnly],
    value: FrameworkAwardType.CompetitionOnly,
  },
  {
    label: AWARD_TYPE_LABEL[FrameworkAwardType.DirectAward],
    value: FrameworkAwardType.DirectAward,
  },
  { label: AWARD_TYPE_LABEL[FrameworkAwardType.Other], value: FrameworkAwardType.Other },
];

export const BUYER_PARTICIPANT_OPTIONS = [
  { label: "All buyers", value: "" },
  { label: "Framework providers only", value: BuyerParticipantType.ProvidersOnly },
  {
    label: "Contracting authority only",
    value: BuyerParticipantType.ContractingAuthorityOnly,
  },
];

export function parseFrameworksUrlState(
  state: unknown,
  defaultFilters = DEFAULT_FILTERS,
): FrameworkFilters {
  if (typeof state !== "object" || state === null) {
    return defaultFilters;
  }

  const filters = state as FrameworkFilters;

  return {
    ...filters,
    value: {
      from: parseNumberValue(filters.value?.from),
      to: parseNumberValue(filters.value?.to),
      hideNulls: parseBooleanValue(filters.value?.hideNulls),
    },
    startDate: {
      ...filters.startDate,
      hideNulls: parseBooleanValue(filters.startDate?.hideNulls),
    },
    endDate: {
      ...filters.endDate,
      hideNulls: parseBooleanValue(filters.endDate?.hideNulls),
    },
    closeDate: {
      ...filters.closeDate,
      hideNulls: parseBooleanValue(filters.closeDate?.hideNulls),
    },
    signals: filters.signals ?? [], // Should always have a default value
  };
}
