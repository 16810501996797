import { Key } from "react";
import _ from "lodash";
import qs from "qs";

import { convertSignalArrayToSignalValue } from "components/form_components/SignalSelect";
import {
  DEFAULT_NOTICE_COLUMNS,
  DEFAULT_NOTICE_SORT_ORDER,
} from "components/my_feed/useMyFeedPageState";
import { FrameworkFilterOptions, RecordFilters } from "components/record_search/types";
import {
  ColumnSetting,
  DateSetting,
  RecordSearchRequestDto,
  RecordSearchRequestDtoFrameworksFrameworkRelationshipEnum as FrameworkRelationship,
  RecordSearchRequestDtoProcurementStageQualificationsEnum as QualificationsEnum,
  ViewConfiguration,
  ViewConfigurationFilterSettings,
  ViewConfigurationFilterSettingsRelevanceScoreEnum,
  ViewConfigurationSortOrder,
  ViewConfigurationTableSettings,
} from "lib/generated/app-api";
import { parseBooleanValue } from "lib/hooks/useURLState";
import { CountryRegion, Status, TenderStage } from "lib/types/models";
import { mapSignalsToViewSignals, SIGNAL_SCORE_MAP } from "lib/utils/signalUtils";
import { NoticesSearchRequest } from "../../lib/generated/app-service-gql/graphql";
import {
  NoticesQualifications as NoticeQualification,
  NoticesQualifications,
  NoticesSort as SortEnum,
  NoticesSortOrder as SortOrder,
  NoticesStages as Stages,
  SearchRequestQualifications as SearchReqQualification,
  SupplierMentionType,
} from "../../lib/types/graphQLEnums";
import { SortState } from "../buyer_details/types";

type DateFilter = { from?: string; relativeFrom?: string; to?: string; relativeTo?: string };

export type NoticeFilters = {
  procurementStageQualifications: QualificationsEnum[];
  signals: string[];
  assignee: string[];
  suppliers: number[];
  supplierGuids?: string[];
  supplierSme: boolean | string;
  buyerListIds: string[];
  buyers: string[];
  // This is only required for compatibility with report pages.
  // The buyer ids will be converted to buyer guids before page loads,
  // so we don't have to support this in the UI.
  // @deprecated - use GUIDS in buyers filter.
  buyerIds?: number[];
  buyerCategories: string[];
  buyerCountryRegions: CountryRegion;
  text: string;
  value: { from?: number; to?: number; hideNulls?: boolean };
  stage: Status[];
  relevanceScore: ViewConfigurationFilterSettingsRelevanceScoreEnum[];
  closeDate: { filter?: DateFilter; hideNulls?: boolean };
  expiryDate: { filter?: DateFilter; hideNulls?: boolean };
  publishDate: DateFilter;
  cpvDimensions: string[];
  noticeLists?: string[];
  frameworkIds: string[];
  supplierMentionType?: SupplierMentionType;
  frameworkFilterOptions: FrameworkFilterOptions | string;
  keywords: string[];
  excludeKeywords: string[];
  cpvCodes: string[];
  sort: {
    field: string;
    order: "ASC" | "DESC";
  };
};

export type FilterSection = "buyers" | "suppliers" | "frameworks";

type Pagination = {
  pageSize: number;
  current: number;
};

// map between rails enums to GQL enum
const qualificationMap = new Map<QualificationsEnum, SearchReqQualification>([
  [QualificationsEnum.NotRelevant, SearchReqQualification.NotRelevant],
  [QualificationsEnum.InReview, SearchReqQualification.InReview],
  [QualificationsEnum.BidPrep, SearchReqQualification.BidPrep],
  [QualificationsEnum.BidSubmitted, SearchReqQualification.BidSubmitted],
  [QualificationsEnum.NoBid, SearchReqQualification.NoBid],
  [QualificationsEnum.Won, SearchReqQualification.Won],
  [QualificationsEnum.Lost, SearchReqQualification.Lost],
  [QualificationsEnum.PreEngageToDo, SearchReqQualification.PreEngageToDo],
  [QualificationsEnum.PreEngageDone, SearchReqQualification.PreEngageDone],
  [QualificationsEnum.PreEngageNotRelevant, SearchReqQualification.PreEngageNotRelevant],
  [QualificationsEnum.Unqualified, SearchReqQualification.Unqualified],
]);

function formatDateToView(date: {
  filter?: DateFilter;
  hideNulls?: boolean;
}): DateSetting | undefined {
  const { filter } = date;
  if (
    _.isEmpty(date) ||
    (!filter?.from && !filter?.to && !filter?.relativeFrom && !filter?.relativeTo) ||
    !date
  ) {
    return undefined;
  } else {
    return {
      from: filter.from ? new Date(filter.from) : undefined,
      to: filter.to ? new Date(filter.to) : undefined,
      relativeFrom: filter.relativeFrom,
      relativeTo: filter.relativeTo,
    };
  }
}

export function convertNoticeFiltersToView(
  noticeFilters: NoticeFilters,
  columns?: ColumnSetting[],
): ViewConfiguration {
  const filterSettings: ViewConfigurationFilterSettings = {
    text: noticeFilters.text ? noticeFilters.text : undefined,
    stage: noticeFilters.stage.length > 0 ? noticeFilters.stage : undefined,
    buyerIds: undefined,
    buyerGuids: noticeFilters.buyers,
    closeDate: formatDateToView(noticeFilters.closeDate),
    closeNulls:
      noticeFilters.closeDate?.hideNulls !== undefined
        ? !noticeFilters.closeDate?.hideNulls
        : undefined,
    cpvDimensions: noticeFilters.cpvDimensions, // industry filter
    expiryDate: formatDateToView(noticeFilters.expiryDate),
    expiryNulls:
      noticeFilters.expiryDate?.hideNulls !== undefined
        ? !noticeFilters.expiryDate?.hideNulls
        : undefined,
    publishDate: formatDateToView({ filter: noticeFilters.publishDate }),
    userTagIds: [],
    buyerCountryCodes: noticeFilters.buyerCountryRegions.countries,
    buyerRegionIds: noticeFilters.buyerCountryRegions.regions,
    indicatorTagIds: [],
    buyerCategoryId: noticeFilters.buyerCategories,
    buyerCategoryNulls: false,
    value: !_.isEmpty(noticeFilters.value)
      ? {
          from: noticeFilters.value?.from,
          to: noticeFilters.value?.to,
          nulls: undefined,
        }
      : undefined,
    valueNulls:
      noticeFilters.value?.hideNulls !== undefined ? !noticeFilters.value?.hideNulls : undefined,
    showUserHiddenLeads: "1",
    qualificationStates: undefined,
    includeAllDisqualifiedStates: false,
    assignee: noticeFilters.assignee.length > 0 ? noticeFilters.assignee : undefined,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    suppliers:
      _.isEmpty(noticeFilters.suppliers) &&
      _.isEmpty(noticeFilters.supplierGuids) &&
      noticeFilters.supplierSme === ""
        ? undefined
        : {
            id: noticeFilters.suppliers,
            guid: noticeFilters.supplierGuids,
            isSme: noticeFilters.supplierSme === "" ? null : noticeFilters.supplierSme,
          },
    procurementStageQualifications:
      noticeFilters.procurementStageQualifications.length > 0
        ? noticeFilters.procurementStageQualifications
        : undefined,
    relevanceScore: noticeFilters.relevanceScore,
    buyerListIds: noticeFilters.buyerListIds.length > 0 ? noticeFilters.buyerListIds : undefined,
    signalCategory: undefined,
    signals: !_.isEmpty(noticeFilters.signals)
      ? mapSignalsToViewSignals(noticeFilters.signals)
      : { categories: [], ids: [] },
    noticeLists:
      noticeFilters.noticeLists && noticeFilters.noticeLists.length > 0
        ? noticeFilters.noticeLists.map((noticeList) => Number(noticeList))
        : undefined,
    keywords: noticeFilters.keywords.length > 0 ? noticeFilters.keywords : undefined,
    excludeKeywords:
      noticeFilters.excludeKeywords.length > 0 ? noticeFilters.excludeKeywords : undefined,
    cpvCodes: noticeFilters.cpvCodes.length > 0 ? noticeFilters.cpvCodes : undefined,
    frameworks: {
      ids: noticeFilters.frameworkIds,
      frameworkRelationship: convertFrameworkRelationshipFilter(
        noticeFilters.frameworkFilterOptions,
      ),
    },
  };

  const sortOrder: ViewConfigurationSortOrder = {
    field: noticeFilters.sort.field,
    order: noticeFilters.sort.order === "ASC" ? "ASC" : "DESC",
  };

  const tableSettings: ViewConfigurationTableSettings = {
    columns: columns || DEFAULT_NOTICE_COLUMNS,
  };

  return {
    filterSettings,
    sortOrder,
    tableSettings,
  };
}

export function convertNoticeFiltersToApi(
  filters: NoticeFilters,
  pagination?: Pagination,
  isPreview = false,
): RecordSearchRequestDto {
  if (filters.buyerCategories) {
    // handles when the parent category is selected as it is stringified array
    filters.buyerCategories = filters.buyerCategories.reduce<string[]>((acc, val) => {
      val.split(",").map((v) => acc.push(v));
      return acc;
    }, []);
  }

  const recordListIds = filters.noticeLists
    ? filters.noticeLists.map((noticeList) => Number(noticeList))
    : undefined;

  const data: RecordSearchRequestDto = {
    anySignals: convertSignalArrayToSignalValue(filters.signals),
    procurementStageQualifications: filters.procurementStageQualifications,
    assignee: filters.assignee,
    buyerListIds: filters.buyerListIds,
    buyers: {
      guid: filters.buyers,
      categories: filters.buyerCategories.length > 0 ? { id: filters.buyerCategories } : undefined,
      regionIds: filters.buyerCountryRegions.regions,
      countryCode: filters.buyerCountryRegions.countries,
    },
    suppliers: {
      id: filters.suppliers,
      isSme: parseBooleanValue(filters.supplierSme),
      guid: filters.supplierGuids,
    },
    relevanceScore: filters.relevanceScore,
    cpvDimensions: filters.cpvDimensions,
    text: filters.text,
    value: {
      from: filters.value?.from,
      to: filters.value?.to,
      includeNulls: !filters.value?.hideNulls,
    },
    stage: filters.stage,
    closeDate: {
      filter: {
        ...filters.closeDate?.filter,
        from: filters.closeDate?.filter?.from
          ? new Date(filters.closeDate?.filter?.from)
          : undefined,
        to: filters.closeDate.filter?.to ? new Date(filters.closeDate.filter?.to) : undefined,
      },
      includeNulls: !filters.closeDate?.hideNulls,
    },
    expiryDate: {
      filter: {
        ...filters.expiryDate?.filter,
        from: filters.expiryDate?.filter?.from
          ? new Date(filters.expiryDate?.filter?.from)
          : undefined,
        to: filters.expiryDate.filter?.to ? new Date(filters.expiryDate.filter?.to) : undefined,
      },
      includeNulls: !filters.expiryDate?.hideNulls,
    },
    publishDate: {
      ...filters.publishDate,
      from: filters.publishDate.from ? new Date(filters.publishDate.from) : undefined,
      to: filters.publishDate.to ? new Date(filters.publishDate.to) : undefined,
    },
    limit: pagination ? pagination.pageSize : undefined,
    offset: pagination ? (pagination.current - 1) * pagination.pageSize : undefined,
    sort:
      filters.sort?.field === "signals"
        ? DEFAULT_NOTICE_SORT_ORDER.field
        : filters.sort?.field || DEFAULT_NOTICE_SORT_ORDER.field,
    recordListIds: recordListIds,
    sortOrder: filters.sort?.order || DEFAULT_NOTICE_SORT_ORDER.order,
    signalSort: filters.sort?.field === "signals" ? filters.sort?.order : undefined,
    preview: isPreview,
    frameworks: {
      ids: filters.frameworkIds,
      frameworkRelationship: convertFrameworkRelationshipFilter(filters.frameworkFilterOptions),
    },
  };

  return data;
}

export function convertNoticeFiltersToSearchRequest(
  filters: NoticeFilters,
  pagination?: Pagination,
): NoticesSearchRequest {
  if (filters.buyerCategories) {
    filters.buyerCategories = filters.buyerCategories.reduce<string[]>((acc, val) => {
      val.split(",").map((v) => acc.push(v));
      return acc;
    }, []);
  }

  const sortFields = mapToSortOrder(filters.sort);
  const signals = convertSignalArrayToSignalValue(filters.signals);

  const data: NoticesSearchRequest = {
    signals: {
      // TODO: fix this
      categories: signals.categories,
      signalIds: signals.ids,
    },
    buyerFilters: {
      buyers: filters.buyers,
      buyerCategories: filters.buyerCategories,
      buyerCountryCodes: filters.buyerCountryRegions.countries,
      buyerLists: filters.buyerListIds,
      buyerRegions: filters.buyerCountryRegions.regions,
    },
    cpvCodes: filters.cpvCodes,
    suppliers: filters.supplierGuids,
    isSME: getSupplierIsSME(filters.supplierSme),
    searchText: filters.text,
    value: {
      from: filters.value?.from,
      to: filters.value?.to,
      hideNulls: filters.value?.hideNulls,
    },
    stages: mapToStages(filters.stage),
    closeDate: {
      filter: {
        ...filters.closeDate?.filter,
        from: filters.closeDate?.filter?.from,
        to: filters.closeDate.filter?.to,
      },
      hideNulls: filters.closeDate?.hideNulls,
    },
    expiryDate: {
      filter: {
        ...filters.expiryDate?.filter,
        from: filters.expiryDate?.filter?.from,
        to: filters.expiryDate.filter?.to,
      },
      hideNulls: filters.expiryDate?.hideNulls,
    },
    publishDate: {
      filter: {
        from: filters.publishDate?.from,
        to: filters.publishDate?.to,
        relativeFrom: filters.publishDate?.relativeFrom,
        relativeTo: filters.publishDate?.relativeTo,
      },
    },
    supplierMentionType: Object.values(SupplierMentionType).includes(
      filters.supplierMentionType as SupplierMentionType,
    )
      ? (filters.supplierMentionType as SupplierMentionType)
      : SupplierMentionType.MentionedOrDirectlyAwarded,
    qualifications: !_.isEmpty(filters.procurementStageQualifications)
      ? mapToNoticeQualifications(filters.procurementStageQualifications)
      : undefined,
    assignees: filters.assignee,
    minScore: getMaxRelevanceScore(filters.relevanceScore),
    keywords: filters.keywords,
    excludeKeywords: filters.excludeKeywords,
    limit: pagination?.pageSize ?? 10,
    page: pagination?.current ?? 1,
    sort: sortFields.field,
    sortOrder: sortFields.order,
  };

  return data;
}

/**
 * This function is for backwards compatibility reasons, in case a user created a view with multiple relevance scores
 * @param relevanceScores
 * @returns
 */
function getMaxRelevanceScore(
  relevanceScores: ViewConfigurationFilterSettingsRelevanceScoreEnum[],
): number | undefined {
  const maxScore = Math.max(...relevanceScores.map((score) => SIGNAL_SCORE_MAP.get(score) || 0));

  return maxScore > 0 ? maxScore : undefined;
}

function mapToStages(stages: Status[]) {
  const getStage = (stage: Status) => {
    switch (stage) {
      case "OPEN":
        return Stages.Open;
      case "STALE_PRE_TENDER":
        return Stages.StalePreTender;
      case "PRE_TENDER":
        return Stages.PreTender;
      case "CLOSED":
        return Stages.Closed;
      case "AWARDED":
        return Stages.Awarded;
    }
  };
  return stages.map((stage) => getStage(stage));
}

function mapToNoticeQualifications(qualifications: QualificationsEnum[]) {
  const getNoticeQualification = (qualification: QualificationsEnum) => {
    const res = qualificationMap.get(qualification);
    if (!res) {
      throw new Error(`Unknown qualification: ${qualification}`);
    }
    return res;
  };
  return qualifications.map((qualification) => getNoticeQualification(qualification));
}

export function getFrontendQualification(qualification: NoticeQualification) {
  const res = Array.from(qualificationMap.entries()).find(
    ([_, value]) => value.toString() === qualification.toString(),
  )?.[0];

  if (!res) {
    throw new Error(`Unknown qualification: ${qualification}`);
  }

  return res;
}

export function getGQLSearchQualificationType(qualification: QualificationsEnum) {
  const res = Array.from(qualificationMap.entries()).find(
    ([key, _]) => key.toString() === qualification.toString(),
  )?.[1];

  if (!res) {
    throw new Error(`Unknown qualification: ${qualification}`);
  }

  return res;
}

export function getGQLQualificationType(qualification: QualificationsEnum): NoticesQualifications {
  switch (qualification) {
    case QualificationsEnum.BidPrep:
      return NoticesQualifications.BidPrep;
    case QualificationsEnum.BidSubmitted:
      return NoticesQualifications.BidSubmitted;
    case QualificationsEnum.InReview:
      return NoticesQualifications.InReview;
    case QualificationsEnum.Lost:
      return NoticesQualifications.Lost;
    case QualificationsEnum.NoBid:
      return NoticesQualifications.NoBid;
    case QualificationsEnum.NotRelevant:
      return NoticesQualifications.NotRelevant;
    case QualificationsEnum.PreEngageDone:
      return NoticesQualifications.PreEngageDone;
    case QualificationsEnum.PreEngageNotRelevant:
      return NoticesQualifications.PreEngageNotRelevant;
    case QualificationsEnum.PreEngageToDo:
      return NoticesQualifications.PreEngageToDo;
    case QualificationsEnum.Won:
      return NoticesQualifications.Won;
    case QualificationsEnum.Unqualified:
      return NoticesQualifications.Unqualified;
    default:
      throw new Error(`Unknown qualification: ${qualification}`);
  }
}

function mapToSortOrder(sort: SortState) {
  //TODO: limit use of the generated types due to automatic renaming
  const order = sort.order === "ASC" ? SortOrder.Asc : SortOrder.Desc;
  // TODO: add fields: indicator, buyer_categories, qualification, stage, country, suppliers, is_sme, hidden_date, assignee
  const getField = (sortField: Key | undefined) => {
    switch (sortField) {
      case "award_date":
        return SortEnum.AwardDate;
      case "publish_date":
        return SortEnum.PublishedDate;
      case "close_date":
        return SortEnum.CloseDate;
      case "expiry_date":
        return SortEnum.ExpiryDate;
      case "value":
        return SortEnum.ValueAmount;
      case "title":
        return SortEnum.Title;
      case "relevance_score":
      default:
        return SortEnum.Score;
    }
  };

  return { field: getField(sort.field), order: order };
}

export function convertRecordFiltersToNoticeFilters(filters: RecordFilters): NoticeFilters {
  let stage: NoticeFilters["stage"] =
    (filters.stage?.map((v) => v.toUpperCase()) as NoticeFilters["stage"]) || [];

  if (filters.searchMode === "AWARDS") {
    stage = ["AWARDED"];
  }
  if (filters.searchMode === "TENDERS") {
    stage = ["CLOSED", "OPEN", "PRE_TENDER", "STALE_PRE_TENDER"];
  }

  return {
    assignee: filters.assignee || [],
    buyerCategories: filters.buyerCategoryIds || [],
    buyerListIds: filters.buyerListIds || [],
    buyers: filters.buyerGuids || [],
    buyerIds: filters.buyerIds,
    buyerCountryRegions: {
      countries: filters.buyerCountry || [],
      regions: filters.buyerRegions || [],
    },
    closeDate: {
      filter: {
        ...filters.closeDate?.filter,
        to: filters.closeDate?.filter?.to?.toISOString(),
        from: filters.closeDate?.filter?.from?.toISOString(),
      },
      hideNulls:
        filters.closeDate?.includeNulls === undefined ? false : !filters.closeDate?.includeNulls,
    },
    cpvDimensions: filters.cpvDimensions || [],
    expiryDate: {
      filter: {
        ...filters.expiryDate?.filter,
        to: filters.expiryDate?.filter?.to?.toISOString(),
        from: filters.expiryDate?.filter?.from?.toISOString(),
      },
      hideNulls:
        filters.expiryDate?.includeNulls === undefined ? false : !filters.expiryDate?.includeNulls,
    },
    procurementStageQualifications: filters.procurementStageQualifications || [],
    publishDate: {
      from: filters.publishDate?.from?.toISOString(),
      to: filters.publishDate?.to?.toISOString(),
      relativeFrom: filters.publishDate?.relativeFrom,
      relativeTo: filters.publishDate?.relativeTo,
    },
    signals: filters.signalCategory || [],
    sort: {
      field: filters.sort || DEFAULT_NOTICE_SORT_ORDER.field,
      order: filters.sortOrder || DEFAULT_NOTICE_SORT_ORDER.order,
    },
    stage,
    relevanceScore: filters.relevanceScore || [],
    suppliers: filters.supplierIds || [],
    supplierSme: filters.supplierSme === undefined ? "" : filters.supplierSme,
    text: filters.text || "",
    value: {
      from: filters.value?.from,
      to: filters.value?.to,
      hideNulls: filters.value?.includeNulls === undefined ? false : !filters.value?.includeNulls,
    },
    noticeLists: filters.recordListIds?.map((recordListId) => recordListId.toString()) || [],
    frameworkIds: filters.frameworkIds || [],
    frameworkFilterOptions:
      filters.hideFrameworkAgreementType === undefined ? "" : filters.hideFrameworkAgreementType,
    supplierMentionType:
      filters.supplierMentionType || SupplierMentionType.MentionedOrDirectlyAwarded,
    keywords: filters.keywords || [],
    excludeKeywords: filters.excludeKeywords || [],
    cpvCodes: filters.cpvCodes || [],
  };
}

export function generateNoticeUrl(noticeFilters: Partial<NoticeFilters>, prefix = "") {
  const params = {
    noticeFilters,
  };
  const paramsString = qs.stringify(params, { arrayFormat: "brackets" });
  return `${prefix}?${paramsString}`;
}

export function generateNoticeSearchUrl(
  noticeFilters: Partial<NoticeFilters>,
  selectedViewId?: string,
) {
  const params = {
    noticeFilters,
    current_view_id: selectedViewId,
    showNoView: selectedViewId === undefined,
  };
  const paramsString = qs.stringify(params, { arrayFormat: "brackets" });
  return `/?${paramsString}`;
}

function convertFrameworkRelationshipFilter(
  hideFrameworkAgreementType?: string,
): FrameworkRelationship[] {
  switch (hideFrameworkAgreementType) {
    case FrameworkFilterOptions.HIDE_FRAMEWORK_AGREEMENT_NOTICES:
      return [FrameworkRelationship.CallOff, FrameworkRelationship.NotFrameworkRelated];

    case FrameworkFilterOptions.HIDE_ALL_FRAMEWORK_ACTIVITY:
      return [FrameworkRelationship.NotFrameworkRelated];

    case FrameworkFilterOptions.SHOW_ONLY_FRAMEWORK_CALL_OFFS:
      return [FrameworkRelationship.CallOff];

    // TODO: If options are added later, this API can support filtering by "FRAMEWORK_NOTICE"
    default:
      return [];
  }
}

export function convertFrameworkRelationshipFilterToFrameworkFilterOptions(
  frameworkRelationship?: FrameworkRelationship[],
): FrameworkFilterOptions | string {
  if (!frameworkRelationship) {
    return "";
  }

  if (
    frameworkRelationship.includes(FrameworkRelationship.CallOff) &&
    frameworkRelationship.includes(FrameworkRelationship.NotFrameworkRelated)
  ) {
    return FrameworkFilterOptions.HIDE_FRAMEWORK_AGREEMENT_NOTICES;
  }

  if (frameworkRelationship.includes(FrameworkRelationship.CallOff)) {
    return FrameworkFilterOptions.SHOW_ONLY_FRAMEWORK_CALL_OFFS;
  }

  if (frameworkRelationship.includes(FrameworkRelationship.NotFrameworkRelated)) {
    return FrameworkFilterOptions.HIDE_ALL_FRAMEWORK_ACTIVITY;
  }

  return "";
}

export function getLotTitle(
  lotNumber: string | null | undefined,
  lotTitle: string | null | undefined,
): string | null {
  if (lotTitle) {
    // sometimes lot_title starts with "Lot 1 - ...", sometimes not
    if (lotTitle.match(/^Lot\s\d+/) || !lotNumber) {
      return lotTitle;
    }
    return `Lot ${lotNumber}: ${lotTitle}`;
  }
  if (lotNumber) {
    return `Lot ${lotNumber}`;
  }
  return null;
}

export function mapToTenderStage(stage: Stages) {
  switch (stage) {
    case Stages.Awarded:
      return TenderStage.AWARDED;
    case Stages.Closed:
      return TenderStage.CLOSED;
    case Stages.Open:
      return TenderStage.OPEN;
    case Stages.PreTender:
      return TenderStage.PRE_TENDER;
    case Stages.StalePreTender:
      return TenderStage.STALE_PRE_TENDER;
    default:
      throw new Error(`Unknown stage: ${stage}`);
  }
}
function getSupplierIsSME(supplierSme: string | boolean) {
  if (typeof supplierSme === "boolean") {
    return supplierSme;
  }
  if (typeof supplierSme === "string") {
    if (supplierSme.toLowerCase() === "true") {
      return true;
    }

    if (supplierSme.toLowerCase() === "false") {
      return false;
    }
  }
  return undefined;
}
