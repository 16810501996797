import moment from "moment";

import {
  RecordSearchRequestDto,
  ViewConfigurationFilterSettingsRelevanceScoreEnum,
} from "lib/generated/app-api";
import { SortPaginationParams } from "lib/search/SearchTable";
import { SupplierMentionType } from "lib/types/graphQLEnums";
import { SignalFilters, UserHiddenLeadsFilter } from "lib/types/models";

type NullableDateRangeCriteria = {
  filter?: DateRangeCriteria;
  includeNulls?: boolean;
};

export type DateRangeCriteria = {
  from?: moment.Moment;
  relativeFrom?: string;
  to?: moment.Moment;
  relativeTo?: string;
};

export type SerialisedDateRangeCriteria = {
  from?: string;
  relative_from?: string;
  to?: string;
  relative_to?: string;
};

export type NumericRangeCriteria = {
  from?: number;
  to?: number;
  includeNulls?: boolean;
  nulls?: boolean;
};

export enum FrameworkFilterOptions {
  HIDE_FRAMEWORK_AGREEMENT_NOTICES = "HIDE_FRAMEWORK_AGREEMENT_NOTICES",
  HIDE_ALL_FRAMEWORK_ACTIVITY = "HIDE_ALL_FRAMEWORK_ACTIVITY",
  SHOW_ONLY_FRAMEWORK_CALL_OFFS = "SHOW_ONLY_FRAMEWORK_CALL_OFFS",
}

type StageFilter =
  | "stale_pre_tender"
  | "pre_tender"
  | "open"
  | "closed"
  | "awarded"
  | "STALE_PRE_TENDER"
  | "PRE_TENDER"
  | "OPEN"
  | "CLOSED"
  | "AWARDED";

export type RecordFilters = {
  searchMode?: "TENDERS" | "AWARDS";
  publishDate?: DateRangeCriteria;
  closeDate?: NullableDateRangeCriteria;
  language?: string[];
  value?: NumericRangeCriteria;
  recordType?: string;
  stage?: StageFilter[];
  cpvDimensions?: string[];
  buyerIds?: number[];
  buyerGuids?: string[];
  /**This filter is most probably deprecated */
  buyerType?: string[];
  awardDate?: DateRangeCriteria;
  expiryDate?: NullableDateRangeCriteria;
  text?: string;
  buyerCountry?: string[];
  buyerRegions?: string[];
  supplierSme?: boolean;
  supplierIds?: number[];
  supplierCountry?: string[];
  buyerCategoryIds?: string[];
  buyerCategoryNulls?: boolean;
  countryCode?: string[];
  addedDate?: DateRangeCriteria;
  procurementStageQualifications?: RecordSearchRequestDto["procurementStageQualifications"];

  anySignals?: SignalFilters | undefined;
  signalCategory?: string[];
  relevanceScore?: ViewConfigurationFilterSettingsRelevanceScoreEnum[];
  showUserHiddenLeads?: UserHiddenLeadsFilter;
  includeAllDisqualifiedStates?: boolean;
  qualificationStates?: string[];
  assignee?: string[];
  buyerListIds?: string[];
  // TODO: Remove this after backfill of views to use buyer_list_ids instead of buyer_list_id
  buyerListId?: string;
  // TODO: Remove this after backfill of views to use record_list_ids instead of record_list_id
  recordListId?: number;
  recordListIds?: number[];
  frameworkIds?: string[];
  hideFrameworkAgreementType?: FrameworkFilterOptions;
  supplierMentionType?: SupplierMentionType;
  keywords?: string[];
  excludeKeywords?: string[];
  cpvCodes?: string[];
} & SortPaginationParams;

export const dateRangeObjToParams = <
  T extends "award_date" | "expiry_date" | "publish_date" | "close_date" | "date_added",
>(
  range: DateRangeCriteria | null | undefined,
  name: T,
): Record<string, string> | undefined => {
  if (!range) return undefined;

  const values: Record<string, string> = {};
  if (range.relativeTo) {
    values[`${name}_relative_to`] = range.relativeTo;
  } else if (range.to) {
    values;
    values[`${name}_to`] = range.to.toISOString();
  }
  if (range.relativeFrom) {
    values[`${name}_relative_from`] = range.relativeFrom;
  } else if (range.from) {
    values[`${name}_from`] = range.from.toISOString();
  }
  return values;
};

function getIntParam(urlParams: URLSearchParams, key: string): number | undefined {
  const stringValue = urlParams.get(key);
  if (!stringValue) {
    return undefined;
  }
  const value = parseInt(stringValue);
  return isNaN(value) ? undefined : value;
}

function getIsoDateParam(urlParams: URLSearchParams, key: string): moment.Moment | undefined {
  const stringValue = urlParams.get(key);
  if (!stringValue) {
    return undefined;
  }
  const value = moment(stringValue, [moment.ISO_8601, moment.RFC_2822]);
  return value.isValid() ? value : undefined;
}

export function isoStringToDate(isoString?: string): moment.Moment | undefined {
  if (!isoString) {
    return undefined;
  }
  const value = moment(isoString, [moment.ISO_8601, moment.RFC_2822]);
  return value.isValid() ? value : undefined;
}

export function paramsToNumericRangeObj(
  urlParams: URLSearchParams,
  value: string,
): NumericRangeCriteria | undefined {
  const range: NumericRangeCriteria = {
    to: getIntParam(urlParams, `${value}_to`),
    from: getIntParam(urlParams, `${value}_from`),
  };

  const paramValue = urlParams.get(`${value}_nulls`) ?? urlParams.get(`${value}_include_nulls`);
  range.includeNulls = paramValue ? paramValue === "true" || paramValue === "Y" : undefined;

  return Object.values(range).some((v) => !!v) ? range : undefined;
}

export function paramsToDateRangeObj(
  urlParams: URLSearchParams,
  value: string,
): DateRangeCriteria | undefined {
  const range: DateRangeCriteria = {}; // TODO: proper typing

  range.relativeTo = urlParams.get(`${value}_relative_to`) || undefined;
  range.relativeFrom = urlParams.get(`${value}_relative_from`) || undefined;

  if (!range.relativeTo) {
    range.to = getIsoDateParam(urlParams, `${value}_to`);
  }

  if (!range.relativeFrom) {
    range.from = getIsoDateParam(urlParams, `${value}_from`);
  }

  return Object.values(range).some((v) => !!v) ? range : undefined;
}

export function paramsToNullableDateRangeObj(
  urlParams: URLSearchParams,
  value: string,
): NullableDateRangeCriteria | undefined {
  const range: NullableDateRangeCriteria = {
    filter: paramsToDateRangeObj(urlParams, value),
    includeNulls: urlParams.has(`${value}_nulls`)
      ? urlParams.get(`${value}_nulls`) === "true"
      : undefined,
  };
  return Object.values(range).some((v) => !!v) ? range : undefined;
}
