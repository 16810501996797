import React from "react";
import { CaretDownOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, MenuProps, Popconfirm, Tooltip } from "antd5";
import classnames from "classnames";
import { Link } from "wouter";

import { Banner } from "components/actions/Banner";
import LearnMoreModal from "components/integrations/LearnMoreModal";
import { getIntegrationIcon, getProviderMetadata } from "components/integrations/util";
import { BetaIcon } from "lib/core_components/Labels";
import { ProviderOption } from "lib/generated/integration-api/models/ProviderOption";
import { useFindIntegrationProvider } from "lib/hooks/api/integrations/useFindIntegrationProvider";
import { useInstallProvider } from "lib/hooks/api/integrations/useInstallUrlProvider";
import { useProviderList } from "lib/hooks/api/integrations/useProviderList";
import { useUninstallProvider } from "lib/hooks/api/integrations/useUninstallProvider";
import { ExternalLink } from "lib/icons/ExternalLink";
import { useDialogManager } from "lib/providers/DialogManager";
import { white } from "lib/themes/colors";
import { EventNames, logEvent, TrackingProvider, useTracking } from "lib/tracking";
import { Routes } from "../../pages/app/AccountManagementPage";

import css from "./Integrations.module.scss";

import crmConnectorDesign from "../../../assets/images/crm_connectors.svg";

const INTEGRATION_HELP_STORAGE_KEY = "INTEGRATION_HELP";

function ProviderCard({
  provider,
  installedProviderName,
}: {
  provider: ProviderOption;
  installedProviderName: string | undefined;
}): JSX.Element {
  const { mutate: uninstallProvider } = useUninstallProvider();
  const { mutate: installProvider } = useInstallProvider();
  const metadata = getProviderMetadata(provider.type);
  const dialogManager = useDialogManager();
  const { logEvent } = useTracking();

  return (
    <div key={provider.id}>
      {provider.isInstalled && (
        <div className={css.connectedConfirmation}>
          <CheckCircleOutlined /> You're connected to {metadata.name}
        </div>
      )}
      <div className={classnames(css.providerCard, { [css.connected]: provider.isInstalled })}>
        {getIntegrationIcon(provider.type)}
        <h2 className={css.cardTitle}>{metadata.name}</h2>
        <p className={css.cardDescription}>{metadata.description}</p>
        <div className={css.buttonContainer}>
          {provider.isInstalled ? (
            <Popconfirm
              title={`Are you sure you want to disconnect from ${metadata.name}?`}
              okText="Disconnect"
              cancelText="Cancel"
              onConfirm={() => {
                logEvent(EventNames.disconnectFromIntegrationClicked, {
                  "Context source": "Integration card",
                  Provider: provider.type,
                });
                uninstallProvider({ providerId: provider.id });
              }}
            >
              <Button danger type="primary">
                Disconnect
              </Button>
            </Popconfirm>
          ) : (
            <Tooltip
              title={
                installedProviderName
                  ? `You can only connect one CRM at a time. Disconnect ${installedProviderName} to connect to ${metadata.name}.`
                  : undefined
              }
            >
              {provider.type === "SALESFORCE" ? (
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    onClick: (key: MenuProps) => {
                      logEvent(EventNames.connectToIntegrationClicked, {
                        "Context source": "Integration card",
                        Provider: provider.type,
                      });
                      installProvider({
                        providerId: provider.id,
                        environment: key.key === "production" ? "PRODUCTION" : "SANDBOX",
                      });
                    },
                    items: [
                      { label: "Production environment", key: "production" },
                      { label: "Sandbox environment", key: "sandbox" },
                    ],
                  }}
                >
                  <Button type="primary" disabled={!!installedProviderName}>
                    Connect <CaretDownOutlined className={css.downIcon} />
                  </Button>
                </Dropdown>
              ) : (
                <Button
                  type="primary"
                  onClick={() => {
                    logEvent(EventNames.connectToIntegrationClicked, {
                      "Context source": "Integration card",
                      Provider: provider.type,
                    });
                    installProvider({ providerId: provider.id });
                  }}
                  disabled={!!installedProviderName}
                >
                  Connect
                </Button>
              )}
            </Tooltip>
          )}
          {provider.isInstalled ? (
            <Link type="button" to={Routes.INTEGRATIONS_SETUP}>
              <Button>Setup</Button>
            </Link>
          ) : (
            <Button
              onClick={async () => {
                logEvent(EventNames.learnMoreIntegrationModalOpened, {
                  "Context source": "Integration card",
                  Provider: provider.type,
                });
                dialogManager.openDialog(LearnMoreModal, {
                  providerType: provider.type,
                });
              }}
            >
              Learn more
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export function Integrations(): JSX.Element {
  const { data: providerList } = useProviderList();
  const { data: providerTypeResponse } = useFindIntegrationProvider();
  const providerType = providerTypeResponse?.providerType;

  const installedProviderName = React.useMemo(() => {
    return providerType ? getProviderMetadata(providerType).name : undefined;
  }, [providerType]);

  // check to see if the CRM has just been connected by checking for connected=true in the URL
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const isConnected = urlParams.get("connected");

    if (isConnected && isConnected === "true") {
      logEvent(EventNames.integrationSuccessfullyConnected, {
        "Page source": "Account Management - Integration connected",
        "Connected provider": providerType,
      });
    }
  }, [providerType]);

  return (
    <TrackingProvider
      data={{
        "Page source": "Account Management - Integrations",
        "Connected provider": providerType || "None",
      }}
    >
      <div className={css.integrations}>
        <h1 className={css.pageTitle}>
          Integrations <BetaIcon />
        </h1>

        <p className={css.subtitle}>
          Connect one of your CRMs to your Stotles accounts to send notices and contacts direct to
          your CRM within your workflow.
        </p>
        <Banner
          header="Insights instantly pushed to your CRM"
          graphicSrc={crmConnectorDesign}
          keyName={INTEGRATION_HELP_STORAGE_KEY}
          message="Push notices and contacts direct to your Salesforce or HubSpot account. Achieve your sales goals more efficiently and faster by simply integrating your CRM, and clicking send on the relevant notice or contact you want to store."
          buttons={
            <Button
              type="primary"
              className={css.learnMore}
              onClick={() => window.open("https://help.stotles.com/crm-integrations", "_blank")}
            >
              <ExternalLink fill={white} className={css.icon} /> Learn more about how our CRM
              integration works
            </Button>
          }
        />
        <div className={css.marketplaceWrapper}>
          {providerList?.map((provider) => (
            <ProviderCard
              provider={provider}
              key={provider.id}
              installedProviderName={installedProviderName}
            />
          ))}
        </div>
      </div>
    </TrackingProvider>
  );
}
