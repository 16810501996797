import React, { useMemo } from "react";
import classNames from "classnames";
import { startCase } from "lodash";
import moment from "moment";
import { GetComponentProps } from "rc-table/lib/interface";

import { TextLink } from "components/actions/Links";
import { Currency } from "components/app_layout/Typography";
import { stringSort } from "lib/columnSort";
import RedactedWrapper from "lib/core_components/RedactedWrapper";
import { commonTableColumns } from "lib/core_components/Table/ColumnTypes";
import {
  useBuyerSummary,
  useSearchSpendData,
  useSupplierGuidSummary,
  useSupplierSummaryGrouped,
} from "lib/generated/spend-data-api/spendDataManagementAPI";
import {
  BuyerSummary,
  SpendDataDto,
  SpendDataFilters as Filters,
  SupplierGuidSummary,
  SupplierSummary,
} from "lib/generated/spend-data-api/spendDataManagementAPI.schemas";
import { useSignalSettings } from "lib/hooks/api/teams/useSignalSettings";
import { TypedColumnProps } from "lib/search/SearchTable";
import { GroupByKey } from "../config";

// eslint-disable-next-line css-modules/no-unused-class
import css from "../Table.module.scss";

export const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_COLUMN_SIZE = { large: 240, small: 160, medium: 160, xlarge: 300 };

type TransactionTableRow = SpendDataDto & { descriptiveColumns?: Record<string, string> };

type UseSpendTable = {
  data?: TransactionTableRow[] | SupplierSummary[] | BuyerSummary[] | SupplierGuidSummary[];
  isLoading: boolean;
  columns: TypedColumnProps<
    TransactionTableRow | SupplierSummary | BuyerSummary | SupplierGuidSummary
  >[];
  rowKey: (r: TransactionTableRow | SupplierSummary | BuyerSummary | SupplierGuidSummary) => string;
  pagination?: {
    total?: number;
    onChange: (page: number, pageSize?: number) => void;
  };
  onRow?: GetComponentProps<
    TransactionTableRow | SupplierSummary | BuyerSummary | SupplierGuidSummary
  >;
};

type Pagination = {
  current: number;
  pageSize: number;
};

export const useSpendTable = (
  filters: Filters,
  tableType: GroupByKey | undefined,
  defaultPageSize = DEFAULT_PAGE_SIZE,
): UseSpendTable => {
  const [pagination, setPagination] = React.useState<Pagination>({
    current: 1,
    pageSize: defaultPageSize,
  });
  const offset = (pagination.current - 1) * pagination.pageSize;

  const { data: groupedSupplierData, isLoading: groupedSupplierIsLoading } =
    useSupplierSummaryGrouped(
      {
        filters,
        limit: pagination.pageSize,
        offset,
        orderBy: "total",
        sortOrder: "DESC",
      },
      { query: { enabled: tableType === "partners" || tableType === "competitors" } },
    );
  const { data: supplierData, isLoading: supplierIsLoading } = useSupplierGuidSummary(
    {
      filters,
      limit: pagination.pageSize,
      offset,
      orderBy: "total",
      sortOrder: "DESC",
    },
    { query: { enabled: tableType === "allSuppliers" } },
  );
  const { data: buyerData, isLoading: buyerIsLoading } = useBuyerSummary(
    {
      filters,
      limit: pagination.pageSize,
      offset,
      orderBy: "total",
      sortOrder: "DESC",
    },
    { query: { enabled: tableType === "buyers" } },
  );
  const { data: transactionData, isLoading: transactionIsLoading } = useSearchSpendData(
    {
      filters,
      limit: pagination.pageSize,
      offset,
    },
    { query: { enabled: tableType === "allTransactions" } },
  );
  const { data: signalSettings } = useSignalSettings({
    enabled: tableType === "partners" || tableType === "competitors",
  });

  const result = useMemo(() => {
    let { data, columns, isLoading, rowKey, pagination, onRow }: UseSpendTable = {
      data: [],
      isLoading: false,
      columns: [],
      rowKey: (r) => r.toString(),
    };

    switch (tableType) {
      case "partners":
      case "competitors": {
        if (groupedSupplierData && signalSettings) {
          const key = tableType === "partners" ? "Partners" : "Competitors";

          const signals = new Set(
            signalSettings.signals.filter((s) => s.category === key).map((s) => s.name),
          );

          data = groupedSupplierData.data.filter((d) => signals.has(d.supplier));
        } else {
          data = undefined;
        }
        isLoading = groupedSupplierIsLoading;
        columns = [
          {
            ...commonTableColumns.titleColumn,
            title: tableType === "partners" ? "Partner" : "Competitor",
            width: "33em",
            sizeConfig: { large: 500, small: 200, medium: 400, xlarge: 600 },
            sorter: stringSort((r) => (r as SupplierSummary).supplier),
            key: "title",
            render: (r) => (
              <RedactedWrapper
                requiredDataType={"SUPPLIERS"}
                redactContent={(r as SupplierSummary).supplier}
                contextSource={"In-row"}
              >
                <span
                  className={classNames(
                    css.supplierCell,
                    tableType === "partners" ? "tag-yellow" : "tag-red",
                  )}
                >
                  {(r as SupplierSummary).supplier}
                </span>
              </RedactedWrapper>
            ),
          },
          {
            ...commonTableColumns.valueColumn,
            title: "Total transaction value",
            render: (r) => <Currency value={r.total} shortFormat />,
            sizeConfig: { large: 500, small: 200, medium: 400, xlarge: 600 },
            sorter: (a, b) => (a as SupplierSummary).total - (b as SupplierSummary).total,
            sortDirections: ["descend", "ascend"],
            defaultSortOrder: "descend",
            key: "amount",
          },
          {
            ...commonTableColumns.valueColumn,
            title: "Number of transactions",
            render: (r) => (r as SupplierSummary).totalRecords,
            sizeConfig: { large: 400, small: 100, medium: 300, xlarge: 500 },
            sorter: (a, b) =>
              (a as SupplierSummary).totalRecords - (b as SupplierSummary).totalRecords,
            key: "numTransactions",
            sortDirections: ["descend", "ascend"],
          },
        ];
        rowKey = (r) => (r as SupplierSummary).supplier;

        pagination = {
          onChange: (pageNum: number, pageSize: number | undefined) =>
            setPagination((prevPag) => ({
              current: pageNum,
              pageSize: pageSize ?? prevPag.pageSize,
            })),
          total: groupedSupplierData?.total,
        };

        onRow = (r) => ({
          onClick() {
            if ("supplier" in r) {
              window.location.href = `${tableType}/${r.supplier}`;
            }
          },
        });

        break;
      }
      case "allSuppliers": {
        data = supplierData?.data;
        isLoading = supplierIsLoading;

        columns = [
          {
            ...commonTableColumns.titleColumn,
            title: "Supplier",
            width: "33em",
            sizeConfig: { large: 500, small: 200, medium: 400, xlarge: 600 },
            sorter: stringSort((r) => (r as SupplierGuidSummary).supplierName),
            render: (r) => (
              <span className={css.supplierCell}>
                <TextLink to={`/suppliers/${(r as SupplierGuidSummary).supplierGuid}`}>
                  {(r as SupplierGuidSummary).supplierName}
                </TextLink>
              </span>
            ),
            key: "title",
          },
          {
            ...commonTableColumns.valueColumn,
            title: "Total transaction value",
            render: (r) => <Currency value={r.total} shortFormat />,
            sizeConfig: { large: 500, small: 200, medium: 400, xlarge: 600 },
            sorter: (a, b) => (a as SupplierSummary).total - (b as SupplierSummary).total,
            defaultSortOrder: "descend",
            key: "amount",
          },
          {
            ...commonTableColumns.valueColumn,
            title: "Number of transactions",
            render: (r) => (r as SupplierSummary).totalRecords,
            sizeConfig: DEFAULT_COLUMN_SIZE,
            sorter: (a, b) =>
              (a as SupplierSummary).totalRecords - (b as SupplierSummary).totalRecords,
            key: "numTransactions",
          },
        ];
        rowKey = (r) => (r as SupplierSummary).supplier;
        break;
      }
      case "buyers": {
        data = buyerData?.data;
        isLoading = buyerIsLoading;
        columns = [
          {
            ...commonTableColumns.titleColumn,
            title: "Buyer",
            render: (r) => (
              <span className={css.nameColumnLink}>{(r as BuyerSummary).buyerName}</span>
            ),
            sizeConfig: { large: 500, small: 200, medium: 400, xlarge: 600 },
            sorter: stringSort((r) => (r as BuyerSummary).buyerName),
            key: "title",
          },
          {
            ...commonTableColumns.valueColumn,
            title: "Total transaction value",
            render: (r) => <Currency value={r.total} shortFormat />,
            sizeConfig: { large: 500, small: 200, medium: 400, xlarge: 600 },
            sorter: (a, b) => (a as BuyerSummary).total - (b as BuyerSummary).total,
            defaultSortOrder: "descend",
            key: "amount",
          },
          {
            ...commonTableColumns.valueColumn,
            title: "Number of transactions",
            render: (r) => (r as BuyerSummary).totalRecords,
            sizeConfig: DEFAULT_COLUMN_SIZE,
            sorter: (a, b) => (a as BuyerSummary).totalRecords - (b as BuyerSummary).totalRecords,
            key: "numTransactions",
          },
        ];
        rowKey = (r) => (r as BuyerSummary).buyer;

        pagination = {
          onChange: (pageNum: number, pageSize: number | undefined) =>
            setPagination((prevPag) => ({
              current: pageNum,
              pageSize: pageSize ?? prevPag.pageSize,
            })),
          total: buyerData?.total,
        };

        onRow = (r) => ({
          onClick() {
            if ("buyer" in r) {
              window.location.href = `buyers/${r.buyer}`;
            }
          },
        });

        break;
      }
      case "allTransactions": {
        const typeCounts: Record<string, number> = {};

        data =
          transactionData?.data?.map((row) => {
            const descriptiveColumns = row.descriptiveFields?.reduce<Record<string, string>>(
              (fields, field) => {
                if (!field.type) {
                  return fields;
                }
                if (fields[field.type]) {
                  typeCounts[field.type] = !typeCounts[field.type] ? typeCounts[field.type] + 1 : 2;
                  fields[`${field.type}_${typeCounts[field.type]}`] = field.data;
                  return fields;
                }
                typeCounts[field.type] = 1;
                fields[field.type] = field.data;
                return fields;
              },
              {},
            );
            return { ...row, descriptiveColumns };
          }) || [];
        isLoading = transactionIsLoading;
        const descriptiveFieldColumns: typeof columns = [];

        // calc descriptive columns so that they can be displayed in the table as we don't know what they are before we get the data
        for (const [key, value] of Object.entries(typeCounts)) {
          descriptiveFieldColumns.push({
            title: startCase(key),
            render: (r) =>
              (r as TransactionTableRow)[`descriptiveColumns.${key}` as keyof TransactionTableRow],
            sizeConfig: DEFAULT_COLUMN_SIZE,
            ellipsis: true,
            key,
          });
          if (value > 1) {
            for (let i = 2; i <= value; i++) {
              const title = startCase(`${key} ${i}`);
              descriptiveFieldColumns.push({
                title,
                render: (r) =>
                  (r as TransactionTableRow)[
                    `descriptiveColumns.${key}_${i}` as keyof TransactionTableRow
                  ],
                sizeConfig: DEFAULT_COLUMN_SIZE,
                ellipsis: true,
                key: title,
              });
            }
          }
        }

        columns = [
          {
            ...commonTableColumns.titleColumn,
            title: "Buyer",
            render: (r) => (r as TransactionTableRow).buyerName,
            sizeConfig: { large: 500, small: 200, medium: 400, xlarge: 600 },
            key: "title",
          },
          {
            ...commonTableColumns.titleColumn,
            title: "Supplier",
            render: (r) => (r as TransactionTableRow).supplier,
            sizeConfig: { large: 500, small: 200, medium: 400, xlarge: 600 },
            key: "supplier",
          },
          {
            ...commonTableColumns.valueColumn,
            title: "Total transaction value",
            render: (r) => <Currency value={r.amount} />,
            sizeConfig: { large: 300, small: 200, medium: 300, xlarge: 400 },
            key: "amount",
          },
          {
            ...commonTableColumns.dateColumn,
            title: "Date",
            render: (r) => {
              const d = (r as TransactionTableRow).date;
              return d ? moment(d).format("MMM D, YYYY") : null;
            },
            key: "data",

            sizeConfig: DEFAULT_COLUMN_SIZE,
          },
          ...descriptiveFieldColumns,
        ];

        rowKey = (r) => (r as SpendDataDto).id;

        pagination = {
          onChange: (pageNum: number, pageSize: number | undefined) =>
            setPagination((prevPag) => ({
              current: pageNum,
              pageSize: pageSize ?? prevPag.pageSize,
            })),
          total: transactionData?.total,
        };
        break;
      }
    }

    return {
      data,
      isLoading,
      columns,
      rowKey,
      pagination,
      onRow,
    };
  }, [
    tableType,
    signalSettings,
    buyerData,
    buyerIsLoading,
    supplierData,
    supplierIsLoading,
    groupedSupplierData,
    groupedSupplierIsLoading,
    transactionData,
    transactionIsLoading,
  ]);

  return result;
};
