import React, { useMemo, useState } from "react";
import { Control, Controller, UseFormReset } from "react-hook-form";
import { Tag } from "antd5";
import { MigrateSignalsFormState } from "pages/app/OrgSignalMigrationPage";

import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { CompanyToMigrate } from "lib/hooks/api/admin/signal_migration/useCompaniesToMigrate";
import { useSignalsToMigrate } from "lib/hooks/api/admin/signal_migration/useSignalsToMigrate";
import SelectUnmigratedCompanies from "./SelectUnmigratedCompanies";

import css from "./SelectCompanySignalsStep.module.scss";

type Props = {
  control: Control<MigrateSignalsFormState, unknown>;
  reset: UseFormReset<MigrateSignalsFormState>;
};

function SelectCompanySignalsStep({ control, reset }: Props): JSX.Element {
  const [selectedCompany, setSelectedCompany] = useState<CompanyToMigrate>();

  const { data } = useSignalsToMigrate(
    {
      feedSettingIds: selectedCompany?.feedSettingIds || [],
      textSearch: "",
    },
    {
      enabled: !!selectedCompany?.feedSettingIds,
    },
  );

  const allSignals = useMemo(
    () => data?.signalsToMigrate.sort((a, b) => b.ids.length - a.ids.length) || [],
    [data],
  );

  return (
    <>
      <div className={css.pageContent}>
        <SelectUnmigratedCompanies
          selectedCompany={selectedCompany}
          onCompanyChange={(newCompany) => {
            reset();
            setSelectedCompany(newCompany);
          }}
        />
        {/* Need to also check company here, otherwise if user goes to next step, then comes back...
         these tags are populated */}
        {!!selectedCompany?.feedSettingIds && allSignals.length > 0 && (
          <Controller
            name="signals"
            control={control}
            render={({ field: { value: signals, onChange: onSignalsChange } }) => (
              <div className={css.section}>
                <ul className={css.tagsContainer}>
                  {allSignals.map((signal) => (
                    <Tag
                      key={signal.name}
                      onClick={() => {
                        const hasSignal = signals.find((s) => s.name === signal.name);
                        if (hasSignal) {
                          onSignalsChange(signals.filter((s) => s.name !== signal.name));
                        } else {
                          onSignalsChange([...signals, signal]);
                        }
                      }}
                      color={signals.some((s) => s.name === signal.name) ? "green" : "red"}
                      className={css.tag}
                    >
                      <EllipsisTooltipText
                        fullText={`${signal.name} (${signal.ids.length})`}
                        containerClassname={css.selectedItem}
                      />
                    </Tag>
                  ))}
                </ul>
              </div>
            )}
          />
        )}
      </div>
    </>
  );
}

export default SelectCompanySignalsStep;
