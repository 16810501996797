import moment from "moment";

import { DateRangeCriteria, dateRangeObjToParams } from "components/record_search/types";
import { SearchRecordsParams } from "lib/StotlesApi";
import { StageFilter } from "lib/types/models";
import { PrimaryTab, SecondaryTab, SupplierRelationshipFilter, TabConfigType } from "./types";

export const labelledRoutes: Record<PrimaryTab, string> & Record<SecondaryTab, string> = {
  "/": "Notices: All notices",
  "/awards": "Notices: Awards",
  "/upcoming_expiries": "Notices: Upcoming contract expiries",
  "/buyers": "Buyers: All buyers",
  "/buyers/saved_buyers": "Buyers: Saved buyers",
};

const isPrimaryTab = (tab: string): tab is PrimaryTab => {
  return Object.values(PrimaryTab).some((path) => path === tab);
};

export const getTabConfigFromLocation = (loc: string): TabConfigType => {
  const locWithoutTrailingSlash = loc === "/" ? loc : loc.match(/(.+)\/$/)?.[1] || loc;

  if (isPrimaryTab(locWithoutTrailingSlash)) {
    return { primary: locWithoutTrailingSlash, secondary: null };
  } else {
    let primary: PrimaryTab;

    switch (locWithoutTrailingSlash) {
      case SecondaryTab.ALL_NOTICES:
        primary = PrimaryTab.NOTICES;
        break;
      case SecondaryTab.UPCOMING_EXPIRIES:
        primary = PrimaryTab.NOTICES;
        break;
      case SecondaryTab.AWARDS:
        primary = PrimaryTab.NOTICES;
        break;
      case SecondaryTab.ALL_BUYERS:
        primary = PrimaryTab.BUYERS;
        break;
      case SecondaryTab.SAVED_BUYERS:
        primary = PrimaryTab.BUYERS;
        break;
      default:
        return { primary: PrimaryTab.NOTICES, secondary: SecondaryTab.ALL_NOTICES };
    }
    return { primary, secondary: locWithoutTrailingSlash };
  }
};

export const toUrlParams = (params: SupplierRelationshipFilter) => {
  const urlParams: Partial<Record<keyof SupplierRelationshipFilter, string>> = {};
  if (params.keywords.length) {
    urlParams.keywords = params.keywords.join(",");
  }

  urlParams.textSearch = params.textSearch || undefined;
  return urlParams;
};

export const fromUrlParams = (urlSearchParams: URLSearchParams) => {
  const keywords = urlSearchParams.get("keywords");
  const textSearch = urlSearchParams.get("textSearch");
  return {
    keywords: keywords?.split(",") || [],
    textSearch: textSearch || "",
  };
};

/* START HELPERS FOR ROUTING ON DRILL DOWN PAGES  */
export type FilterParams = {
  partner?: {
    name: string;
  };
  competitor?: {
    name: string;
  };
  supplier?: {
    id: number;
    name: string;
  };
  buyer?: {
    guid: string;
    name: string;
  };
  stage: StageFilter[];
  expiryDate?: DateRangeCriteria;
  expiryNulls?: boolean;
  keywords?: string[];
};

export const filterParamsToSearchParams = (fp: FilterParams) => {
  const params: Partial<SearchRecordsParams> = {
    close_nulls: "Y",
    signals: {},
    stage: fp.stage,
    ...dateRangeObjToParams(fp.expiryDate, "expiry_date"),
    expiry_nulls: fp.expiryNulls === true ? "Y" : "N",
  };

  if (fp.partner) {
    params.signals = { ...params.signals, partners: [fp.partner.name] };
  }

  if (fp.competitor) {
    params.signals = { ...params.signals, competitors: [fp.competitor.name] };
  }

  if (fp.buyer) {
    params.buyer_guid = [fp.buyer.guid];
  }
  if (fp.keywords) {
    params.signals = { ...params.signals, keywords: fp.keywords };
  }

  return params;
};

export const urlParamsToFilterParams = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const stage = urlParams.get("stage");

  if (!stage) return undefined;

  const partnerName = urlParams.get("partner_name");
  const competitorName = urlParams.get("competitor_name");
  const expiryNulls = urlParams.get("expiry_nulls");
  const expiryFrom = urlParams.get("expiry_from");
  const expiryTo = urlParams.get("expiry_to");
  const expiryRF = urlParams.get("expiry_relative_from");
  const expiryRT = urlParams.get("expiry_relative_to");
  const buyerGuid = urlParams.get("buyer_guid");
  const buyerName = urlParams.get("buyer_name");

  const params: FilterParams = {
    stage: stage.split(",") as StageFilter[],
    partner: partnerName ? { name: partnerName } : undefined,
    competitor: competitorName ? { name: competitorName } : undefined,
    keywords: urlParams.get("keywords")?.split(","),
    expiryNulls: expiryNulls ? expiryNulls === "Y" : undefined,
    expiryDate:
      expiryFrom || expiryTo || expiryRF || expiryRT
        ? {
            from: expiryFrom ? moment(expiryFrom) : undefined,
            to: expiryTo ? moment(expiryFrom) : undefined,
            relativeFrom: expiryRF || undefined,
            relativeTo: expiryRT || undefined,
          }
        : undefined,
    buyer: buyerGuid && buyerName ? { name: buyerName, guid: buyerGuid } : undefined,
  };

  return params;
};

export const filterParamsToUrlParams = (fp: FilterParams) => {
  const params: Record<string, string> = {
    stage: fp.stage.join(","),
  };

  if (fp.partner?.name) {
    params.partner_name = fp.partner.name;
  }

  if (fp.competitor?.name) {
    params.competitor_name = fp.competitor.name;
  }

  if (fp.buyer) {
    params.buyer_name = fp.buyer.name;
    params.buyer_guid = fp.buyer.guid;
  }

  if (fp.expiryNulls) {
    params.expiry_nulls = "Y";
  } else if (fp.expiryNulls === false) {
    params.expiry_nulls = "N";
  }

  const eD = fp.expiryDate;

  if (eD) {
    if (eD.from) params.expiry_from = eD.from.toISOString();

    if (eD.to) params.expiry_to = eD.to.toISOString();

    if (eD.relativeFrom) params.expiry_relative_from = eD.relativeFrom;

    if (eD.relativeTo) params.expiry_relative_to = eD.relativeTo;
  }

  if (fp.keywords?.length) {
    params.keywords = fp.keywords.join(",");
  }

  return new URLSearchParams(params);
};

/* END HELPERS FOR ROUTING ON DRILL DOWN PAGES  */
