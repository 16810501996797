import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  GetOrganisationsQuery,
  GetOrganisationsQueryVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const getOrganisations = graphql(`
  query getOrganisations($ids: [String!]!) {
    getOrganisations(ids: $ids) {
      id
      name
      url
      email
      phone
      address
      town
      postalCode
    }
  }
`);

export function useOrganisations(
  ids: GetOrganisationsQueryVariables["ids"],
  options?: UseQueryOptions<GetOrganisationsQuery, unknown, GetOrganisationsQuery, QueryKey>,
) {
  return useGraphQlQuery(["organisations", ids], getOrganisations, [{ ids }], {
    ...options,
  });
}
