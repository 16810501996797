import React from "react";
import Icon from "@ant-design/icons";
import { Tooltip } from "antd"; // upgrade and verify

import { useComments } from "lib/hooks/api/notices/comments/useComments";
import Comment from "lib/icons/Comment";
import { joinToEnglishList, pluralise } from "lib/utils";

function CommentIcon({
  commentCount = 0,
  procurementStageId,
}: {
  commentCount: number;
  procurementStageId: string;
}): JSX.Element | null {
  const [tooltipVisible, setTooltipVisible] = React.useState(false);
  const { data: comments, isLoading } = useComments(procurementStageId, {
    enabled: !!window.currentUser && tooltipVisible,
  });

  const currentUser = window.currentUser;

  const tooltipText = () => {
    if (!currentUser) {
      return undefined;
    }
    if (isLoading) {
      return "Loading...";
    }
    if (!comments) {
      return "No comments found";
    }

    const userNames = new Set<string>();
    for (const c of comments) {
      if (c.deletedAt) {
        continue;
      }
      userNames.add(c.author.guid === currentUser.guid ? "you" : c.author.firstName);
    }

    return `${pluralise(commentCount, "comment")} by ${joinToEnglishList(Array.from(userNames))}`;
  };

  return !currentUser || commentCount === 0 ? null : (
    <Tooltip
      title={tooltipText}
      visible={tooltipVisible}
      onVisibleChange={(vis) => setTooltipVisible(vis)}
    >
      <div aria-label="Notice has comments">
        <Icon component={Comment} />
      </div>
    </Tooltip>
  );
}

export default CommentIcon;
