import React, { ReactNode } from "react";
import { Content, Header, Item, Root, Trigger } from "@radix-ui/react-accordion";

import css from "./CollapseGroup.module.scss";

export type CollapseItem = {
  header: ReactNode;
  content: ReactNode;
  key: string;
};

export interface FilterCollapseProps {
  items: CollapseItem[];
  value?: string[];
  onChange?: (value: string[]) => void;
}

function CollapseGroup({ items, value, onChange }: FilterCollapseProps) {
  return (
    <Root type="multiple" value={value} onValueChange={onChange}>
      {items &&
        items.map((item) => (
          <Item key={item.key} value={item.key} className={css.item}>
            <Header className={css.header}>
              <Trigger className={css.trigger}>
                {item.header}
                <div className={css.plusWrapper}>
                  <div className={css.horizontalLine} />
                  <div className={css.verticalLine} />
                </div>
              </Trigger>
            </Header>
            <Content className={css.content}>{item.content}</Content>
          </Item>
        ))}
    </Root>
  );
}

export default CollapseGroup;
