import React from "react";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd5";
import classNames from "classnames";

import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";

import css from "./SelectedKeywordTags.module.scss";

type Props = {
  selectedKeywords: string[];
  isLoading: boolean;
  icon: React.ReactNode;
  tooltipTitle?: string;
  tagClassname?: string;
  onChange: (keyword: string[]) => void;
  closeDropdown: () => void;
  showIconForTag: (keyword: string) => boolean;
};

export default function SelectedKeywordTags({
  selectedKeywords,
  isLoading,
  icon,
  tooltipTitle,
  tagClassname,
  onChange,
  closeDropdown,
  showIconForTag,
}: Props): JSX.Element | null {
  if (selectedKeywords.length === 0) {
    return null;
  }

  return (
    <div className={css.keywordSelections} aria-label="keywordSelections" onClick={closeDropdown}>
      <div className={css.tagContainer}>
        {selectedKeywords.sort().map((keyword: string) => {
          const showIcon = showIconForTag(keyword);
          return (
            <Tooltip title={showIcon ? tooltipTitle : ""} key={keyword} placement="bottom">
              <Tag
                key={keyword}
                aria-label={`${keyword} tag`}
                className={classNames(css.tag, tagClassname)}
                closeIcon={<CloseOutlined />}
                onClose={() => onChange(selectedKeywords.filter((v: string) => v !== keyword))}
              >
                {isLoading ? (
                  <LoadingOutlined />
                ) : (
                  <>
                    {showIcon && <>{icon}</>}
                    <EllipsisTooltipText
                      fullText={keyword}
                      textProps={{ className: css.tagLabel }}
                    />
                  </>
                )}
              </Tag>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
}
