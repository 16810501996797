import * as React from "react";
import { Route, RouteProps, Router, useRoute, useRouter } from "wouter";

type SubPath = `${string}/:rest*`;

type SubRouteProps = {
  path: SubPath;
  onMatch?: () => void;
} & RouteProps;

export function SubRoute({ onMatch, path, ...props }: SubRouteProps): JSX.Element | null {
  const router = useRouter();
  const [isActive] = useRoute(path);

  React.useEffect(() => {
    if (isActive && onMatch) {
      onMatch();
    }
  }, [isActive, onMatch]);

  const nestedBase = `${router.base}${path.slice(0, -7)}`;
  return (
    <Router base={nestedBase}>
      <Route {...props} path="/" />
    </Router>
  );
}
