import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { MigrateSignalsFormState } from "pages/app/OrgSignalMigrationPage";

import { CandidateOperation } from "components/organisation_clean/types";
import { SubmitOrgCleaningTaskRequestTaskTypeEnum, SubmittedDecision } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { assertDefined } from "lib/utils";
import { SignalsToMigrate } from "./useSignalsToMigrate";

/**
 * Creates a list of OrgToAlias decisions to be sent for later review
 * @param orgGuid
 * @param newOrg
 * @returns
 */
export function createAliasDecisions(orgId: string, aliases: string[]): SubmittedDecision[] {
  return aliases.map((alias) => {
    return {
      candidateOrganisationId: orgId,
      decisionData: {
        createAlias: {
          qualification: CandidateOperation.CREATE_ALIAS,
          alias,
        },
      },
    };
  });
}

/**
 * Creates a list of OrgToAlias decisions to be sent for later review
 * @param orgGuid
 * @param newOrg
 * @returns
 */
export function createSignalToOrgDecisions(
  orgId: string,
  signalsToMigrate: SignalsToMigrate[],
): SubmittedDecision[] {
  return signalsToMigrate.map((signal) => {
    return {
      candidateOrganisationId: orgId,
      decisionData: {
        signalsToOrg: {
          qualification: CandidateOperation.SIGNALS_TO_ORG,
          signalIds: signal.ids,
          signalName: signal.name,
        },
      },
    };
  });
}

export const useSubmitMigrateSignals = (
  submitNow: boolean,
  options?: UseMutationOptions<void, unknown, MigrateSignalsFormState, unknown>,
) => {
  const api = useOpenApi();

  return useMutation(async (formState: MigrateSignalsFormState) => {
    const { signals, orgs, aliases } = formState;

    assertDefined(signals);
    let decisions: SubmittedDecision[] = [];

    const orgIds = orgs.map((org) => org.id);
    for (const id of orgIds) {
      const aliasDecisions = createAliasDecisions(id, aliases);

      const signalToOrgDecisions = createSignalToOrgDecisions(id, signals);

      decisions = decisions.concat(aliasDecisions).concat(signalToOrgDecisions);
    }

    if (submitNow) {
      await api.submitAndProcessOrgCleaningTask({
        submitAndProcessOrgCleaningTaskRequest: {
          decisions,
          organisationId: orgIds[0],
          taskType: SubmitOrgCleaningTaskRequestTaskTypeEnum.DataMigration,
        },
      });
    } else {
      const cancelLocation = "ORG_SIGNAL_MIGRATION_PAGE";
      const cancelNote = "SUBMITTED_FOR_REVIEW";
      // Only one task for all of the decisions, because we want one review page
      await api.submitOrgCleaningTask({
        submitOrgCleaningTaskRequest: {
          decisions,
          // This id will just be an anchor org used to represent the task, there are actually several anchor orgs within this task. Each with their own decision
          organisationId: orgIds[0],
          cancelLocation,
          cancelNote,
          taskType: SubmitOrgCleaningTaskRequestTaskTypeEnum.DataMigration,
        },
      });
    }
  }, options);
};
