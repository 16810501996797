import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { Divider } from "antd5";

import { SelectBuyerList } from "components/form_components/BuyerListSelect";
import { BuyerSelect } from "components/form_components/BuyerSelect";
import { BuyerTypeSelect } from "components/form_components/BuyerTypeSelect";
import { CountryRegionSelect } from "components/form_components/CountryRegionSelect";
import { SignalSelect } from "components/form_components/SignalSelect";
import { AllBuyersStatsRequestAllBuyersStatsFilters } from "lib/generated/app-api";
import FilterFormTitle from "../filter_form/filter_form_title/FilterFormTitle";

import css from "./BuyerFilterForm.module.scss";

export type BuyerFilterSections = "signals" | "buyerInformation" | "suppliers";

export function BuyerFilterForm({
  filters,
  onFiltersChange,
  closeDrawer,
  showTitle = false,
  options,
}: {
  filters: AllBuyersStatsRequestAllBuyersStatsFilters;
  onFiltersChange: (filters: AllBuyersStatsRequestAllBuyersStatsFilters) => void;
  closeDrawer?: () => void;
  showTitle?: boolean;
  options?: {
    diabledFields?: keyof AllBuyersStatsRequestAllBuyersStatsFilters[];
    disabledSections?: BuyerFilterSections[];
  };
}) {
  const previous = useRef(filters);

  const { control, watch, handleSubmit } = useForm<AllBuyersStatsRequestAllBuyersStatsFilters>({
    mode: "onChange",
    defaultValues: filters,
  });

  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((d) => {
        previous.current = d;
        onFiltersChange(d);
      })(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, onFiltersChange]);

  const disabledSections = options?.disabledSections || [];

  return (
    <>
      {showTitle && <FilterFormTitle close={closeDrawer as () => void} />}
      <form>
        <div className={showTitle ? css.formContent : undefined}>
          {!disabledSections.includes("signals") && (
            <>
              <h3 className={css.sectionTitle}>Signals</h3>
              <div className={css.filterSection}>
                <SignalSelect
                  name="signals"
                  label=""
                  control={control}
                  placeholder="Select..."
                  allowClear
                />
              </div>
              <Divider />
            </>
          )}
          {!disabledSections.includes("buyerInformation") && (
            <>
              <h3 className={css.sectionTitle}>Buyer information</h3>
              <div className={css.filterSection}>
                <BuyerSelect
                  name="buyerGuids"
                  label="Buyers"
                  control={control}
                  mode="multiple"
                  placeholder="Search buyers"
                  allowClear
                />
                <SelectBuyerList
                  name="buyerLists"
                  label="Buyer lists"
                  control={control}
                  mode="multiple"
                  placeholder="Select"
                  allowClear
                />
                <BuyerTypeSelect
                  label="Buyer type"
                  name="buyerTypes"
                  control={control}
                  multiple
                  placeholder="Select..."
                  allowClear
                />
                <CountryRegionSelect
                  name="hqCountry"
                  control={control}
                  label="Buyer location"
                  placeholder="Select..."
                  allowClear
                  aria-label="Buyer location filter"
                />
              </div>
              <Divider />
            </>
          )}
        </div>
      </form>
    </>
  );
}
