import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { graphql } from "lib/generated/app-service-gql";
import {
  DescribeFrameworkWithDebugQuery,
  QueryFrameworkArgs,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const frameworkDebugQuery = graphql(`
  query describeFrameworkWithDebug($frameworkId: String!) {
    framework(frameworkId: $frameworkId) {
      id
      signalScore
      title
      debugInfo {
        frameworkInfo
        frameworkEventInfo
      }
      textSections {
        title
        content {
          content
          content_type
        }
        role
      }
      sourceUrl
      countryCode
      procedureType
      awardType
      validatedByProvider
      stage
      value {
        amount
        currency
      }
      contactDetails {
        address
        email
        name
        phone
      }
      contractPeriod {
        startDate
        endDate
      }
      tenderPeriod {
        startDate
        endDate
      }
      planningPeriod {
        startDate
        endDate
      }
      serviceProvider {
        id
        name
      }
      suppliers {
        id
        name
      }
      buyers {
        id
        name
      }
      contractingAuthority {
        id
        name
      }

      lots {
        id
        title
        description {
          content
          content_type
        }
        supplierGuids
        contractPeriod {
          startDate
          endDate
        }
        value {
          amount
          currency
        }
      }
      timeline {
        notice {
          recordGuid
          title
          stotlesUrl
          publishDate
        }
        stage
        externalSourceUrl
        startDate
        endDate
      }
      extensionAvailable
    }
  }
`);

const frameworkQuery = graphql(`
  query describeFramework($frameworkId: String!) {
    framework(frameworkId: $frameworkId) {
      id
      signalScore
      title
      textSections {
        title
        content {
          content
          content_type
        }
        role
      }
      sourceUrl
      countryCode
      procedureType
      awardType
      validatedByProvider
      stage
      value {
        amount
        currency
      }
      contactDetails {
        address
        email
        name
        phone
      }
      contractPeriod {
        startDate
        endDate
      }
      tenderPeriod {
        startDate
        endDate
      }
      planningPeriod {
        startDate
        endDate
      }
      serviceProvider {
        id
        name
      }
      suppliers {
        id
        name
      }
      buyers {
        id
        name
      }
      contractingAuthority {
        id
        name
      }

      lots {
        id
        title
        description {
          content
          content_type
        }
        supplierGuids
        contractPeriod {
          startDate
          endDate
        }
        value {
          amount
          currency
        }
      }
      timeline {
        notice {
          recordGuid
          title
          stotlesUrl
          publishDate
        }
        stage
        externalSourceUrl
        startDate
        endDate
      }
      extensionAvailable
    }
  }
`);

// Create a type to describe the data returned by the query
export type FrameworkDetails = DescribeFrameworkWithDebugQuery["framework"];

export function useDescribeFramework(
  frameworkId: QueryFrameworkArgs["frameworkId"],
  options?: UseQueryOptions<
    DescribeFrameworkWithDebugQuery,
    unknown,
    DescribeFrameworkWithDebugQuery,
    QueryKey
  >,
) {
  const includeDebugInfo =
    window.currentUser?.admin && FeatureToggles.isEnabled(Feature.SHOW_DEBUG_DATA);

  const { data, ...rest } = useGraphQlQuery(
    ["framework", frameworkId],
    includeDebugInfo ? frameworkDebugQuery : frameworkQuery,
    [{ frameworkId }],
    {
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
      ...options,
    },
  );

  return { data: data?.framework, ...rest };
}
