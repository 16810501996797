import * as React from "react";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Input, message, Modal, Radio, Skeleton } from "antd5";
import classnames from "classnames";
import { Link, Redirect, Route, Switch, useLocation } from "wouter";

import { InfoButton } from "components/actions/InfoButton";
import TextButton from "components/actions/TextButton";
import { useDialogManager } from "lib/providers/DialogManager";
import { useNotificationSettings } from "lib/providers/NotificationSettings";
import { IntegrationSubscription, LeadSubscription } from "lib/types/models";
import { openIntercom, pluralise } from "lib/utils";
import SettingsContentContainer from "../SettingsContentContainer";
import NotificationEditor from "./NotificationEditor";

import css from "./IntegrationNotifications.module.scss";

const typeLabel = {
  TEAMS: "Teams",
  SLACK: "Slack",
};

type IntegrationsProps = {
  type: "TEAMS" | "SLACK";
};

function IntegrationNotifications({ type }: IntegrationsProps): JSX.Element {
  return (
    <Switch>
      <Route path="/">
        <Index type={type} />
      </Route>
      <Route path="/create">
        <IntegrationsNotificationSettings type={type} />
      </Route>
      <Route path="/edit/:id">
        {(params) => <IntegrationsNotificationSettings type={type} subscriptionId={params.id} />}
      </Route>
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}

type IntegrationsIndexProps = {
  type: "TEAMS" | "SLACK";
};

function Index({ type }: IntegrationsIndexProps) {
  return (
    <SettingsContentContainer>
      <h1>{typeLabel[type]} notifications</h1>
      <SubscriptionList type={type} />
    </SettingsContentContainer>
  );
}

const getInitialIds = (
  existingSubscription: LeadSubscription | undefined,
  resource_type: "SavedView" | "RecordList",
): string[] => {
  if (!existingSubscription) return [];
  const selectedIds = [];
  for (const subscr of Object.values(existingSubscription.lead_subscription_contents)) {
    if (subscr.resource_type === resource_type) selectedIds.push(subscr.resource_id);
  }
  return selectedIds;
};

type IntegrationNotificationsSettingsProps = {
  subscriptionId?: string;
  type: "SLACK" | "TEAMS";
};

function IntegrationsNotificationSettings({
  subscriptionId,
  type,
}: IntegrationNotificationsSettingsProps) {
  const settings = useNotificationSettings();
  const subscriptionType = type.toLowerCase() as "slack" | "teams";
  const [, setLocation] = useLocation();

  const existingSubscription = React.useMemo((): IntegrationSubscription | undefined => {
    // If there's no subscriptionID then it's a create route and we don't need to find an existing one
    if (!subscriptionId) return;
    const existing = settings.subscriptions?.company_subscriptions[subscriptionType].find(
      (i) => i.id === subscriptionId,
    ) as IntegrationSubscription | undefined;
    if (!existing && settings.status === "ready") {
      // TODO: Send sentry error? What do we want to do in this case?
      void message.error("We were not able to find this notification.");
    } else return existing;
  }, [settings.subscriptions, settings.status, subscriptionId, subscriptionType]);

  const [selectedViewIds, setSelectedViewIds] = React.useState<string[]>(
    getInitialIds(existingSubscription, "SavedView"),
  );
  const [selectedListIds, setSelectedListIds] = React.useState<string[]>(
    getInitialIds(existingSubscription, "RecordList"),
  );

  React.useEffect(() => {
    if (existingSubscription) {
      setSelectedViewIds(getInitialIds(existingSubscription, "SavedView"));
      setSelectedListIds(getInitialIds(existingSubscription, "RecordList"));
      setWebhookURL(existingSubscription?.webhook_url);
      setName(existingSubscription?.name);
      setFrequency(existingSubscription?.frequency);
    }
  }, [existingSubscription]);

  // TODO handle the form differently. Didn't want to user ant forms though
  const [webhookURL, setWebhookURL] = React.useState<string | undefined>(
    existingSubscription ? existingSubscription.webhook_url : undefined,
  );
  const [name, setName] = React.useState<string | undefined>(
    existingSubscription ? existingSubscription.name : undefined,
  );
  const [frequency, setFrequency] = React.useState<"DAILY" | "WEEKLY">(
    existingSubscription ? existingSubscription.frequency : "DAILY",
  );

  const handleUpdate = React.useCallback(async () => {
    if (!existingSubscription) return;
    try {
      await settings.updateSubscription(
        {
          webhook_url: webhookURL,
          name: name,
          view_ids: selectedViewIds,
          list_ids: selectedListIds,
          frequency: frequency,
        },
        existingSubscription.id,
      );
      await settings.reloadSubscription();
      void message.success("Changes saved");
      setLocation(`~/account-management/notifications/${subscriptionType}`);
    } catch {
      void message.error("Issue saving the notification");
    }
  }, [
    name,
    selectedViewIds,
    selectedListIds,
    existingSubscription,
    frequency,
    settings,
    webhookURL,
    subscriptionType,
    setLocation,
  ]);

  const handleCreate = React.useCallback(async () => {
    try {
      await settings.createSubscription({
        webhook_url: webhookURL,
        name: name,
        view_ids: selectedViewIds,
        list_ids: selectedListIds,
        frequency: frequency,
        type: type,
      });
      await settings.reloadSubscription();

      void message.success("Notification successfully created");
      setLocation(`~/account-management/notifications/${subscriptionType}`);
    } catch {
      void message.error("Issue saving the notification");
    }
  }, [
    settings,
    name,
    selectedViewIds,
    selectedListIds,
    frequency,
    webhookURL,
    type,
    subscriptionType,
    setLocation,
  ]);

  const validForm = React.useMemo(() => {
    // Potentially also have to check length of subscription content
    if (!webhookURL || !name) return false;
    return true;
  }, [webhookURL, name]);

  return (
    <SettingsContentContainer className={css.integrationsNotifications}>
      {" "}
      <Link to={`~/account-management/notifications/${subscriptionType}`}>
        <LeftOutlined />
        {typeLabel[type]} Notifications
      </Link>
      <h1>
        {existingSubscription ? `Edit "${existingSubscription.name}"` : "Create a new notification"}
      </h1>
      {settings.status === "ready" || settings.status === "reloading" ? (
        <>
          <div className={css.formItem}>
            <label className={css.label} htmlFor="subscription-name">
              Name:
            </label>
            <Input
              id="subscription-name"
              placeholder="Enter a name for this notification"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className={css.formItem}>
            <label className={css.label} htmlFor="subscription-webhook-url">
              Webhook URL:{" "}
              <InfoButton
                note={
                  <p>
                    Unsure of what a <i>Webhook URL</i> is? Please{" "}
                    <TextButton onClick={() => openIntercom("Export button tooltip")}>
                      get in touch
                    </TextButton>{" "}
                    to find out more.
                  </p>
                }
                tooltipOverlayStyle={{ minWidth: 400 }}
              />
            </label>
            <Input
              id="subscription-webhook-url"
              placeholder="Enter your URL here"
              value={webhookURL}
              onChange={(e) => setWebhookURL(e.target.value)}
            />
          </div>
          <div className={classnames(css.formItem, css.inlineFormItem)}>
            <label className={css.label} htmlFor="subscription-frequency">
              Frequency:
            </label>
            <Radio.Group
              onChange={(e) => setFrequency(e.target.value)}
              value={frequency}
              id="subscription-frequency"
            >
              <Radio.Button value="DAILY">Daily</Radio.Button>
              <Radio.Button value="WEEKLY">Weekly</Radio.Button>
            </Radio.Group>
          </div>
        </>
      ) : (
        <Skeleton active={settings.status === "loading"} paragraph={{ rows: 2, width: "100%" }} />
      )}
      <NotificationEditor
        selectedViewIds={selectedViewIds}
        selectedListIds={selectedListIds}
        onViewsChange={setSelectedViewIds}
        onListsChange={setSelectedListIds}
      />
      <div className={css.buttonContainer}>
        <Link to={`~/account-management/notifications/${subscriptionType}`}>
          <Button>Cancel</Button>
        </Link>
        <Button
          disabled={!(settings.status === "ready" && validForm)}
          type="primary"
          onClick={existingSubscription ? handleUpdate : handleCreate}
        >
          Save
        </Button>
      </div>
    </SettingsContentContainer>
  );
}

type DeleteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  subscription: IntegrationSubscription;
  handleDelete: (id: string) => Promise<void>;
};

const DeleteModal = ({ subscription, isOpen, onClose, handleDelete }: DeleteModalProps) => (
  <Modal
    title="Delete notification?"
    okButtonProps={{
      type: "primary",
      children: "Delete",
      danger: true,
    }}
    onOk={() => {
      void handleDelete(subscription.id);
      onClose();
    }}
    open={isOpen}
    onCancel={onClose}
  >
    Would you like to delete "{subscription.name}"?
  </Modal>
);

type SubscriptionListProps = {
  type: "TEAMS" | "SLACK";
};

function SubscriptionList({ type }: SubscriptionListProps): JSX.Element {
  const settings = useNotificationSettings();
  const dialogManager = useDialogManager();

  const handleDelete = React.useCallback(
    async (id: string) => {
      try {
        await settings.deleteSubscription(id);
        await settings.reloadSubscription();
        void message.success("Successfully deleted notification");
      } catch {
        void message.error("There was an issue deleting this notification.");
      }
    },
    [settings],
  );

  if (settings.status === "ready" || settings.status === "reloading") {
    const subscriptionType = type.toLowerCase() as "teams" | "slack";
    const subscriptions = settings.subscriptions.company_subscriptions[subscriptionType];

    return (
      <>
        {subscriptions.length ? (
          subscriptions.map((s) => {
            const views = Object.values(s.lead_subscription_contents)
              .filter((c) => c.resource_type === "SavedView")
              .map((v) => settings.savedViews.find((sv) => sv.id === v.resource_id)?.name);

            return (
              <Link key={s.id} to={`/edit/${s.id}`}>
                {/* The extra div is needed to stop the navigation when "delete" is clicked */}
                <div onClick={(e) => e.stopPropagation()} className={css.subscriptionSelector}>
                  <h2>{s.name}</h2>
                  <Link to={`/edit/${s.id}`} className={css.edit}>
                    Edit
                  </Link>
                  <TextButton
                    danger
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      dialogManager.openDialog(DeleteModal, {
                        subscription: s,
                        handleDelete: handleDelete,
                      });
                    }}
                    className={css.deleteIcon}
                  >
                    Delete
                  </TextButton>

                  <div className={css.listsAndViews}>
                    <p>
                      <b>{`${pluralise(views.length, "View")}${views.length ? ":" : ""}`}</b>{" "}
                      {views.join(", ")}
                    </p>
                  </div>
                  <div>
                    <b>Frequency:</b> {s.frequency.toLowerCase()}
                  </div>
                </div>
              </Link>
            );
          })
        ) : (
          <div className={css.noSubscriptionsMessage}>
            You don’t currently have any {typeLabel[type]} notifications set up.
          </div>
        )}
        <div>
          <Link to="/create">
            <Button type="primary" icon={<PlusOutlined />}>
              Create a new notification
            </Button>
          </Link>
        </div>
      </>
    );
  }

  return <Skeleton active={settings.status === "loading"} />;
}

export default IntegrationNotifications;
