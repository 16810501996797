import { UseMutationOptions } from "@tanstack/react-query";
import { GraphQLError } from "graphql";

import { graphql } from "lib/generated/app-service-gql";
import {
  ProcessNoticeDocumentMutation,
  ProcessNoticeDocumentMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const processNoticeDoc = graphql(`
  mutation ProcessNoticeDocument($input: ProcessNoticeDocumentRequest!) {
    processNoticeDocument(ProcessNoticeDocumentRequest: $input) {
      success
    }
  }
`);

export function useProcessNoticeDocument(
  options?: UseMutationOptions<
    ProcessNoticeDocumentMutation,
    GraphQLError,
    ProcessNoticeDocumentMutationVariables,
    unknown
  >,
) {
  const { data, ...rest } = useGraphQlMutation(processNoticeDoc, {
    ...options,
    onSuccess: (data, vars, ctx) => {
      options?.onSuccess?.(data, vars, ctx);
    },
  });
  return { data: data?.processNoticeDocument, ...rest };
}
