import React from "react";
import { Divider } from "antd5";
import moment from "moment";

import { BackToLink } from "components/actions/Links";
import LinksDisplay from "components/actions/MultipleLinksDisplay";
import {
  DetailsContent,
  DetailsContentCard,
  DetailsHeader,
  DetailsPage,
} from "components/app_layout/DetailsLayout";
import ContactsTable from "components/contacts/ContactsTable";
import SignalsContainer from "components/tags/SignalsContainer";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import RecordStage from "lib/core_components/RecordStage";
import { getRecordSignals, RecordDetails } from "lib/types/models";
import { formatAmount } from "lib/utils";
import { toHumanRelativeTimeFromNow } from "lib/utils/relative_time";
import { extractUniqueSuppliersFromRecord } from "lib/utils/stotlesDataUtils";

import css from "./RelatedContactsPage.module.scss";

type Props = {
  record: RecordDetails;
};

export function RelatedContactsPage({ record }: Props) {
  // The commented out blocks of code are for when we add the all contacts and actioned contacts tabs.

  // const [allContactsCount, setAllContactsCount] = React.useState<number>();
  // const [actionedContactsCount, setActionedContactsCount] = React.useState<number>();
  // const [tab, setTab] = React.useState<"All contacts tab" | "Actioned contacts tab">(
  //   "All contacts tab",
  // );

  // const onTabChange = (key: "All contacts tab" | "Actioned contacts tab") => {
  //   logEvent(EventNames.tabChanged, {
  //     "Tab selected": key === "All contacts tab" ? "All contacts tab" : "Actioned contacts tab",
  //   });
  //   setTab(key);
  // };

  // const onContactsLoad = React.useCallback((apiResp: ContactSearchResponseDto) => {
  //   setAllContactsCount(apiResp.pagingInfo.totalResults);

  //   // Will need to do more when this is called
  //   setActionedContactsCount(apiResp.pagingInfo.totalResults);
  // }, []);

  const suppliers = extractUniqueSuppliersFromRecord(record);

  const signals = getRecordSignals(record);

  return (
    <DetailsPage>
      <DetailsHeader className={css.headerBar}>
        <div className={css.titleContainer}>
          <BackToLink to="/">Back to notices</BackToLink>
          <h1 className={css.pageHeader}>Find contacts</h1>
        </div>
      </DetailsHeader>
      <DetailsContent className={css.relatedContactsContent}>
        <DetailsContentCard className={css.recordPreviewContent}>
          <div className={css.relatedRecordSection}>
            <span className={css.headerText}>
              <RecordStage stage={record.stage} />{" "}
              {!!record.award_date && ` | ${toHumanRelativeTimeFromNow(record.award_date)}`}
            </span>
            <h3 className={css.recordTitle}>{record.name}</h3>
            <span className={css.headerText}>
              {record.expiry_date
                ? `Expiring in ${toHumanRelativeTimeFromNow(record.expiry_date)}`
                : "Unknown expiry"}
            </span>
            <Divider className={css.divider} />
          </div>
          <div className={css.relatedRecordSection}>
            <div className={css.detailSection}>
              <h4 className={css.subheading}>Buyer</h4>
              <EllipsisTooltipTextLink
                fullText={record.buyer.name}
                linkText={record.buyer.name}
                linkProps={{ to: `/buyers/${record.buyer.guid}`, targetType: "new-tab" }}
              />
            </div>

            {suppliers.length > 0 && (
              <div className={css.detailSection}>
                <h4 className={css.subheading}>Supplier(s)</h4>
                <LinksDisplay
                  links={suppliers.map((s) => (
                    <EllipsisTooltipTextLink
                      fullText={s.name}
                      linkText={s.name}
                      linkProps={{ to: `/suppliers/${s.id}`, targetType: "new-tab" }}
                    />
                  ))}
                />
              </div>
            )}

            <div className={css.detailSection}>
              <h4 className={css.subheading}>Value</h4>
              <span>{formatAmount(record.value_src, record.currency)}</span>
            </div>

            <div className={css.detailSection}>
              <h4 className={css.subheading}>Published</h4>
              <span>{moment(record.publish_date).format("D MMMM YYYY")}</span>
            </div>
            <Divider className={css.divider} />
          </div>
          <div className={css.relatedRecordSection}>
            {signals && (
              <div className={css.detailSection}>
                <h4 className={css.subheading}>Signals</h4>
                <SignalsContainer signals={signals} />
              </div>
            )}
          </div>
        </DetailsContentCard>

        <DetailsContentCard className={css.relatedContactsTable}>
          {/* <div className={css.menuTabsContainer}>
            <Menu mode="horizontal" selectedKeys={[tab]} className={css.menuTabs}>
              <Menu.Item
                key="All contacts tab"
                onClick={() => onTabChange("All contacts tab")}
                className={css.menuTabItem}
              >
                All contacts {allContactsCount === undefined ? <Spin /> : `(${allContactsCount})`}
              </Menu.Item>
              <Menu.Item
                key="Actioned contacts tab"
                onClick={() => onTabChange("Actioned contacts tab")}
                className={css.menuTabItem}
              >
                Actioned Contacts{" "}
                {actionedContactsCount === undefined ? <Spin /> : `(${actionedContactsCount})`}
              </Menu.Item>
            </Menu>
          </div> */}
          <DetailsContent>
            <ContactsTable
              contextSource={"All contacts tab"}
              pageSize={10}
              buyerId={record.buyer.id}
              showAllFiltersDrawer={true}
              showDropdownFilters={false}
              record={record}
              // onContactsLoad={onContactsLoad}
            />
          </DetailsContent>
        </DetailsContentCard>
      </DetailsContent>
    </DetailsPage>
  );
}
