import * as React from "react";
import { Menu } from "antd"; // upgrade and verify

import { TrackingProvider } from "lib/tracking";
import { labelledRoutes } from "./routingUtils";
import { PrimaryTab, SecondaryTab, TabConfigType } from "./types";

import css from "./SupplierRelationshipTabs.module.scss";

type Props = {
  tabConfig: TabConfigType;
  handleTabNavigation: (loc: PrimaryTab | SecondaryTab) => void;
};

function SupplierRelationshipTabs({ tabConfig, handleTabNavigation }: Props) {
  const activeRouteLabel = React.useMemo(() => {
    const activeRoute = tabConfig.secondary || tabConfig.primary;
    return labelledRoutes[activeRoute];
  }, [tabConfig]);

  return (
    <TrackingProvider data={{ "Context source": activeRouteLabel }}>
      <Menu mode="horizontal" selectedKeys={[tabConfig.primary]} className={css.menuTabs}>
        <Menu.Item
          key={PrimaryTab.NOTICES}
          onClick={() => handleTabNavigation(PrimaryTab.NOTICES)}
          className={css.menuTabItem}
        >
          Notices
        </Menu.Item>
        <Menu.Item
          key={PrimaryTab.BUYERS}
          onClick={() => handleTabNavigation(PrimaryTab.BUYERS)}
          className={css.menuTabItem}
        >
          Buyers
        </Menu.Item>
      </Menu>
    </TrackingProvider>
  );
}

export default SupplierRelationshipTabs;
