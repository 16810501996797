/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships } from './DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships';
import {
    DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsFromJSON,
    DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsFromJSONTyped,
    DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsToJSON,
} from './DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships';
import type { NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate } from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate';
import {
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSONTyped,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON,
} from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate';
import type { NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers } from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers';
import {
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSON,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSONTyped,
    NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersToJSON,
} from './NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers';

/**
 * A set of filters that can be used to search for documents
 * @export
 * @interface DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner
 */
export interface DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner {
    /**
     * The ids of the documents to be matched against the query.
     * @type {Array<string>}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner
     */
    ids?: Array<string>;
    /**
     * The country codes to be matched against the query.
     * @type {Array<string>}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner
     */
    countryCode?: Array<string>;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner
     */
    owners?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliers;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner
     */
    keywords?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner
     */
    categories?: Array<DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerCategoriesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner
     */
    mimeType?: Array<string>;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner
     */
    publishedAt?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate;
    /**
     * 
     * @type {NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner
     */
    createdAt?: NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDate;
    /**
     * 
     * @type {DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner
     */
    relationships?: DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships;
}


/**
 * @export
 */
export const DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerCategoriesEnum = {
    Foi: 'FOI',
    MeetingMinutes: 'MEETING_MINUTES',
    ReportAndAccounts: 'REPORT_AND_ACCOUNTS',
    Strategy: 'STRATEGY',
    TribunalDecisions: 'TRIBUNAL_DECISIONS',
    GuidanceAndRegulation: 'GUIDANCE_AND_REGULATION',
    NewsAndCommunication: 'NEWS_AND_COMMUNICATION',
    ConsultationAndResearch: 'CONSULTATION_AND_RESEARCH',
    Statistics: 'STATISTICS',
    InternalOperations: 'INTERNAL_OPERATIONS',
    SpecificReport: 'SPECIFIC_REPORT',
    CaseStudy: 'CASE_STUDY',
    SpecificStrategy: 'SPECIFIC_STRATEGY',
    Budget: 'BUDGET',
    Other: 'OTHER'
} as const;
export type DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerCategoriesEnum = typeof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerCategoriesEnum[keyof typeof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerCategoriesEnum];


/**
 * Check if a given object implements the DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner interface.
 */
export function instanceOfDocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerFromJSON(json: any): DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner {
    return DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerFromJSONTyped(json, false);
}

export function DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ids': !exists(json, 'ids') ? undefined : json['ids'],
        'countryCode': !exists(json, 'country_code') ? undefined : json['country_code'],
        'owners': !exists(json, 'owners') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersFromJSON(json['owners']),
        'keywords': !exists(json, 'keywords') ? undefined : json['keywords'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'mimeType': !exists(json, 'mime_type') ? undefined : json['mime_type'],
        'publishedAt': !exists(json, 'published_at') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON(json['published_at']),
        'createdAt': !exists(json, 'created_at') ? undefined : NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateFromJSON(json['created_at']),
        'relationships': !exists(json, 'relationships') ? undefined : DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsFromJSON(json['relationships']),
    };
}

export function DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerToJSON(value?: DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ids': value.ids,
        'country_code': value.countryCode,
        'owners': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerSuppliersToJSON(value.owners),
        'keywords': value.keywords,
        'categories': value.categories,
        'mime_type': value.mimeType,
        'published_at': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON(value.publishedAt),
        'created_at': NoticeSearchQueryScoreFilterScoresInnerCriteriaAllInnerAllInnerAwardDateToJSON(value.createdAt),
        'relationships': DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsToJSON(value.relationships),
    };
}

