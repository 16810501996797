import * as React from "react";
import { Empty, Pagination } from "antd"; // upgrade and verify, button can be textbutton,
import { Button } from "antd5";
import { SorterResult, TableRowSelection as AntTableRowSelection } from "antd5/es/table/interface";

import { TextLink } from "components/actions/Links";
import {
  convertRecordFiltersToNoticeFilters,
  generateNoticeSearchUrl,
} from "components/notices/utils";
import SignalsContainer from "components/tags/SignalsContainer";
import { Table } from "lib/core_components/Table";
import { ColumnType, commonTableColumns } from "lib/core_components/Table/ColumnTypes";
import { createUseDebounce } from "lib/debounce";
import CachedSequentialRequestHandler, {
  OutOfOrderResponseError,
} from "lib/search/SequentialRequestHandler";
import { SortState } from "lib/search/types";
import { useStotlesApi } from "lib/stotlesApiContext";
import { LeadSignal, SignalCategory, SupplierSummary } from "lib/types/models";
import { EM_DASH } from "lib/utils";

import css from "./SupplierTable.module.scss";

const LEAD_SIGNAL_SORT_COLUMN = "lead_signals";

type SortStateWithSignal = SortState & {
  signal_sort?: "ASC" | "DESC";
};

export type PaginationConfig = {
  pageSize: number;
  current: number;
};

export type TableRowSelection = {
  selectedRowKeys: number[];
  onChange: (
    selectedKeys: number[],
    newItems: Record<string, SupplierSummary & AwardsPerSupplier>,
  ) => void;
};

type SupplierFilters = {
  text?: string;
  country?: string[];
  isSME?: string[];
  recordIds?: string[];
  buyerIds?: number[];
  stats?: boolean;
};

type Props = {
  companyGuid: string;
  teamId: string | undefined;
  excludeSignalCategories?: SignalCategory[];
  filters: SupplierFilters;
  initialSort: SortStateWithSignal;
  emptyStateMessage: string;
  handlePageChange: (page: number, pageSize?: number) => void;
  paginationState: PaginationConfig;
  buyerGuid: string;
  buyerId: number;
  rowSelectionConfig: TableRowSelection;
};

export type AwardsPerSupplier = {
  award_count: number;
  latest_activity: string;
  signals: LeadSignal[];
};

type AwardsPerSuppliers = Record<number, AwardsPerSupplier>;

function ViewAwardsLink({ s, buyerGuid }: { s: SupplierSummary; buyerGuid: string }) {
  const filters = convertRecordFiltersToNoticeFilters({
    searchMode: "AWARDS",
    supplierIds: [s.id],
    buyerGuids: [buyerGuid],
  });

  return (
    <Button type="link" href={generateNoticeSearchUrl(filters)} target="_blank">
      View awards
    </Button>
  );
}

export const getSortOrder = (
  sort: SortStateWithSignal,
  key: string,
): "ascend" | "descend" | undefined => {
  if (sort.signal_sort) {
    if (key === LEAD_SIGNAL_SORT_COLUMN) {
      return sort.signal_sort === "ASC" ? "ascend" : "descend";
      // if `signal_sort` is defined it means it overrides the regular sort and we won't show sort.field as the sorted column
    } else return undefined;
  } else if (sort.field === key) {
    return sort.order === "ASC" ? "ascend" : "descend";
  } else return undefined;
};

function getSupplierColumns(
  sort: SortStateWithSignal,
  buyerGuid: string,
  awardStatsPerSupplier?: AwardsPerSuppliers,
): ColumnType<SupplierSummary>[] {
  const name: ColumnType<SupplierSummary> = {
    ...commonTableColumns.expandableColumn,
    title: "Supplier Name",
    dataIndex: "name",
    key: "name",
    render: (_: unknown, s: SupplierSummary) => (
      <TextLink to={`/suppliers/${s.id}`}>{s.name}</TextLink>
    ),
    sorter: true,
    sortOrder: getSortOrder(sort, "name"),
  };

  const isSME = {
    ...commonTableColumns.isSMEColumn,
    title: "Is SME",
    render: (_: unknown, s: SupplierSummary) =>
      s.is_sme ? "Yes" : s.is_sme === false ? "No" : EM_DASH,
    key: "is_sme",
    sorter: true,
    width: 182,
    sortOrder: getSortOrder(sort, "is_sme"),
  };

  const signals: ColumnType<SupplierSummary> = {
    ...commonTableColumns.signalsColumn,
    title: "Signals",
    key: "lead_signals",
    render: (_: unknown, s: SupplierSummary) => {
      const signals = awardStatsPerSupplier?.[s.id] ? awardStatsPerSupplier[s.id].signals : [];
      return signals?.length > 0 ? (
        <SignalsContainer
          requiredDataType="BUYERS" // They'll only be hidden if the user doesn't meet the requiredDataTypes
          redactedSignalCategories={["Competitors", "Partners"]}
          signals={signals}
          contextSource="In-row"
        />
      ) : null;
    },
    sorter: true,
    sortOrder: getSortOrder(sort, "lead_signals"),
    sizeConfig: {
      xlarge: 250,
      large: 250,
      medium: 220,
      small: 190,
    },
    // think twice before removing PRODUCT_TOUR_*
    className: "PRODUCT_TOUR_supplierSignalCell",
  };

  const awardCount = {
    title: "Number of Awards",
    render: (_: unknown, s: SupplierSummary) =>
      (awardStatsPerSupplier?.[s.id] && awardStatsPerSupplier[s.id].award_count) || EM_DASH,
    key: "award_count",
    sorter: true,
    sortOrder: getSortOrder(sort, "award_count"),
    sizeConfig: {
      large: 185,
      medium: 185,
      small: 120,
    },
  };

  const latestActivity: ColumnType<SupplierSummary> = {
    ...commonTableColumns.dateColumn,
    title: "Latest activity",
    render: (_: unknown, s: SupplierSummary) =>
      (awardStatsPerSupplier?.[s.id] && awardStatsPerSupplier[s.id].latest_activity) || EM_DASH,
    key: "latest_activity",
    sorter: true,
    sortOrder: getSortOrder(sort, "latest_activity"),
    sizeConfig: {
      // Has to be at least 120 otherwise you don't see the sorter
      xlarge: 140,
      large: 140,
      medium: 140,
      small: 120,
    },
  };

  const awardLink = {
    title: "View awards",
    key: "award_link",
    render: (_: unknown, s: SupplierSummary) => <ViewAwardsLink buyerGuid={buyerGuid} s={s} />,
  };

  return [signals, name, latestActivity, isSME, awardCount, awardLink];
}

const useDebounce200 = createUseDebounce(200);

export default function SuppliersTable({
  buyerId,
  buyerGuid,
  filters,
  initialSort,
  emptyStateMessage,
  handlePageChange,
  paginationState,
  companyGuid,
  teamId,
  rowSelectionConfig,
  excludeSignalCategories,
}: Props): JSX.Element {
  const api = useStotlesApi();
  const [suppliers, setSuppliers] = React.useState<SupplierSummary[] | undefined>(undefined);
  const [awardsPerSupplier, setAwardsPerSupplier] = React.useState<AwardsPerSuppliers | undefined>(
    undefined,
  );
  const [sortState, setSortState] = React.useState(initialSort);
  const [totalCount, setTotalCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [sequentialRequestHandler] = React.useState(
    () => new CachedSequentialRequestHandler(api.getSuppliersForBuyer.bind(api)),
  );

  const handleOnRowSelect = React.useCallback(
    (ids: number[], items: SupplierSummary[]) => {
      if (!awardsPerSupplier) {
        return;
      }

      const selectedKeys = rowSelectionConfig.selectedRowKeys as number[];
      const newIds = ids.filter((id: number) => !selectedKeys.includes(id));
      const newItems: Record<string, SupplierSummary & AwardsPerSupplier> = {};
      for (const it of items) {
        // We're slightly misuing ant's table selection behaviour:
        // we pass in selectedKeys from multiple tables (because there could be multiple buyers selected)
        // but expect it to work correctly and pass back some sensible `items` for those keys.
        // Ant5 is stricter and passes `undefined` in `items` for the ids
        // that are not in the result set so we need to skip them.
        if (it === undefined) {
          continue;
        }

        if (newIds.includes(it.id)) {
          newItems[it.id] = { ...it, ...awardsPerSupplier[it.id] };
        }
      }

      rowSelectionConfig.onChange(ids, newItems);
    },
    [awardsPerSupplier, rowSelectionConfig],
  );

  const searchSuppliers = React.useCallback(async () => {
    try {
      setLoading(true);
      const offset = ((paginationState.current || 1) - 1) * (paginationState.pageSize || 0);

      const query = {
        ...filters,
        sort: sortState.field,
        sort_order: sortState.order,
        offset,
        limit: paginationState.pageSize,
        company_guid_override: companyGuid,
        team_id: teamId,
        ...(excludeSignalCategories && { exclude_signal_categories: excludeSignalCategories }),
        ...(sortState.signal_sort && { signal_sort: sortState.signal_sort }),
      };
      const response = await sequentialRequestHandler.makeRequest(buyerId, query);
      if (!response) {
        setLoading(false);
        return;
      }
      const { suppliers, additional_supplier_data, paging_info } = response;
      setSuppliers(suppliers);
      setAwardsPerSupplier(additional_supplier_data);
      setTotalCount(paging_info.total_results);
      setLoading(false);
    } catch (e) {
      if (e instanceof OutOfOrderResponseError) {
        // Nothing to do, we just discard the results
        return;
      }
      setLoading(false);
      throw e;
    }
  }, [
    buyerId,
    filters,
    sortState,
    paginationState,
    sequentialRequestHandler,
    companyGuid,
    teamId,
    excludeSignalCategories,
  ]);

  const debouncedSearchSuppliers = useDebounce200(searchSuppliers);

  React.useEffect(() => {
    debouncedSearchSuppliers();
  }, [buyerId, filters, sortState, paginationState, debouncedSearchSuppliers]);

  const handleChange = React.useCallback(
    (
      _pagination,
      _filters,
      newSorterState: SorterResult<SupplierSummary> | SorterResult<SupplierSummary>[],
    ) => {
      if (Array.isArray(newSorterState)) {
        newSorterState = newSorterState[0];
      }
      const newSortState: SortStateWithSignal = {
        order: newSorterState.order === "descend" ? "DESC" : "ASC",
        field: newSorterState.columnKey as string,
      };
      if (!(newSortState.order === sortState.order && newSortState.field === sortState.field)) {
        // If the selected sort column is lead signals we override the field to fall back to the original sort
        // and set the special signal_sort to its order
        if (newSortState.field === LEAD_SIGNAL_SORT_COLUMN) {
          newSortState.signal_sort = newSortState.order;
          newSortState.field = initialSort.field;
          newSortState.order = initialSort.order;
        }
        setSortState(newSortState);
      }
    },
    [sortState, initialSort],
  );

  const footer = React.useCallback(() => {
    const searchFilters: SupplierFilters = {
      buyerIds: [buyerId],
    };
    return (
      <div className={css.tableFooter}>
        <Pagination {...paginationState} total={totalCount} onChange={handlePageChange} />
        <Button
          type="link"
          href={generateNoticeSearchUrl(convertRecordFiltersToNoticeFilters(searchFilters))}
          target="_blank"
        >
          See all
        </Button>
      </div>
    );
  }, [buyerId, totalCount, paginationState, handlePageChange]);

  const tableColumns = React.useMemo(
    () => getSupplierColumns(sortState, buyerGuid, awardsPerSupplier),
    [sortState, awardsPerSupplier, buyerGuid],
  );

  return (
    <Table<SupplierSummary>
      rowKey="id"
      pagination={false}
      loading={loading}
      dataSource={suppliers}
      columns={tableColumns}
      footer={footer}
      onChange={handleChange}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyStateMessage} />,
      }}
      rowSelection={
        {
          ...rowSelectionConfig,
          onChange: handleOnRowSelect,
          preserveSelectedRowKeys: true,
        } as AntTableRowSelection<SupplierSummary>
      }
      scroll={{ x: true }}
    />
  );
}
