import { SupplierMentionType } from "../../lib/types/graphQLEnums";

export function countFilters(
  filters: Record<string, unknown>,
  groupedKeys: Record<string, string[]>,
) {
  const result: Record<string, number> = {};

  for (const [groupKey, filterKeys] of Object.entries(groupedKeys)) {
    result[groupKey] = filterKeys.reduce((count: number, key: string) => {
      const value = filters[key];

      // special cases
      if (key === "supplierSme") {
        return count + (value === "" ? 0 : 1);
      }

      if (key === "supplierMentionType") {
        return (
          count +
          (value === undefined || value === SupplierMentionType.MentionedOrDirectlyAwarded ? 0 : 1)
        );
      }

      return count + countValues(value);
    }, 0);
  }

  return result;
}

function countValues(value: unknown): number {
  if (value === null || value === undefined) return 0;

  if (Array.isArray(value)) {
    return value.length;
  }

  if (typeof value === "object") {
    let count = 0;
    let hasFromOrTo = false;

    for (const [key, nestedValue] of Object.entries(value)) {
      if (key === "from" || key === "to") {
        if (nestedValue !== null && nestedValue !== undefined && !hasFromOrTo) {
          count += 1;
          hasFromOrTo = true;
        }
      } else if (key === "hideNulls") {
        count += nestedValue ? 1 : 0;
      } else if (typeof nestedValue === "object" && nestedValue !== null) {
        count += countValues(nestedValue); // Recursively count nested values
      }
    }
    return count;
  }

  if (typeof value === "string" || typeof value === "boolean") {
    return value ? 1 : 0;
  }

  return 0;
}
