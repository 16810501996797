import * as React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd5";
import produce from "immer";

import TextButton from "components/actions/TextButton";
import MultiSelectDropdown from "lib/core_components/MultiSelectDropdownList";
import { createUseDebounce } from "lib/debounce";
import { useOpenApi } from "lib/openApiContext";
import { EventNames, useTracking } from "lib/tracking";
import { SELECT_ALL } from "lib/types/models";
import { SignalCategory } from "../../lib/StotlesApi";
import { SupplierRelationshipFilter } from "./types";

import css from "./RelationshipProfileFilter.module.scss";

const useDebounce500 = createUseDebounce(500);

type Props = {
  search_type: string;
  onFiltersChange: React.Dispatch<React.SetStateAction<SupplierRelationshipFilter>>;
  filters: SupplierRelationshipFilter;
};
//TODO: Potentially make this component even more reusable accross buyer details + relationship pages
function RelationshipProfileFilter({ search_type, filters, onFiltersChange }: Props) {
  const openApi = useOpenApi();
  const { logEvent } = useTracking();
  const [interests, setInterests] = React.useState<SupplierRelationshipFilter>({
    keywords: [],
  });

  const [textSearch, setTextSearch] = React.useState("");

  React.useEffect(() => {
    void (async () => {
      if (window.currentUser) {
        const resp = await openApi.getTeamSignalSettings();
        setInterests({
          keywords: resp.signals
            .filter((s) => s.category === SignalCategory.KEYWORD)
            .map((s) => s.name),
        });
      }
    })();
  }, [openApi]);

  const onKeywordsChange = React.useCallback(
    (newKeywords: string[]) =>
      onFiltersChange(
        produce(filters, (draft) => {
          draft.keywords = newKeywords;
        }),
      ),
    [filters, onFiltersChange],
  );

  const hasActiveFilters = React.useMemo(() => {
    if (!filters) {
      return false;
    } else return Object.values(filters).some((value) => value.length > 0);
  }, [filters]);

  const handleClearFilters = React.useCallback(() => {
    onFiltersChange({ keywords: [], textSearch: "" });
    logEvent(EventNames.filterActioned, {
      "Filter name": "All filters",
      "Filter selection": "All options",
      "Action type": "Filter cleared",
    });
  }, [logEvent, onFiltersChange]);

  const selectedkeywords = React.useMemo(() => {
    return filters?.keywords.includes(SELECT_ALL) ? interests?.keywords : filters?.keywords;
  }, [interests?.keywords, filters?.keywords]);

  const keywordOptions = React.useMemo(() => {
    return interests?.keywords.map((kw) => ({ title: kw, value: kw }));
  }, [interests?.keywords]);

  const textSearchChangeEvent = React.useCallback(
    (value: string) => {
      logEvent(EventNames.filterActioned, {
        "Filter name": `${search_type} search`,
        "Filter selection": value,
        "Action type": value.length > 0 ? "Filter applied" : "Filter cleared",
      });
      onFiltersChange(
        produce((f) => {
          f.textSearch = value;
        }),
      );
    },
    [search_type, logEvent, onFiltersChange],
  );

  const debouncedTextSearchChangeEvent = useDebounce500(textSearchChangeEvent);

  const handleTextSearch = React.useCallback(
    (value: string) => {
      setTextSearch(value);
      debouncedTextSearchChangeEvent(value);
    },
    [debouncedTextSearchChangeEvent],
  );

  return (
    <div className={css.filtersContainer}>
      <Input
        placeholder={`Search ${search_type}`}
        onChange={(e) => handleTextSearch(e.target.value)}
        value={textSearch}
        className={css.searchInput}
        prefix={<SearchOutlined className={css.searchIcon} />}
        allowClear
      />
      <MultiSelectDropdown
        title="Keywords"
        optionClassName={css.keywordTag}
        options={keywordOptions}
        selectedOptions={selectedkeywords}
        onChange={onKeywordsChange}
      />
      {hasActiveFilters && (
        <TextButton bold className={css.clearFilters} onClick={handleClearFilters}>
          Clear all filters
        </TextButton>
      )}
    </div>
  );
}

export default RelationshipProfileFilter;
