import { TypedLogs } from "pages/admin/ReviewOrgRelationshipTask";

import {
  CandidateAction,
  CandidateOperation,
  DistinctCandidate,
  HierarchicalCandidate,
  MultipleEntitiesTypes,
  OrgMap,
  OrgToBeCreated,
  OrgWithStats,
  ProcessStages,
  QualifiedCandidate,
  SubmittedLog,
  UpdatableAttributes,
  ValidOrgsCount,
} from "components/organisation_clean/types";
import {
  DecisionToEdit,
  DecisionType,
  NewBuyerFromJSON,
  NewBuyerToJSON,
} from "lib/generated/app-api";
import { DecisionTypeUpdateAttributesFromJSON } from "lib/generated/app-api/models/DecisionTypeUpdateAttributes";
import { HierarchicalCandidate as HierarchicalBackendType } from "lib/generated/app-api/models/HierarchicalCandidate";
import { MultipleEntitiesDecisionCleaningData } from "lib/generated/app-api/models/MultipleEntitiesDecisionCleaningData";
import { SubmittedDecision } from "lib/generated/app-api/models/SubmittedDecision";

const THREE_OR_MORE_CANCELLED_NOTE = "Candidate buyer contains 3 or more valid buyers";

const decisionTypeToQualificationString: Record<keyof DecisionType, CandidateOperation> = {
  duplicate: CandidateOperation.DUPLICATE,
  transferResponsibilities: CandidateOperation.TRANSFER_RESPONSIBILITIES,
  notRelated: CandidateOperation.NOT_RELATED,
  inactive: CandidateOperation.INACTIVE,
  unsure: CandidateOperation.UNSURE,
  multipleEntities: CandidateOperation.MULTIPLE_ENTITIES,
  updateAttributes: CandidateOperation.UPDATE_ATTRIBUTES,
  signalsToOrg: CandidateOperation.SIGNALS_TO_ORG,
  createAlias: CandidateOperation.CREATE_ALIAS,
};

function generateMultipleEntitiesCleaningData(cleaningData: MultipleEntitiesDecisionCleaningData) {
  return {
    multipleEntities: {
      qualification: CandidateOperation.MULTIPLE_ENTITIES,
      cancelled: false,
      cleaningData: cleaningData,
    },
  };
}

/**
 * Function decides whether or not to set the decision type as TRANSFER_RESPONSIBILITIES or INACTIVE.
 * Transfer responsibilities backend logic sets the anchor buyer to inactive AND Transfers responsibilities to the target or new buyer
 * @param orgGuid
 * @param newOrg
 * @returns
 */
export function createDecision(
  guid: string,
  decision: keyof DecisionType,
  newOrg?: OrgToBeCreated,
): SubmittedDecision {
  if (decision === "inactive") {
    return {
      candidateOrganisationId: guid,
      decisionData: {
        inactive: {
          qualification: CandidateOperation.INACTIVE,
        },
      },
    };
  } else {
    // When executed, this decision will also set the anchor buyer to inactive automatically in the backend
    return {
      candidateOrganisationId: guid,
      decisionData: {
        transferResponsibilities: {
          qualification: decisionTypeToQualificationString[decision],
          newBuyer: NewBuyerFromJSON(newOrg),
        },
      },
    };
  }
}

export function candidatesToSubmittedDecisions(
  qualifiedCandidates: Record<string, QualifiedCandidate>,
): SubmittedDecision[] {
  return Object.values(qualifiedCandidates).map(convertCandidateToDecision);
}

export function candidatesToDecisionsToEdit({
  candidates,
  logToOrgIdMap,
}: {
  candidates: Record<string, QualifiedCandidate>;
  logToOrgIdMap: Record<string, string>;
}): DecisionToEdit[] {
  return Object.values(candidates).map(
    (c) =>
      ({
        ...convertCandidateToDecision(c),
        decisionLogId: logToOrgIdMap[c.buyer.guid],
      }) as DecisionToEdit,
  );
}

function convertCandidateToDecision(qualifiedCandidate: QualifiedCandidate) {
  const guid = qualifiedCandidate.buyer.guid;
  switch (qualifiedCandidate.qualification) {
    case CandidateOperation.DUPLICATE:
      return {
        candidateOrganisationId: guid,
        decisionData: { duplicate: { qualification: "DUPLICATE" } },
      };
    case CandidateOperation.NOT_RELATED:
      return {
        candidateOrganisationId: guid,
        decisionData: { notRelated: { qualification: "NOT_RELATED" } },
      };
    case CandidateOperation.UNSURE:
      if (!qualifiedCandidate.note) throw `Missing note for unsure qualified candidate ${guid}`;
      return {
        candidateOrganisationId: guid,
        decisionData: { unsure: { qualification: "UNSURE", note: qualifiedCandidate.note } },
      };
    case CandidateOperation.UPDATE_ATTRIBUTES:
      return {
        candidateOrganisationId: guid,
        decisionData: {
          updateAttributes: DecisionTypeUpdateAttributesFromJSON(qualifiedCandidate.newValues),
        },
      };
    case CandidateOperation.MULTIPLE_ENTITIES:
      if (qualifiedCandidate.processStage === ProcessStages.CANCELLED) {
        return {
          candidateOrganisationId: guid,
          decisionData: {
            multipleEntities: {
              qualification: "MULTIPLE_ENTITIES",
              cancelled: true,
              note: qualifiedCandidate.note,
            },
          },
        };
      } else if (qualifiedCandidate.cleaningData?.type === MultipleEntitiesTypes.DISTINCT) {
        return {
          candidateOrganisationId: guid,
          decisionData: generateMultipleEntitiesCleaningData({
            distinct: {
              type: "DISTINCT",
              distinctBuyers: qualifiedCandidate.cleaningData.selectedDistinctBuyers?.map(
                (b) => b.guid,
              ),
              newDistinctBuyers: qualifiedCandidate.cleaningData.newDistinctBuyers,
            },
          }),
        };
      } else if (qualifiedCandidate.cleaningData?.type === MultipleEntitiesTypes.HIERARCHICAL) {
        if (qualifiedCandidate.cleaningData.validBuyersCount === ValidOrgsCount.THREE_OR_MORE) {
          return {
            candidateOrganisationId: guid,
            decisionData: {
              multipleEntities: {
                qualification: "MULTIPLE_ENTITIES",
                cancelled: true,
                note: THREE_OR_MORE_CANCELLED_NOTE,
              },
            },
          };
        } else if (qualifiedCandidate.cleaningData.validBuyersCount === ValidOrgsCount.UP_TO_ONE) {
          return {
            candidateOrganisationId: guid,
            decisionData: generateMultipleEntitiesCleaningData({
              hierarchical: {
                type: "HIERARCHICAL",
                multipleValidBuyersExist: false,
              },
            }),
          };
        } else {
          if (!qualifiedCandidate.cleaningData.candidateAction) {
            throw `Missing candidate action`;
          }
          if (!qualifiedCandidate.cleaningData.relationToAnchor) {
            throw `Missing relation to anchor`;
          }
          const { candidateAction } = qualifiedCandidate.cleaningData;
          const candidateActionDecision =
            candidateAction.type === "MERGE"
              ? {
                  merge: {
                    type: "MERGE",
                    replaceByGuid: candidateAction.replacedBy.guid,
                  },
                }
              : {
                  rename: {
                    type: "RENAME",
                    newName: candidateAction.newName,
                  },
                };
          return {
            candidateOrganisationId: guid,
            decisionData: generateMultipleEntitiesCleaningData({
              hierarchical: {
                type: "HIERARCHICAL",
                multipleValidBuyersExist: true,
                relationToAnchor: qualifiedCandidate.cleaningData.relationToAnchor,
                candidateAction: candidateActionDecision,
              },
            }),
          };
        }
      } else throw `Incorrect multiple entities type`;
    default:
      throw `Invalid qualification`;
  }
}

// Converts a set of backend formatted logs to the frontend accepted QualifiedCandidate map
export function submittedDecisionToCandidates(
  logs: SubmittedLog<DecisionType>[],
  orgMap: OrgMap,
): Record<string, QualifiedCandidate> {
  const candidates: Record<string, QualifiedCandidate> = {};
  for (const log of logs) {
    candidates[log.candidate_organisation_id] = convertDecisionToCandidate(log, orgMap);
  }
  return candidates;
}

function convertDecisionToCandidate(
  log: SubmittedLog<DecisionType>,
  orgMap: OrgMap,
): QualifiedCandidate {
  const decision_data = log.decision_json;
  const candidateBuyer = getOrgMatch(log.candidate_organisation_id, orgMap);
  if (decision_data.duplicate) {
    return {
      qualification: CandidateOperation.DUPLICATE,
      buyer: candidateBuyer,
      processStage: ProcessStages.DONE,
    };
  } else if (decision_data.notRelated) {
    return {
      qualification: CandidateOperation.NOT_RELATED,
      buyer: candidateBuyer,
      processStage: ProcessStages.DONE,
    };
  } else if (decision_data.unsure) {
    return {
      qualification: CandidateOperation.UNSURE,
      buyer: candidateBuyer,
      processStage: ProcessStages.DONE,
      note: decision_data.unsure.note,
    };
  } else if (decision_data.multipleEntities) {
    return {
      qualification: CandidateOperation.MULTIPLE_ENTITIES,
      buyer: candidateBuyer,
      processStage: decision_data.multipleEntities.cancelled
        ? ProcessStages.CANCELLED
        : ProcessStages.DONE,
      note: decision_data.multipleEntities.note,
      cleaningData: convertCleaningData(
        decision_data.multipleEntities.cleaningData,
        orgMap,
        decision_data.multipleEntities.note,
      ),
    };
  } else if (decision_data.updateAttributes) {
    const attrs: UpdatableAttributes = {};

    if (decision_data.updateAttributes.oscarId !== undefined) {
      attrs.oscar_id = decision_data.updateAttributes.oscarId;
    }

    if (decision_data.updateAttributes.isStotlesVerified !== undefined) {
      attrs.is_stotles_verified = decision_data.updateAttributes.isStotlesVerified;
    }

    if (decision_data.updateAttributes.companiesHouseId !== undefined) {
      attrs.companies_house_id = decision_data.updateAttributes.companiesHouseId;
    }

    return {
      buyer: candidateBuyer,
      qualification: CandidateOperation.UPDATE_ATTRIBUTES,
      processStage: ProcessStages.DONE,
      newValues: attrs,
    };
  } else throw `Unknown type`;
}

export function relationshipDecisionToTargetOrgs(
  logs: SubmittedLog<DecisionType>[],
  orgMap: OrgMap,
): OrgWithStats[] {
  const orgs: OrgWithStats[] = [];
  for (const log of logs) {
    if (log.decision_json.transferResponsibilities) {
      if (!log.decision_json.transferResponsibilities.newBuyer) {
        orgs.push(getOrgMatch(log.candidate_organisation_id, orgMap));
      }
    }
  }
  return orgs;
}

export function logsToDecisionsToEdit(
  decisionType: keyof DecisionType,
  typedLogs: TypedLogs[],
): DecisionToEdit[] {
  const decisionsForType = typedLogs.filter((log) => !!log.decision_json[decisionType]);

  return decisionsForType.map((log) => {
    // May be present in log info, most liekely will be undefined unless this is a transferResponsibilities decision to a new buyer
    const newBuyerFromLog: OrgToBeCreated = NewBuyerToJSON(
      log.decision_json.transferResponsibilities?.newBuyer,
    );

    return {
      ...createDecision(log.candidate_organisation_id, decisionType, newBuyerFromLog),
      decisionLogId: log.id,
    } as DecisionToEdit;
  });
}

export function submittedDecisionToNewOrgs(logs: SubmittedLog<DecisionType>[]): OrgToBeCreated[] {
  const orgs: OrgToBeCreated[] = [];
  for (const log of logs) {
    const newBuyer = log.decision_json.transferResponsibilities?.newBuyer;

    const newOrg = NewBuyerToJSON(newBuyer);
    if (newOrg) {
      orgs.push(newOrg);
    }
  }
  return orgs;
}

// Converts the cleaning data object on the multiple entities candidates
// From our backend format to the frontend format
function convertCleaningData(
  cleaningData: MultipleEntitiesDecisionCleaningData | undefined,
  orgMap: OrgMap,
  cancelNote: string | undefined,
): HierarchicalCandidate | DistinctCandidate | undefined {
  if (!cleaningData) return;
  if (cleaningData.distinct) {
    const distinctBuyers = cleaningData.distinct.distinctBuyers
      ? cleaningData.distinct.distinctBuyers.map((d) => getOrgMatch(d, orgMap))
      : undefined;
    return {
      type: MultipleEntitiesTypes.DISTINCT,
      selectedDistinctBuyers: distinctBuyers,
      newDistinctBuyers: cleaningData.distinct.newDistinctBuyers,
    };
  } else if (cleaningData.hierarchical) {
    const hierarchicalData = cleaningData.hierarchical;
    const validBuyersCount = getValidOrgsCount(hierarchicalData, cancelNote);
    const candidateAction = getCandidateAction(hierarchicalData, orgMap);
    return {
      type: MultipleEntitiesTypes.HIERARCHICAL,
      validBuyersCount: validBuyersCount,
      relationToAnchor: hierarchicalData.relationToAnchor as "IS_CHILD" | "IS_PARENT" | undefined,
      hierarchyConfirmed: hierarchicalData.candidateAction ? true : false,
      candidateAction: candidateAction,
    };
  }
}

export function getOrgMatch(guid: string, orgMap: OrgMap): OrgWithStats {
  const buyerMatch = orgMap[guid];
  if (!buyerMatch) throw `${guid} missing buyer in map.`;
  else return { ...buyerMatch.org, stats: buyerMatch.stats };
}

function getValidOrgsCount(hierarchical: HierarchicalBackendType, cancelNote?: string) {
  if (cancelNote === THREE_OR_MORE_CANCELLED_NOTE) {
    return ValidOrgsCount.THREE_OR_MORE;
  } else if (hierarchical.multipleValidBuyersExist) {
    return ValidOrgsCount.TWO;
  } else if (hierarchical.multipleValidBuyersExist === false) {
    return ValidOrgsCount.UP_TO_ONE;
  } else return undefined;
}

function getCandidateAction(
  hierarchical: HierarchicalBackendType,
  orgMap: OrgMap,
): CandidateAction | undefined {
  if (!hierarchical.candidateAction) return;
  else if (hierarchical.candidateAction.merge) {
    return {
      type: "MERGE",
      replacedBy: getOrgMatch(hierarchical.candidateAction.merge.replaceByGuid, orgMap),
    };
  } else if (hierarchical.candidateAction.rename) {
    return { type: "RENAME", newName: hierarchical.candidateAction.rename.newName };
  } else return undefined;
}
