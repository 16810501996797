import React from "react";
import { Control, Controller } from "react-hook-form";

import { NEW_NOTICES_SEARCH, useVariableValue } from "lib/featureFlags";
import { NewRelevanceScoreButtons } from "../../form_components/NewRelevanceScoreButtons";
import { RelevanceScoreButtons } from "../../form_components/RelevanceScoreButtons";
import { NoticeFilters } from "../utils";

import css from "./SignalScore.module.scss";

export interface SignalScoreFilterContentProps {
  control: Control<NoticeFilters, unknown>;
}

function SignalScoreFilterContent({ control }: SignalScoreFilterContentProps) {
  const enableNewNoticeSearch = useVariableValue(NEW_NOTICES_SEARCH, false);
  return (
    <div className={css.filterSection} aria-label="Signals filters">
      <Controller
        name="relevanceScore"
        control={control}
        render={({ field }) => (
          <div className={css.inputContainer}>
            {enableNewNoticeSearch ? (
              <NewRelevanceScoreButtons {...field} />
            ) : (
              <RelevanceScoreButtons {...field} checkedRelevanceScores={field.value} />
            )}
          </div>
        )}
      />
    </div>
  );
}

export default SignalScoreFilterContent;
