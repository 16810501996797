import React, { useMemo, useState } from "react";
import { hot } from "react-hot-loader/root";
import { Card, ConfigProvider } from "antd5";
import classNames from "classnames";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsContent, DetailsHeader, DetailsPage } from "components/app_layout/DetailsLayout";
import { PaywallBanner } from "components/paywall/PaywallBanner";
import { FilterableSuppliers } from "components/suppliers/FilterableSuppliers";
import { FilterSections } from "components/suppliers/FilterForm";
import {
  DEFAULT_FILTERS,
  parseSuppliersUrlState,
  SupplierFilters,
} from "components/suppliers/utils";
import { LAYER_CAKE_FILTERS, NEW_SUPPLIER_FILTER, useVariableValue } from "lib/featureFlags";
import { useSavedSuppliers } from "lib/hooks/api/signals/useSavedSuppliers";
import { useURLState } from "lib/hooks/useURLState";
import PaywallStar from "lib/icons/PaywallStar";
import { useCheckSubscription } from "lib/providers/ProHelper";
import { TrackingProvider } from "lib/tracking";

import css from "./SupplierSearchPage.module.scss";

export enum ActiveSupplierTab {
  ALL_SUPPLIERS = "all_suppliers",
  COMPETITORS = "competitors",
  PARTNERS = "partners",
}

export function SupplierSearchPage() {
  const { authorised: hasSuppliers } = useCheckSubscription("SUPPLIERS", {
    "Context source": "Suppliers - Find suppliers",
  });

  const hiddenSections: FilterSections[] = useVariableValue(LAYER_CAKE_FILTERS, false)
    ? ["signals", "buyers", "lots"]
    : ["buyers", "lots"];

  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);

  const { data: savedSuppliers } = useSavedSuppliers({
    enabled: hasSuppliers,
  });

  const { partnerGuids, competitorGuids } = savedSuppliers || {
    partnerGuids: [],
    competitorGuids: [],
  };

  const defaultFilters = useMemo((): Record<ActiveSupplierTab, SupplierFilters> => {
    return {
      partners: {
        ...DEFAULT_FILTERS,
        guids: partnerGuids,
      },
      competitors: {
        ...DEFAULT_FILTERS,
        guids: competitorGuids,
      },
      all_suppliers: {
        ...DEFAULT_FILTERS,
      },
    };
  }, [partnerGuids, competitorGuids]);

  const [lastSelectedTab, setLastSelectedTab] = useState(ActiveSupplierTab.ALL_SUPPLIERS);

  const [supplierFilters] = useURLState<SupplierFilters>(
    "supplierFilters",
    DEFAULT_FILTERS,
    parseSuppliersUrlState,
  );

  /**
   * This useMemo is used to determine which tab should actually be highlighted based on what filters are currently active
   * For example, if a user selects the "My competitors" tab, but then adds another one or two more suppliers to the filter,
   * it's no longer My competitors
   */
  const actuallyActiveTab = useMemo((): ActiveSupplierTab => {
    const selectedGuids = supplierFilters?.guids || [];
    const hasUserModifiedFilters =
      !selectedGuids.every((item) => defaultFilters[lastSelectedTab].guids?.includes(item)) ||
      selectedGuids.length !== defaultFilters[lastSelectedTab].guids?.length;

    // When supplier paywall is active, the filters don't matter. The currently active tab is just the last one selected
    if (!hasSuppliers || supplierFilters === undefined) {
      return lastSelectedTab;
    } else {
      return hasUserModifiedFilters ? ActiveSupplierTab.ALL_SUPPLIERS : lastSelectedTab;
    }
  }, [supplierFilters, defaultFilters, lastSelectedTab, hasSuppliers]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            paddingSM: 16,
            paddingLG: 16,
          },
        },
      }}
    >
      <TrackingProvider
        data={{
          "Context source": "Suppliers - Find suppliers",
        }}
      >
        <DetailsPage className={css.supplierSearchPage}>
          <DetailsHeader borderBottom={false}>
            <h2 className={css.title}>Find suppliers</h2>
            {enableNewSupplierFilter && window.currentUser?.use_supplier_name === false && (
              <div className={css.cardsContainer} aria-label="Supplier views">
                <Card
                  className={classNames(css.card, {
                    [css.active]: actuallyActiveTab === ActiveSupplierTab.ALL_SUPPLIERS,
                  })}
                  onClick={() => setLastSelectedTab(ActiveSupplierTab.ALL_SUPPLIERS)}
                >
                  <h3>All suppliers</h3>
                </Card>
                <Card
                  className={classNames(css.card, {
                    [css.active]: actuallyActiveTab === ActiveSupplierTab.COMPETITORS,
                  })}
                  onClick={() => setLastSelectedTab(ActiveSupplierTab.COMPETITORS)}
                >
                  <div className={css.cardContent}>
                    <h3>My competitors</h3>
                    {!hasSuppliers && <PaywallStar className={css.paywallIcon} />}
                  </div>
                </Card>
                <Card
                  className={classNames(css.card, {
                    [css.active]: actuallyActiveTab === ActiveSupplierTab.PARTNERS,
                  })}
                  onClick={() => setLastSelectedTab(ActiveSupplierTab.PARTNERS)}
                >
                  <div className={css.cardContent}>
                    <h3>My partners</h3>
                    {!hasSuppliers && <PaywallStar className={css.paywallIcon} />}
                  </div>
                </Card>
              </div>
            )}
          </DetailsHeader>

          {/* When supplier view is my competitor or my partner - and user has not got paid supplier data access, show only the paywall banner */}
          {!hasSuppliers && actuallyActiveTab !== ActiveSupplierTab.ALL_SUPPLIERS ? (
            <PaywallBanner
              featureType={
                actuallyActiveTab == ActiveSupplierTab.COMPETITORS ? "COMPETITORS" : "PARTNERS"
              }
            />
          ) : (
            <>
              {/* Otherwise, we show the paywall banner + supplier table (which will have paywalls in the table) */}
              {!hasSuppliers && <PaywallBanner featureType="SUPPLIERS" />}
              <DetailsContent className={css.supplierSearchContent}>
                <FilterableSuppliers
                  hiddenFilterSections={hiddenSections}
                  defaultFilters={defaultFilters[lastSelectedTab]}
                  activeTab={actuallyActiveTab}
                  setActiveTab={setLastSelectedTab}
                />
              </DetailsContent>
            </>
          )}
        </DetailsPage>
      </TrackingProvider>
    </ConfigProvider>
  );
}

export default hot(
  withAppLayout(SupplierSearchPage, {
    pageName: "Supplier Search",
  }),
);
