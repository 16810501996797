import * as React from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd"; // check button colours, remove mid blue, border radius etc. a lot of it should be default, beware global ant styles
import { FormComponentProps } from "antd/lib/form";
import classnames from "classnames";
import Cookies from "js-cookie";

import { DISALLOWED_DOMAINS_PATTERN } from "lib/disallowedDomains";
import { STOTLES_UTM_COOKIE } from "lib/UtmParams";

import css from "./SignUpForm.module.scss";

export type SignUpFormData = {
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  password: string;
  passwordConfirmation: string;
  signupUtm?: string;
  leadNotes?: string;
  campaignDescription?: string;
};

export type SignupFormDataNoPasswords = Omit<SignUpFormData, "password" | "passwordConfirmation">;
type State = {
  passwordCompletedFields: string[] | null;
  passwordErrors: string[] | null;
};
type Props = {
  onSubmit: (data: SignUpFormData) => void;
  formSubmitting: boolean;
  initialValues: SignupFormDataNoPasswords;
  companyInputDisabled: boolean;
  hasInvitation: boolean;
} & FormComponentProps;

class SignUpForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      passwordCompletedFields: null,
      passwordErrors: null,
    };
  }

  _handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit({
          email: values.email,
          firstName: values.first_name,
          lastName: values.last_name,
          company: values.company,
          password: values.password,
          passwordConfirmation: values.password_confirmation,
          signupUtm: Cookies.getJSON(STOTLES_UTM_COOKIE),
          leadNotes: this.props.initialValues.leadNotes,
          campaignDescription: this.props.initialValues.campaignDescription,
        });
        e.preventDefault();
        return true;
      } else {
        e.preventDefault();
      }
    });
  };

  comparePasswordConfirmationToPassword = (
    _rule: unknown,
    value: string | null,
    callback: (message?: JSX.Element) => void,
  ) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback(<span className={css.errorMessage}>⚠ Passwords do not match</span>);
    } else {
      callback();
    }
  };

  messageRenderer = (message: string, valid: boolean) => (
    <span
      key={message}
      className={classnames(valid ? css.validMessage : css.errorMessage, css.validationMessage)}
    >
      {valid ? (
        <CheckOutlined className={css.checkIcon} />
      ) : (
        <CloseOutlined className={css.crossIcon} />
      )}{" "}
      {message}
    </span>
  );

  passwordValidator = (
    _rule: unknown,
    value: string | null,
    callback: (messages?: JSX.Element[]) => void,
  ) => {
    if (!value) {
      callback();
      this.setState({ ...this.state, passwordCompletedFields: null });
    } else {
      const errors = new Set(this.state.passwordErrors);
      const completed = new Set(this.state.passwordCompletedFields);
      const validations = [
        { message: "At least 8 characters in length", rule: value.length > 7 },
        { message: "At least one lower case letter (a-z)", rule: /(?=.*[a-z])/.test(value) },
        { message: "At least one upper case letter (A-Z)", rule: /(?=.*[A-Z])/.test(value) },
        { message: "At least one number (0-9)", rule: /(?=.*\d)/.test(value) },
      ];
      for (const validation of validations) {
        const message = validation.message;
        const validRule = validation.rule;
        if (validRule) {
          completed.add(message);
          errors.delete(message);
        } else {
          completed.delete(message);
          errors.add(message);
        }
      }
      if (!this.props.formSubmitting) {
        this.setState({
          ...this.state,
          passwordCompletedFields: Array.from(completed),
          passwordErrors: Array.from(errors), // we can't return these in the callback because they'll be interpreted as errors
        });
      }
      const styledErrors = Array.from(errors).map((e) => this.messageRenderer(e, false));
      if (errors.size > 0) callback(styledErrors);
      else callback();
    }
  };

  handlePasswordBlur() {
    const passwordErrors = this.props.form.getFieldError("password");
    // If there are no errors in the password field we don't have to show the completed ones anymore either
    if (!passwordErrors) {
      this.setState({ ...this.state, passwordCompletedFields: null });
    }
  }

  componentDidMount() {
    const { form, initialValues } = this.props;
    form.setFieldsValue({
      first_name: initialValues.firstName,
      last_name: initialValues.lastName,
      email: initialValues.email,
      company: initialValues.company,
    });
  }

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <Form onSubmit={this._handleSubmit} className={css.onboardingForm}>
        <div className={css.firstRow}>
          <Form.Item>
            {getFieldDecorator("first_name", {
              rules: [{ required: true, message: "First name is required" }],
            })(<Input placeholder="First name" className="no-outline" autoFocus />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("last_name", {
              rules: [{ required: true, message: "Last name is required" }],
            })(<Input placeholder="Last name" className="no-outline" />)}
          </Form.Item>
        </div>
        <Form.Item>
          {getFieldDecorator("email", {
            rules: [
              { required: true, message: "Email is required" },
              {
                required: true,
                pattern: DISALLOWED_DOMAINS_PATTERN,
                message: "Please enter work email address",
              },
            ],
          })(
            <Input
              placeholder="Work email"
              type="email"
              className="no-outline"
              disabled={this.props.hasInvitation}
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("company", {
            rules: [{ required: true, message: "Company name is required" }],
          })(
            <Input
              placeholder="Company name"
              className="no-outline"
              disabled={this.props.companyInputDisabled}
            />,
          )}
        </Form.Item>
        <>
          <Form.Item
            extra={this.state.passwordCompletedFields?.map((cf) => this.messageRenderer(cf, true))}
          >
            {getFieldDecorator("password", {
              rules: [
                { required: true, message: "Password is required" },
                { validator: this.passwordValidator },
              ],
            })(
              <Input.Password
                autoComplete="password"
                placeholder="Password"
                className="no-outline"
                onBlur={this.handlePasswordBlur.bind(this)}
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("password_confirmation", {
              rules: [
                { required: true, message: "Password confirmation is required" },
                { validator: this.comparePasswordConfirmationToPassword },
              ],
            })(
              <Input.Password
                autoComplete="password confirmation"
                placeholder="Password confirmation"
                className="no-outline"
              />,
            )}
          </Form.Item>
        </>

        <div className={css.center}>
          <Button
            disabled={
              Object.values(form.getFieldsValue()).filter((v) => !v?.length).length !== 0 ||
              Object.values(form.getFieldsError()).filter((v) => v).length > 0
            }
            loading={this.props.formSubmitting}
            type="primary"
            htmlType="submit"
            className={css.button}
          >
            Continue
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create<Props>({ name: "SignUpForm" })(SignUpForm);
