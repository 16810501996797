import * as React from "react";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { Tooltip } from "antd5";

import css from "./previewQueryUtils.module.scss";

export function validateTerm(count: number | undefined, value: string): string | undefined {
  if (count && count > 500) {
    return `“${value}” is finding over 500 results. Consider narrowing the term down to reduce noise.`;
  }

  for (const part of value.split(" AND ")) {
    const trimmedPart = part.trim();

    if (trimmedPart[0] === '"' && trimmedPart[trimmedPart.length - 1] !== '"') {
      const suggestion = trimmedPart.replace(/(^"|'$)/g, "");
      return `Mismatched double quotation marks (") around exact phrase expression. Did you mean "${suggestion}"?`;
    }
  }

  return undefined;
}

export function getCountLabel({
  count,
  value,
  warning,
  isLoading,
}: {
  count: number | undefined;
  value: string | React.ReactNode;
  warning: string | undefined;
  isLoading?: boolean;
}): JSX.Element {
  const CustomTooltipContent = ({ title, description }: { title: string; description: string }) => (
    <div className={css.customTooltipContent}>
      <p className={css.customTooltipTitle}>{title}</p>
      <p className={css.customTooltipDescription}>
        {description.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
    </div>
  );

  const tooltipTitle = warning ? "You’ve added a generic keyword" : "Number of notices found";

  const tooltipDescription = warning
    ? `Generic keywords can pull irrelevant notices into your feed. 
     Consider changing this to a word with fewer results where possible, or combine
     it with specific keywords.`
    : `This number shows how many notices have been returned with 
     activity in the past six months.
     
     This timeframe is a useful indicator 
     of the keyword's relevance in your feed, but you will also be able to 
     see historic notices from further back, too.`;

  return (
    <span className={isLoading ? css.loading : css.default}>
      {isLoading ? (
        <>
          {value} <LoadingOutlined size={10} />
        </>
      ) : (
        <>
          <Tooltip
            placement="bottom"
            title={<CustomTooltipContent title={tooltipTitle} description={tooltipDescription} />}
            overlayClassName={css.warningTooltip}
          >
            {warning && <ExclamationCircleOutlined className={css.warningIcon} />}

            {value}
            {count !== undefined && (
              <span style={{ fontWeight: 400 }} className={css.noticeCount}>
                ({count})
              </span>
            )}
          </Tooltip>
        </>
      )}
    </span>
  );
}

export function formatKeywordTag(text: string, className: string): React.ReactNode {
  if (!text.includes(" AND ")) {
    return text;
  }
  const spans: React.ReactNode[] = [];
  for (const k of text.split(" AND ")) {
    // to have AND between each word we add AND before each keyword but not the first one
    if (spans.length > 0) {
      spans.push(
        // we will add spacing with CSS
        <em key={spans.length} className={className}>
          AND
        </em>,
      );
    }
    spans.push(<span key={spans.length}>{k}</span>);
  }
  return <>{spans}</>;
}
