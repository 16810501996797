import React, { useRef } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, message } from "antd"; // upgrade message to use context, move button icon to icon prop

import { useExportData } from "lib/generated/spend-data-api/spendDataManagementAPI";
import {
  ExportDataRequestGroupBy,
  SpendDataFilters,
} from "lib/generated/spend-data-api/spendDataManagementAPI.schemas";
import { useTracking } from "lib/tracking";
import { createDataExportedEvent } from "./tracking";

function ExportDataButton({
  filters,
  totalRows,
  groupBy,
  className,
}: {
  filters: SpendDataFilters;
  totalRows?: number;
  groupBy?: ExportDataRequestGroupBy;
  className?: string;
}) {
  const { logEvents } = useTracking();
  const downloadFileRef = useRef<HTMLAnchorElement>(null);
  const { mutate: exportData, isLoading } = useExportData({
    mutation: {
      onSuccess(data, vars) {
        if (downloadFileRef.current) {
          const url = URL.createObjectURL(data);
          downloadFileRef.current.href = url;
          downloadFileRef.current.download = `spend-data-export-${new Date()
            .toJSON()
            .slice(0, 10)}.${vars.data.fileType}`;
          downloadFileRef.current.click();
        }
      },
      onError() {
        void message.error("Failed to download file");
      },
    },
  });

  const isTooManyRows = totalRows ? totalRows > 1000000 : false;

  return (
    <Button
      disabled={isLoading || isTooManyRows}
      className={className}
      onClick={() => {
        exportData({ data: { filters, fileType: "csv", groupBy } });
        logEvents(createDataExportedEvent("CSV", totalRows || 0));
      }}
    >
      <DownloadOutlined /> Export CSV {totalRows ? `(${totalRows})` : null}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a ref={downloadFileRef} download />
    </Button>
  );
}

export default ExportDataButton;
