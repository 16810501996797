import React from "react";
import { Form, Input, message, Modal } from "antd"; // upgrade message to new context
import { FormComponentProps } from "antd/lib/form";

import { BuyerListDto } from "lib/generated/app-api";
import { useUpdateBuyerList } from "lib/hooks/api/buyer_lists/useUpdateBuyerList";

type ModalProps = { isOpen: boolean; onClose: () => void; list: BuyerListDto };
export type RenameBuyerListModalProps = ModalProps & FormComponentProps;

function RenameBuyerListModal({ isOpen, onClose, form, list }: RenameBuyerListModalProps) {
  const { isLoading, mutate } = useUpdateBuyerList({
    onSuccess: () => {
      void message.success("Buyer list updated successfully!");
      onClose();
    },
    onError() {
      void message.error("Failed to update buyer list");
    },
  });
  const { getFieldDecorator } = form;
  const isTouched = form.isFieldsTouched();

  const onSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      mutate({
        name: values.name,
        list: list,
      });
    });
  };

  return (
    <Modal
      title="Rename buyer list"
      visible={isOpen}
      onCancel={onClose}
      okText={isLoading ? "Updating..." : "Rename buyer list"}
      onOk={onSubmit}
      okButtonProps={{ disabled: isLoading || !isTouched }}
      cancelButtonProps={{ disabled: isLoading }}
    >
      <Form layout="vertical" hideRequiredMark>
        <Form.Item label="List name">
          {getFieldDecorator("name", {
            rules: [{ required: true, message: "List name is required" }],
            initialValue: list.name,
          })(<Input autoFocus />)}
        </Form.Item>
      </Form>
    </Modal>
  );
}

const UpdateBuyerListForm = Form.create<RenameBuyerListModalProps>({ name: "Update_buyer_list" })(
  RenameBuyerListModal,
);

export default (props: ModalProps) => <UpdateBuyerListForm {...props} />;
