/* tslint:disable */
/* eslint-disable */
/**
 * Query Runner
 * Provides an API for running a query against records and returning detailed per query node match information.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships
 */
export interface DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships {
    /**
     * 
     * @type {string}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships
     */
    type: DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships
     */
    ids: Array<string>;
}


/**
 * @export
 */
export const DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsTypeEnum = {
    Organisation: 'ORGANISATION',
    Document: 'DOCUMENT',
    Framework: 'FRAMEWORK',
    ProcurementProcessEvent: 'PROCUREMENT_PROCESS_EVENT'
} as const;
export type DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsTypeEnum = typeof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsTypeEnum[keyof typeof DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsTypeEnum];


/**
 * Check if a given object implements the DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships interface.
 */
export function instanceOfDocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "ids" in value;

    return isInstance;
}

export function DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsFromJSON(json: any): DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships {
    return DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsFromJSONTyped(json, false);
}

export function DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'ids': json['ids'],
    };
}

export function DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationshipsToJSON(value?: DocumentSignalsRequestSignalsInnerFiltersAllInnerAllInnerRelationships | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'ids': value.ids,
    };
}

