import * as React from "react";
import { Tooltip } from "antd"; // upgrade and verify
import classnames from "classnames";

import { convertSignalCategoryToFeatureType } from "components/paywall/paywallUtils";
import RedactedWrapper from "lib/core_components/RedactedWrapper";
import { Signal as SignalType } from "lib/generated/app-api";
import { ProHelperDataTypes } from "lib/providers/ProHelper";
import { tagColourFromSignal } from "lib/tagUtils";
import { SignalCategory } from "lib/types/models";

import "../../styles/tagColours.module.scss";
import css from "styles/tag_helpers.module.scss";

export type SignalPaywallConfig = {
  requiredDataType: ProHelperDataTypes;
  categoriesToHide: SignalCategory[];
};

type Props = {
  signal: SignalType;
  paywallConfig?: SignalPaywallConfig;

  // Including to get rid of tooltip on the titles of the signals filters
  // (e.g Competitors: Competitors, Partners: Partners ), so that we don't have a popover and a tooltip at the same time
  noTooltip?: boolean;
  className?: string;
};

function Signal({ signal, paywallConfig, noTooltip, className }: Props) {
  const { name, category } = signal;

  const signalCategory = category as SignalCategory;
  const requiredDataType =
    paywallConfig && paywallConfig.categoriesToHide.includes(signalCategory)
      ? paywallConfig.requiredDataType
      : undefined;

  // Only apply paywall where requiredDatatype was specified. The displayed paywall will be primarily
  // based on the signal category, failing that we'll fall back to the data type passed in as a prop.
  const featureType = paywallConfig?.requiredDataType
    ? convertSignalCategoryToFeatureType(signalCategory, paywallConfig?.requiredDataType)
    : undefined;

  return (
    <span
      className={classnames(
        `tag-${tagColourFromSignal(category) || "default"}`,
        css.nonTagCellTag,
        className,
      )}
    >
      <RedactedWrapper
        requiredDataType={requiredDataType}
        redactContent={name}
        featureType={featureType}
        contextSource={`${signalCategory} filter`}
      >
        <Tooltip title={noTooltip ? undefined : `${category}: ${name}`}>{name}</Tooltip>
      </RedactedWrapper>
    </span>
  );
}

export default Signal;
