import * as React from "react";
import { Card, Divider } from "antd5";

import { TextLink } from "components/actions/Links";
import { formatNumber } from "components/app_layout/Typography";
import ContactCard from "components/form_components/ContactCard";
import { generateSupplierUrl } from "components/suppliers/utils";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { NewRelevanceScore } from "lib/core_components/NewRelevanceScore";
import { PropertyInline } from "lib/core_components/Property";
import { FrameworkDetails } from "lib/hooks/api/frameworks/useDescribeFramework";
import { grey50 } from "lib/themes/colors";
import { EventDataTypes, EventNames, logEvent } from "lib/tracking";
import { ProcedureType } from "lib/types/graphQLEnums";
import { isDefined, pluralise } from "lib/utils";
import { SignalEntityType } from "lib/utils/signalUtils";
import FrameworkTimeline from "./FrameworkTimeline";

import css from "./FrameworkOverview.module.scss";

type Props = {
  framework?: FrameworkDetails;
  isLoading: boolean;
};

export default function FrameworkOverview({ framework, isLoading }: Props): JSX.Element {
  const dividerStyle = { color: grey50, margin: "0px" };
  return (
    <div className={css.frameworkDetailsContent}>
      <div className={css.detailsContainer} aria-label="Framework overview">
        {framework &&
          framework.textSections?.map((section) => (
            <div className={css.section}>
              <h2 className={css.subtitle}>{section.title}</h2>
              <pre className={css.description}>{section.content.content}</pre>
            </div>
          ))}
        <Divider style={dividerStyle} />
        <div className={css.section}>
          {framework && (
            <FrameworkTimeline
              timeline={framework.timeline}
              extensionAvailable={framework.extensionAvailable}
              stage={framework.stage}
              isLoading={isLoading}
            />
          )}
        </div>
        <Divider style={dividerStyle} />
        {framework && (
          <div className={css.section}>
            <h2 className={css.subtitle}>Lot information</h2>
            <FrameworkLotDetails framework={framework} />
          </div>
        )}
      </div>
      <div className={css.summaryDetailsContainer}>
        <Card loading={isLoading} aria-label="Framework key info">
          <h3 className={css.smallerTitle}>Details</h3>
          <div className={css.summaryDetails}>
            {/* TODO: Change to external website */}
            {framework && (
              <>
                <PropertyInline
                  title="Signal score"
                  value={
                    <NewRelevanceScore
                      entityType={SignalEntityType.Framework}
                      id={framework.id}
                      relevanceScore={framework.signalScore}
                      eventAttributes={{ "Data type": EventDataTypes.frameworks }}
                    />
                  }
                  className={css.detailLabel}
                  borderBottom
                />
                <PropertyInline
                  title="Provider"
                  value={
                    framework.serviceProvider?.id ? (
                      <TextLink
                        to={`/buyers/${framework.serviceProvider.id}`}
                        onClick={() =>
                          logEvent(EventNames.navToBuyerProfile, {
                            "Data type": EventDataTypes.frameworks,
                            "Context source": "Framework provider",
                          })
                        }
                      >
                        {framework.serviceProvider.name}
                      </TextLink>
                    ) : (
                      <>{framework.serviceProvider?.name ?? "-"}</>
                    )
                  }
                  className={css.detailLabel}
                  borderBottom
                />
                <PropertyInline
                  title="Contracting auth."
                  value={
                    framework.contractingAuthority?.id ? (
                      <TextLink
                        to={`/buyers/${framework.contractingAuthority.id}`}
                        onClick={() =>
                          logEvent(EventNames.navToBuyerProfile, {
                            "Data type": EventDataTypes.frameworks,
                            "Context source": "Contracting authority",
                          })
                        }
                      >
                        {framework.contractingAuthority.name}
                      </TextLink>
                    ) : (
                      <>{framework.contractingAuthority?.name ?? "-"}</>
                    )
                  }
                  className={css.detailLabel}
                  borderBottom
                />
                <PropertyInline
                  title="Value"
                  value={formatNumber({
                    value: framework.value?.amount,
                    locale: "en-GB",
                    currency: framework.value?.currency,
                  })}
                  className={css.detailLabel}
                  borderBottom
                />
                {isDefined(framework.procedureType) && (
                  <PropertyInline
                    title="Type"
                    value={getProcedureType(framework.procedureType)}
                    className={css.detailLabel}
                    borderBottom
                  />
                )}
                <PropertyInline
                  title="Lots"
                  value={framework.lots.length}
                  className={css.detailLabel}
                  borderBottom
                />
                <PropertyInline
                  title="Suppliers"
                  value={framework.suppliers?.length ?? "0"}
                  className={css.detailLabel}
                  borderBottom
                />

                {framework.sourceUrl && (
                  <PropertyInline
                    title="Source"
                    value={
                      <EllipsisTooltipTextLink
                        linkText={framework.sourceUrl}
                        linkProps={{
                          to: framework.sourceUrl,
                          targetType: "new-tab",
                          showIcon: true,
                          onClick: () =>
                            logEvent(EventNames.navToSourceDocs, {
                              "Data type": EventDataTypes.frameworks,
                            }),
                        }}
                        fullText={framework.sourceUrl}
                        containerClassname={css.sourceLink}
                      />
                    }
                    className={css.detailLabel}
                  />
                )}
              </>
            )}
          </div>
        </Card>

        <ContactCard
          email={framework?.contactDetails?.email ?? ""}
          phone={framework?.contactDetails?.phone ?? ""}
          address={framework?.contactDetails?.address ?? ""}
          name={framework?.contactDetails?.name ?? ""}
          isLoading={isLoading}
          className={css.contactLabel}
        />
      </div>
    </div>
  );
}

type LotDetailsProps = {
  framework: FrameworkDetails;
};
function FrameworkLotDetails({ framework }: LotDetailsProps): JSX.Element {
  const supplierCount = pluralise(framework.suppliers?.length ?? 0, "supplier");
  return (
    <div aria-label="Framework lot info">
      <span>
        There are a total of{" "}
        {framework.suppliers?.length ? (
          <TextLink
            to={`/frameworks/${framework.id}/suppliers`}
            onClick={() =>
              logEvent(EventNames.suppliersClicked, {
                "Context source": "Lot information all suppliers",
              })
            }
          >
            {supplierCount}
          </TextLink>
        ) : (
          <>{supplierCount}</>
        )}{" "}
        across <b>{pluralise(framework.lots.length, "lot")}</b> on this Framework
      </span>
      <div className={css.lotsContainer}>
        {framework.lots.map((lot) => (
          <div key={lot.id} className={css.lotContent}>
            <h3 className={css.lotTitle}>{`${lot.id}: ${lot?.title ?? ""}`}</h3>
            {lot.description?.content && (
              <pre className={css.description}>{lot.description?.content}</pre>
            )}
            <div className={css.lotProperties}>
              {!!lot.supplierGuids?.length && (
                <PropertyInline
                  title="Suppliers:"
                  value={
                    <TextLink
                      to={generateSupplierUrl(
                        { lotIds: [lot.id] },
                        `/frameworks/${framework.id}/suppliers`,
                      )}
                      onClick={() =>
                        logEvent(EventNames.suppliersClicked, {
                          "Context source": "Lot suppliers",
                        })
                      }
                    >
                      {pluralise(lot.supplierGuids.length, "supplier")}
                    </TextLink>
                  }
                />
              )}
              {framework.value?.amount && (
                <PropertyInline
                  title="Lot value"
                  value={formatNumber({
                    value: framework.value.amount,
                    locale: "en-GB",
                    currency: framework.value?.currency,
                  })}
                />
              )}
              {lot.contractPeriod?.endDate && (
                <PropertyInline title="Expiry date" value={lot.contractPeriod?.endDate} />
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function getProcedureType(procedureType: ProcedureType) {
  switch (procedureType) {
    case "FRAMEWORK":
      return "Framework";
    case "DYNAMIC_PURCHASING_SYSTEM":
      return "DPS";
  }
}
