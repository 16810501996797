import React, { useEffect, useState } from "react";
import { Skeleton } from "antd5";

import RightChevron from "lib/icons/RightChevron";

import css from "./Dropdown.module.scss";

export type Category = string;

type Props = {
  isLoading: boolean;
  isOpen: boolean;
  textSearch: string;
  value: string[];
  description: string;
  categoryToLabel: Record<Category, string | React.ReactElement>;
  categoryToContent: Record<Category, React.ReactElement>;
  textSearchCategory?: Category; // category to show when text search is active
};

function Dropdown({
  isLoading,
  isOpen,
  textSearch,
  description,
  categoryToLabel,
  categoryToContent,
  textSearchCategory,
}: Props) {
  const [categorySelected, setCategorySelected] = useState<Category | undefined>(undefined);

  // if modal hidden, reset the category
  useEffect(() => {
    if (!isOpen) {
      setCategorySelected(undefined);
    }
  }, [isOpen]);

  const categories = Object.keys(categoryToLabel) as Category[];

  function handleCategorySelection(label: Category) {
    setCategorySelected(label);
  }

  function getCategoryLabel(category?: Category): string | React.ReactElement {
    return category ? categoryToLabel[category] : "";
  }

  const renderedCategoryInput = () => {
    if (isLoading) {
      return <Skeleton active style={{ paddingTop: "8px" }} />;
    }

    if (textSearch.length > 0 && textSearchCategory) {
      return categoryToContent[textSearchCategory];
    }
    if (categorySelected === undefined && textSearch.length === 0) {
      return (
        <DropdownOptions
          categories={categories}
          handleCategorySelection={handleCategorySelection}
          getCategoryLabel={getCategoryLabel}
        />
      );
    }
    if (categorySelected !== undefined) {
      return categoryToContent[categorySelected];
    }

    return null;
  };

  return (
    <div
      className={`${css.options} ${isOpen ? css.show : ""}`}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
    >
      {
        // show the description when not searching
        textSearch.length === 0 && (
          <div className={css.filterDescription}>
            <span className={css.filterDescriptionText}>{description}</span>
          </div>
        )
      }
      <div className={css.dropdown}>{renderedCategoryInput()}</div>
    </div>
  );
}

interface DropdownOptionsProps {
  categories: Category[];
  handleCategorySelection: (label: Category) => void;
  getCategoryLabel: (category: Category) => string | React.ReactElement;
}

function DropdownOptions({
  categories,
  handleCategorySelection,
  getCategoryLabel,
}: DropdownOptionsProps): JSX.Element {
  return (
    <>
      {categories.map((category) => (
        <li key={category} className={css.option} onClick={() => handleCategorySelection(category)}>
          <div className={css.optionlabel}>
            <span>{getCategoryLabel(category)}</span>
          </div>
          <RightChevron />
        </li>
      ))}
    </>
  );
}

export default Dropdown;
