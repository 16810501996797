import React from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd5";

import { TextLink } from "components/actions/Links";

import css from "./DocumentHeader.module.scss";

type DocumentHeaderProps = {
  documentId: string;
  title: string;
  downloadUrl?: string;
  disableTitleLink?: boolean;
};

export default function DocumentHeader({
  documentId,
  title,
  downloadUrl,
  disableTitleLink,
}: DocumentHeaderProps) {
  return (
    <div className={css.header}>
      <TextLink
        className={css.title}
        targetType="new-tab"
        to={`/documents/${documentId}`}
        disabled={disableTitleLink}
      >
        {title}
      </TextLink>
      <div className={css.actions}>
        {downloadUrl && (
          <Button
            download
            href={downloadUrl ?? ""}
            icon={<DownloadOutlined className={css.downloadIcon} />}
          />
        )}
      </div>
    </div>
  );
}
