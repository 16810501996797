import React from "react";
import { Control } from "react-hook-form";
import { Tag } from "antd5";

import { NoticeFilters } from "components/notices/utils";
import { SupplierMentionType } from "lib/types/graphQLEnums";
import { Radio } from "./Inputs";

import css from "./MentionsAwardsFilter.module.scss";

function Label({
  name,
  description,
  isDefault,
}: {
  name: string;
  description: string;
  isDefault?: boolean;
}) {
  return (
    <div className={css.optionContainer}>
      <div className={css.optionNameContainer}>
        <span className={css.optionName}>{name}</span>
        {isDefault && <Tag className={css.defaultTag}> Default</Tag>}
      </div>
      <span>{description}</span>
    </div>
  );
}

export function MentionsAwardsFilter({ control }: { control: Control<NoticeFilters, unknown> }) {
  return (
    <Radio
      className={css.radioContainer}
      label="Showing all notices where these suppliers are:"
      name={"supplierMentionType"}
      defaultValue={SupplierMentionType.MentionedOrDirectlyAwarded}
      control={control}
      options={[
        {
          label: (
            <Label
              name="Directly awarded & Mentioned"
              description="Show notices where these suppliers are the awarded supplier or mentioned in the title or description."
              isDefault={true}
            />
          ),
          value: SupplierMentionType.MentionedOrDirectlyAwarded,
        },
        {
          label: (
            <Label
              name="Directly awarded"
              description="Show notices where these suppliers are the awarded supplier."
            />
          ),
          value: SupplierMentionType.DirectlyAwarded,
        },
        {
          label: (
            <Label
              name="Mentioned in"
              description="Show notices where these suppliers are mentioned in the title or description"
            />
          ),
          value: SupplierMentionType.MentionedInOnly,
        },
      ]}
      optionType="default"
      direction="vertical"
    />
  );
}
