import React, { useMemo, useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { Button, Input } from "antd5";

import { useDebouncedValue } from "lib/debounce";
import { useSignalSettingsGQL } from "lib/hooks/api/teams/useSignalSettingsGQL";
import Signal from "lib/icons/Signal";
import { Option } from "./dropdown_list_selects/ClassificationListSelect";
import { getCpvPrefix } from "./dropdown_list_selects/cpv_code_selects/cpv_code_utils";
import CpvDropdown from "./dropdown_list_selects/cpv_code_selects/CpvDropdown";
import { useCpvTree } from "./dropdown_list_selects/cpv_code_selects/useCpvTree";
import SelectionTags from "./dropdown_list_selects/SelectionTags";
import { SelectProps } from "./Inputs";

import css from "./CpvCodeSelect.module.scss";

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options"> & {
  hideLabel?: boolean;
  hideClear?: boolean;
};

function CpvCodeSelect<T extends FieldValues>(props: FieldProps<T>) {
  const [searchText, setSearchText] = useState<string>("");
  const [debouncedText] = useDebouncedValue(searchText, 300);
  const [isOpen, setIsOpen] = useState(false);

  const { hideLabel, hideClear } = props;

  const { field } = useController(props);

  const { data: signalSettings, isLoading: isSignalsLoading } = useSignalSettingsGQL();
  const signalCodeIds = signalSettings?.cpvCodes || [];

  const { cpvTree, allCpvCodes, signalCpvCodes, isLoading } = useCpvTree(signalCodeIds);

  const displayedCpvCodes = useMemo((): Option[] => {
    if (!field.value || !allCpvCodes) {
      return [];
    }

    // get the label for the selected cpv codes
    return field.value.map((v: string) => {
      const code = allCpvCodes.find((c) => c.code === v);
      return {
        label: `${code?.code}: ${code?.name}`,
        value: v,
      };
    });
  }, [field.value, allCpvCodes]);

  return (
    <div
      aria-label="cpvCodeSelect"
      onBlur={(e) => {
        if (e.currentTarget.contains(e.relatedTarget as Node)) {
          return;
        }
        setIsOpen(false);
      }}
    >
      <div className={css.filterLabelDiv} aria-label="filterLabel" data-label={!hideLabel}>
        {!hideLabel && <span>CPV Codes</span>}
        {field.value && field.value.length > 0 && !hideClear && (
          <Button className={css.clearButton} onClick={() => field.onChange([])}>
            Clear
          </Button>
        )}
      </div>

      <Input
        aria-label="cpvSearchInput"
        className={css.inputField}
        onClick={() => {
          setIsOpen(true);
        }}
        placeholder={isOpen ? undefined : "Search CPV Codes"}
        onChange={(e) => {
          const newValue = e.currentTarget.value.trim();
          if (newValue.length === 0 && searchText.length > 0) {
            setIsOpen(false);
          }
          if (newValue.length > 0 && !isOpen) {
            setIsOpen(true);
          }
          setSearchText(e.currentTarget.value.trim());
        }}
      />
      <CpvDropdown
        aria-label="cpvDropdown"
        textSearch={debouncedText}
        isOpen={isOpen}
        cpvTree={cpvTree}
        value={field.value || []}
        isLoading={isSignalsLoading || isLoading}
        allCpvCodes={allCpvCodes}
        signalCpvCodes={signalCpvCodes}
        signalCodeIds={signalCodeIds}
        onChange={field.onChange}
      />

      <SelectionTags
        selections={displayedCpvCodes}
        handleRemoval={(removedSelection: Option) => {
          const prefix = getCpvPrefix(removedSelection.value);
          const codesToRemove = field.value.filter((code: string) => code.startsWith(prefix));
          const updateValue = field.value.filter((v: string) => !codesToRemove.includes(v));
          field.onChange(updateValue);
        }}
        icon={<Signal />}
        iconedSelectionIds={signalCodeIds}
      />
    </div>
  );
}

export default CpvCodeSelect;
