import React, { useState } from "react";
import { hot } from "react-hot-loader/root";
import { DownloadOutlined, ExportOutlined, LinkOutlined } from "@ant-design/icons";
import { Button, Empty, Input, message } from "antd5";

import { TextLink } from "components/actions/Links";
import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsHeader, DetailsPage, DetailsSection } from "components/app_layout/DetailsLayout";
import { PaywallBanner } from "components/paywall/PaywallBanner";
import { Markdown } from "components/reports/builder_components/Markdown";
import { copyToClipboard } from "lib/clipboard";
import { stringSort } from "lib/columnSort";
import { Table } from "lib/core_components/Table";
import { assertCurrentUser } from "lib/currentUser";
import { ReportDto } from "lib/generated/app-api";
import { useCompanyReports } from "lib/hooks/api/reports/useCompanyReports";
import { ExternalLink } from "lib/icons/ExternalLink";
import { white } from "lib/themes/colors";
import { EventNames, useTracking } from "lib/tracking";

import css from "./CustomBriefingsPage.module.scss";

import SPARKLES from "../../../assets/images/ai_stars.png";
import AccountBriefingImage from "../../../assets/images/paywall/Account_Briefing_Paywall.svg";

export function formatReportType(reportType: string) {
  // reportTypes are snake_case coming in from the db
  return (
    reportType
      // Replace underscores with spaces
      .replace(/_/g, " ")
      // If the word is "ai" replace it with "AI"
      .replace(/\bai\b/g, "AI")
      // Capitalize the first letter of each word
      .replace(/\b\w/g, (l) => l.toUpperCase())
  );
}

export function CustomBriefingsPage() {
  const user = assertCurrentUser();

  const { data, isLoading } = useCompanyReports(user.company.guid, {
    enabled: window.currentUser?.has_account_briefing_permission,
  });
  const [messageApi, contextHolder] = message.useMessage();
  const [searchText, setSearhText] = useState<string>();
  const { logEvent } = useTracking();

  const copyReportLink = (reportId: string, title: string) => {
    const clipboardContent = {
      plain: `https://app.stotles.com/reports/${reportId}?utm_source=share`,
    };
    const copiedToClipboard = copyToClipboard(clipboardContent);
    if (copiedToClipboard) {
      void messageApi.success("Copied!", 2);
    } else {
      void messageApi.error(`Could not copy link to clipboard`, 2);
    }
    logEvent(EventNames.customBriefingLinkCopied, {
      "Report name": title,
      "Report URL": `/reports/${reportId}`,
    });
  };

  if (!window.currentUser?.has_account_briefing_permission) {
    return <BriefingsPaywall />;
  }

  let reports = data || [];

  if (searchText) {
    reports = reports.filter((r) => r.title.toLowerCase().includes(searchText.toLowerCase()));
  }

  return (
    <DetailsPage>
      <DetailsHeader className={css.header} borderBottom={false}>
        <h1>Custom Briefings {data && `(${data.length})`}</h1>
        <Input.Search
          placeholder="Search"
          className={css.search}
          onChange={(e) => setSearhText(e.target.value)}
          value={searchText}
        />
      </DetailsHeader>
      {data && data.length === 0 ? (
        <div>
          <Empty
            description="Custom briefings will appear here"
            image={<img src={AccountBriefingImage} />}
          >
            {/* TODO: enable request reports requires new api */}
            {/* {user.has_account_briefing_permission && (
              <Button type="primary">Request briefing</Button>
            )} */}
          </Empty>
        </div>
      ) : (
        <DetailsSection>
          <Table
            columns={[
              {
                key: "title",
                title: "Title",
                dataIndex: "title",
                sorter: stringSort((r) => r.title),
                render: (_: unknown, r: ReportDto) => (
                  <>
                    <TextLink to={`/reports/${r.id}`} targetType="new-tab">
                      {r.title}
                      {r.reportType === "ai_report" && (
                        <img src={SPARKLES} alt="AI marker" height="20" className={css.aiMarker} />
                      )}
                    </TextLink>
                    {r.description && (
                      <div className={css.descriptionContainer}>
                        <Markdown text={r.description} />
                      </div>
                    )}
                  </>
                ),
              },
              {
                key: "publishedAt",
                title: "Published date",
                dataIndex: "publishedAt",
                sorter: stringSort((r) => r.publishedAt),
                width: "20%",
              },
              {
                key: "reportType",
                title: "Report type",
                dataIndex: "reportType",
                sorter: stringSort((r) => r.reportType),
                width: "20%",
                render(_, record) {
                  return formatReportType(record.reportType);
                },
              },
              {
                key: "actions",
                title: "Actions",
                render: (_, record) => (
                  <div className={css.actionsContainer}>
                    <Button
                      type="primary"
                      icon={<ExportOutlined />}
                      href={`/reports/${record.id}`}
                      target="new-tab"
                      onClick={(e) => {
                        e.stopPropagation();
                        logEvent(EventNames.customBriefingViewed, {
                          "Report name": record.title,
                          "Report URL": `/reports/${record.id}`,
                        });
                      }}
                    >
                      View
                    </Button>{" "}
                    <Button
                      title="Download"
                      icon={<DownloadOutlined />}
                      href={`/reports/${record.id}/pdf`}
                      download
                      onClick={(e) => {
                        e.stopPropagation();
                        logEvent(EventNames.customBriefingDownloaded, {
                          "Report name": record.title,
                          "Report URL": `/reports/${record.id}`,
                        });
                      }}
                    />{" "}
                    <Button
                      title="Copy link"
                      icon={<LinkOutlined />}
                      onClick={() => {
                        copyReportLink(record.id, record.title);
                      }}
                    />
                  </div>
                ),
                width: "20%",
              },
            ]}
            dataSource={reports}
            loading={isLoading}
            rowKey={"id"}
          />
        </DetailsSection>
      )}
      {contextHolder}
    </DetailsPage>
  );
}

function BriefingsPaywall() {
  return (
    <DetailsPage>
      <DetailsHeader className={css.header} borderBottom={false}>
        <h1>Custom Briefings</h1>
      </DetailsHeader>
      <PaywallBanner featureType="ACCOUNT_BRIEFINGS" />
      <DetailsSection>
        <Table
          dataSource={EXAMPLE_REPORTS_DATA}
          columns={[
            {
              key: "title",
              title: "Title",
              render: (_, r) => (
                <>
                  <TextLink to={r.link} targetType="new-tab">
                    {r.title}
                  </TextLink>
                  {r.description && (
                    <div className={css.descriptionContainer}>
                      <Markdown text={r.description} />
                    </div>
                  )}
                </>
              ),
            },
            {
              key: "publishedAt",
              title: "Published date",
              dataIndex: "publishedAt",
              sorter: stringSort((r) => r.publishedAt),
              width: "20%",
            },
            {
              key: "actions",
              title: "Actions",
              render: (_, r) => (
                <Button
                  type="primary"
                  className={css.primaryBtn}
                  icon={<ExternalLink fill={white} className={css.externalLink} />}
                  href={r.link}
                  target="new-tab"
                >
                  View example
                </Button>
              ),
              width: "20%",
            },
          ]}
          rowKey={"link"}
        />
      </DetailsSection>
    </DetailsPage>
  );
}

export const EXAMPLE_REPORTS_DATA = [
  {
    title: "Account briefing",
    description:
      "A deep-dive on buyer organisations highlighting upcoming opportunities and key supplier relationships.",
    publishedAt: "2022-05-17",
    link: "https://app.stotles.com/reports/87a930f6-5414-481e-91be-8efe7db5c72a",
  },
  {
    title: "Competitor Briefing",
    description:
      "Gain insights into competitor movement, key buyer relationships and upcoming contract renewals.",
    publishedAt: "2022-08-02",
    link: "https://app.stotles.com/reports/4901638e-d3a6-4957-a59d-268d4b41a804",
  },
  {
    title: "Opportunity Briefing",
    description:
      "Access key datapoints on opportunities you’re interested in and qualify their importance.",
    publishedAt: "2022-02-15",
    link: "https://app.stotles.com/reports/e12deab6-3988-4679-be96-b2248169bbe1",
  },
  {
    title: "Weekly Prospecting Briefing",
    description:
      "Receive weekly summaries of relevant events like new contracts or competitor and partner activity.",
    publishedAt: "2020-04-23",
    link: "https://app.stotles.com/reports/6e60664f-73cc-4d44-a339-033ad7e4dec2",
  },
  {
    title: "Persona Briefing",
    description:
      "Learn everything you need to know about a particular decision maker within your key account(s).",
    publishedAt: "2022-08-29",
    link: "https://app.stotles.com/reports/195b1bdb-c446-434b-b3e5-5001435f96f5",
  },
  {
    title: "Partner Briefing",
    description:
      "Analyse a partner organisation/s to identify potential opportunities they’re working on.",
    publishedAt: "2021-12-15",
    link: "https://app.stotles.com/reports/53595160-a3c1-4bd8-8f1f-ddb28c782386",
  },
  {
    title: "Management Briefing",
    description:
      "Get an overview of relevant market opportunities and understand the best path to involvement.",
    publishedAt: "2022-01-23",
    link: "https://app.stotles.com/reports/72fbfa7a-d373-4ae8-b4bf-427628945606",
  },
  {
    title: "Competitor Landscape Analysis",
    description:
      "Identify competitor impact across buyers to qualify organisations worth reaching out to.",
    publishedAt: "2022-08-31",
    link: "https://app.stotles.com/reports/9f78af73-062f-413c-b3d6-424f03e31364",
  },
];

export default hot(
  withAppLayout(CustomBriefingsPage, {
    pageName: "Custom Briefings",
    trackingData: { "Page source": "Custom Briefings" },
  }),
);
