import * as React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Spin } from "antd"; //upgrade to use menu props on dropdown with items

import TextButton from "components/actions/TextButton";
import { CpvSummary } from "components/cpv_summary/CpvSummary";
import { Line } from "lib/core_components/Line";
import useAsync from "lib/hooks/useAsync";
import { useStotlesApi } from "lib/stotlesApiContext";
import { EventNames, useTracking } from "lib/tracking";

import css from "./SummaryTab.module.scss";

type Props = {
  buyerId: number;
  buyerGuid: string;
  showMatchingExpiries: () => void;
};

const CpvLookbackDates: Record<string, string> = {
  P1Y: "1 year",
  P2Y: "2 years",
  P3Y: "3 years",
  P4Y: "4 years",
  P5Y: "5 years",
};

function SummarySection({ buyerId, buyerGuid, showMatchingExpiries }: Props): JSX.Element {
  const api = useStotlesApi();
  const { logEvent } = useTracking();
  const [upcomingExpiries, setUpcomingExpiries] = React.useState(false);
  const [cpvLookback, setCpvLookback] = React.useState("P5Y");
  const getBuyerCpvStats = React.useCallback(
    () => api.getBuyerCpvStats(buyerId, upcomingExpiries, cpvLookback),
    [api, buyerId, upcomingExpiries, cpvLookback],
  );
  const results = useAsync(getBuyerCpvStats);

  const onShowMatchingExpiriesClicked = React.useCallback(() => {
    logEvent(EventNames.seeMatchingExpiriesClicked);
    showMatchingExpiries();
  }, [logEvent, showMatchingExpiries]);

  return (
    <>
      <div className={css.header}>
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item
                className={upcomingExpiries ? css.selected : ""}
                onClick={() => {
                  logEvent(EventNames.summaryViewChanged, {
                    "View selected": "Upcoming contract expiries",
                  });
                  setUpcomingExpiries(true);
                }}
              >
                Upcoming contract expiries
              </Menu.Item>
              <Menu.Item
                className={upcomingExpiries ? "" : css.selected}
                onClick={() => {
                  logEvent(EventNames.summaryViewChanged, {
                    "View selected": "Historical awards",
                  });
                  setUpcomingExpiries(false);
                }}
              >
                Historical awards
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <TextButton>
            {upcomingExpiries ? "Upcoming contract expiries" : "Historical awards"} <DownOutlined />
          </TextButton>
        </Dropdown>{" "}
        <div>{upcomingExpiries ? "In the next" : "From the past"}</div>{" "}
        <Dropdown
          overlay={
            <Menu>
              {Object.keys(CpvLookbackDates).map((d) => (
                <Menu.Item
                  key={d}
                  className={cpvLookback === d ? css.selected : ""}
                  onClick={() => {
                    logEvent(EventNames.summaryTimelineChanged, {
                      "View selected": CpvLookbackDates[d],
                    });
                    setCpvLookback(d);
                  }}
                >
                  {CpvLookbackDates[d]}
                </Menu.Item>
              ))}
            </Menu>
          }
          trigger={["click"]}
        >
          <TextButton>
            {CpvLookbackDates[cpvLookback]} <DownOutlined />
          </TextButton>
        </Dropdown>{" "}
        {upcomingExpiries && (
          <>
            <Line vertical />
            <TextButton onClick={onShowMatchingExpiriesClicked}>
              See expiries matching my settings
            </TextButton>
          </>
        )}
      </div>
      <Line />
      <div className={css.summary}>
        {results.status === "ready" ? (
          <CpvSummary
            requiredSlideoutType="BUYERS"
            overallStats={results.value.overall_stats}
            perCpvStats={results.value.per_cpv_stats}
            buyerGuid={buyerGuid}
            dateFilter={
              upcomingExpiries
                ? { expiryDate: { filter: { relativeTo: cpvLookback, relativeFrom: "PT0S" } } }
                : {
                    publishDate: {
                      // the lookback date must be negated
                      relativeFrom: cpvLookback.replace(/P/, "P-"),
                      relativeTo: "PT0S",
                    },
                  }
            }
          />
        ) : (
          <div className={css.spinner}>
            <Spin />
          </div>
        )}
      </div>
    </>
  );
}

export default SummarySection;
