import * as React from "react";
import { CaretDownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Empty } from "antd5";

import { DetailsSection } from "components/app_layout/DetailsLayout";
import UserInitials from "components/comments/UserInitials";
import { numberSort, stringSort } from "lib/columnSort";
import SkeletonTable from "lib/core_components/SkeletonTable";
import { Table } from "lib/core_components/Table";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { GetAssignedNoticesResponseResult } from "lib/generated/app-api";
import { useAssignedNotices } from "lib/hooks/api/notices/useAssignedNotices";
import { useUsers } from "lib/hooks/api/useUsers";
import { EventNames, useTracking } from "lib/tracking";
import { generateNoticeSearchUrl } from "./utils";

import css from "./AssignedNotices.module.scss";

const ASSIGNED_COLS: ColumnType<GetAssignedNoticesResponseResult>[] = [
  {
    title: "Assignee",
    dataIndex: "assignee",
    key: "assignee",
    sizeConfig: { small: 200, medium: 250, large: 400, xlarge: 450 },
    render: (_: unknown, record: GetAssignedNoticesResponseResult) => (
      <div className={css.user}>
        <UserInitials firstName={record.firstName} lastName={record.lastName} />
        {record.assignee}
      </div>
    ),
    sorter: stringSort((r) => `${r.firstName} ${r.lastName}`),
    // there is a bug in ant design where the sort order returns undefined when it reaches the
    // the end of the array of orders rather than returning the first value, the types say it
    // should never be undefined, since we default to decending adding an extra ascend fixes this
    sortDirections: ["ascend", "descend", "ascend"],
  },
  {
    title: "Notices",
    dataIndex: "count",
    key: "count",
    sizeConfig: { small: 200, medium: 250, large: 400, xlarge: 450 },
    render: (_: unknown, record: GetAssignedNoticesResponseResult) => record.count,
    sorter: numberSort((r) => r.count),
    defaultSortOrder: "descend",
    sortDirections: ["descend", "ascend", "descend"],
  },
];

export function AssignedNotices() {
  const { logEvent } = useTracking();
  const { isLoading, isError, data } = useAssignedNotices();
  const { data: users } = useUsers();

  const [tab, setTab] = React.useState<"allUsers" | "myTeam">("myTeam");

  const onTabChange = (newTab: "allUsers" | "myTeam") => {
    setTab(newTab);
    logEvent(EventNames.noticeListTabChanged, {
      "List type": "Assigned notices",
      "Tab name": newTab === "allUsers" ? "All users" : "My team",
    });
  };

  const onRow = React.useCallback(
    ({ id, assignee }: GetAssignedNoticesResponseResult) => ({
      onClick() {
        logEvent(EventNames.noticeListClicked, {
          "List type": "Assigned notices",
          "List name": assignee,
          "List ID": id,
        });
        window.location.assign(generateNoticeSearchUrl({ assignee: [id] }));
      },
    }),
    [logEvent],
  );

  const results = tab === "allUsers" ? data?.allNotices : data?.teamNotices;

  if (isError) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description="An error occurred - please try again later"
      />
    );
  }

  return (
    <DetailsSection
      title={
        <div className={css.title}>
          Notices assigned to{" "}
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                { key: "allUsers", label: "All users", onClick: () => onTabChange("allUsers") },
                { key: "myTeam", label: "My team", onClick: () => onTabChange("myTeam") },
              ],
              selectable: true,
              defaultSelectedKeys: ["myTeam"],
            }}
          >
            <Button type="link" className={css.menuLink}>
              {tab === "allUsers" ? "all users" : "my team"} <CaretDownOutlined />
            </Button>
          </Dropdown>
        </div>
      }
      className={css.topRowItem}
      action={
        <Button
          type="link"
          href={generateNoticeSearchUrl({
            assignee: users?.filter((x) => x.active).map((u) => u.guid),
          })}
        >
          View All
        </Button>
      }
      aria-label="Assigned notices"
    >
      <SkeletonTable loading={isLoading} active={!isLoading} pageSize={5} columns={ASSIGNED_COLS}>
        <Table
          columns={ASSIGNED_COLS}
          dataSource={results}
          pagination={false}
          onRow={onRow}
          rowKey={(record) => record.id}
        />
      </SkeletonTable>
    </DetailsSection>
  );
}
