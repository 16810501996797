import React from "react";
import { FieldValues } from "react-hook-form";
import { CloseOutlined } from "@ant-design/icons";
import { Tag, TreeSelect as AntTreeSelect } from "antd5";
import { DefaultOptionType } from "antd5/node_modules/rc-tree-select/es/TreeSelect";
import { startCase } from "lodash";

import PaywallPopover from "components/paywall/PaywallPopover";
import Signal from "components/tags/Signal";
import {
  NameKeys,
  SignalCategories,
  useSignalCategories,
} from "lib/hooks/api/teams/useSignalSettings";
import PaywallStar from "lib/icons/PaywallStar";
import { useSubscription } from "lib/providers/Subscription";
import { SignalCategory as SignalCategoryEnum } from "lib/StotlesApi";
import * as colors from "lib/themes/colors";
import { SignalCategory } from "lib/types/models";
import { TreeSelect, TreeSelectProps } from "./Inputs";

type SignalValue = { ids: string[]; categories: SignalCategory[] };

function convertToApiCategories(cat: string) {
  switch (cat) {
    case "cpvCodes":
      return "CPV codes";
    default:
      return startCase(cat);
  }
}

const uuidRegexExp = /^([0-9a-fA-F]{8})-(([0-9a-fA-F]{4}-){3})([0-9a-fA-F]{12})$/i;

const filterKeys = [
  "partnerNames",
  "competitorNames",
  "keywordNames",
  "buyerNames",
  "cpvCodeNames",
];

export function convertSignalArrayToSignalValue(array?: string[]): SignalValue {
  const ids: string[] = [];
  const categories: SignalCategory[] = [];
  if (!array) {
    return { ids: [], categories: [] };
  }
  array.forEach((item) => {
    if (uuidRegexExp.test(item)) {
      ids.push(item);
    } else {
      categories.push(item as SignalCategory);
    }
  });

  return {
    ids,
    categories,
  };
}

function generateTreeData(data: SignalCategories, hasAccess: boolean) {
  const paidCategories = [SignalCategoryEnum.PARTNER, SignalCategoryEnum.COMPETITOR];

  return Object.keys(data).reduce<DefaultOptionType[]>((acc, key) => {
    if (filterKeys.includes(key)) {
      return acc;
    }

    // This is a paywall, we don't allow clicking on partner/competitor tags if they don't have access
    const cat = convertToApiCategories(key) as SignalCategoryEnum;
    const disableCategory = paidCategories.includes(cat) && !hasAccess;

    const category = data[key as keyof Omit<SignalCategories, NameKeys>];

    const children = category.map((signal) => ({
      title: (
        <Signal
          signal={{ name: signal.name, category: convertToApiCategories(key) }}
          paywallConfig={{ categoriesToHide: paidCategories, requiredDataType: "SUPPLIERS" }}
          noTooltip
        />
      ),
      label: signal.name,
      value: signal.id,
      disable: disableCategory,
    }));
    acc.push({
      title: disableCategory ? (
        <div onClick={(e) => e.stopPropagation()}>
          <PaywallPopover
            featureType={cat === SignalCategoryEnum.PARTNER ? "PARTNERS" : "COMPETITORS"}
            contextSource={`${cat} filter`}
          >
            <Signal
              signal={{ name: startCase(key), category: convertToApiCategories(key) }}
              noTooltip
            />
            <PaywallStar />
          </PaywallPopover>
        </div>
      ) : (
        <Signal
          signal={{ name: startCase(key), category: convertToApiCategories(key) }}
          noTooltip
        />
      ),
      value: convertToApiCategories(key),
      disabled: disableCategory,
      children,
      label: convertToApiCategories(key),
    });
    return acc;
  }, []);
}

function getSignalColor(category: string) {
  switch (category) {
    case "Keywords":
      return colors.sysSignalKeyword;
    case "Partners":
      return colors.sysSignalPartner;
    case "Competitors":
      return colors.sysSignalCompetitor;
    case "Buyers":
      return colors.sysSignalBuyer;
    case "CPV codes":
      return colors.sysSignalDefault;
    default:
      return "";
  }
}

function SignalTag({
  value,
  onClose,
  treeData,
}: {
  value: string;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => void;
  treeData: DefaultOptionType[];
}) {
  const signalCategory = treeData.find(
    (item) => item.value === value || item.children?.find((child) => child.value === value),
  );
  let label = signalCategory?.label || "";

  // if it's a uuid it's not a category so find the label
  if (uuidRegexExp.test(value)) {
    const signal = signalCategory?.children?.find((child) => child.value === value);
    label = signal?.label || "";
  }

  if (label === "") {
    return null;
  }

  return (
    <Tag
      color={getSignalColor(signalCategory?.value?.toString() || "")}
      onClose={onClose}
      closable
      // using inline styles to override ants behaviour with custom colours
      closeIcon={<CloseOutlined style={{ color: "#000000bf" }} />}
      style={{ color: "#000000bf", marginTop: "2px", marginBottom: "2px" }}
    >
      {label}
    </Tag>
  );
}

type FieldProps<T extends FieldValues> = Omit<TreeSelectProps<T>, "treeData"> & {
  hideLabel?: boolean;
};
export function SignalSelect<T extends FieldValues>(props: FieldProps<T>) {
  const { data, isLoading, status } = useSignalCategories();
  const subscription = useSubscription();
  const hasSupplierAccess = subscription.hasDataTypes("SUPPLIERS");

  const treeData = status === "success" ? generateTreeData(data, hasSupplierAccess) : [];

  const { hideLabel } = props;

  return (
    <TreeSelect
      allowClear
      autoClearSearchValue={false}
      showSearch
      multiple
      treeData={treeData}
      loading={isLoading}
      treeCheckable
      showCheckedStrategy={AntTreeSelect.SHOW_PARENT}
      {...props}
      label={hideLabel ? "" : props.label}
      placeholder="Select signals"
      // only set the value if we have data otherwise it will error
      value={treeData?.length > 0 ? props.value : []}
      treeNodeFilterProp="label"
      tagRender={({ onClose, value }) => (
        <SignalTag value={value} onClose={onClose} treeData={treeData} />
      )}
    />
  );
}
