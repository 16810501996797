import React from "react";

import {
  convertDocumentFiltersToSearchRequest,
  DEFAULT_PAGINATION,
  DocumentFilters,
  SortField,
} from "components/documents/utils";
import { QuickFilterBar as FilterBar } from "components/form_components/QuickFilterBar";
import { useDocuments } from "lib/hooks/api/useDocuments";
import { EventDataTypes } from "lib/tracking";
import { capitalize, convertCamelCaseToSpaceSeparatedWords } from "lib/utils";

function getAppliedFilters(filters: DocumentFilters) {
  const appliedFiltersArray = Object.entries(filters).filter(([_key, value]) => {
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    if (typeof value === "object") {
      if (_key === "sort") {
        return false;
      }
      return Object.keys(value).length > 0;
    }
    if (typeof value === "string") {
      return value.length > 0;
    }
    return false;
  });

  return appliedFiltersArray.map(([key]) => key);
}

const filterKeyToLabel: Record<keyof DocumentFilters, string> = {
  signalScore: "Signal score",
  keywords: "Keywords",
  categories: "Document type",
  publishDate: "Published date",
  organisationIds: "Buyers",
  buyerTypes: "Buyer type",
  buyerListIds: "Buyer lists",
  buyerCountryRegions: "Buyer headquarters",
  title: "",
  sort: "",
};

const getSortKey = (key: SortField) => {
  switch (key) {
    case "title":
      return "documentTitle";
    case "category":
      return "type";
    case "publishedAt":
      return "publishedDate";
    default:
      return key;
  }
};

export function QuickFilterBar({
  filters,
  onClearFilter,
  onFilterClick,
  hiddenFilters,
}: {
  filters: DocumentFilters;
  onClearFilter: (value: keyof DocumentFilters) => void;
  onFilterClick: (value: keyof DocumentFilters) => void;
  hiddenFilters?: (keyof DocumentFilters)[];
}) {
  const appliedFiltersMapped = getAppliedFilters(filters);

  const { data, isLoading } = useDocuments(
    convertDocumentFiltersToSearchRequest(filters, DEFAULT_PAGINATION),
  );

  const sortKey = getSortKey(filters.sort.field);
  const sortField: string = capitalize(convertCamelCaseToSpaceSeparatedWords(sortKey));

  return (
    <FilterBar<DocumentFilters>
      appliedFilters={appliedFiltersMapped}
      isLoading={isLoading}
      totalResults={data?.totalResults || 0}
      filterKeyToLabel={filterKeyToLabel}
      onClear={onClearFilter}
      onFilterClick={onFilterClick}
      sortField={sortField}
      dataType={EventDataTypes.buyer}
      hiddenFields={[...(hiddenFilters ?? []), "title"]}
    />
  );
}
