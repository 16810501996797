import React, { Key, useCallback, useState } from "react";
import { TablePaginationConfig } from "antd5";
import Checkbox, { CheckboxChangeEvent } from "antd5/es/checkbox";
import { SorterResult } from "antd5/es/table/interface";

import { TextLink } from "components/actions/Links";
import { DetailsContent } from "components/app_layout/DetailsLayout";
import BuyerCategoriesWithPopover from "components/table_components/BuyerCategoriesWithPopover";
import SignalsContainer from "components/tags/SignalsContainer";
import { getBuyerDestination } from "lib/appDestinations";
import { ColumnHeader } from "lib/core_components/ColumnHeader";
import { createHandleTableRowClickthrough } from "lib/core_components/commonTableItems";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import SkeletonTable from "lib/core_components/SkeletonTable";
import { Table } from "lib/core_components/Table";
import {
  ColumnSizeConfig,
  ColumnType,
  commonTableColumns,
} from "lib/core_components/Table/ColumnTypes";
import { IN_ROW_STAT_LINK, InRowStatText } from "lib/core_components/Table/Table";
import {
  BuyerCategoryAssignmentDto,
  BuyersRelationshipStatsResponseSignalStats,
} from "lib/generated/app-api";
import { TypedColumnProps } from "lib/search/SearchTable";
import { PagingState, SortState } from "lib/search/types";
import { SignalCategory } from "lib/StotlesApi";
import { tagColourFromSignal } from "lib/tagUtils";
import { BuyerColumn, ColumnSetting, SELECT_ALL } from "lib/types/models";
import { convertParamsToUrl } from "lib/urlParamsTracker";
import { isDefined } from "lib/utils";
import { EventNames } from "../../lib/tracking";
import { BuyerSearchStats } from "./AllBuyersTable";
import { BuyerFilter, BuyerSignalFilters } from "./BuyerFilters";
import { buyerListColumns } from "./BuyerList";
import { toUrlParams } from "./paramUtils";
import SaveBuyer from "./SaveBuyer";

import css from "./BuyersTable.module.scss";

//hack to use snake case and use nested keys
export type BuyerListStats = BuyersRelationshipStatsResponseSignalStats & {
  buyer_name: never;
  partners: never;
  competitors: never;
  matching_records: never;
  expiries: never;
  open_ops: never;
};

const getSortOrder = (
  sort: SortState,
  key: keyof BuyerListStats,
): "ascend" | "descend" | undefined => {
  if (sort.field === key) {
    return sort.order === "ASC" ? "ascend" : "descend";
  } else return undefined;
};

const BASIC_SIZE: ColumnSizeConfig = {
  small: 150,
  medium: 150,
  large: 180,
  xlarge: 200,
};

export function NameColumn({
  buyerGuid,
  buyerName,
  categories,
}: {
  buyerGuid: string;
  buyerName: string;
  categories: BuyerCategoryAssignmentDto[];
}) {
  const [mouseEntered, setMouseEntered] = useState(false);
  return (
    <div
      className={css.nameColumn}
      onMouseEnter={() => setMouseEntered(true)}
      onMouseLeave={() => setMouseEntered(false)}
    >
      <div className={css.buyerName}>
        <EllipsisTooltipTextLink
          fullText={buyerName}
          linkText={buyerName}
          linkProps={{ to: `/buyers/${buyerGuid}`, className: css.titleLink }}
        />
        {window.currentUser?.show_buyer_categories && (
          <BuyerCategoriesWithPopover
            size="default"
            buyerCategories={categories.map((c) => ({
              buyer_category_id: c.buyerCategoryId,
              source: c.source,
            }))}
            buyerName={buyerName}
          />
        )}
      </div>
      <div
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <SaveBuyer
          buyerGuid={buyerGuid}
          buyerName={buyerName}
          triggerType={mouseEntered ? "button" : "bookmark"}
          contextSource="In-row"
        />
      </div>
    </div>
  );
}

const getBuyerSignalUrlParams = (filteredSignals: BuyerSignalFilters) => {
  const extractedBuyerFilter: BuyerFilter = {
    signals: filteredSignals,
    categoryIds: [],
    includeUncategorisedBuyers: undefined,
    countryCodes: [],
    regionIds: [],
    buyerName: "",
  };

  const url = convertParamsToUrl(toUrlParams, extractedBuyerFilter);

  return url;
};

type ColumnTypeMap = {
  [key in BuyerColumn]: TypedColumnProps<BuyerListStats>;
};

export function getBuyerListColumns(
  sort: SortState,
  columnSettings?: ColumnSetting<BuyerColumn>[],
  filteredSignals?: BuyerSignalFilters,
): TypedColumnProps<BuyerListStats>[] {
  let keywordParamString = `keywords=${SELECT_ALL}`;
  if (filteredSignals && filteredSignals.keywords) {
    keywordParamString = getBuyerSignalUrlParams({
      keywords: filteredSignals.keywords,
    });
  }
  const buyer_name: ColumnType<BuyerListStats> = {
    ...commonTableColumns.titleColumn,
    title: <ColumnHeader header="Buyer name" subHeader="&amp; buyer type" />,
    render: (_, r) => (
      <NameColumn buyerGuid={r.buyerGuid} buyerName={r.buyerName} categories={r.categories} />
    ),
    dataIndex: "buyer_name",
    key: "buyer_name",
    //there is a bug in ant design where the sort order returns undefined when it reaches the
    // the end of the array of orders rather than returning the first value, the types say it
    // should never be undefined, since we default to decending adding an extra ascend fixes this
    sortDirections: ["ascend", "descend", "ascend"],
    sorter: true,
    sortOrder: getSortOrder(sort, "buyer_name"),
  };

  const matching_records: TypedColumnProps<BuyerListStats> = {
    title: <ColumnHeader header="Notices" subHeader="matching signals" />,
    render: (_, r) =>
      r.stats.matchingRecords.count ? (
        <TextLink
          to={`/buyers/${r.buyerGuid}/activity/all?${keywordParamString}`}
          eventName={EventNames.highlightClicked}
          eventAttributes={{
            "Highlight type": "Records matching keywords",
          }}
          className={IN_ROW_STAT_LINK}
        >
          {r.stats.matchingRecords.count}
        </TextLink>
      ) : (
        <InRowStatText>{r.stats.matchingRecords.count}</InRowStatText>
      ),
    dataIndex: "matching_records",
    key: "matching_records",
    sorter: true,
    sortOrder: getSortOrder(sort, "matching_records"),
    sortDirections: ["descend", "ascend", "descend"],
    sizeConfig: BASIC_SIZE,
  };

  const open_ops: TypedColumnProps<BuyerListStats> = {
    title: <ColumnHeader header="Open opportunities" subHeader="matching signals" />,
    render: (_, r) =>
      r.stats.openOps.count ? (
        <TextLink
          to={`/buyers/${r.buyerGuid}/activity/open_opportunities?${keywordParamString}`}
          eventName={EventNames.highlightClicked}
          eventAttributes={{
            "Highlight type": "Open opportunities matching keywords",
          }}
          className={IN_ROW_STAT_LINK}
        >
          {r.stats.openOps.count}
        </TextLink>
      ) : (
        <InRowStatText>{r.stats.openOps.count}</InRowStatText>
      ),
    dataIndex: "open_ops",
    key: "open_ops",
    sorter: true,
    sortOrder: getSortOrder(sort, "open_ops"),
    sortDirections: ["descend", "ascend", "descend"],
    sizeConfig: BASIC_SIZE,
  };

  const expiries: TypedColumnProps<BuyerListStats> = {
    title: <ColumnHeader header="Contract expiries" subHeader="matching signals" />,
    render: (_, r) =>
      r.stats.expiries.count ? (
        <TextLink
          to={`/buyers/${r.buyerGuid}/activity/upcoming_expiries?${keywordParamString}`}
          eventName={EventNames.highlightClicked}
          eventAttributes={{
            "Highlight type": "Upcoming expiries matching keywords",
          }}
          className={IN_ROW_STAT_LINK}
        >
          {r.stats.expiries.count}
        </TextLink>
      ) : (
        <InRowStatText>{r.stats.expiries.count}</InRowStatText>
      ),
    dataIndex: "expiries",
    key: "expiries",
    sorter: true,
    sortOrder: getSortOrder(sort, "expiries"),
    sortDirections: ["descend", "ascend", "descend"],
    sizeConfig: BASIC_SIZE,
  };

  const competitors: TypedColumnProps<BuyerListStats> = {
    title: <ColumnHeader header="My competitors" subHeader="connected to the buyer" />,
    render: (_, r) =>
      r.stats.competitors.count ? (
        <TextLink
          to={`/buyers/${r.buyerGuid}/suppliers/competitors?${
            filteredSignals
              ? getBuyerSignalUrlParams({
                  competitors: filteredSignals.competitors,
                })
              : ""
          }`}
          eventName={EventNames.highlightClicked}
          eventAttributes={{
            "Highlight type": "Competitors connected to this buyer",
          }}
          className={IN_ROW_STAT_LINK}
        >
          {r.stats.competitors.count}
        </TextLink>
      ) : (
        <InRowStatText>{r.stats.competitors.count}</InRowStatText>
      ),
    dataIndex: "competitors",
    key: "competitors",
    sorter: true,
    sortOrder: getSortOrder(sort, "competitors"),
    sortDirections: ["descend", "ascend", "descend"],
    sizeConfig: BASIC_SIZE,
  };

  const partners: TypedColumnProps<BuyerListStats> = {
    title: <ColumnHeader header="My partners" subHeader="connected to the buyer" />,
    render: (_, r) =>
      r.stats.partners.count ? (
        <TextLink
          to={`/buyers/${r.buyerGuid}/suppliers/partners?${
            filteredSignals
              ? getBuyerSignalUrlParams({
                  partners: filteredSignals.partners,
                })
              : ""
          }`}
          eventName={EventNames.highlightClicked}
          eventAttributes={{
            "Highlight type": "Partners connected to this buyer",
          }}
          className={IN_ROW_STAT_LINK}
        >
          {r.stats.partners.count}
        </TextLink>
      ) : (
        <InRowStatText>{r.stats.partners.count}</InRowStatText>
      ),
    dataIndex: "partners",
    key: "partners",
    sorter: true,
    sortOrder: getSortOrder(sort, "partners"),
    sortDirections: ["descend", "ascend", "descend"],
    sizeConfig: BASIC_SIZE,
  };

  const signals: TypedColumnProps<BuyerListStats> = {
    dataIndex: "signals",
    key: "signals",
    title: <ColumnHeader header="Signals" subHeader="connected to buyer" />,
    render: (_, r) => {
      const signals = r.signals.map((s) => ({
        ...s,
        colour: tagColourFromSignal(s.category),
      }));
      return (
        <SignalsContainer
          signals={signals}
          maxSignals={2}
          redactedSignalCategories={[SignalCategory.COMPETITOR, SignalCategory.PARTNER]}
          requiredDataType="BUYERS"
          contextSource="In-row"
        />
      );
    },
    sizeConfig: {
      small: 150,
      medium: 200,
      large: 250,
      xlarge: 300,
    },
  };

  const columnTypeMap: Partial<ColumnTypeMap> = {
    buyer_name,
    matching_records,
    open_ops,
    expiries,
    competitors,
    partners,
    signals,
  };

  const listOfColumns = columnSettings ? columnSettings : buyerListColumns;

  const columns: (TypedColumnProps<BuyerListStats> | undefined)[] = [];
  for (const column of listOfColumns) {
    columns.push(columnTypeMap[column.field]);
  }

  return columns.filter(isDefined);
}

type BuyersTableProps = {
  stats: BuyerListStats[];
  onSortChange: (sort: SortState) => void;
  onPaginationStateChange: (newState: PagingState) => void;
  paginationState: PagingState;
  isLoading: boolean;
  resultsCount?: number;
  columns: TypedColumnProps<BuyerListStats>[];
  onSelectedRowsChange: (newGuids: string[]) => void;
  selectedRowGuids: string[];
};

export function BuyersTable({
  stats,
  onSortChange,
  paginationState,
  onPaginationStateChange,
  resultsCount,
  isLoading,
  columns,
  selectedRowGuids,
  onSelectedRowsChange,
}: BuyersTableProps): JSX.Element {
  const handleChange = useCallback(
    (
      _pagination: TablePaginationConfig,
      _filters: Partial<Record<keyof BuyerListStats, string[]>>,
      sorter: SorterResult<BuyerListStats> | SorterResult<BuyerListStats>[],
    ) => {
      if (Array.isArray(sorter)) {
        sorter = sorter[0];
      }

      onSortChange({
        field: sorter.field as string,
        order: sorter.order === "ascend" ? "ASC" : "DESC",
      });
    },
    [onSortChange],
  );

  const onRow = createHandleTableRowClickthrough<BuyersRelationshipStatsResponseSignalStats>((r) =>
    getBuyerDestination(r.buyerGuid),
  );

  // Hacky way to force re-render of Table every time data changes.
  // This ensures that the table is always scrolled to the top when we get new data.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const tableKey = React.useMemo(() => Math.random(), [stats]);

  return (
    <DetailsContent>
      <Table
        key={tableKey}
        onChange={handleChange}
        columns={columns}
        dataSource={stats}
        rowKey={(r) => r.buyerGuid}
        onRow={onRow}
        loading={isLoading}
        rowSelection={{
          selectedRowKeys: selectedRowGuids,
          onChange: (selectedKeys: Key[]) => {
            onSelectedRowsChange(selectedKeys as string[]); // our keys are guids
          },
          columnWidth: "60px",
          columnTitle: () => {
            const checkBoxProps = {
              indeterminate: selectedRowGuids.length > 0,
              checked: stats ? selectedRowGuids.length === stats.length : false,
              onChange: (e: CheckboxChangeEvent) => {
                const checked = e.target.checked;
                if (checked && stats) {
                  onSelectedRowsChange(stats.map((item) => item.buyerGuid));
                } else {
                  onSelectedRowsChange([]);
                }
              },
            };

            return <Checkbox {...checkBoxProps} />;
          },
        }}
        pagination={{
          ...paginationState,
          total: resultsCount || 0,
          showSizeChanger: true,
          onChange: (page, pageSize) => onPaginationStateChange({ currentPage: page, pageSize }),
        }}
      />
    </DetailsContent>
  );
}

type SkeletonBuyerListTableProps = {
  pageSize: number;
  resultsCount?: number;
  columns: TypedColumnProps<BuyerListStats>[] | TypedColumnProps<BuyerSearchStats>[];
};

export function SkeletonBuyerListTable({
  resultsCount,
  pageSize,
  columns,
}: SkeletonBuyerListTableProps): JSX.Element {
  return (
    <DetailsContent>
      <SkeletonTable columns={columns} pageSize={pageSize} rowCount={resultsCount} active />
    </DetailsContent>
  );
}
