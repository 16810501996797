import * as React from "react";

import { CandidateItem, CandidateListPanel } from "components/organisation_clean/OrgListPanel";
import { OrgWithStats } from "components/organisation_clean/types";
import { FilterType, OrgSearchFilters, useOrgSearch } from "./useOrgSearch";

import css from "./SelectAnchorPage.module.scss";

type Props = {
  anchorOrg?: OrgWithStats;
  onAnchorChange: (b: OrgWithStats) => void;
  orgPrimaryRole: "Buyer" | "Supplier";
};

function SelectAnchorPage({ onAnchorChange, anchorOrg, orgPrimaryRole }: Props): JSX.Element {
  const [filterState, setFilterState] = React.useState<FilterType>({
    textSearch: anchorOrg?.name || "",
    countryCode: ["UK"],
    // in the future this could be fully buyer/supplier/role agnostic
    primaryRole: orgPrimaryRole,
  });
  const searchResults = useOrgSearch(filterState);

  return (
    <div className={css.selectAnchorPage}>
      <div className={css.searchPanel}>
        <header>
          <h1>Step 1. Select the anchor organisation</h1>
          <OrgSearchFilters filters={filterState} setFilters={setFilterState} />
        </header>
        <CandidateListPanel
          orgs={searchResults}
          buttonConfig={{
            selectedLabel: "Selected",
            label: "Select",
            onSelect: (b) => {
              onAnchorChange(b);
            },
          }}
          disableSelected={true}
          selectedGuids={anchorOrg ? [anchorOrg.guid] : []}
        />
      </div>
      <div className={css.resultPanel}>
        <div className={css.selectedAnchorCard}>
          <h2>Selected anchor</h2>

          {!anchorOrg?.name && (
            <h3>
              <i>No anchor selected</i>{" "}
            </h3>
          )}

          {anchorOrg && <CandidateItem org={anchorOrg} />}
        </div>
      </div>
    </div>
  );
}

export default SelectAnchorPage;
