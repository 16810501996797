import React, { useState } from "react";
import { useIsDevCycleInitialized } from "@devcycle/react-client-sdk";
import { Button, Result } from "antd5";
import { Redirect } from "wouter";

import { useVariableValue } from "../../lib/featureFlags";
import { QualifyNoticeRequest_LlmProviderEnum_1 as LLMProvider } from "../../lib/generated/app-service-gql/graphql";
import { useNoticeQualification } from "../../lib/hooks/api/bid/useNoticeQualification";
import { useLocalStorage } from "../../lib/hooks/useLocalStorage";
import { DetailsContent, DetailsHeader, DetailsPage } from "../app_layout/DetailsLayout";
import LoadingState from "../buyer_details/question_panel/LoadingState";
import { NoticeDocumentUpload } from "./notice_document_upload/NoticeDocumentUpload";
import { NoticeQualifcationForm, QualifcationFormValues } from "./NoticeQualificationForm";
import { NoticeQualifcationResult } from "./NoticeQualificationResult";

export function NoticeQualificationPage({ noticeId }: { noticeId: string }) {
  const devCycleReady = useIsDevCycleInitialized();
  const isEnabled = useVariableValue("notice-auto-qualification", false);
  if (devCycleReady && !isEnabled) {
    return <Redirect to="/" />;
  }
  return (
    <DetailsPage>
      <DetailsHeader>
        <h1>Notice Qualification</h1>
      </DetailsHeader>
      <DetailsContent>
        <NoticeQualifcation noticeId={noticeId} />
      </DetailsContent>
    </DetailsPage>
  );
}

const DEFAULT_PROMPT = `We are trying to pull answers from the documents related to a government tender.
Keep answers to the questions short concise and factual.
After each answer provide which documents were used for the answer with the corresponding section and any relevant context
for example in the query was "deadline for questions" the output would be like below

Answer: 10 a.m. UK time on Friday, 23 August 2024
Document: tender_doc.docx
Section: Section A
Chunk: 106

You will be provided a set of functions that you can use to search and retrieve the documents to help answer the query.

Query: {{ question }}
`;

function NoticeQualifcation({ noticeId }: { noticeId: string }) {
  // TODO: this should be retrieved from the backend
  const [isDocumentsUploaded, setIsDocumentsUploaded] = useState(false);
  const [formValues, setFormValues] = useLocalStorage<QualifcationFormValues>(
    "noticeQualificationForm",
    {
      llmPrompt: DEFAULT_PROMPT,
      noticeId,
      qualificationQuestions: [],
      llmProvider: LLMProvider.Mistral,
    },
  );
  const { mutate: qualifyNotice, isLoading, isError, reset, data } = useNoticeQualification();

  if (!isDocumentsUploaded) {
    return (
      <NoticeDocumentUpload noticeId={noticeId} onComplete={() => setIsDocumentsUploaded(true)} />
    );
  }

  if (isLoading) {
    // this is temporarily borrowing the loading state from the question panel
    return <LoadingState />;
  }

  if (isError) {
    return (
      <Result
        status="error"
        title="Error"
        subTitle="There was an error qualifying the notice"
        extra={<Button onClick={reset}>Restart</Button>}
      />
    );
  }

  if (data) {
    return (
      <NoticeQualifcationResult
        result={data.result}
        onRetry={() => qualifyNotice({ input: { ...formValues, noticeId } })}
        onEdit={() => reset()}
      />
    );
  }

  return (
    <NoticeQualifcationForm
      onSubmit={(values) => {
        setFormValues(values);
        qualifyNotice({ input: values });
      }}
      noticeId={noticeId}
      defaultValues={{
        ...formValues,
        noticeId,
      }}
    />
  );
}
