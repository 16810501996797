import * as React from "react";
import { hot } from "react-hot-loader/root";
import { message } from "antd5";

import { withAppLayout } from "components/app_layout/AppLayout";
import Modal from "components/modals/Modal";
import CreateTeamPage from "components/onboarding/CreateTeamPage";
import ExperienceSelection from "components/onboarding/ExperienceSelection";
import FeedUpdateProgressBar from "components/onboarding/FeedUpdateProgressBar";
import GeneratedFeedPreviewInputs from "components/onboarding/GeneratedFeedPreviewInputs";
import JoinTeamPage from "components/onboarding/JoinTeamPage";
import { assertCurrentUser } from "lib/currentUser";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { TeamsQuery } from "lib/generated/app-service-gql/graphql";
import { useKeywords } from "lib/hooks/api/keywords/useKeywords";
import { useSubmitOnboarding } from "lib/hooks/api/onboarding/useSubmitOnboarding";
import { useTeams } from "lib/hooks/api/teams/useTeams";
import { SelfServeQuery } from "lib/StotlesApi";
import * as tracking from "lib/tracking";
import { Status } from "lib/types/models";
import { ACCOUNT_SETUP_FORM } from "../../components/onboarding/tracking";
import { sendGa4Event } from "../../lib/ga4Tracking";

import css from "./OnboardingPage.module.scss";

import FRESHWORKS_LOGO from "../../../assets/images/onboarding/freshworks_logo.png";
import KEYWORD_EXAMPLE from "../../../assets/images/onboarding/keywords_example.png";
import TEAMS_EXAMPLE from "../../../assets/images/onboarding/teams_example.png";

type Props = {
  company_id: number;
  status?: string;
};

export enum Steps {
  FIRST,
  SECOND,
  THIRD,
  FORTH,
}

export const OTHER_INDUSTRY = "Other";
export type OTHER_INDUSTRY_TYPE = "Other";

export type FormState = {
  experience?: string;
  countries: string[];
  languages?: string[];
  keywords: string[];
  excludeKeywords?: string[];
  keywordsCpvCodes?: string[];
  keywordsCpvCodesIncludeNull: boolean | undefined;
};

const initialFormState: FormState = {
  experience: undefined,
  countries: ["United Kingdom", "Ireland"],
  keywords: [],
  keywordsCpvCodesIncludeNull: undefined,
};

const defaultFrom = new Date();
defaultFrom.setMonth(defaultFrom.getMonth() - 1);
defaultFrom.setHours(0, 0, 0, 0);

export function formStateToFeedCriteria(
  formState: FormState,
  statusFilters: Status[],
): SelfServeQuery {
  return {
    countries: formState.countries,
    languages: formState.languages,
    keywords: {
      keywords: formState.keywords,
      from_date: defaultFrom,
      status: statusFilters,
      exclude_keywords: formState.excludeKeywords,
      cpv_codes: formState.keywordsCpvCodes,
      cpv_codes_include_null: formState.keywordsCpvCodesIncludeNull,
    },
  };
}

function filterTeams(teams: TeamsQuery["teams"], currentTeamId: string) {
  return teams.filter((team) => {
    if (team.id === currentTeamId) {
      return false;
    }
    if (team.users.length === 0) {
      return false;
    }
    if (!team.feedSettingsId) {
      return false;
    }
    return true;
  });
}

function Onboarding({ company_id, status }: Props) {
  const [currentStep, setCurrentStep] = React.useState<Steps>(Steps.FIRST);
  const [formState, setFormState] = React.useState<FormState>(initialFormState);
  const [updateJobId, setUpdateJobId] = React.useState<string>();

  const onProgressBarComplete = React.useCallback(() => {
    window.location.href = "/";
  }, []);

  const currentUser = assertCurrentUser();
  const domainAddress = currentUser.email && currentUser.email.match(/@(.*)/)?.[1];
  const { data, isLoading } = useKeywords({
    domainAddress: domainAddress || "",
  });

  const { data: teams } = useTeams();

  const filteredTeams = teams ? filterTeams(teams, currentUser.team.id) : [];

  React.useEffect(() => {
    setFormState((prevFormState) => {
      return {
        ...prevFormState,
        keywords: data?.keywords || [],
      };
    });
  }, [data?.keywords]);

  const { mutate } = useSubmitOnboarding({
    onSuccess: (jobId) => {
      setUpdateJobId(jobId);
    },
  });

  // Handling the status from verifying your email
  React.useEffect(() => {
    if (status === "confirmed") message.success("Thanks for confirming your email!", 2);
    if (status === "previously_confirmed")
      message.info("Your email has already been confirmed.", 2);
  }, [status]);

  // Amplitude page tracking per step in the process
  React.useEffect(() => {
    switch (currentStep) {
      case Steps.FIRST:
        tracking.pageView("Onboarding - Select Public Sector Experience", {});
        sendGa4Event({
          event: "continue_click",
          form: ACCOUNT_SETUP_FORM,
        });
        break;
      case Steps.SECOND:
        tracking.pageView("Onboarding - Team - Join Team", {});
        sendGa4Event({
          event: "continue_click",
          form: ACCOUNT_SETUP_FORM,
        });
        break;
      case Steps.THIRD:
        tracking.pageView("Onboarding - Team - New Team", {});
        sendGa4Event({
          event: "continue_click",
          form: ACCOUNT_SETUP_FORM,
        });
        break;
      case Steps.FORTH:
        tracking.pageView("Onboarding - Select Keywords", {});
        sendGa4Event({
          event: "continue_click",
          form: ACCOUNT_SETUP_FORM,
        });
    }
  }, [currentStep]);

  const renderLeftPane = () => {
    switch (currentStep) {
      case Steps.FIRST:
        return (
          <ExperienceSelection
            onSubmit={(value) => {
              setFormState({
                ...formState,
                experience: value,
              });
              if (FeatureToggles.isEnabled(Feature.TEAM_ONBOARDING)) {
                if (filteredTeams.length > 0) {
                  setCurrentStep(Steps.SECOND);
                } else {
                  setCurrentStep(Steps.THIRD);
                }
              } else {
                setCurrentStep(Steps.FORTH);
              }
            }}
            companyName={currentUser.company.name ?? data?.companyName}
          />
        );
      case Steps.SECOND:
        return (
          <JoinTeamPage
            teams={filteredTeams}
            companyName={currentUser.company.name ?? data?.companyName}
            onCreateTeam={() => {
              setCurrentStep(Steps.THIRD);
            }}
            onJoinTeam={() => {
              window.location.href = "/";
            }}
          />
        );
      case Steps.THIRD:
        return (
          <CreateTeamPage
            onSubmit={() => setCurrentStep(Steps.FORTH)}
            companyName={currentUser.company.name ?? data?.companyName}
            onBack={filteredTeams.length > 0 ? () => setCurrentStep(Steps.SECOND) : undefined}
          />
        );
      case Steps.FORTH:
        return (
          <GeneratedFeedPreviewInputs
            keywords={formState.keywords}
            isLoading={isLoading}
            onSubmit={(keywords) => {
              const newFormState = { ...formState, keywords };
              setFormState(newFormState);
              mutate({ companyId: company_id, formState: newFormState });

              sendGa4Event({
                event: "account_setup_form_submit",
                form: ACCOUNT_SETUP_FORM,
              });
            }}
          />
        );
    }
  };

  const renderRightPane = () => {
    switch (currentStep) {
      case Steps.FIRST:
        return (
          <div className={`${css.rightImageContainer} ${css.authorisation}`}>
            <div className={css.content}>
              <img src={FRESHWORKS_LOGO} alt="Freshworks Logo" className={css.companyLogo} />
              <p className={css.quote}>
                "We're more aware of everything that's going on in the market and can use the
                information to make sure we engage the right people"
              </p>
              <p className={css.quoteAuthor}>Olly Phillips</p>
              <p className={css.quoteAuthorTitle}>Director of Sales, Freshworks</p>
            </div>
          </div>
        );
      case Steps.SECOND:
      case Steps.THIRD:
        return (
          <div className={`${css.rightImageContainer} ${css.teams}`}>
            <img src={TEAMS_EXAMPLE} alt="Teams example" className={css.rightImage} />
          </div>
        );
      case Steps.FORTH:
        return (
          <div className={`${css.rightImageContainer} ${css.keywords}`}>
            <img src={KEYWORD_EXAMPLE} alt="Keywords example" className={css.rightImage} />
          </div>
        );
    }
  };

  return (
    <div className={css.onboardingContainer}>
      <div className={css.onboarding}>
        <Modal isOpen={updateJobId !== undefined} centered>
          <h1 className={css.loadingHeader}>Populating your feed 🎉</h1>
          <p className={css.loadingBody}>This should only take a moment</p>
          <FeedUpdateProgressBar onComplete={onProgressBarComplete} jobId={updateJobId} />
        </Modal>
        <div className={css.leftPane}>
          <div className={css.leftPaneInner}>{renderLeftPane()}</div>
        </div>
        <div className={css.rightPane}>{renderRightPane()}</div>
      </div>
    </div>
  );
}

export default hot(
  withAppLayout(Onboarding, {
    hideMenuItems: true,
    hideUserItems: true,
    disableLogoNav: true,
  }),
);
