import React, { useCallback, useMemo } from "react";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { App, Button, Divider, Input, Menu, MenuProps, Popover } from "antd5";

import BookmarkIcon from "components/actions/Bookmark";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { BuyerListDto, GetBuyerListsPermissionEnum } from "lib/generated/app-api";
import { useAddBuyerEntries } from "lib/hooks/api/buyer_lists/useAddBuyerEntries";
import { useBuyerLists } from "lib/hooks/api/buyer_lists/useBuyerLists";
import { BuyerContextSource } from "lib/hooks/api/buyer_lists/useCreateBuyerList";
import { useRemoveBuyerEntries } from "lib/hooks/api/buyer_lists/useRemoveBuyerEntries";
import { useDialogManager } from "lib/providers/DialogManager";
import { escapeRegExp, isArrayInArray } from "lib/utils";
import { UserCreateBuyerListModal, UserModalProps } from "./CreateBuyerListModal";

import css from "./SaveBuyerPopover.module.scss";

type Props = {
  buyerGuids: string[];
  children: React.ReactNode;
  contextSource: BuyerContextSource;
  isPopoverOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onBuyerListOp: (buyerGuids: string[], buyerListName: string, op: "add" | "remove") => void;
};

function SaveBuyerPopover({
  buyerGuids,
  children,
  contextSource,
  isPopoverOpen,
  onOpenChange,
  onBuyerListOp,
}: Props) {
  const dialogManager = useDialogManager();

  const { message } = App.useApp();

  const { isLoading, data: lists } = useBuyerLists(
    GetBuyerListsPermissionEnum.CreateBuyerListEntries,
    {
      enabled: !!window.currentUser,
    },
  );

  const [searchValue, setSearchValue] = React.useState("");

  const { mutate: addBuyers, isLoading: isAddLoading } = useAddBuyerEntries({
    onSuccess: (_data, variables, _context) =>
      onBuyerListOp(variables.buyerGuids, variables.buyerListName, "add"),
    onError: () => {
      void message.error(
        `Failed to ${
          buyerGuids.length > 1 ? "bulk add buyers" : "add buyer"
        } to buyer list, please contact an admin if this issue persists.`,
      );
    },
  });
  const { mutate: removeBuyers, isLoading: isRemoveLoading } = useRemoveBuyerEntries({
    onSuccess: (_data, variables, _context) =>
      onBuyerListOp(variables.buyerGuids, variables.buyerListName, "remove"),
    onError: () => {
      void message.error(
        `Failed to ${
          buyerGuids.length > 1 ? "bulk remove buyers" : "remove buyer"
        } from buyer list, please contact an admin if this issue persists.`,
      );
    },
  });

  /**
   * Opens the modal which allows the user to create either a private or team buyer list
   */
  const openCreateBuyerListModal = () => {
    onOpenChange(false);
    dialogManager.openDialog(UserCreateBuyerListModal, {
      buyerGuids: buyerGuids,
      contextSource: contextSource,
    } as Omit<UserModalProps, "isOpen" | "onClose">);
    onOpenChange(true);
  };

  // This map tells us whether or not all of the buyerGuids supplied by the props are in the list - if they are, then bookmark icon is shown
  const isSavedMap = React.useMemo((): Map<string, boolean> => {
    const savedMap = new Map();

    for (const list of lists?.private || []) {
      const listEntries = list.entries.flatMap((entry) => entry.id);
      savedMap.set(list.id, isArrayInArray(buyerGuids, listEntries));
    }

    for (const list of lists?.team || []) {
      const listEntries = list.entries.flatMap((entry) => entry.id);
      savedMap.set(list.id, isArrayInArray(buyerGuids, listEntries));
    }

    return savedMap;
  }, [buyerGuids, lists?.private, lists?.team]);

  /**
   * Creates a submenu for both team buyer lists and private buyer lists. Handles the logic for
   * what happens when user clicks one of these buyer lists (either add or remove buyer from list), shows whether
   * or not the buyer is already saved to the list and handles the display when a team list or a private list doesn't exist yet
   * @param buyerLists
   * @param noListsLabel component shown when there is no list
   * @returns
   */
  const createSubmenu = useCallback(
    (buyerLists: BuyerListDto[], noListsLabel: MenuProps["items"]): MenuProps["items"] => {
      if (buyerLists.length <= 0) {
        return noListsLabel;
      }

      const onClickBuyerList = (list: BuyerListDto) => {
        const buyerGuidsInList = new Set(list.entries.flatMap((entry) => entry.id));
        const guidsToAdd = buyerGuids.filter((guid) => !buyerGuidsInList.has(guid));
        if (guidsToAdd.length > 0) {
          addBuyers({
            buyerListGuid: list.id,
            buyerListName: list.name,
            buyerGuids: guidsToAdd,
          });
        } else {
          removeBuyers({
            buyerListGuid: list.id,
            buyerListName: list.name,
            buyerGuids: buyerGuids,
          });
        }
      };

      return buyerLists.map((list) => ({
        key: list.id,
        onClick: () => onClickBuyerList(list),
        label: (
          <div className={css.listItem}>
            <EllipsisTooltipText
              fullText={list.name}
              textProps={{ className: css.listName }}
              childText={list.name}
            />
            {isSavedMap.get(list.id) && <BookmarkIcon colour="blue" filled />}
          </div>
        ),
        loading: isAddLoading || isRemoveLoading,
      }));
    },
    [addBuyers, buyerGuids, isAddLoading, isRemoveLoading, isSavedMap, removeBuyers],
  );

  const privateBuyerLists: MenuProps["items"] = useMemo(() => {
    const filteredPrivateLists =
      lists?.private.filter((list) =>
        list.name.match(new RegExp(escapeRegExp(searchValue), "i")),
      ) || [];
    return createSubmenu(filteredPrivateLists, [
      {
        key: "privateBuyerLists",
        label: "No private buyer lists currently exist",
        className: css.listHeader,
      },
    ]);
  }, [createSubmenu, lists?.private, searchValue]);

  const teamBuyerLists: MenuProps["items"] = useMemo(() => {
    const filteredTeamLists =
      lists?.team.filter((list) => list.name.match(new RegExp(escapeRegExp(searchValue), "i"))) ||
      [];
    return createSubmenu(filteredTeamLists, [
      {
        key: "teamBuyerLists",
        label: "No team buyer lists currently exist",
        className: css.listHeader,
      },
    ]);
  }, [createSubmenu, lists?.team, searchValue]);

  const buyerListOptions: MenuProps["items"] = [
    {
      key: "privateBuyerList",
      type: "group",
      label: <h4 className={css.listHeader}>Private</h4>,
      loading: isLoading,
      children: privateBuyerLists,
    },
    {
      key: "teamBuyerList",
      type: "group",
      label: <h4 className={css.listHeader}>Team lists</h4>,
      children: teamBuyerLists,
      loading: isLoading,
    },
  ];

  return (
    <Popover
      // Click to open popover, then when open it closes automatically after 0.3 secs
      trigger={isPopoverOpen ? ["hover"] : ["click"]}
      mouseLeaveDelay={0.3}
      placement="bottomLeft"
      arrow={false}
      open={isPopoverOpen}
      onOpenChange={(open) => onOpenChange(open)}
      overlayInnerStyle={{ padding: 0 }}
      content={
        <div className={css.popoverContainer}>
          <Input
            className={css.searchbox}
            placeholder="Search menu"
            prefix={<SearchOutlined className={css.searchIcon} />}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Divider className={css.divider} />

          <Menu
            mode="inline"
            className={css.menuContent}
            items={buyerListOptions}
            inlineIndent={12}
            selectable={false}
            multiple
          />

          <Divider className={css.divider} />
          <Button
            type="primary"
            onClick={() => openCreateBuyerListModal()}
            className={css.createListBtn}
            icon={<PlusOutlined className={css.plusIcon} />}
          >
            Create list
          </Button>
        </div>
      }
    >
      {children}
    </Popover>
  );
}

export default SaveBuyerPopover;
