import React from "react";
import { Button } from "antd5";

import { Table } from "../../lib/core_components/Table";
import { ColumnType } from "../../lib/core_components/Table/ColumnTypes";
import { Markdown } from "../reports/builder_components/Markdown";

import css from "./NoticeQualificationResult.module.scss";

type QualificationResult = {
  answer: string;
  question: string;
};
type Props = {
  result: QualificationResult[];
  onEdit: () => void;
  onRetry: () => void;
};
export function NoticeQualifcationResult({ result, onEdit, onRetry }: Props) {
  const columns: ColumnType<QualificationResult>[] = [
    {
      title: "Qualification Criteria",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      render: (text: string) => <Markdown text={text} targetType="new-tab" />,
    },
  ];

  return (
    <div className={css.container}>
      <Table columns={columns} dataSource={result} rowKey="question" />

      <div className={css.actionsContainer}>
        <Button type="primary" onClick={onEdit}>
          Edit criteria
        </Button>
        <Button onClick={onRetry}>Retry</Button>
      </div>
    </div>
  );
}
