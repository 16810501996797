import moment from "moment";

import { DocumentReferenceParameters, ReportConfig } from "lib/generated/admin-api";
import { DocumentReferenceInfo } from "./items/document_list/DocumentList";
import { ReportMetadata } from "./ReportSectionBuilder";

const documentApiUrl = "/api/documents/download?";

function validateMetadata(metadata: ReportMetadata): boolean {
  return !!(metadata.companyId && metadata.label.length > 0 && metadata.title.length > 0);
}

export function extractMetadata(report: ReportConfig): ReportMetadata | undefined {
  const metadata: ReportMetadata = {
    companyId: report.companyId,
    teamId: report.teamId,
    adminNotes: report.adminNotes,
    title: report.reportData.title,
    reportType: report.reportType,
    creatorId: report.creatorId,
    label: report.label,
    publishedAt: report.publishedAt,
    documents: report.documents,
    reportId: report.id,
  };
  return validateMetadata(metadata) ? metadata : undefined;
}

export function formatReportDate(date: string): string {
  return moment(date).format("MMM D, YYYY");
}

export function getDocumentInfo(
  ref?: DocumentReferenceParameters,
): DocumentReferenceInfo | undefined {
  if (!ref) {
    return undefined;
  }

  if (ref.googleDriveDoc) {
    return {
      url: `${window.location.origin}${documentApiUrl}id=${ref.googleDriveDoc.id}`,
      searchTerm: ref.googleDriveDoc.searchTerm,
    };
  }
  return undefined;
}

export function updateSearchTermParam(
  searchTerm: string,
  documentRef: DocumentReferenceParameters,
): DocumentReferenceParameters {
  if (documentRef?.googleDriveDoc) {
    return {
      googleDriveDoc: {
        ...documentRef.googleDriveDoc,
        searchTerm,
      },
    };
  }
  return documentRef;
}
