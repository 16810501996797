import { categorizeSignals, SignalEntityType } from "lib/utils/signalUtils";
import { useDocumentSignals } from "./useDocumentSignals";
import { useFrameworkSignals } from "./useFrameworkSignals";
import { useNoticeSignals } from "./useNoticeSignals";

/**
 * For an entity, (whether that's a notice, framework or organisation) returns the signal score
 * @param req
 * @param options
 * @returns
 */
export function useCalculateSignals(entityId: string, entityType: SignalEntityType) {
  const {
    data: noticeSignals,
    isLoading: isLoadingNoticeSigs,
    ...restOfNoticeOptions
  } = useNoticeSignals(entityId, {
    enabled: entityType === SignalEntityType.ProcurementNotice,
  });

  const {
    data: frameworkSignals,
    isLoading: isLoadingFrameworkSigs,
    ...restOfFrameworkOptions
  } = useFrameworkSignals(entityId, {
    enabled: entityType === SignalEntityType.Framework,
  });

  const {
    data: documentSignals,
    isLoading: isLoadingDocumentSigs,
    ...restOfDocumentOptions
  } = useDocumentSignals(entityId, {
    enabled: entityType === SignalEntityType.Document,
  });

  switch (entityType) {
    case SignalEntityType.ProcurementNotice:
      return {
        data: categorizeSignals(noticeSignals),
        isLoading: isLoadingNoticeSigs,
        ...restOfNoticeOptions,
      };
    case SignalEntityType.Framework:
      return {
        data: categorizeSignals(frameworkSignals),
        isLoading: isLoadingFrameworkSigs,
        ...restOfFrameworkOptions,
      };
    case SignalEntityType.Document:
      return {
        data: categorizeSignals(documentSignals),
        isLoading: isLoadingDocumentSigs,
        ...restOfDocumentOptions,
      };
    default:
      throw new Error(`Query for entity type: ${entityType} not implemented`);
  }
}
