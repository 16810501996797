import React, { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Input, Progress, Skeleton } from "antd5";
import classNames from "classnames";

import SuggestedKeywordsOnboarding from "components/account_management/feed_settings/SuggestedKeywordsOnboarding";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import {
  sysBackgroundInactive,
  sysPrimaryHover,
  sysStatusInProgress,
  sysStatusNegative,
  sysStatusPositive,
} from "lib/themes/colors";
import * as tracking from "lib/tracking";
import { fieldInteractionEvent } from "./tracking";

import css from "./GeneratedFeedPreviewInputs.module.scss";

type GeneratedFeedPreviewInputsProps = {
  keywords: string[];
  isLoading: boolean;
  onSubmit: (keywords: string[]) => void;
};

function GeneratedFeedPreviewInputs({
  keywords,
  isLoading,
  onSubmit,
}: GeneratedFeedPreviewInputsProps) {
  const [dismissedKeywords, setDismissedKeywords] = React.useState<string[]>([]);
  const [manualKeyword, setManualKeyword] = React.useState<string>("");

  const {
    control,
    setValue,
    handleSubmit,
    formState: { isValid },
    watch,
  } = useForm<{ keywords: { keyword: string }[] }>({
    defaultValues: { keywords: [] },
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: "keywords",
    rules: { required: true, minLength: 1 },
  });

  // Remove this useEffect when the feature flag is removed
  useEffect(() => {
    if (keywords.length > 0 && !FeatureToggles.isEnabled(Feature.RECOMMENDED_KEYWORDS)) {
      setValue(
        "keywords",
        keywords.map((keyword) => ({ keyword })),
      );
    }
  }, [keywords, setValue]);

  const keywordsButtons = fields.map((field, index) => {
    return (
      <Button
        className={css.keywordButton}
        key={field.id}
        onClick={() => {
          remove(index);
          setDismissedKeywords([...dismissedKeywords, field.keyword]);
          tracking.logEvent(tracking.EventNames.customKeywordAdded, {
            Action: "Deselected",
            "Page source": "Onboarding Page",
            "Keyword Type": "Custom keyword",
            "Keyword Name": field.keyword,
          });
          fieldInteractionEvent(field.keyword, "removeKeyword");
        }}
      >
        {field.keyword} <CloseOutlined />
      </Button>
    );
  });

  const renderKeywordsProgressBar = (count: number) => {
    return (
      <>
        <div className={css.keywordBarContainer}>
          <Progress
            percent={100}
            showInfo={false}
            className={css.keywordBar}
            strokeColor={
              count < 5
                ? sysStatusNegative
                : count >= 5 && count < 10
                  ? sysStatusInProgress
                  : count >= 10
                    ? sysStatusPositive
                    : undefined
            }
          />
          <Progress
            percent={100}
            showInfo={false}
            className={css.keywordBar}
            strokeColor={
              count < 5
                ? sysBackgroundInactive
                : count >= 5 && count < 10
                  ? sysStatusInProgress
                  : count >= 10
                    ? sysStatusPositive
                    : undefined
            }
          />
          <Progress
            percent={100}
            showInfo={false}
            strokeColor={count < 10 ? sysBackgroundInactive : sysStatusPositive}
          />
        </div>
        <p className={css.keywordHelperText}>
          {count === 0 &&
            "You need at least 1 keyword to get started. Try adding some suggested keywords, or typing your own."}
          {count < 5 &&
            "Start adding more keywords to see relevant results. Use our suggestions or type your own."}
          {count >= 5 &&
            count < 10 &&
            "Add a few more keywords, we recommend around 10 to 15 to get started."}
          {count >= 10 &&
            count < 15 &&
            `${count} keywords is a great start! Aim for 15 to supercharge your feed even further 🚀`}
          {count >= 15 && "Nice work, that will be a great start 🎉"}
        </p>
      </>
    );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setManualKeyword(e.target.value);
  };

  return (
    <>
      <div className={css.header}>
        <div className={css.progressBarContainer}>
          <Progress percent={100} showInfo={false} strokeColor={sysPrimaryHover} />
        </div>
        <h1>What are you looking for?</h1>
        <p>
          Add keywords specific to your services and the contracts you're looking for, so we can
          find the most relevant notices for you.
        </p>
      </div>
      <form
        onSubmit={handleSubmit((values) => {
          const keywords = values.keywords.map((item) => item.keyword);
          onSubmit(keywords);
        })}
      >
        <div className={css.selectKeywords}>
          {FeatureToggles.isEnabled(Feature.RECOMMENDED_KEYWORDS) && (
            <SuggestedKeywordsOnboarding
              initialKeywords={keywords}
              onClick={(keyword: string) => {
                append({ keyword });
                tracking.logEvent(tracking.EventNames.customKeywordAdded, {
                  "Page source": "Onboarding Page",
                  "Context source": "Recommended",
                  "Keyword Name": keyword,
                });
                fieldInteractionEvent(keyword, "recommendedKeyword");
              }}
              existingKeywords={watch("keywords").map((item) => item.keyword)}
              dismissedKeywords={dismissedKeywords}
            />
          )}

          {FeatureToggles.isEnabled(Feature.RECOMMENDED_KEYWORDS) ? (
            <label>Or add keywords manually</label>
          ) : (
            <label>Add keywords manually</label>
          )}
          <div className={css.inputRow}>
            <Input
              value={manualKeyword}
              className={css.keywordInput}
              onChange={handleInputChange}
              onPressEnter={(e: React.KeyboardEvent<HTMLInputElement>) => {
                e.preventDefault();
                append({ keyword: e.currentTarget.value });
                setManualKeyword("");
                tracking.logEvent(tracking.EventNames.customKeywordAdded, {
                  "Page source": "Onboarding Page",
                  "Context source": "Freetyped",
                  "Keyword Name": e.currentTarget.value,
                });
                fieldInteractionEvent(e.currentTarget.value, "customKeyword");
              }}
              onBlur={(event) => {
                fieldInteractionEvent(event.target.value, "customKeyword");
              }}
              placeholder="Type keyword and press enter"
              suffix={<PlusOutlined />}
            />
          </div>

          <Divider />

          <label>Your selected keywords</label>
          {isLoading && (
            <div className={css.buttonsRow}>
              <Skeleton.Button active className={css.loadingButton} />
              <Skeleton.Button active className={css.loadingButton} />
              <Skeleton.Button active className={css.loadingButton} />
              <Skeleton.Button active className={css.loadingButton} />
              <Skeleton.Button active className={css.loadingButton} />
              <Skeleton.Button active className={css.loadingButton} />
              <Skeleton.Button active className={css.loadingButton} />
              <Skeleton.Button active className={css.loadingButton} />
              <Skeleton.Button active className={css.loadingButton} />
              <Skeleton.Button active className={css.loadingButton} />
            </div>
          )}
          <div className={css.buttonsRow}>{keywordsButtons} </div>
        </div>

        {FeatureToggles.isEnabled(Feature.RECOMMENDED_KEYWORDS) &&
          renderKeywordsProgressBar(watch("keywords").length)}
        <div className={css.finishContainer}>
          <Button
            className={classNames(!isValid && css.finishButtonDisabled)}
            type="primary"
            disabled={!isValid}
            htmlType="submit"
          >
            Finish
          </Button>
        </div>
      </form>
    </>
  );
}

export default GeneratedFeedPreviewInputs;
