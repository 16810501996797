import { useEffect } from "react";
import { MessageInstance } from "antd5/es/message/interface";
import isEqual from "lodash.isequal";
import qs from "qs";

import {
  convertFrameworkRelationshipFilterToFrameworkFilterOptions,
  NoticeFilters,
} from "components/notices/utils";
import {
  paramsToDateRangeObj,
  paramsToNullableDateRangeObj,
  paramsToNumericRangeObj,
} from "components/record_search/types";
import {
  ColumnSetting,
  ColumnSettingFieldEnum,
  RecordSearchRequestDtoProcurementStageQualificationsEnum,
  SavedViewType,
} from "lib/generated/app-api";
import { usePinView } from "lib/hooks/api/notices/views/usePinView";
import { useLocalStorage } from "lib/hooks/useLocalStorage";
import { parseBooleanValue, parseNumberValue, useURLState } from "lib/hooks/useURLState";
import { SortState } from "lib/search/types";
import { urlParamToArray, urlParamToTypedArray } from "lib/search/url_params";
import { EventNames, useTracking } from "lib/tracking";
import { Status } from "lib/types/models";
import { useAllViews } from "../../lib/hooks/api/notices/views/useAllViews";
import { usePinnedViews } from "../../lib/hooks/api/notices/views/usePinnedViews";
import { useStotlesApi } from "../../lib/stotlesApiContext";
import { createSavedViewSelectedEvent } from "./tracking";

// Some props here are assigned undefined explicitly to ensure correct amplitude tracking.
// This fixes the deep equality check between previous and next filter objects and captures only the filter field being applied.
export const DEFAULT_FILTERS: NoticeFilters = {
  procurementStageQualifications: [
    "unqualified",
    "in_review",
    "bid_prep",
    "bid_submitted",
    "pre_engage_to_do",
    "pre_engage_done",
  ],
  signals: ["Keywords", "Partners", "Competitors", "CPV codes"],
  assignee: [],
  buyerListIds: [],
  buyers: [],
  buyerCategories: [],
  buyerCountryRegions: {
    countries: [],
    regions: [],
    selected: [],
  },
  value: { to: undefined, from: undefined, hideNulls: undefined },
  text: "",
  stage: [],
  relevanceScore: [],
  closeDate: {
    filter: { from: undefined, relativeFrom: undefined, to: undefined, relativeTo: undefined },
    hideNulls: undefined,
  },
  expiryDate: {
    filter: { from: undefined, relativeFrom: undefined, to: undefined, relativeTo: undefined },
    hideNulls: undefined,
  },
  publishDate: {},
  sort: {
    field: "relevance_score",
    order: "DESC",
  },
  suppliers: [],
  supplierGuids: [],
  supplierSme: "",
  cpvDimensions: [],
  frameworkIds: [],
  frameworkFilterOptions: "",
  supplierMentionType: undefined,
  keywords: [],
  excludeKeywords: [],
  cpvCodes: [],
};

export const EMPTY_FILTERS: NoticeFilters = {
  ...DEFAULT_FILTERS,
  closeDate: {},
  expiryDate: {},
  procurementStageQualifications: [],
  signals: [],
  value: {},
};

// In the rare case that sort order is not defined for a view, this is the failsafe
export const DEFAULT_NOTICE_SORT_ORDER: SortState = {
  field: "publish_date",
  order: "DESC",
};

// This is for the rare case that columns aren't defined in the db - not sure if it happens.
// It just mirrors the relevant notices view columns
export const DEFAULT_NOTICE_COLUMNS: ColumnSetting[] = [
  { field: "relevance_score" },
  { field: "title" },
  { field: "buyer" },
  { field: "stage" },
  { field: "value" },
  { field: "qualification" },
  { field: "assignee" },
  { field: "published_date" },
];

export const ALL_NOTICE_COLUMNS: ColumnSetting[] = [
  { title: "Signal score", field: "relevance_score" },
  { title: "Signals", field: "indicator" },
  { title: "Title & buyer", field: "title", disabled: true },
  { title: "Buyer type", field: "buyer_categories" },
  { title: "Qualification", field: "qualification" },
  { title: "Stage", field: "stage" },
  { title: "Value", field: "value" },
  { title: "Country", field: "country" },
  { title: "Publish date", field: "published_date" },
  { title: "Close date", field: "close_date" },
  { title: "Award date", field: "award_date" },
  { title: "Expiry date", field: "expiry_date" },
  { title: "Supplier(s)", field: "suppliers" },
  { title: "SME suppliers?", field: "is_sme" },
  { title: "Assignee", field: "assignee" },
];

function convertViewToNoticeFilters(
  savedView: SavedViewType,
  isLayerCakeEnabled = false,
): NoticeFilters {
  const { view, isStandardView } = savedView;
  let signals = isLayerCakeEnabled ? [] : DEFAULT_FILTERS.signals;
  if (
    !isLayerCakeEnabled &&
    (view.filterSettings.signals?.categories ||
      view.filterSettings.signalCategory ||
      view.filterSettings.signals?.ids)
  ) {
    signals = [
      ...(view.filterSettings.signals?.categories || []),
      ...(view.filterSettings.signalCategory || []),
      ...(view.filterSettings.signals?.ids || []),
    ];
  }

  let procurementStageQualifications = view.filterSettings.procurementStageQualifications;

  if (isStandardView && !procurementStageQualifications) {
    procurementStageQualifications = DEFAULT_FILTERS.procurementStageQualifications;
  }

  const noticeLists =
    view.filterSettings.noticeLists?.map((noticeList) => noticeList.toString()) || [];

  //TODO: remove this after migrating saved views to use noticeLists instead of noticeList
  if (!view.filterSettings.noticeLists && view.filterSettings.noticeList) {
    noticeLists?.push(view.filterSettings.noticeList.toString());
  }

  return {
    procurementStageQualifications: procurementStageQualifications || [],
    signals,
    assignee: view.filterSettings.assignee || [],
    buyerListIds: view.filterSettings.buyerListIds || [],
    buyers: view.filterSettings.buyerGuids || [],
    buyerCategories: view.filterSettings.buyerCategoryId || [],
    buyerCountryRegions: {
      countries: view.filterSettings.buyerCountryCodes || [],
      regions: view.filterSettings.buyerRegionIds || [],
      selected: [],
    },
    value: {
      to: view.filterSettings.value?.to || undefined,
      from: view.filterSettings.value?.from || undefined,
      hideNulls:
        view.filterSettings.valueNulls === undefined ? false : !view.filterSettings.valueNulls,
    },
    text: view.filterSettings.text || "",
    stage: (view.filterSettings.stage as NoticeFilters["stage"]) || [],
    relevanceScore: view.filterSettings.relevanceScore || [],
    closeDate: {
      filter: {
        ...view.filterSettings.closeDate,
        to: view.filterSettings.closeDate?.to?.toISOString(),
        from: view.filterSettings.closeDate?.from?.toISOString(),
      },
      hideNulls:
        view.filterSettings.closeNulls === undefined ? false : !view.filterSettings.closeNulls,
    },
    expiryDate: {
      filter: {
        ...view.filterSettings.expiryDate,
        to: view.filterSettings.expiryDate?.to?.toISOString(),
        from: view.filterSettings.expiryDate?.from?.toISOString(),
      },
      hideNulls:
        view.filterSettings.expiryNulls === undefined ? false : !view.filterSettings.expiryNulls,
    },
    publishDate: {
      ...view.filterSettings.publishDate,
      to: view.filterSettings.publishDate?.to?.toISOString(),
      from: view.filterSettings.publishDate?.from?.toISOString(),
    },
    sort: {
      field: view.sortOrder?.field || DEFAULT_NOTICE_SORT_ORDER.field,
      order:
        (view.sortOrder?.order as NoticeFilters["sort"]["order"]) ||
        DEFAULT_NOTICE_SORT_ORDER.order,
    },
    suppliers: view.filterSettings.suppliers?.id || [],
    supplierGuids: view.filterSettings.suppliers?.guid || [],
    supplierSme: view.filterSettings.suppliers?.isSme ?? "",
    cpvDimensions: view.filterSettings.cpvDimensions || [],
    noticeLists: noticeLists || [],
    frameworkIds: view.filterSettings.frameworks?.ids || [],
    frameworkFilterOptions:
      convertFrameworkRelationshipFilterToFrameworkFilterOptions(
        view.filterSettings.frameworks?.frameworkRelationship,
      ) || "",
    supplierMentionType: undefined,
    keywords: view.filterSettings.keywords || [],
    excludeKeywords: view.filterSettings.excludeKeywords || [],
    cpvCodes: view.filterSettings.cpvCodes || [],
  };
}

type FiltersArrayKeys = keyof Pick<
  NoticeFilters,
  "signals" | "procurementStageQualifications" | "assignee"
>;

type FiltersObjectKeys = keyof Pick<NoticeFilters, "publishDate">;

function parseUrlState(val?: NoticeFilters, defaultFilters?: NoticeFilters) {
  if (!val) {
    return defaultFilters || DEFAULT_FILTERS;
  }

  // if array values are undefined set as an empty array otherwise default will override
  Object.keys(DEFAULT_FILTERS).forEach((k) => {
    const key = k as keyof NoticeFilters;
    if (
      typeof DEFAULT_FILTERS[key] === "object" &&
      !Array.isArray(DEFAULT_FILTERS[key]) &&
      !val[key]
    ) {
      val[key as FiltersObjectKeys] = {};
    }
    if (Array.isArray(DEFAULT_FILTERS[key]) && !val[key]) {
      val[key as FiltersArrayKeys] = [];
    }
  });

  return {
    ...defaultFilters,
    ...val,
    value: {
      from: parseNumberValue(val.value?.from),
      to: parseNumberValue(val.value?.to),
      hideNulls: parseBooleanValue(val.value?.hideNulls),
    },
    closeDate: {
      ...val.closeDate,
      hideNulls: parseBooleanValue(val.closeDate?.hideNulls),
    },
    expiryDate: {
      ...val.expiryDate,
      hideNulls: parseBooleanValue(val.expiryDate?.hideNulls),
    },
    suppliers: val.suppliers.map((id) => Number(id)) || [],
    supplierGuids: val.supplierGuids || [],
    supplierSme: parseBooleanValue(val.supplierSme) ?? "",
  };
}

function parseOldUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const currentView = urlParams.get("current_view_id");
  const currentPage = urlParams.get("page");
  const pageSize = urlParams.get("page_size");

  const filters: Partial<NoticeFilters> = {};

  filters.text = urlParams.get("text") || undefined;
  filters.buyers = urlParamToArray(urlParams, "buyer_guids");
  filters.stage = urlParamToArray(urlParams, "stage")?.map((s) => s.toUpperCase()) as Status[];
  const closeDate = paramsToNullableDateRangeObj(urlParams, "close_date");
  if (closeDate) {
    filters.closeDate = {
      filter: {
        ...closeDate.filter,
        from: closeDate.filter?.from?.toISOString(),
        to: closeDate.filter?.to?.toISOString(),
      },
      hideNulls: !closeDate.includeNulls,
    };
  }
  const expiryDate = paramsToNullableDateRangeObj(urlParams, "expiry_date");
  if (expiryDate) {
    filters.expiryDate = {
      filter: {
        ...expiryDate.filter,
        from: expiryDate.filter?.from?.toISOString(),
        to: expiryDate.filter?.to?.toISOString(),
      },
      hideNulls: !expiryDate.includeNulls,
    };
  }
  const publishDate = paramsToDateRangeObj(urlParams, "publish_date");
  if (publishDate) {
    filters.publishDate = {
      ...publishDate,
      from: publishDate.from?.toISOString(),
      to: publishDate.to?.toISOString(),
    };
  }
  const signalIds = urlParamToArray(urlParams, "any_signals_ids");
  const signalCategories = urlParamToArray(urlParams, "any_signals_categories");
  if (signalIds || signalCategories) {
    filters.signals = [...(signalIds || []), ...(signalCategories || [])];
  }
  filters.buyerCategories = urlParamToArray(urlParams, "buyer_category_id");
  const value = paramsToNumericRangeObj(urlParams, "value");
  if (value) {
    filters.value = {
      from: value.from,
      to: value.to,
      hideNulls: !value.includeNulls,
    };
  }
  filters.procurementStageQualifications = urlParamToTypedArray(
    urlParams,
    "procurement_stage_qualifications",
    (p) => p as RecordSearchRequestDtoProcurementStageQualificationsEnum,
  );
  filters.assignee = urlParamToArray(urlParams, "assignee");
  filters.buyerListIds = urlParamToArray(urlParams, "buyer_list_ids");

  const sortField = urlParams.get("sort");
  const sortOrder = urlParams.get("sort_order");
  if (sortField || sortOrder) {
    filters.sort = {
      field: sortField || "publish_date",
      order: (sortOrder as NoticeFilters["sort"]["order"]) || "desc",
    };
  }

  const params = {
    noticeFilters: isEqual(filters, {}) ? undefined : filters,
    current_view_id: currentView,
    page: currentPage,
    page_size: pageSize,
  };

  // only check filters as the other params are handled by the useURLState hook
  if (isEqual(filters, {}) || Object.values(filters).every((v) => !v)) {
    return undefined;
  }

  // signal were hidden by default so if they are not set then set them to the default
  if (!filters.signals) {
    filters.signals = DEFAULT_FILTERS.signals;
  }

  return params;
}

export function useMyFeedPageState(isLayerCakeEnabled = false) {
  const { logEvent } = useTracking();

  useEffect(() => {
    const convertedParams = parseOldUrl();
    if (convertedParams) {
      const newQsValue = qs.stringify(convertedParams, { arrayFormat: "brackets" });
      window.location.assign(`/?${newQsValue}`);
    }
  }, []);

  const { data: pinnedViews, isLoading: isLoadingPinned } = usePinnedViews();

  const { data: allViews, isLoading: isLoadingAllViews } = useAllViews();
  const defaultView = allViews?.find((v) => v.id === pinnedViews?.[0].savedViewId) || allViews?.[0];

  const isLoadingViews = isLoadingPinned || isLoadingAllViews;

  const [urlViewId, setUrlViewId] = useURLState("current_view_id", "");
  const urlView = allViews?.find((v) => v.id === urlViewId);

  const [recentViews, setRecentViews] = useLocalStorage<string[]>("RECENT_VIEWS", []);
  const recentView = recentViews[0] ? allViews?.find((v) => v.id === recentViews[0]) : undefined;

  // used to determine when a user purposely deselects a view
  const [showNoView, setShowNoView] = useURLState<boolean | undefined>(
    "showNoView",
    false,
    parseBooleanValue,
  );

  const selectedView = showNoView ? undefined : urlView || recentView || defaultView;

  const [filters, setFilters] = useURLState<NoticeFilters | undefined>(
    "noticeFilters",
    DEFAULT_FILTERS,
    (val) =>
      parseUrlState(
        val as NoticeFilters,
        selectedView && convertViewToNoticeFilters(selectedView, isLayerCakeEnabled),
      ),
  );

  // Report pages generate search links with buyer id filters.
  // These are no longer supported by the search page, so we need to fetch
  // the buyers by id to convert them to buyer GUID filters.
  // Unfortunately converting reports to use buyer GUIDS is signficantly harder than
  // this small hack which will only affect the one case of links from reports,
  // as no other part of code uses buyer id filters.
  //
  // TODO: Remove this once we get rid of buyer id filters passed in from the report pages
  const api = useStotlesApi();
  useEffect(() => {
    if (!filters?.buyerIds || filters.buyerIds.length == 0) {
      return;
    }
    const buyerIds = filters.buyerIds;
    // Run this async - there is unfortunately a small risk of first fetching
    // the incorrect data and then doing the right search once the filters are updated.
    void (async () => {
      const searchBuyersResponse = await api.searchBuyers({
        id: buyerIds,
        limit: buyerIds.length,
      });

      setFilters(
        (oldFilters) =>
          oldFilters && {
            ...oldFilters,
            buyerIds: undefined,
            buyers: searchBuyersResponse.results.map((b) => b.guid),
          },
      );
    })();
  }, [api, filters?.buyerIds, setFilters]);

  const [urlTableColumns, setUrlTableColumns] = useURLState<ColumnSettingFieldEnum[] | undefined>(
    "noticeTableColumns",
    undefined,
  );

  let tableColumns;
  if (urlTableColumns) {
    tableColumns = urlTableColumns.map((field) => ({ field }));
  } else if (
    selectedView?.view.tableSettings.columns &&
    selectedView?.view.tableSettings.columns.length !== 0
  ) {
    tableColumns = selectedView.view.tableSettings.columns;
  } else {
    tableColumns = DEFAULT_NOTICE_COLUMNS;
  }

  const setSelectedView = (viewId?: string, viewName?: string, context?: string | undefined) => {
    if (!viewId) {
      setShowNoView(true);
      setUrlViewId("");
      setFilters(EMPTY_FILTERS);
      setUrlTableColumns(undefined);
      return;
    }

    setUrlViewId(viewId);
    if (viewId) {
      setRecentViews([viewId, ...recentViews.filter((v) => v !== viewId)]);
    }
    setFilters(undefined);
    setShowNoView(undefined);
    setUrlTableColumns(undefined);
    viewId &&
      logEvent(EventNames.viewSelected, createSavedViewSelectedEvent(viewName, viewId, context));
  };

  const clearViewAndFilters = () => {
    setShowNoView(true);
    setUrlViewId("");
    // clear all filters except text
    setFilters({ ...EMPTY_FILTERS, text: filters?.text || "" });
    setUrlTableColumns(undefined);
  };

  const viewAsNoticeFilter =
    selectedView && convertViewToNoticeFilters(selectedView, isLayerCakeEnabled);

  const filterValue = filters || viewAsNoticeFilter || DEFAULT_FILTERS;

  const hasUserModifiedFilters = filters ? !isEqual(filters, viewAsNoticeFilter) : false;
  const hasUserModifiedTableSettings = !isEqual(
    tableColumns,
    selectedView?.view.tableSettings.columns,
  );
  const hasUserModifiedView = hasUserModifiedFilters || hasUserModifiedTableSettings;

  return {
    pinnedViews,
    allViews,
    selectedView,
    setSelectedView,
    filters: filterValue,
    setFilters,
    clearViewAndFilters,
    isLoadingViews,
    hasUserModifiedView,
    hasUserModifiedFilters,
    hasUserModifiedTableSettings,
    tableColumns,
    setTableColumns: setUrlTableColumns,
  };
}

/**
 *
 * @param message
 * Checks that the view in the URL is available to the user and pins it if isnt pinned.
 */
export function useViewUrlCheck(message: MessageInstance) {
  const { data: allViews } = useAllViews();
  const { data: pinnedViews } = usePinnedViews();
  const [urlViewId, setUrlViewId] = useURLState("current_view_id", "");
  const { mutate: pinView } = usePinView();

  useEffect(() => {
    if (
      urlViewId &&
      pinnedViews &&
      allViews &&
      !pinnedViews.find((v) => v.savedViewId === urlViewId)
    ) {
      const view = allViews.find((v) => v.id === urlViewId);
      if (view && view.id) {
        return pinView({ id: view.id, name: view.name });
      }
      void message.error("The view in your URL is not available.");
      return setUrlViewId("");
    }
  }, [allViews, message, pinView, pinnedViews, setUrlViewId, urlViewId]);
}
