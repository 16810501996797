/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect } from "react";
import { hot } from "react-hot-loader/root";
import { Route, Router, Switch, useLocation, useRoute } from "wouter";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsHeader, DetailsPage } from "components/app_layout/DetailsLayout";
import { BuyerFilter } from "components/buyers/BuyerFilters";
import { BuyerList } from "components/buyers/BuyerList";
import BuyerLists from "components/buyers/BuyerLists";
import { FilterableBuyerTable } from "components/buyers/FilterableBuyerTable";
import { BuyerListRoute } from "components/buyers/FindBuyersCommon";
import { DEFAULT_PAGE_PARAMS } from "components/buyers/paramUtils";
import { RelevantBuyers } from "components/buyers/RelevantBuyers";
import { DEFAULT_BUYER_FILTERS } from "components/buyers/useFilterableBuyerTableState";
import { LAYER_CAKE_FILTERS, useVariableValue } from "lib/featureFlags";
import { TrackingProvider } from "lib/tracking";

import css from "./BuyersPage.module.scss";

// Because it's wouter version 2.x, we can't pass props to the component - so had to make 2 components
function BuyersPageWrapper() {
  return (
    <Router base={`/buyers`}>
      <Switch>
        <Route path="/lists/:listId">{(params) => <BuyersListPage id={params.listId} />}</Route>
        <Route path="/lists" component={BuyersListsPage} />
        <Route path="/:tab+" component={BuyersPage} />
      </Switch>
    </Router>
  );
}

/**
 * This page displays the contents of an individual buyer list when it is navigated to from the BuyersListsPage
 * @returns
 */
function BuyersListPage({ id }: { id: string }) {
  useEffect(() => {
    document.title = "Buyer list";
  }, []);
  return (
    <DetailsPage>
      <BuyerList id={id} />
    </DetailsPage>
  );
}

/**
 * Displays the buyer lists for a user's team and their privately created buyer lists
 * @param param0
 * @returns
 */
function BuyersListsPage() {
  useEffect(() => {
    document.title = "Buyer lists";
  }, []);
  return (
    <DetailsPage>
      <BuyerLists />
    </DetailsPage>
  );
}

/**
 * The content for the Find buyers section of the buyer side of the app. Contains All buyers & Relevant buyers
 * @returns
 */
function BuyersPage() {
  useEffect(() => {
    document.title = "Find buyers";
  }, []);
  const [location] = useLocation();

  // Putting filters in this parent component so that search can persist when switching from
  // relevant buyers to all buyers
  const [currentFilters, setCurrentFilters] = React.useState<BuyerFilter>(DEFAULT_PAGE_PARAMS);

  const isLayerCakeFiltersEnabled = useVariableValue(LAYER_CAKE_FILTERS, false);

  return (
    <DetailsPage>
      <DetailsHeader>
        <h3 className={css.buyerTitle}>Find buyers</h3>
      </DetailsHeader>
      <>
        {location === BuyerListRoute.RELEVANT_BUYERS ? (
          <RelevantBuyers
            currentFilters={currentFilters}
            onCurrentFiltersChange={setCurrentFilters}
          />
        ) : (
          <TrackingProvider data={{ "Context source": "In-row" }}>
            <div className={css.buyerTable}>
              <FilterableBuyerTable
                defaultFilters={DEFAULT_BUYER_FILTERS}
                hiddenSections={isLayerCakeFiltersEnabled ? ["signals"] : undefined}
              />
            </div>
          </TrackingProvider>
        )}
      </>
    </DetailsPage>
  );
}

export default hot(
  withAppLayout(BuyersPageWrapper, () => {
    const [, params] = useRoute<{ id: string }>("/buyers/lists/:id");

    return {
      trackingData: { "List id": params?.id },
    };
  }),
);
