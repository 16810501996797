import { useState } from "react";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";

import { useNoticeDocumentUploadUrl } from "./useNoticeDocumentUploadUrl";

type UploadDocument = {
  file: File;
  noticeId: string;
  // documentType: string;
};
export function useUploadNoticeDocument(
  options?: UseMutationOptions<void, AxiosError, UploadDocument, unknown>,
) {
  const [uploadProgress, setUploadProgress] = useState(0);
  const { mutateAsync: getUrl } = useNoticeDocumentUploadUrl();
  const mutation = useMutation<void, AxiosError, UploadDocument>(async ({ file, noticeId }) => {
    const {
      uploadNoticeDocument: { signedUrl },
    } = await getUrl({
      input: { noticeId, documentName: file.name, documentType: file.type },
    });

    return axios.put(signedUrl, file, {
      headers: {
        "Content-Type": "application/octet-stream",
        "Access-Control-Allow-Origin": "*",
      },
      onUploadProgress(progressEvent) {
        progressEvent.progress && setUploadProgress(Math.floor(progressEvent.progress * 100));
      },
    });
  }, options);

  return { ...mutation, uploadProgress };
}
