import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldValues, useController } from "react-hook-form";
import { Button, Input } from "antd5";
import classNames from "classnames";

import { createUseDebounce } from "lib/debounce";
import Signal from "lib/icons/Signal";
import { red500 } from "lib/themes/colors";
import { useSignalSettingsGQL } from "../../../lib/hooks/api/teams/useSignalSettingsGQL";
import { searchRefraction, slashCircle1 } from "../../../lib/icons/untitled_ui/SVGs";
import UIcon from "../../../lib/icons/untitled_ui/UIcon";
import { SelectProps } from "../Inputs";
import KeywordDropdownMenu from "./KeywordDropdownMenu";
import SelectedKeywordTags from "./SelectedKeywordTags";

// eslint-disable-next-line css-modules/no-unused-class
import css from "./KeywordSelect.module.scss";

type FieldProps<T extends FieldValues> = Omit<SelectProps<T>, "options"> & {
  containerClassName?: string;
  type?: "keywords" | "excludeKeywords";
};

export function KeywordSelect<T extends FieldValues>({
  type = "keywords",
  ...props
}: FieldProps<T>) {
  const [searchText, setSearchText] = useState<string>("");

  const containerRef = useRef<HTMLDivElement>(null);

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const useDebounce = createUseDebounce(500);

  const delayDropdownOpen = useDebounce(setDropdownOpen);

  useEffect(() => {
    /**
     * If there's a click outside of this component, close the dropdown
     * @param event
     */
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        delayDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [delayDropdownOpen, dropdownOpen]);

  const { field } = useController(props);

  const { value, onChange } = field;

  const { data: signals, isLoading } = useSignalSettingsGQL();

  const allKeywords = useMemo((): string[] => {
    if (type === "excludeKeywords") {
      return [];
    }

    return signals?.keywords || [];
  }, [signals?.keywords, type]);

  const selectedKeywords: string[] = value || [];

  const { containerClassName, label } = props;

  const showIconForTag = useCallback(
    (keyword: string): boolean => {
      // Always show icon for exclude keyword
      if (type === "excludeKeywords") {
        return true;
      }

      // Only show icon when keyword is saved as a signal
      return allKeywords.includes(keyword);
    },
    [allKeywords, type],
  );

  return (
    <div className={classNames(css.container, containerClassName)} ref={containerRef}>
      <div className={css.filterLabelDiv} aria-label="filterLabel">
        <span className={css.filterName}>{label}</span>

        {selectedKeywords && selectedKeywords.length > 0 && (
          <Button
            className={css.clearButton}
            onClick={() => {
              onChange([]);
              delayDropdownOpen(false);
            }}
          >
            Clear
          </Button>
        )}
      </div>
      <Input
        aria-label="keywordSearchInput"
        className={css.inputField}
        onPressEnter={(e) => {
          e.preventDefault();
          if (selectedKeywords.includes(searchText)) {
            onChange([selectedKeywords.filter((v: string) => v !== searchText)]);
          } else {
            onChange([...selectedKeywords, searchText]);
          }

          setSearchText("");
          delayDropdownOpen(false);
        }}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        onChange={(e) => {
          const searchText = e.currentTarget.value;

          // if we're typing, open the dropdown
          if (searchText.length > 0 && !dropdownOpen) {
            setDropdownOpen(true);
          }

          setSearchText(searchText);
        }}
        placeholder={type === "keywords" ? "Search keywords" : "Type and press enter"}
        prefix={<UIcon svg={searchRefraction} size={16} />}
        value={searchText}
      />
      <div className={css.dropdownAndTags}>
        <KeywordDropdownMenu
          allKeywords={allKeywords}
          dropdownOpen={dropdownOpen}
          onChange={onChange}
          selectedKeywords={selectedKeywords}
          searchText={searchText}
          type={type}
        />
        <SelectedKeywordTags
          selectedKeywords={selectedKeywords}
          tooltipTitle={type === "keywords" ? "Keyword signal" : undefined}
          isLoading={isLoading}
          onChange={onChange}
          closeDropdown={() => delayDropdownOpen(false)}
          icon={
            type === "excludeKeywords" ? (
              <UIcon className={css.icon} svg={slashCircle1} size={16} colour={red500} />
            ) : (
              <Signal className={css.icon} size={16} label="signalIcon" />
            )
          }
          showIconForTag={showIconForTag}
          tagClassname={type === "excludeKeywords" ? css.excludeTag : undefined}
        />
      </div>
    </div>
  );
}
