import * as React from "react";
import { InfoCircleOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Alert, Button, ConfigProvider, Skeleton, Tooltip } from "antd5";
import classnames from "classnames";

import BookmarkIcon from "components/actions/Bookmark";
import { TextLink } from "components/actions/Links";
import { SendNoticeToCRM } from "components/integrations/SendNoticeToCRMButton";
import ShareModal from "components/modals/ShareModal";
import { mapToTenderStage } from "components/notices/utils";
import SaveRecordPopoverWrapper from "components/user_lists/SaveRecord";
import RecordStage from "lib/core_components/RecordStage";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { useFrameworkUrlInformation } from "lib/hooks/api/frameworks/useFrameworkUrlInformation";
import { useNoticeListsForRecord } from "lib/hooks/api/notices/lists/useNoticeListsForRecord";
import { NoticeDetails } from "lib/hooks/api/useNotice";
import { useRecordHighlighting } from "lib/hooks/useRecordHighlighting";
import { useDialogManager } from "lib/providers/DialogManager";
import { useSubscription } from "lib/providers/Subscription";
import { BlueSkeleton } from "lib/themes/BlueSkeleton";
import { EventNames, useTracking } from "lib/tracking";
import { BasicSignal, RecordListSummary, TenderStage } from "lib/types/models";
import { assertDefined } from "lib/utils";
import { toHumanRelativeTimeFromNow } from "lib/utils/relative_time";
import { FrameworkProcessType, NoticesStages as Stages } from "../../lib/types/graphQLEnums";
import { NoticeSeenBy } from "./SeenBy";

import css from "./RecordHeader.module.scss";

function getRecordDate(notice: NoticeDetails) {
  switch (notice.stage) {
    case Stages.Awarded:
      return notice.expiryDate;
    case Stages.Open:
    case Stages.Closed:
      return notice.closeDate;
    default:
      return notice.publishDate;
  }
}

function getRelativeTimeLabel(stage: Stages, isFuture: boolean) {
  switch (stage) {
    case Stages.Awarded:
      return isFuture ? "Expiring" : "Expired";
    case Stages.Open:
    case Stages.Closed:
      return isFuture ? "Closing" : "Closed";
    default:
      return "Published";
  }
}

function getEmptyDateLabel(stage: Stages) {
  switch (stage) {
    case Stages.Awarded:
      return "expiry";
    case Stages.Open:
    case Stages.Closed:
      return "close";
    default:
      return "publish";
  }
}

function getRelativeTimeFromNow(notice: NoticeDetails): string | null {
  assertDefined(notice.stage);

  const recordDate = getRecordDate(notice);

  if (!recordDate) {
    return `Unknown ${getEmptyDateLabel(notice.stage)} date`;
  }

  const timeFromNow = toHumanRelativeTimeFromNow(recordDate);

  const isFuture = new Date(recordDate).getTime() > Date.now();

  const label = getRelativeTimeLabel(notice.stage, isFuture);

  return `${label} ${timeFromNow}`;
}

function getNoticeListTooltip(noticeLists: RecordListSummary[]): string {
  if (noticeLists.length > 1) {
    return `Saved to "${noticeLists[0].name}" & ${noticeLists.length - 1} more`;
  }
  if (noticeLists.length === 1) {
    return `Saved to "${noticeLists[0].name}"`;
  }
  return "Save notice";
}

type SaveNoticeProps = {
  recordGuid: string;
  recordStage: TenderStage;
  signals: BasicSignal[];
  contextSource: "Title header" | "Record preview slide-out" | "In-table row" | "In-table dropdown";
};

function SaveNoticeButton({
  recordGuid,
  recordStage,
  signals,
  contextSource,
}: SaveNoticeProps): JSX.Element {
  const { data: recordLists } = useNoticeListsForRecord(recordGuid);

  return (
    <SaveRecordPopoverWrapper
      recordGuid={recordGuid}
      recordStage={recordStage}
      signals={signals}
      placement="bottom"
      contextSource={contextSource}
      trigger={
        <Tooltip title={getNoticeListTooltip(recordLists || [])}>
          <Button
            icon={<BookmarkIcon colour="blue" filled={!recordLists || recordLists?.length > 0} />}
            title="Save notice"
          />
        </Tooltip>
      }
    />
  );
}

function getFrameworkRelatedAlertMessage(processType?: FrameworkProcessType | null) {
  let message = "This notice is part of a framework process,";

  if (processType == FrameworkProcessType.FrameworkCallOff) {
    message = "This notice is a call-off as part of a framework,";
  }

  return message;
}

type NoticeHeaderProps = {
  notice: NoticeDetails;
  trackingContextName: SaveNoticeProps["contextSource"];
  disableTitleLink?: boolean;
  showStage?: boolean;
  headerClassName?: string;
  scrollClassName?: string;
  isTopOfPageVisible?: boolean;
};

export default function NoticeHeader({
  notice,
  trackingContextName,
  disableTitleLink,
  showStage = true,
  headerClassName,
  scrollClassName,
  isTopOfPageVisible,
}: NoticeHeaderProps) {
  assertDefined(notice.stage);

  const { logEvent } = useTracking();
  const dialogManager = useDialogManager();

  const subscription = useSubscription();
  const hasFrameworks =
    subscription.hasDataTypes("FRAMEWORKS") && FeatureToggles.isEnabled(Feature.FRAMEWORK_DETAILS);

  const { recordTitle } = useRecordHighlighting(
    notice.guid,
    notice.name ?? "",
    notice.description ?? "",
  );

  const frameworkId = notice.relatedFrameworkProcess?.frameworkId;
  const frameworkProcessType = notice.relatedFrameworkProcess?.processType;
  const { data: framework, isLoading } = useFrameworkUrlInformation(frameworkId ?? "", {
    enabled: !!frameworkId && hasFrameworks,
  });

  const handleShareModalOpen = React.useCallback(() => {
    logEvent(EventNames.shareEmailModalOpen, { "Record GUIDs": [notice.guid] });
    dialogManager.openDialog(ShareModal, {
      records: [
        {
          guid: notice.guid,
          signals: notice.signals ?? [],
          stage: notice.stage,
          score: notice.score ?? 0,
        },
      ],
    });
  }, [dialogManager, notice, logEvent]);

  const headerClass = classnames(
    headerClassName,
    scrollClassName && !isTopOfPageVisible && scrollClassName,
  );

  const timeFromNowLabel = getRelativeTimeFromNow(notice);

  return (
    <div className={headerClass}>
      <div className={css.headerColumn}>
        <div className={css.recordHeaderContent}>
          <div className={css.titleContainer}>
            {showStage && (
              <div className={css.stage}>
                <RecordStage stage={mapToTenderStage(notice.stage)} />
                <div className={css.date}>
                  •<div>{timeFromNowLabel}</div>
                </div>
              </div>
            )}
            <div aria-label="Record link">
              <TextLink
                className={css.title}
                targetType="new-tab"
                to={`/records/${notice.guid}`}
                disabled={disableTitleLink}
              >
                {recordTitle}
              </TextLink>
              <NoticeSeenBy
                seenByUsers={notice.seenByUsers}
                displayNumber={true}
                className={css.seenBy}
              />
            </div>
          </div>
          {!window.guestUser && (
            <div className={css.actions}>
              <SaveNoticeButton
                recordGuid={notice.guid}
                recordStage={mapToTenderStage(notice.stage)}
                signals={notice.signals ?? []}
                contextSource={trackingContextName}
              />
              <Tooltip title="Share via email">
                <Button
                  onClick={handleShareModalOpen}
                  icon={<ShareAltOutlined className={css.shareIcon} />}
                  title="Share via email"
                />
              </Tooltip>
              <SendNoticeToCRM
                notice={{
                  guid: notice.guid,
                  name: notice.name ?? "",
                  value: notice.value ?? undefined,
                  url: notice.url,
                  closeDate: notice.closeDate ?? "",
                  type: notice.entityType ?? undefined,
                }}
              />
            </div>
          )}
        </div>
        {!!framework && (
          <Alert
            className={css.frameworkInfo}
            message={
              <span aria-label="Framework agreement info">
                <InfoCircleOutlined className={css.infoIcon} />
                {isLoading ? (
                  <ConfigProvider theme={BlueSkeleton}>
                    <Skeleton.Input active style={{ height: "16px", width: "100%" }} />
                  </ConfigProvider>
                ) : (
                  <>
                    {getFrameworkRelatedAlertMessage(frameworkProcessType)}{" "}
                    <TextLink className={css.frameworkLink} to={`/frameworks/${framework.id}`}>
                      {framework.title}
                    </TextLink>
                  </>
                )}
              </span>
            }
            type="info"
          />
        )}
      </div>
    </div>
  );
}
