import * as React from "react";
import { ConfigProvider, Segmented } from "antd5";
import { TooltipProps } from "antd5/lib/tooltip";

import { ViewConfigurationFilterSettingsRelevanceScoreEnum } from "lib/generated/app-api";
import RelevanceIcon from "lib/icons/Relevance";
import { RelevanceButton } from "lib/themes/RelevanceConfig";
import { SIGNAL_SCORE_MAP } from "lib/utils/signalUtils";

import css from "./NewRelevanceScoreButtons.module.scss";

type Props = {
  onChange: (values: ViewConfigurationFilterSettingsRelevanceScoreEnum[]) => void;
  id?: string;
  value: RelevanceScoreEnum[];
  tooltipPlacement?: TooltipProps["placement"];
};

type RelevanceScoreEnum = "None" | "Low" | "Medium" | "High";

// Because this is used within Form.Item we have to wrap it in a forwardRef
export const NewRelevanceScoreButtons = React.forwardRef<HTMLDivElement, Props>(
  ({ onChange, id, value }, ref) => {
    const handleSelect = React.useCallback(
      (stage: RelevanceScoreEnum) => {
        if (value.includes(stage)) {
          onChange([]);
        } else {
          onChange([stage]);
        }
      },
      [value, onChange],
    );

    const isSelected = (val: string) =>
      Object.values(value).includes(val as ViewConfigurationFilterSettingsRelevanceScoreEnum);

    return (
      <div className={css.relevanceScoreButtonContainer} id={id} ref={ref}>
        <ConfigProvider theme={RelevanceButton}>
          <Segmented
            defaultValue={"NO_OP"}
            onChange={(value) =>
              handleSelect(value as ViewConfigurationFilterSettingsRelevanceScoreEnum)
            }
            options={Object.keys(ViewConfigurationFilterSettingsRelevanceScoreEnum)
              .reverse() // Reverses the order of keys, now it's from highest to lowest
              .map((key) => {
                const score =
                  SIGNAL_SCORE_MAP.get(key as ViewConfigurationFilterSettingsRelevanceScoreEnum) ||
                  0;

                return {
                  label: (
                    <span key={key} className={css.newRelevanceScoreButton}>
                      <RelevanceIcon darkMode={isSelected(key)} relevance={score} />
                      <b>
                        {score}
                        {score < 3 && "+"}
                      </b>
                    </span>
                  ),
                  value: key,
                };
              })}
          />
        </ConfigProvider>
      </div>
    );
  },
);
