import React from "react";
import { Form } from "antd"; //form upgrade
import { FormComponentProps } from "antd/lib/form";
import { Input, message, Modal, Radio } from "antd5";

import { useAdminCreateBuyerList } from "lib/hooks/api/buyer_lists/admin";
import {
  BuyerContextSource,
  useCreateBuyerList,
} from "lib/hooks/api/buyer_lists/useCreateBuyerList";

export type UserModalProps = {
  isOpen: boolean;
  onClose: () => void;
  buyerGuids: string[];
  contextSource: BuyerContextSource;
};

export function UserCreateBuyerListModal(props: UserModalProps) {
  const { isLoading, mutate } = useCreateBuyerList({
    options: {
      onSuccess: () => {
        void message.success("Buyer list created successfully!");
        props.onClose();
      },
      onError() {
        void message.error("Failed to create buyer list");
      },
    },
    contextSource: props.contextSource,
  });

  return <CreateBuyerListForm {...props} mutate={mutate} isMutating={isLoading} />;
}

type AdminProps = {
  isOpen: boolean;
  onClose: () => void;
  userGuid: string;
};

export function AdminCreateBuyerListModal(props: AdminProps) {
  const { isLoading, mutate } = useAdminCreateBuyerList({
    options: {
      onSuccess: () => {
        void message.success("Buyer list created successfully!");
        props.onClose();
      },
      onError() {
        void message.error("Failed to create buyer list");
      },
    },
  });

  return (
    <CreateBuyerListForm
      {...props}
      buyerGuids={[]}
      mutate={(values) => mutate({ ...values, userGuid: props.userGuid })}
      isMutating={isLoading}
    />
  );
}

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  buyerGuids: string[];
  mutate: (props: { name: string; sharingType: string; buyerGuids?: string[] }) => void;
  isMutating: boolean;
};
type Props = ModalProps & FormComponentProps;

function CreateBuyerListModal({ isOpen, onClose, form, buyerGuids, mutate, isMutating }: Props) {
  const { getFieldDecorator, getFieldValue } = form;
  const typeValue = getFieldValue("type");
  const onSubmit = () => {
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      mutate({
        name: values.name,
        sharingType: values.type,
        buyerGuids: buyerGuids,
      });
    });
  };

  return (
    <Modal
      title="Create new buyer list"
      open={isOpen}
      onCancel={onClose}
      okText={isMutating ? "Creating..." : "Create list"}
      onOk={onSubmit}
      okButtonProps={{ disabled: isMutating }}
      cancelButtonProps={{ disabled: isMutating }}
    >
      <Form layout="vertical" hideRequiredMark>
        <Form.Item label="List name">
          {getFieldDecorator("name", {
            rules: [{ required: true, message: "List name is required" }],
          })(<Input autoFocus />)}
        </Form.Item>
        <Form.Item
          label="Shared with"
          help={
            <p style={{ marginTop: "12px" }}>
              {typeValue === "TEAM"
                ? "Everyone can view and edit this list"
                : "Only visible to you and those that you invite"}
            </p>
          }
        >
          {getFieldDecorator("type", { initialValue: "PRIVATE" })(
            <Radio.Group>
              <Radio value="PRIVATE">Private</Radio>
              <Radio value="TEAM">Team</Radio>
            </Radio.Group>,
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}

const CreateBuyerListForm = Form.create<Props>({ name: "create_buyer_list" })(CreateBuyerListModal);
