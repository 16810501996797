import * as React from "react";

import CentredSpinner from "lib/core_components/CentredSpinner";
import { EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { RecordDtoRelatedFrameworkProcessDtoProcessTypeEnum as ProcessType } from "lib/generated/app-api";
import { useFrameworkUrlInformation } from "lib/hooks/api/frameworks/useFrameworkUrlInformation";

import css from "./FrameworkCell.module.scss";

type Props = {
  processType: ProcessType;
  frameworkId: string;
};
export function FrameworkCell({ processType, frameworkId }: Props): JSX.Element | null {
  const { data: framework, isLoading } = useFrameworkUrlInformation(frameworkId);

  if (isLoading) {
    return <CentredSpinner />;
  }

  return framework?.title ? (
    <div className={css.frameworkColumnContainer}>
      <EllipsisTooltipTextLink
        fullText={framework?.title}
        linkProps={{
          to: `/frameworks/${frameworkId}`,
          className: css.titleText,
        }}
        linkText={framework?.title}
        tooltipProps={{ overlayClassName: css.tooltip }}
        containerClassname={css.frameworkTitle}
      />
      <span className={css.processTypeCell}>
        {processType === ProcessType.Framework ? "Framework agreement" : "Call-off"}
      </span>
    </div>
  ) : null;
}
