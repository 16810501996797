import React from "react";
import { Popover } from "antd"; // upgrade and verify
import { PopoverProps } from "antd/lib/popover";

import css from "./InfoTooltip.module.scss";

export type InfoTooltipProps = React.PropsWithChildren<{
  title: string;
  content: string | React.ReactNode;
  buttons?: React.ReactNode;
  trigger?: PopoverProps["trigger"];
  placement?: PopoverProps["placement"];
}>;

export function InfoTooltip(props: InfoTooltipProps) {
  return (
    <Popover
      overlayClassName={css.overlay}
      content={
        <div className={css.content}>
          <h1 className={css.title}>{props.title}</h1>
          {props.content}
          {props.buttons}
        </div>
      }
      trigger={props.trigger}
      placement={props.placement}
    >
      {props.children}
    </Popover>
  );
}
