import React from "react";
import { Control } from "react-hook-form";
import { Divider, Tooltip } from "antd5";

import RedactedWrapper from "../../../lib/core_components/RedactedWrapper";
import { FrameworkSelect } from "../../form_components/FrameworkSelect";
import { Radio } from "../../form_components/Inputs";
import { FrameworkFilterOptions } from "../../record_search/types";
import { NoticeFilters } from "../utils";

import css from "./Frameworks.module.scss";

export interface FrameworksFilterContentProps {
  control: Control<NoticeFilters, unknown>;
}

function FrameworksFilterContent({ control }: FrameworksFilterContentProps) {
  return (
    <div className={css.filterSection} aria-label="Framework filters">
      <FrameworkSelect
        name="frameworkIds"
        label=""
        control={control}
        mode="multiple"
        placeholder="Search frameworks..."
        allowClear
      />
      <Divider style={{ margin: "8px 0" }} />
      <RedactedWrapper
        redactContent={
          <Radio
            label=""
            name={"frameworkFilterOptions"}
            control={control}
            disabled
            options={[
              { label: "Include all framework activity", value: "" },
              {
                label: (
                  <Tooltip
                    placement="left"
                    title="Show only call-offs that relate to frameworks agreements"
                    overlayClassName={css.tooltip}
                  >
                    Show only call-offs
                  </Tooltip>
                ),
                value: FrameworkFilterOptions.SHOW_ONLY_FRAMEWORK_CALL_OFFS,
              },
              {
                label: (
                  <Tooltip
                    placement="left"
                    title="Any pre-tenders, tenders or contract awards that relate to the creation of a framework will be hidden. Call-offs within frameworks will still appear."
                    overlayClassName={css.tooltip}
                  >
                    Hide framework agreement notices
                  </Tooltip>
                ),
                value: FrameworkFilterOptions.HIDE_FRAMEWORK_AGREEMENT_NOTICES,
              },
              {
                label: (
                  <Tooltip
                    placement="left"
                    title="Any notices relating to framework agreements and their underlying call-offs will be hidden."
                    overlayClassName={css.tooltip}
                  >
                    Hide all framework activity
                  </Tooltip>
                ),
                value: FrameworkFilterOptions.HIDE_ALL_FRAMEWORK_ACTIVITY,
              },
            ]}
            optionType="default"
            direction="vertical"
          />
        }
        // TODO: Add framework paywall here
        //   requiredDataType="SUPPLIERS"
        contextSource="Framework activity type filter"
      >
        <Radio
          label=""
          name={"frameworkFilterOptions"}
          control={control}
          options={[
            { label: "Include all framework activity", value: "" },
            {
              label: (
                <Tooltip
                  placement="left"
                  title="Show only call-offs that relate to frameworks agreements"
                  overlayClassName={css.tooltip}
                >
                  Show only call-offs
                </Tooltip>
              ),
              value: FrameworkFilterOptions.SHOW_ONLY_FRAMEWORK_CALL_OFFS,
            },
            {
              label: (
                <Tooltip
                  placement="left"
                  title="Any pre-tenders, tenders or contract awards that relate to the creation of a framework will be hidden. Call-offs within frameworks will still appear."
                  overlayClassName={css.tooltip}
                >
                  Hide framework agreement notices
                </Tooltip>
              ),
              value: FrameworkFilterOptions.HIDE_FRAMEWORK_AGREEMENT_NOTICES,
            },
            {
              label: (
                <Tooltip
                  placement="left"
                  title="Any notices relating to framework agreements and their underlying call-offs will be hidden."
                  overlayClassName={css.tooltip}
                >
                  Hide all framework activity
                </Tooltip>
              ),
              value: FrameworkFilterOptions.HIDE_ALL_FRAMEWORK_ACTIVITY,
            },
          ]}
          optionType="default"
          direction="vertical"
        />
      </RedactedWrapper>
    </div>
  );
}

export default FrameworksFilterContent;
