import * as React from "react";
import { Select } from "antd"; //upgrade and verify
import classnames from "classnames";

import { InfoButton } from "components/actions/InfoButton";
import { LabeledValue } from "components/form_components/AutocompleteMultiselect";
import SupplierMultiselect from "components/form_components/SupplierMultiselect";
import { countriesTranslationLayer, CountryOptions } from "components/onboarding/onboardingUtils";
import { MatchCountMap } from "components/onboarding/SharedOnboarding";
import { getCountLabel } from "lib/previewQueryUtils";
import { SupplierSummary } from "lib/types/models";

import css from "components/form_components/SupplierTagMultiselect.module.scss"; // ant-5 change to relative path and use classnames object? lint fails here otherwise chack with michael if unsure

const { Option } = Select;

type Props = {
  countries?: string[];
  id?: string;
  supplierCounts?: MatchCountMap;
  supplierType: "partner" | "competitor";
  supplierNames: string[] | undefined;
  onSupplierNamesChange: (newNames: string[]) => void;
  selectionType: "NAME" | "ID";
  supplierIds: number[] | undefined;
  onSupplierIdsChange: (newIds: number[]) => void;
};

function SupplierTagMultiselect({
  supplierCounts,
  supplierType,
  countries,
  selectionType,
  supplierNames,
  onSupplierNamesChange,
  ...rest
}: Props): JSX.Element {
  const renderOption = React.useCallback(
    (s: SupplierSummary | LabeledValue<number>) => {
      const supplierName = "name" in s ? s.name : (s.label as string);
      const supplierId = "id" in s ? s.id : (s.key as number);
      const supplierCount = supplierCounts?.[supplierName];
      const countLabel = getCountLabel({
        count: supplierCount,
        value: supplierName,
        warning: undefined,
      });
      return (
        <Option key={supplierId} value={supplierId} label={countLabel}>
          {supplierName}
        </Option>
      );
    },
    [supplierCounts],
  );

  return (
    <div className={classnames(css.supplierTagMultiselect, css[supplierType])}>
      {selectionType === "ID" ? (
        <SupplierMultiselect
          customPlaceholder="Type organisation names (e.g. Google, Amazon)..."
          optionLabelProp="label"
          renderOption={renderOption}
          countries={countriesTranslationLayer(countries as CountryOptions[])}
          noMaxTagCount
          {...rest}
        />
      ) : (
        <Select
          mode="tags"
          value={supplierNames}
          onChange={onSupplierNamesChange}
          optionLabelProp="label"
          style={{ width: "100%" }}
          placeholder="Type organisation names (e.g. Google, Amazon)..."
          tokenSeparators={[",", "\n", ";"]}
        >
          {supplierNames?.map((n) => {
            const count = supplierCounts?.[n];
            const label = `${n}${count ? " (" + count + ")" : ""}`;
            return (
              <Option key={n} value={n} label={label}>
                {n}
              </Option>
            );
          })}
        </Select>
      )}
    </div>
  );
}

export default SupplierTagMultiselect;

type ExplanationProps = {
  supplierType: "partner" | "competitor";
  useSupplierName: boolean;
};

export function SupplierTagMultiselectExplanation({
  supplierType,
  useSupplierName,
}: ExplanationProps): JSX.Element {
  const text = React.useMemo(() => {
    let typeSpecific = "";
    if (supplierType === "partner") {
      typeSpecific =
        "Tracking your partners’ activity will help you have an insight on potential sub-contracting opportunities, and find warm introductions to relevant buyers.";
    } else {
      typeSpecific =
        "Tracking your competitors’ activity will help you have an insight on their existing contracts and upcoming expiries. Use this data to anticipate and prepare for upcoming tenders.";
    }

    if (useSupplierName) return typeSpecific;

    return `${typeSpecific}\n\nThe text you enter here will search both the names of suppliers awarded work, and the text within documents. For example, if you input Salesforce, we will return awards where Salesforce won the contract, and results that include text like "We need a firm to implement our Salesforce system".`;
  }, [supplierType, useSupplierName]);

  return <InfoButton tooltipOverlayStyle={{ whiteSpace: "pre-line", minWidth: 507 }} note={text} />;
}
