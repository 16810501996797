import React, { useState } from "react";
import { Popover } from "antd5";

import { useDialogManager } from "lib/providers/DialogManager";
import { useSubscription } from "lib/providers/Subscription";
import { EventData } from "lib/tracking";
import PaywallModal, { PaywallModalProps } from "./PaywallModal";
import { convertFeatureTypeToDataType, FeatureType, PaidTag, PaywallImg } from "./paywallUtils";

import css from "./PaywallPopover.module.scss";

type PopoverContent = {
  title: string;
  description: string;
  image: JSX.Element;
};

type PaywallPopoverContent = Record<FeatureType, PopoverContent>;

export const popoverContent: PaywallPopoverContent = {
  AWARDS: {
    title: "Contract award data",
    description:
      "Use expiring contracts to identify upcoming opportunities and build relationships, before the tender is written.",
    image: <PaywallImg context={"POPOVER"} featureType={"AWARDS"} />,
  },

  PARTNERS: {
    title: "Partner intelligence",
    description:
      "Map your partner network to identify contracts they've won, as well as upcoming opportunities you can pursue.",
    image: <PaywallImg context={"POPOVER"} featureType={"PARTNERS"} />,
  },

  COMPETITORS: {
    title: "Competitor intelligence",
    description:
      "Map your competitors to identify contracts they've won and upcoming opportunities you can infiltrate.",
    image: <PaywallImg context={"POPOVER"} featureType={"COMPETITORS"} />,
  },

  BUYERS: {
    title: "Buyer intelligence",
    description:
      "View profiles for target accounts and existing customers, identifying contracts they’ve won and upcoming opportunities you can pursue.",
    image: <PaywallImg context={"POPOVER"} featureType={"BUYERS"} />,
  },

  SUPPLIERS: {
    title: "Supplier intelligence",
    description:
      "View profiles of potential partners and competitors and leverage custom dashboards to identify contracts they've won and upcoming opportunities you can pursue.",
    image: <PaywallImg context={"POPOVER"} featureType={"SUPPLIERS"} />,
  },

  CONTACTS: {
    title: "Decision maker contacts",
    description:
      "Go beyond procurement contacts and reach out to decision-makers directly. Unlock contact details and generate personalised outreach in seconds with Stotles AI.",
    image: <PaywallImg context={"POPOVER"} featureType={"CONTACTS"} />,
  },

  DOCUMENTS: {
    title: "Strategic documents",
    description:
      "Get access to thousands of buyer-related documents, including budgets, meeting minutes, delivery plans, strategy documents, and more.",
    image: <PaywallImg context={"POPOVER"} featureType={"DOCUMENTS"} />,
  },

  SPEND: {
    title: "Spend data",
    description:
      "Analysing invoice-level spend data can help you uncover deeper relationships, market opportunities, and industry insights.",
    image: <PaywallImg context={"POPOVER"} featureType={"SPEND"} />,
  },

  ACCOUNT_BRIEFINGS: {
    title: "Custom Briefings",
    description:
      "A suite of custom briefings designed to help commercial teams save hundreds of hours researching and spend more time closing deals.",
    image: <PaywallImg context={"POPOVER"} featureType={"ACCOUNT_BRIEFINGS"} />,
  },
  GUEST_ACCESS_UPGRADE: {
    title: "Book a demo",
    description:
      "As a guest pass user, certain functionalities are restricted. To unlock a more personalised experience and explore the full potential of Stotles data, schedule a demo with our sales team.",
    image: <PaywallImg context={"POPOVER"} featureType={"GUEST_ACCESS_UPGRADE"} />,
  },
  FRAMEWORKS: {
    title: "Upgrade to unlock Framework data",
    description:
      "Sift through thousands of frameworks in minutes to discover and qualify the most valuable opportunities and routes to market for your business.",
    image: <PaywallImg context={"POPOVER"} featureType={"FRAMEWORKS"} />,
  },
};

type Props = {
  children: React.ReactNode;
  featureType: FeatureType;
  showModalOnClick?: boolean;
  isAddOn?: boolean;
  contextSource?: string;
  // Just to pass tests for now
  manualOpen?: boolean;
  trackingData?: EventData;
};

function PaywallPopover({
  children,
  featureType,
  isAddOn,
  manualOpen,
  contextSource,
  trackingData,
  showModalOnClick = true,
}: Props) {
  const dialogManager = useDialogManager();

  const subscription = useSubscription();

  const blockAccess =
    !subscription.hasDataTypes(convertFeatureTypeToDataType(featureType)) || !!window.guestUser;

  const [open, setOpen] = useState<boolean>(manualOpen ?? false);

  const { title, description, image } = popoverContent[featureType];

  const openPaywallModal = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (showModalOnClick && blockAccess) {
      dialogManager.openDialog(PaywallModal, {
        featureType: featureType,
        isAddOn: isAddOn,
        contextSource: contextSource,
        trackingData,
      } as Omit<PaywallModalProps, "isOpen" | "onClose">);
      e.stopPropagation();
    }
  };

  return (
    <Popover
      placement="bottomLeft"
      arrow={false}
      open={open}
      defaultOpen={false}
      onOpenChange={(open) => {
        if (blockAccess) setOpen(open);
      }}
      zIndex={9998}
      mouseEnterDelay={1}
      overlayInnerStyle={{ padding: 0 }}
      content={
        <div className={css.popoverContainer}>
          {image}
          <div className={css.popoverContent}>
            <PaidTag />
            <h3>{title}</h3>
            <p data-testid="description">{description}</p>
          </div>
        </div>
      }
    >
      <div onClick={openPaywallModal}>{children}</div>
    </Popover>
  );
}

export default PaywallPopover;
