import React, { useState } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { Empty } from "antd5";
import isEqual from "lodash.isequal";

import { formatNumber } from "components/app_layout/Typography";
import { convertNoticeFiltersToApi, NoticeFilters } from "components/notices/utils";
import { numberSort, stringSort } from "lib/columnSort";
import { EllipsisTooltipText, EllipsisTooltipTextLink } from "lib/core_components/EllipsisTooltip";
import { RelevanceScorePopover } from "lib/core_components/RelevanceScore";
import { Table } from "lib/core_components/Table";
import { ColumnType, commonTableColumns } from "lib/core_components/Table/ColumnTypes";
import { RecordDto } from "lib/generated/app-api";
import { useRecordSearch } from "lib/hooks/api/useRecordSearch";
import { SortState } from "lib/search/types";
import { ColumnSetting } from "lib/types/models";
import { formatDate } from "lib/utils";
import { isValidSortField } from "./utils";

import css from "./CallOffTable.module.scss";

const DEFAULT_PAGINATION = { current: 1, pageSize: 10 };

function getSortOrder(key: string, sort: SortState): "ascend" | "descend" | null {
  return sort.field !== key ? null : sort.order === "ASC" ? "ascend" : "descend";
}

export type CallOffColumns = "relevanceScore" | "title" | "supplier" | "value" | "publishDate";

export const DEFAULT_CALL_OFF_COLUMNS: ColumnSetting<CallOffColumns>[] = [
  { title: "Signal score", field: "relevanceScore" },
  { title: "Call-off & Buyer", field: "title" },
  { title: "Supplier", field: "supplier" },
  { title: "Value", field: "value" },
  { title: "Published", field: "publishDate" },
];

// TODO: clarify if we have additional hidden columns
/** @alias */
export const ALL_CALL_OFF_COLUMNS: ColumnSetting<CallOffColumns>[] = DEFAULT_CALL_OFF_COLUMNS;

function getFrameworkColumns({
  filters,
  selectedColumns,
}: {
  filters: NoticeFilters;
  selectedColumns: CallOffColumns[];
}) {
  const { sort } = filters;

  const relevanceScore: ColumnType<RecordDto> = {
    ...commonTableColumns.relevanceColumn,
    title: "Signal score",
    key: "relevance_score",
    dataIndex: "relevanceScore",
    render: (_, c) => (
      <RelevanceScorePopover
        recordGuid={c.guid}
        recordStage={c.stage}
        signals={c.signals}
        relevanceScore={c.relevanceScore}
        popoverPlacement="bottomLeft"
      />
    ),
    sorter: numberSort((r: RecordDto) => r.relevanceScore),
    sortOrder: getSortOrder("relevance_score", sort),
    sortDirections: ["descend", "ascend", "descend"],
    showSorterTooltip: {
      title:
        "BETA: This score is calculated based on the strength of your signal settings against this notice.",
    },
  };

  const title: ColumnType<RecordDto> = {
    title: "Call-off & Buyer",
    key: "name",
    dataIndex: "name",
    sorter: stringSort((c) => c.name),
    sortOrder: getSortOrder("name", sort),
    sortDirections: ["ascend", "descend", "ascend"],
    render: (_, c: RecordDto) => (
      <div className={css.titleContainer}>
        <div className={css.callOffName}>
          <EllipsisTooltipTextLink
            fullText={c.name}
            linkProps={{
              to: `/records/${c.guid}`,
            }}
            linkText={c.name}
            tooltipProps={{ overlayClassName: css.tooltip }}
          />
        </div>
        {c.buyer?.name && (
          <EllipsisTooltipText fullText={c.buyer?.name} textProps={{ className: css.buyerText }} />
        )}
      </div>
    ),
    align: "left",
    sizeConfig: {
      small: 200,
      medium: 300,
      large: 400,
      xlarge: 500,
    },
  };

  // TODO: add once we migrate record search to graphql
  //
  // const supplier: ColumnType<RecordDto> = {
  //   ...commonTableColumns.supplierColumn,
  //   title: "Supplier",
  //   key: "supplier",
  //   dataIndex: "supplier",
  //   sorter: stringSort(c => c.supplier.name),
  //   sortOrder: getSortOrder("supplier", sort),
  //   sortDirections: ["ascend", "descend", "ascend"],
  //   onCell: _rec => ({
  //     onClick: event => event.stopPropagation(),
  //   }),
  //   render: (_, c) => (
  //     <div className={css.callOffName}>
  //       <EllipsisTooltipTextLink
  //         fullText={c.supplier.name}
  //         linkProps={{
  //           to: `/supplier/${c.supplier.id}`,
  //           className: css.supplierName,
  //         }}
  //         linkText={c.supplier.name}
  //         tooltipProps={{ overlayClassName: css.tooltip }}
  //       />
  //     </div>
  //   ),
  //   align: "left",
  // };

  const value: ColumnType<RecordDto> = {
    ...commonTableColumns.valueColumn,
    title: "Value",
    key: "valueSrc",
    dataIndex: "valueSrc",
    ellipsis: {
      showTitle: false,
    },
    sortDirections: ["descend", "ascend", "descend"],
    sorter: numberSort((f) => f.valueSrc, getSortOrder("value", sort)),
    sortOrder: getSortOrder("value", sort),
    align: "left",
    render: (_, callOff: RecordDto) => {
      return (
        callOff?.valueSrc &&
        formatNumber({
          value: callOff.valueSrc,
          locale: "en-GB",
          currency: callOff.currency || undefined,
        })
      );
    },
  };

  const publishDate: ColumnType<RecordDto> = {
    ...commonTableColumns.dateColumn,
    key: "publish_date",
    title: "Published",
    ellipsis: {
      showTitle: false,
    },
    render: (_, c) => formatDate(new Date(c.publishDate)),
    sorter: stringSort((c) => c.publishDate),
    sortDirections: ["ascend", "descend", "ascend"],
    sortOrder: getSortOrder("publish_date", sort),
    showSorterTooltip: false,
  };

  const columns = {
    relevanceScore,
    title,
    supplier: undefined,
    value,
    publishDate,
  };

  return selectedColumns.map((c) => columns[c]).filter((c) => !!c) as ColumnType<RecordDto>[];
}

type Props = {
  filters: NoticeFilters;
  onSortChange: (sort: NoticeFilters["sort"]) => void;
  selectedRows?: string[];
  onSelectedRowsChange?: (selectedRowKeys: string[], selectedRows: RecordDto[]) => void;
  selectedColumns: CallOffColumns[];
};

export function CallOffTable({
  filters,
  onSortChange,
  selectedRows,
  onSelectedRowsChange,
  selectedColumns,
}: Props) {
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const { data, isLoading, isError } = useRecordSearch(convertNoticeFiltersToApi(filters));

  const columns = React.useMemo(() => {
    return getFrameworkColumns({
      filters,
      selectedColumns,
    });
  }, [filters, selectedColumns]);

  return (
    <Table<RecordDto>
      dataSource={data?.results}
      columns={columns}
      loading={isLoading}
      pagination={{
        ...pagination,
        total: data?.results.length || 0,
        onChange: (page, pageSize) => {
          setPagination({ current: page, pageSize });
        },
        showSizeChanger: true,
      }}
      onChange={(_, __, sorter) => {
        if (Array.isArray(sorter)) {
          sorter = sorter[0];
        }
        const sortField = isValidSortField(sorter.columnKey?.toString());
        // If the sort order is not defined, we don't want to trigger a sort without an order
        if (!sortField || (!filters.sort && !sorter.order)) {
          return;
        }
        const newSortState: SortState = {
          order: sorter.order === "descend" ? "DESC" : "ASC",
          field: sortField,
        };
        if (!isEqual(filters.sort, newSortState)) {
          onSortChange?.(newSortState);
        }
      }}
      rowSelection={
        onSelectedRowsChange &&
        selectedRows && {
          onChange: (selectedRowKeys, selectedRows) => {
            onSelectedRowsChange(selectedRowKeys as string[], selectedRows);
          },
          preserveSelectedRowKeys: true,
          selectedRowKeys: selectedRows,
        }
      }
      rowKey="guid"
      locale={{
        emptyText: isError ? (
          <Empty
            image={<WarningOutlined style={{ fontSize: 100 }} />}
            description="Sorry something has gone wrong"
          />
        ) : undefined,
      }}
    />
  );
}
