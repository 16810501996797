import React from "react";
import { hot } from "react-hot-loader/root";
import { Menu } from "antd5";
import { guestUserCustomHeader } from "pages/_shared/GuestUserHeaderActions";
import { Route, Router, Switch, useLocation } from "wouter";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsContent, DetailsHeader, DetailsPage } from "components/app_layout/DetailsLayout";
import BuyerHeader from "components/buyer_details/BuyerHeader";
import ContactsPanel from "components/buyer_details/contacts_panel/ContactsPanel";
import { getTabConfigFromLocation, labelledRoutes } from "components/buyer_details/routingUtils";
import { SupplierTab } from "components/buyer_details/SupplierTab";
import { PrimaryTab, SecondaryTab } from "components/buyer_details/types";
import { GuestPassBanner } from "components/guest_passes/banner/GuestPassBanner";
import { EMPTY_FILTERS } from "components/my_feed/useMyFeedPageState";
import { FilterableNoticeTable } from "components/notices/FilterableNoticeTable";
import { NoticeColumns } from "components/notices/NoticeTable";
import { useFilterableNoticeTableState } from "components/notices/useFilterableNoticeTableState";
import { NoticeFilters } from "components/notices/utils";
import { SortState } from "lib/search/types";
import { EventNames, logEvent, TrackingProvider } from "lib/tracking";
import { BuyerDetails, GuestAccessPass, Status } from "lib/types/models";
import { BuyerSupplierRelationshipPage } from "./SupplierBuyerRelationshipPage";

import css from "./GuestPassBuyerDetailsPage.module.scss";

type Props = {
  buyer: BuyerDetails;
  guest_access_pass: GuestAccessPass;
};

function GuestPassBuyerDetailsPageWrapper(props: Props) {
  return (
    <Router base={`/guest_access/buyers/${props.buyer.id}`}>
      <Switch>
        {/* buyer supplier relationship page */}
        <Route<{ supplierGuid?: string }> path="/supplier/:supplierGuid">
          {({ supplierGuid }) => (
            <BuyerSupplierRelationshipPage {...props} supplierGuid={supplierGuid} />
          )}
        </Route>
        <Route<{ signalId?: string }> path="/partner/:signalId">
          {({ signalId }) => (
            <BuyerSupplierRelationshipPage {...props} signalId={signalId} isPartner />
          )}
        </Route>
        <Route<{ signalId?: string }> path="/competitor/:signalId">
          {({ signalId }) => (
            <BuyerSupplierRelationshipPage {...props} signalId={signalId} isCompetitor />
          )}
        </Route>
        {/* Default */}
        <Route>
          <GuestPassBuyersDetailsPage {...props} />
        </Route>
      </Switch>
    </Router>
  );
}

function GuestPassBuyersDetailsPage({ buyer, guest_access_pass }: Props) {
  const [location, navigate] = useLocation();
  const { setNoticeTableFilters, setNoticeTableColumns } = useFilterableNoticeTableState();
  const setLocation = React.useCallback(
    (route: string) => navigate(`${route}${window.location.search}`),
    [navigate],
  );

  const [tabConfig, setTabConfig] = React.useState(() =>
    getTabConfigFromLocation(location as PrimaryTab | SecondaryTab),
  );

  const activeTabLabel = React.useMemo(() => {
    const conf = getTabConfigFromLocation(location);
    const activeRoute = conf.secondary || conf.primary;
    return labelledRoutes[activeRoute];
  }, [location]);

  const headerClicked = ({ trackingType, route }: { trackingType: string; route: string }) => {
    logEvent(EventNames.highlightClicked, {
      "Context source": "Header card",
      "Highlight type": trackingType,
      "Organisation name": buyer.name,
      "Guest pass title": guest_access_pass.title,
      "Guest pass GUID": guest_access_pass.id,
    });
    setNoticeTableFilters(undefined);
    setNoticeTableColumns(undefined);
    setLocation(route);
  };

  const tabClicked = React.useCallback(
    (loc: PrimaryTab | SecondaryTab) => {
      setLocation(loc);
      const config = getTabConfigFromLocation(loc);
      const activeRoute = config.secondary || config.primary;
      logEvent(EventNames.tabChanged, {
        "Context source": config.secondary ? "Secondary Tab" : "Primary Tab",
        "Tab Selected": labelledRoutes[activeRoute],
      });
    },
    [setLocation],
  );

  React.useEffect(() => {
    const tc = getTabConfigFromLocation(location);
    // If it's a top-level tab change.... the filters are reset as well as the tab config changing
    if (tc.primary !== tabConfig.primary) {
      setTabConfig(tc);
    } else if (tc.secondary !== tabConfig.secondary) {
      setTabConfig(tc);
    }
  }, [location, tabConfig]);

  const displayedBuyerDetails: JSX.Element = React.useMemo(() => {
    const baseFilter: NoticeFilters = {
      ...EMPTY_FILTERS,
      buyers: [buyer.guid],
    };
    const allMatchingSignalsFilter = {
      ...baseFilter,
      signals: ["Keywords", "Partners", "Competitors", "CPV codes"],
    };
    const allMatchingExpiriesFilter: NoticeFilters = {
      ...baseFilter,
      signals: ["Keywords", "Partners", "Competitors", "CPV codes"],
      expiryDate: {
        filter: {
          relativeFrom: "PT0S",
        },
        hideNulls: true,
      },
      closeDate: {
        hideNulls: false,
      },
      stage: ["AWARDED"] as Status[],
      sort: { field: "expiry_date", order: "DESC" } as SortState,
    };
    const allMatchingOpenOpportunitiesFilter: NoticeFilters = {
      ...baseFilter,
      signals: ["Keywords", "Partners", "Competitors", "CPV codes"],
      stage: ["OPEN", "PRE_TENDER"] as Status[],
    };

    const currentSelectedTab = (): {
      filter: NoticeFilters;
      columns: NoticeColumns[];
      paywall: boolean;
    } => {
      switch (activeTabLabel) {
        case "Activity": {
          return {
            filter: baseFilter,
            columns: ["relevance_score", "title", "published_date", "expiry_date", "stage"],
            paywall: false,
          };
        }
        case "Activity: All activity": {
          return {
            filter: allMatchingSignalsFilter,
            columns: ["relevance_score", "title", "published_date", "expiry_date", "stage"],
            paywall: false,
          };
        }
        case "Activity: Upcoming contract expiries": {
          return {
            filter: allMatchingExpiriesFilter,
            columns: ["relevance_score", "title", "expiry_date", "value", "stage", "suppliers"],
            paywall: true,
          };
        }
        case "Activity: Open opportunities": {
          return {
            filter: allMatchingOpenOpportunitiesFilter,
            columns: ["relevance_score", "title", "published_date", "expiry_date", "stage"],
            paywall: true,
          };
        }
        default: {
          return {
            filter: baseFilter,
            columns: ["relevance_score", "title", "published_date", "expiry_date", "stage"],
            paywall: false,
          };
        }
      }
    };

    switch (tabConfig.primary) {
      case PrimaryTab.CONTACTS:
        return (
          <ContactsPanel
            buyer={buyer}
            handleTabNavigation={tabClicked}
            activeTab={tabConfig.secondary}
          />
        );

      case PrimaryTab.SUPPLIERS:
        return (
          <TrackingProvider data={{ "Context source": "Suppliers tab" }}>
            <SupplierTab buyer={buyer} isGuestPass />;
          </TrackingProvider>
        );

      case PrimaryTab.PROCUREMENT_ACTIVITY:
        return (
          <FilterableNoticeTable
            defaultFilters={currentSelectedTab().filter}
            defaultColumns={currentSelectedTab().columns}
            hiddenSections={["buyers"]}
            paywallEnabled={currentSelectedTab().paywall}
          />
        );

      default:
        return (
          <FilterableNoticeTable
            defaultFilters={currentSelectedTab().filter}
            defaultColumns={currentSelectedTab().columns}
            hiddenSections={["buyers"]}
            paywallEnabled={currentSelectedTab().paywall}
          />
        );
    }
  }, [buyer, tabConfig.primary, tabConfig.secondary, activeTabLabel, tabClicked]);

  return (
    <TrackingProvider
      data={{
        "Context source": "Guest pass modal",
      }}
    >
      <DetailsPage>
        <DetailsHeader className={css.noPaddingBottom}>
          <TrackingProvider data={{ "Context source": "Guest pass banner" }}>
            <GuestPassBanner />
          </TrackingProvider>
          <TrackingProvider data={{ "Context source": "Header" }}>
            <BuyerHeader buyer={buyer} onHeaderChange={headerClicked} />
          </TrackingProvider>

          <Menu mode="horizontal" selectedKeys={[tabConfig.primary]} className={css.menuTabs}>
            <Menu.Item
              key={PrimaryTab.PROCUREMENT_ACTIVITY}
              onClick={() => tabClicked(PrimaryTab.PROCUREMENT_ACTIVITY)}
              className={css.menuTabItem}
            >
              Notices
            </Menu.Item>

            <Menu.Item
              key={PrimaryTab.CONTACTS}
              onClick={() => tabClicked(PrimaryTab.CONTACTS)}
              className={css.menuTabItem}
            >
              Contacts
            </Menu.Item>

            <Menu.Item
              key={PrimaryTab.SUPPLIERS}
              onClick={() => tabClicked(PrimaryTab.SUPPLIERS)}
              className={css.menuTabItem}
            >
              Suppliers
            </Menu.Item>
          </Menu>
        </DetailsHeader>
        <DetailsContent>
          <TrackingProvider data={{ "Context source": activeTabLabel }}>
            {displayedBuyerDetails}
          </TrackingProvider>
        </DetailsContent>
      </DetailsPage>
    </TrackingProvider>
  );
}

export default hot(
  withAppLayout(GuestPassBuyerDetailsPageWrapper, (props) => ({
    pageName: "Guest pass Buyer Profile",
    pageViewProps: {
      "Organisation name": props.buyer.name,
      "Organisation ID": props.buyer.id,
      "Guest pass title": props.guest_access_pass.title,
      "Guest pass GUID": props.guest_access_pass.id,
    },
    trackingData: {
      "Organisation name": props.buyer.name,
      "Guest pass title": props.guest_access_pass.title,
      "Guest pass GUID": props.guest_access_pass.id,
    },
    hideMenuItems: true,
    hideUserItems: true,
    disableLogoNav: true,
    displayCustomAction: guestUserCustomHeader(),
  })),
);
