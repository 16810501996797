import React, { ReactNode } from "react";
import classNames from "classnames";

import css from "./DetailsLayout.module.scss";

type DetailsSectionTitleProps = {
  title: ReactNode;
  classname?: string;
};

function DetailsSectionTitle({ title, classname }: DetailsSectionTitleProps): JSX.Element {
  return <h3 className={classname}>{title}</h3>;
}

type DetailsSectionProps = React.PropsWithChildren<{
  title?: ReactNode;
  noPadding?: boolean;
  contentClassName?: string;
  action?: React.ReactNode;
  className?: string;
  tabs?: React.ReactNode;
  titleClassname?: string;
  "aria-label"?: string;
}>;

export function DetailsSection({
  action,
  children,
  className,
  contentClassName,
  noPadding,
  title,
  tabs,
  titleClassname,
  "aria-label": ariaLabel,
}: DetailsSectionProps): JSX.Element {
  return (
    <div className={classNames(css.sectionContainer, className)} aria-label={ariaLabel}>
      {(title || action) && (
        <div
          className={classNames({
            [css.sectionTitleBorder]: !tabs,
          })}
        >
          <div className={tabs ? css.sectionTitleContentWithTabs : css.sectionTitleContent}>
            {title && <DetailsSectionTitle title={title} classname={titleClassname} />}
            {action && (
              <>
                <div className={css.titleSpacer} />
                {action}
              </>
            )}
          </div>{" "}
          {tabs}
        </div>
      )}
      <div
        className={classNames(css.sectionContent, contentClassName, {
          [css.sectionContentPadding]: !noPadding,
        })}
      >
        {children}
      </div>
    </div>
  );
}

type DetailsRowProps = {
  layoutColumnFr?: number[];
};

export function DetailsRow({
  children,
  layoutColumnFr: layout,
}: React.PropsWithChildren<DetailsRowProps>): JSX.Element {
  const style = React.useMemo(() => {
    if (!layout) {
      return {};
    }
    return {
      gridTemplateColumns: layout.map((size) => `${size}fr`).join(" "),
    };
  }, [layout]);
  return (
    <div className={css.detailsRow} style={style}>
      {children}
    </div>
  );
}

type DetailsPageProps = React.PropsWithChildren<{ className?: string }>;

export function DetailsPage({ children, className }: DetailsPageProps): JSX.Element {
  return <div className={classNames(css.page, className)}>{children}</div>;
}

type DetailsHeaderProps = React.PropsWithChildren<{
  className?: string;
  borderBottom?: boolean;
}>;

export function DetailsHeader({
  children,
  className,
  borderBottom = true,
}: DetailsHeaderProps): JSX.Element {
  return (
    <div
      className={classNames(
        css.detailsHeader,
        {
          [css.borderBottom]: borderBottom,
        },
        className,
      )}
    >
      {children}
    </div>
  );
}

// Plain wrapper div with padding
export function DetailsContent({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>): JSX.Element {
  return <div className={classNames(css.content, className)}>{children}</div>;
}

// White background box with padding and border
export function DetailsContentCard({
  children,
  className,
}: React.PropsWithChildren<{ className?: string }>): JSX.Element {
  return <div className={classNames(css.contentCard, className)}>{children}</div>;
}
