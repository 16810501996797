import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { createAlertsUpdatedEvent } from "components/my_feed/tracking";
import { SavedViewType } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";
import { AlertModificationType, EventNames, useTracking } from "lib/tracking";

type Params = {
  view: SavedViewType;
  alertId?: string;
  alertFrequency: "DAILY" | "WEEKLY" | "NEVER";
};

export function useUpdateAlerts(options?: UseMutationOptions<unknown, unknown, Params, unknown>) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  const { logEvent } = useTracking();

  let modificationType: AlertModificationType;

  return useMutation(
    async ({ view, alertId, alertFrequency }) => {
      if (!view.id) {
        throw new Error("View id is required to update alerts");
      }
      // an alert exists for this view so we either update or delete it
      if (alertId) {
        if (alertFrequency === "DAILY" || alertFrequency === "WEEKLY") {
          modificationType = AlertModificationType.frequency;
          return api.updateLeadSubscriptionContent({
            id: alertId,
            updateLeadSubscriptionContentRequest: { frequency: alertFrequency },
          });
        }
        modificationType = AlertModificationType.deleted;
        return api.deleteLeadSubscriptionContent({
          deleteLeadSubscriptionContentRequest: { ids: [alertId] },
        });
      }
      if (alertFrequency === "DAILY" || alertFrequency === "WEEKLY") {
        // no alert yet exists for this view so we create one
        modificationType = AlertModificationType.created;
        return api.createLeadSubscriptionContent({
          createLeadSubscriptionContentRequest: {
            frequency: alertFrequency,
            resourceId: view.id,
            resourceType: "SavedView",
          },
        });
      }
    },
    {
      ...options,
      onSuccess: async (data, vars, ctx) => {
        await queryClient.invalidateQueries(["leadSubscriptionContents"]);
        await queryClient.invalidateQueries(["leadSubscriptions"]);
        if (modificationType) {
          logEvent(
            EventNames.alertModified,
            createAlertsUpdatedEvent(
              vars.view.name,
              modificationType,
              vars.alertFrequency,
              vars.view.id,
            ),
          );
        }
        options?.onSuccess?.(data, vars, ctx);
      },
    },
  );
}
