import React, { useEffect, useState } from "react";

import { FileUpload } from "./FileUpload";
import { ProcessFile } from "./ProcessFile";

import css from "./FileUploadProgress.module.scss";

type Props = {
  noticeId: string;
  files: File[];
  onComplete: () => void;
};

type FileUploadStatus = { file: File; isError: boolean; isUploaded: boolean; isProcessed: boolean };
export function FileUploadProgress({ noticeId, files, onComplete }: Props) {
  const [fileStatus, setFileStatus] = useState<FileUploadStatus[]>(
    files.map((file) => ({ file, isError: false, isUploaded: false, isProcessed: false })),
  );

  useEffect(() => {
    if (fileStatus.every((status) => status.isProcessed)) {
      onComplete();
    }
  }, [fileStatus, onComplete]);

  return (
    <div>
      <h2>Uploading Files</h2>
      <div className={css.filesContainer}>
        {fileStatus.map((status) =>
          status.isUploaded ? (
            <ProcessFile
              key={status.file.name}
              file={status.file}
              noticeId={noticeId}
              onComplete={(_, error) => {
                setFileStatus((prev) =>
                  prev.map((fileStatus) =>
                    fileStatus.file.name === status.file.name
                      ? { ...fileStatus, isProcessed: !error, isError: error }
                      : fileStatus,
                  ),
                );
              }}
            />
          ) : (
            <FileUpload
              key={status.file.name}
              file={status.file}
              noticeId={noticeId}
              onComplete={(_, isError) => {
                setFileStatus((prev) =>
                  prev.map((fileStatus) =>
                    fileStatus.file.name === status.file.name
                      ? { ...fileStatus, isUploaded: !isError, isError }
                      : fileStatus,
                  ),
                );
              }}
            />
          ),
        )}
      </div>
    </div>
  );
}
