import { useQuery } from "@tanstack/react-query";

import { useOpenApi } from "lib/openApiContext";

export function useAllViews() {
  const api = useOpenApi();
  return useQuery(["views"], async () => {
    const { savedViews } = await api.listSavedViews();
    return savedViews;
  });
}
