import React, { useCallback } from "react";
import { Button, message, Tooltip } from "antd5";

import BookmarkIcon from "components/actions/Bookmark";
import { EventData, EventNames, useTracking } from "lib/tracking";
import { pluralise } from "lib/utils";
import SaveBuyerPopover from "./SaveBuyerPopover";

import css from "./SaveBuyer.module.scss";

type Props = {
  buyerGuids: string[];
};

function BulkSaveBuyer({ buyerGuids }: Props) {
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

  const tracking = useTracking();

  const onBuyerListOp = useCallback(
    (buyerGuids: string[], buyerListName: string, op: "add" | "remove") => {
      const eventData: EventData = {
        "Context source": "Bulk action",
        "Buyer GUIDs": buyerGuids,
        "Buyer list name": buyerListName,
      };
      if (op === "add") {
        tracking.logEvent(EventNames.buyerBulkSave, eventData);
        void message.success(
          `Successfully added ${pluralise(buyerGuids.length, "buyer")} to ${buyerListName}`,
        );
      } else {
        tracking.logEvent(EventNames.buyerBulkRemove, eventData);

        void message.success(
          `Successfully removed ${pluralise(buyerGuids.length, "buyer")} from ${buyerListName}`,
        );
      }
    },
    [tracking],
  );

  return (
    <SaveBuyerPopover
      buyerGuids={buyerGuids}
      onBuyerListOp={onBuyerListOp}
      isPopoverOpen={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
      contextSource="Bulk action"
    >
      <div>
        <Tooltip title={`Save ${buyerGuids.length} buyers to list`}>
          <Button type="default" className={css.saveBuyerButton}>
            <BookmarkIcon colour="blue" /> Save
          </Button>
        </Tooltip>
      </div>
    </SaveBuyerPopover>
  );
}

export default BulkSaveBuyer;
