import React, { useState } from "react";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Button, Input } from "antd5";

import {
  ALL_DOCUMENT_COLUMNS,
  DEFAULT_DOCUMENT_FILTERS,
  DocumentColumns,
  DocumentFilters,
  FilterSection,
  parseDocumentsUrlState,
  SIGNAL_SCORE_COLUMN,
} from "components/documents/utils";
import EditColumnsButton from "lib/core_components/EditColumnsButton";
import { createUseDebounce } from "lib/debounce";
import { DOCUMENTS_SIGNAL_SCORE, FILTER_COLLAPSE_MATCH_STYLING } from "lib/featureFlags";
import { useURLState } from "lib/hooks/useURLState";
import { EventDataTypes } from "lib/tracking";
import { ColumnSetting } from "lib/types/models";
import FilterDrawer from "../filter_form/filter_drawer/FilterDrawer";
import { DocumentTable } from "./DocumentTable";
import { FilterForm } from "./FilterForm";
import { QuickFilterBar } from "./QuickFilterBar";

import css from "./FilterableDocuments.module.scss";

type FilterableDocumentProps = {
  defaultColumns?: ColumnSetting<DocumentColumns>[];
  defaultFilters?: DocumentFilters;
  hiddenFilters?: (keyof DocumentFilters)[];
  hiddenSections?: FilterSection[];
};

export function FilterableDocuments({
  defaultColumns = ALL_DOCUMENT_COLUMNS,
  defaultFilters = DEFAULT_DOCUMENT_FILTERS,
  hiddenFilters,
  hiddenSections,
}: FilterableDocumentProps) {
  const isSignalScoreEnabled = useVariableValue(DOCUMENTS_SIGNAL_SCORE, false);

  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);

  const isCollapseEnabled = useVariableValue(FILTER_COLLAPSE_MATCH_STYLING, false);

  const [tableColumns, setTableColumns] = useState<ColumnSetting<DocumentColumns>[] | undefined>(
    undefined,
  );

  // insert the signal score column
  function getAllDocumentColumns() {
    const columns = [...defaultColumns];
    columns.splice(1, 0, SIGNAL_SCORE_COLUMN);
    return columns;
  }

  const allDocumentColumns = getAllDocumentColumns();

  const appliedColumns: ColumnSetting<DocumentColumns>[] = tableColumns
    ? tableColumns
    : isSignalScoreEnabled
      ? allDocumentColumns
      : [...defaultColumns];

  const [filters = defaultFilters, setFilters] = useURLState<DocumentFilters>(
    "documentFilters",
    defaultFilters,
    parseDocumentsUrlState,
  );

  const [documentTitle, setDocumentTitle] = useState(filters.title);

  const useDebounce = createUseDebounce(500);
  const debouncedSetFilters = useDebounce((filters: DocumentFilters) => {
    setFilters(filters);
    setDocumentTitle(filters.title);
  });

  return (
    <div className={css.container}>
      <div className={css.actionsContainer}>
        <div className={css.actions}>
          <Input
            placeholder="Search by document name"
            prefix={<SearchOutlined />}
            value={documentTitle}
            onChange={(e) => {
              setDocumentTitle(e.target.value);
              debouncedSetFilters({ ...filters, title: e.target.value });
            }}
            allowClear
            className={css.searchInput}
            data-hj-allow
          />
          <Button
            onClick={() => setIsFilterDrawerOpen(true)}
            icon={<FilterOutlined className={css.filterIcon} />}
          >
            Filters
          </Button>
        </div>
        <EditColumnsButton
          allAvailableColumns={isSignalScoreEnabled ? allDocumentColumns : defaultColumns}
          selectedColumns={appliedColumns}
          onNewColumnSettingChange={(c) =>
            setTableColumns([...(c as ColumnSetting<DocumentColumns>[])])
          }
          dataType={EventDataTypes.documents}
        />
      </div>
      <QuickFilterBar
        filters={filters}
        onClearFilter={(key) => setFilters({ ...filters, [key]: undefined })}
        onFilterClick={() => setIsFilterDrawerOpen(true)}
        hiddenFilters={hiddenFilters}
      />
      <DocumentTable
        filters={filters}
        onSortChange={(sort) => setFilters({ ...filters, sort })}
        columnSettings={appliedColumns.map((c) => c.field)}
      />
      <FilterDrawer open={isFilterDrawerOpen} onClose={() => setIsFilterDrawerOpen(false)}>
        {isFilterDrawerOpen && (
          <FilterForm
            onChange={debouncedSetFilters}
            filters={filters}
            hiddenSections={hiddenSections}
            closeDrawer={() => setIsFilterDrawerOpen(false)}
            showTitle={isCollapseEnabled}
          />
        )}
      </FilterDrawer>
    </div>
  );
}
