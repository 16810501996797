import React from "react";
import { hot } from "react-hot-loader/root";
import { LinkOutlined } from "@ant-design/icons";
import { Button, Card, message, Skeleton } from "antd5";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsHeader, DetailsPage, DetailsSection } from "components/app_layout/DetailsLayout";
import { copyToClipboard } from "lib/clipboard";
import { Report } from "lib/generated/app-api/models/Report";
import { usePublicReports } from "lib/hooks/api/reports/usePublicReports";
import { ExternalLink } from "lib/icons/ExternalLink";
import { white } from "lib/themes/colors";
import { EventNames, useTracking } from "lib/tracking";

import css from "./IndustryReportsPage.module.scss";

import placeholderImage from "../../../assets/images/stotles_placeholder.svg";

export function IndustryReportsPage() {
  const { data, isLoading } = usePublicReports();
  const [messageApi, contextHolder] = message.useMessage();
  const { logEvent } = useTracking();

  const copyReportLink = (report: Report) => {
    const clipboardContent = {
      plain: report.pdfUrl
        ? report.pdfUrl
        : `https://app.stotles.com/reports/${report.id}?utm_source=share`,
    };
    const copiedToClipboard = copyToClipboard(clipboardContent);
    if (copiedToClipboard) {
      return void messageApi.success("Copied!", 2);
    }
    void messageApi.error(`Could not copy link to clipboard`, 2);
    logEvent(EventNames.industryReportLinkCopied, {
      "Report name": report.title,
      "Report URL": report.pdfUrl ? report.pdfUrl : `/reports/${report.id}`,
    });
  };

  return (
    <DetailsPage>
      <DetailsHeader borderBottom={false}>
        <h1>Industry reports</h1>
      </DetailsHeader>
      <DetailsSection>
        {isLoading ? (
          <Skeleton />
        ) : (
          <div className={css.grid}>
            {data?.map((report) => (
              <Card
                cover={
                  <img
                    src={report.coverImageUrl || placeholderImage}
                    alt="report cover"
                    className={css.coverImage}
                  />
                }
                key={report.id}
                className={css.card}
                hoverable
                onClick={() => {
                  logEvent(EventNames.industryReportViewed, {
                    "Report name": report.title,
                    "Report URL": report.pdfUrl ? report.pdfUrl : `/reports/${report.id}`,
                  });
                  window.open(report.pdfUrl ? report.pdfUrl : `/reports/${report.id}`, "_blank");
                }}
              >
                <Card.Meta
                  title={report.title}
                  description={
                    <div className={css.descriptionContainer}>
                      <p className={css.description}>{report.description || report.adminNotes}</p>
                      <div className={css.cardActions}>
                        <Button
                          type="primary"
                          href={report.pdfUrl ? report.pdfUrl : `/reports/${report.id}`}
                          target="_blank"
                          className={css.customIconButton}
                          icon={<ExternalLink fill={white} />}
                          onClick={(e) => {
                            e.stopPropagation();
                            logEvent(EventNames.industryReportViewed, {
                              "Report name": report.title,
                              "Report URL": report.pdfUrl ? report.pdfUrl : `/reports/${report.id}`,
                            });
                          }}
                        >
                          View
                        </Button>
                        <Button
                          title="Copy link"
                          icon={<LinkOutlined />}
                          onClick={(e) => {
                            e.stopPropagation();
                            copyReportLink(report);
                          }}
                        />
                      </div>
                    </div>
                  }
                />
              </Card>
            ))}
          </div>
        )}
      </DetailsSection>
      {contextHolder}
    </DetailsPage>
  );
}

export default hot(
  withAppLayout(IndustryReportsPage, {
    pageName: "Industry reports",
    trackingData: { "Page source": "Industry reports" },
  }),
);
