import React, { useState } from "react";
import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EyeOutlined,
  PushpinFilled,
  PushpinOutlined,
  PushpinTwoTone,
  SearchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Dropdown, Input, Skeleton, Tabs, Tooltip } from "antd5";
import { startCase } from "lodash";

import UserInitials from "components/comments/UserInitials";
import { numberSort, stringSort } from "lib/columnSort";
import { EllipsisTooltipText } from "lib/core_components/EllipsisTooltip";
import { SHOW_ON_HOVER } from "lib/core_components/ShowOnHover";
import { Table } from "lib/core_components/Table";
import { ColumnType } from "lib/core_components/Table/ColumnTypes";
import { SavedViewType } from "lib/generated/app-api";
import { useUsers } from "lib/hooks/api/useUsers";
import { useDialogManager } from "lib/providers/DialogManager";
import { EventNames, useTracking } from "lib/tracking";
import { getFormattedDate } from "lib/utils";
import { usePinnedViews } from "../../lib/hooks/api/notices/views/usePinnedViews";
import { usePinView } from "../../lib/hooks/api/notices/views/usePinView";
import { useUnpinView } from "../../lib/hooks/api/notices/views/useUnpinView";
import { DeleteViewModal } from "./views_bar/DeleteViewModal";
import { DuplicateViewModal } from "./views_bar/DuplicateViewModal";
import { EditViewModal } from "./views_bar/EditViewModal";
import { useMyFeedPageState } from "./useMyFeedPageState";

import css from "./NoticeViews.module.scss";

export function NoticeViews({ onClose }: { onClose: () => void }) {
  const { logEvent } = useTracking();

  const {
    isLoadingViews,
    pinnedViews: pinnedViewIds,
    allViews,
    setSelectedView,
  } = useMyFeedPageState();
  const { mutate: pinView } = usePinView();
  const userId = window.currentUser?.guid;

  if (isLoadingViews) {
    return <Skeleton active />;
  }

  if (!allViews) {
    return <div>Sorry something has gone wrong</div>;
  }

  const myViews = allViews.filter((view) => view.ownerId === userId);
  const pinnedViews = allViews.filter(
    (view) => view.id && pinnedViewIds?.find((pv) => pv.savedViewId === view.id),
  );
  const standardViews = allViews.filter((view) => view.isStandardView);

  const onSelectView = (viewId: string, name: string, context: string) => {
    if (!pinnedViewIds?.find((pv) => pv.savedViewId === viewId)) {
      pinView({ id: viewId, name: name });
    }
    setSelectedView(viewId, name, context);
    onClose();
  };

  const tabs = [
    {
      label: `All views (${allViews.length})`,
      children: <ViewTable data={allViews} onSelectView={onSelectView} />,
      key: "allViews",
    },
    {
      label: `My views (${myViews.length})`,
      children: <ViewTable data={myViews} key="myViews" onSelectView={onSelectView} />,
      key: "myViews",
    },
    {
      label: `Pinned views (${pinnedViews?.length})`,
      children: <ViewTable data={pinnedViews} key="pinnedViews" onSelectView={onSelectView} />,
      key: "pinnedViews",
    },
    {
      label: `Standard views (${standardViews.length})`,
      children: <ViewTable data={standardViews} key="standardViews" onSelectView={onSelectView} />,
      key: "standardViews",
    },
  ];

  return (
    <Tabs
      defaultActiveKey="allViews"
      items={tabs}
      onChange={(key) =>
        logEvent(EventNames.tabChanged, {
          "Context source": "All views panel slide-out",
          "Tab Selected": startCase(key),
        })
      }
    />
  );
}

const columns: ColumnType<SavedViewType>[] = [
  {
    title: "View name & description",
    dataIndex: "name",
    key: "name",
    sizeConfig: {
      small: 400,
      medium: 600,
      large: 700,
      xlarge: 800,
    },
    sorter: stringSort((e) => e.name),
  },
  {
    title: "Owner",
    dataIndex: "ownerId",
    key: "owner",
    render: (value, view) => <OwnerCell userId={value} isStandardView={view.isStandardView} />,
  },
  {
    title: "Last updated",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (value) => getFormattedDate(value),
    sorter: numberSort((e) => e.updatedAt?.valueOf()),
  },
];

function getColumns(onSelectView: (viewId: string, name: string, context: string) => void) {
  columns[0].render = (_, view) => <NameCell view={view} onSelectView={onSelectView} />;
  return columns;
}

function ViewTable({
  data,
  onSelectView,
}: {
  data: SavedViewType[];
  onSelectView: (viewId: string, name: string, context: string) => void;
}) {
  const [searchText, setSearchText] = useState("");
  const filteredData = data.filter((view) =>
    view.name.toLowerCase().includes(searchText.toLowerCase()),
  );

  return (
    <div className={css.viewTable}>
      <Input
        placeholder="Search views"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        className={css.searchInput}
        prefix={<SearchOutlined />}
        allowClear
      />
      <Table dataSource={filteredData} columns={getColumns(onSelectView)} rowKey="id" />
    </div>
  );
}

enum ActionKeys {
  Select = "select",
  Unpin = "unpin",
  Pin = "pin",
  Duplicate = "duplicate",
  Edit = "edit",
  Delete = "delete",
}

function NameCell({
  view,
  onSelectView,
}: {
  view: SavedViewType;
  onSelectView: (viewId: string, name: string, context: string) => void;
}) {
  const { name, description, id, isStandardView } = view;
  const { data: pinnedViews } = usePinnedViews();
  const { selectedView, setSelectedView } = useMyFeedPageState();
  const { mutate: unpinView, isLoading: isLoadingUnpin } = useUnpinView();
  const { mutate: pinView, isLoading: isLoadingPin } = usePinView();
  const dialogManager = useDialogManager();
  const [isActionMenuOpen, setIsActionModalOpen] = useState(false);

  const isPinned = pinnedViews?.find((pv) => pv.savedViewId === id);

  const actionMenu = [
    {
      label: "Select view",
      key: ActionKeys.Select,
      icon: <EyeOutlined />,
      disabled: id === selectedView?.id,
    },
    {
      label: isPinned ? "Unpin view" : "Pin view",
      key: isPinned ? ActionKeys.Unpin : ActionKeys.Pin,
      icon: <PushpinOutlined />,
    },
    { label: "Duplicate view", key: ActionKeys.Duplicate, icon: <CopyOutlined /> },
    { label: "Edit view", key: ActionKeys.Edit, icon: <EditOutlined /> },
    { label: "Delete", key: ActionKeys.Delete, icon: <DeleteOutlined />, disabled: isStandardView },
  ];

  const actionsOnClick = ({ key }: { key: ActionKeys }) => {
    if (!id) {
      return;
    }
    setIsActionModalOpen(false);
    switch (key) {
      case ActionKeys.Select:
        return onSelectView(id, name, "All views table action dropdown");
      case ActionKeys.Unpin:
        return unpinView({ id, name });
      case ActionKeys.Pin:
        return pinView({ id, name });
      case ActionKeys.Duplicate:
        return dialogManager.openDialog(DuplicateViewModal, { selectedView: view });
      case ActionKeys.Edit:
        return dialogManager.openDialog(EditViewModal, { selectedView: view });
      case ActionKeys.Delete:
        return dialogManager.openDialog(DeleteViewModal, {
          selectedView: view,
          onSuccess: () => {
            if (selectedView?.id === id) {
              // TODO update this to undefined when we have the empty view state
              const newSelectedView = pinnedViews?.find((pv) => pv.savedViewId !== id);
              newSelectedView &&
                setSelectedView(newSelectedView.savedViewId, "", "Delete view modal");
            }
          },
        });
      default:
        break;
    }
  };

  return (
    <div className={css.viewNameCell}>
      <div>
        <div onClick={() => id && onSelectView(id, name, "All views table row click")}>
          <EllipsisTooltipText fullText={name} textProps={{ className: css.viewNameText }} />
        </div>
        {description && <EllipsisTooltipText fullText={description} />}
      </div>
      <div className={css.viewNameActions}>
        <div className={isActionMenuOpen ? "" : SHOW_ON_HOVER}>
          <Dropdown
            menu={{
              items: actionMenu,
              onClick: actionsOnClick,
            }}
            onOpenChange={(open) => setIsActionModalOpen(open)}
          >
            <Button>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        {isPinned ? (
          <Tooltip title="Unpin this view">
            <Button
              type="link"
              onClick={() => {
                id && unpinView({ id, name });
              }}
              icon={<PushpinFilled />}
              loading={isLoadingUnpin}
              aria-label="Unpin this view"
            />
          </Tooltip>
        ) : (
          <Tooltip title="Pin this view">
            <Button
              type="link"
              onClick={() => {
                id && pinView({ id, name });
              }}
              icon={<PushpinTwoTone />}
              loading={isLoadingPin}
              aria-label="Pin this view"
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
}

function OwnerCell({ userId, isStandardView }: { userId?: string; isStandardView?: boolean }) {
  const { data, isLoading } = useUsers();

  const currentUser = window.currentUser;
  const isCurrentUser = userId === currentUser?.guid;

  if (isStandardView) {
    return (
      <span className={css.ownerCell}>
        <Avatar icon={<UserOutlined />} />
        <p>Standard view</p>
      </span>
    );
  }

  if (isLoading) {
    return <Skeleton.Avatar active />;
  }

  let user = data?.find((u) => u.guid === userId);

  // should only happen for admin users
  if (!user && isCurrentUser && currentUser) {
    user = {
      email: currentUser.email,
      firstName: currentUser.first_name,
      lastName: currentUser.last_name,
      guid: currentUser.guid,
      active: true,
    };
  }

  if (user) {
    return (
      <span className={css.ownerCell}>
        <UserInitials firstName={user.firstName} lastName={user.lastName} />
        <p>{`${user.firstName} ${user.lastName} ${isCurrentUser ? "(Me)" : ""}`}</p>
      </span>
    );
  }

  return (
    <span className={css.ownerCell}>
      <Avatar icon={<UserOutlined />} />
      <p>Admin created view</p>
    </span>
  );
}
