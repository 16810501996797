import React from "react";
import { Form } from "antd"; // check how this looks after upgrading
import Input, { InputProps } from "antd/lib/input";
import { v4 } from "uuid";

import { FormikedInput } from "./formik_helpers";

type AdminTextFieldProps = InputProps & {
  field: string;
  name: string;
  error?: string;
};

/**
 * Simple text field with a label.
 */
function AdminTextField({ field, error, ...props }: AdminTextFieldProps): JSX.Element {
  const elementId = React.useMemo(() => {
    return `${props.name}-${v4()}`;
  }, [props.name]);

  return (
    <div>
      <label htmlFor={elementId}>{field}</label>
      <Form.Item hasFeedback validateStatus={error ? "error" : undefined} help={error}>
        <Input {...props} id={elementId} />
      </Form.Item>
    </div>
  );
}

/**
 * Admin Text Field with formik state injected automatically.
 */
export const FormikAdminTextField = FormikedInput(AdminTextField);
