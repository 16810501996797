import React, { useEffect, useState } from "react";
import { Core } from "@pdftron/webviewer";

import { createUseDebounce } from "lib/debounce";
import { useDocumentHighlight } from "lib/hooks/api/useDocumentHighlight";
import SearchIcon from "lib/icons/SearchIcon";
import { usePdfViewerInstance } from "../../lib/core_components/PdfViewer";
import DocumentSearchResults, { SearchResultSnippet } from "./DocumentSearchResults";
import FilterForm, { DocumentViewerFilters } from "./FilterForm";

import css from "./DocumentSearch.module.scss";

type DocumentSearchProps = {
  documentId?: string;
  defaultFilters: DocumentViewerFilters;
};

export default function DocumentSearch({ documentId, defaultFilters }: DocumentSearchProps) {
  const { instance } = usePdfViewerInstance();

  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [searchInProgress, setSearchInProgress] = useState(false);

  const [searchResults, setSearchResults] = useState<SearchResultSnippet[]>([]);
  const [activeResultIndex, setActiveResultIndex] = useState<number>(0);

  const [filters, setFilters] = useState<DocumentViewerFilters>(defaultFilters);

  const { data: matches, isFetching } = useDocumentHighlight(
    documentId ?? "",
    { keywords: filters.keywords },
    {
      enabled: !!documentId && !!filters.keywords.length,
    },
  );

  const useDebounce = createUseDebounce(500);
  const debouncedSetFilters = useDebounce((filters: DocumentViewerFilters) => {
    setFilters(filters);
  });

  const filterCount = filters.keywords.length;

  useEffect(() => {
    if (!instance) return;

    const { documentViewer, Search } = instance.Core;

    const termsToHighlight = matches?.contentHighlights;

    if (!termsToHighlight?.length && !isFetching) {
      // clear search results if no matches found
      setSearchResults([]);
      documentViewer.clearSearchResults();
    }

    if (documentLoaded) {
      const searchText = termsToHighlight?.length ? termsToHighlight.join("|") : "";

      let index = 0;
      const results: SearchResultSnippet[] = [];

      if (searchText) {
        documentViewer.textSearchInit(
          searchText,
          Search.Mode.PAGE_STOP |
            Search.Mode.HIGHLIGHT |
            Search.Mode.AMBIENT_STRING |
            Search.Mode.REGEX |
            Search.Mode.WHOLE_WORD,
          {
            fullSearch: true,
            onResult: (result: Core.Search.SearchResult) => {
              if (result.resultCode === Search.ResultCode.FOUND) {
                results.push({ ...result, originalIndex: index });
                index++;
              }
            },
            onDocumentEnd: () => {
              documentViewer.displayAdditionalSearchResults(results);
              documentViewer.setActiveSearchResult(results[0]);
              setActiveResultIndex(0);
              setSearchResults(results);
            },
          },
        );
      }
    }
  }, [documentLoaded, matches, instance, isFetching, filterCount]);

  useEffect(() => {
    const documentViewer = instance?.Core.documentViewer;

    const handleDocumentLoaded = () => setDocumentLoaded(true);
    const handleSearchInProgress = (inProgress: boolean) => setSearchInProgress(inProgress);

    if (documentViewer) {
      documentViewer.addEventListener("documentLoaded", () => {
        handleDocumentLoaded();
      });

      documentViewer.addEventListener("searchInProgress", (inProgress) => {
        handleSearchInProgress(inProgress);
      });
    }

    // Clean up event listeners
    return () => {
      if (documentViewer) {
        documentViewer.removeEventListener("documentLoaded", handleDocumentLoaded);
        documentViewer.removeEventListener("searchInProgress", handleSearchInProgress);
      }
    };
  }, [instance]);

  const handleJumpToSearchResult = (result: SearchResultSnippet) => {
    if (instance) {
      instance.Core.documentViewer.setActiveSearchResult(result);
    }
  };

  return (
    <>
      <div className={css.filters}>
        <h3 className={css.filtersHeader}>
          <SearchIcon /> Search
        </h3>
        <FilterForm filters={filters} onChange={debouncedSetFilters} />
      </div>
      <DocumentSearchResults
        activeResultIndex={activeResultIndex}
        setActiveResultIndex={setActiveResultIndex}
        searchResults={searchResults}
        isLoading={isFetching || searchInProgress}
        onJumpToSearchResult={handleJumpToSearchResult}
        filterCount={filters.keywords.length}
      />
    </>
  );
}
