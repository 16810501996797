import { UseMutationOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  UploadNoticeDocumentUrlMutation,
  UploadNoticeDocumentUrlMutationVariables,
} from "lib/generated/app-service-gql/graphql";
import { useGraphQlMutation } from "../useGraphQlClient";

const uploadNoticeDoc = graphql(`
  mutation UploadNoticeDocumentURL($input: UploadNoticeDocumentRequest!) {
    uploadNoticeDocument(UploadNoticeDocumentRequest: $input) {
      signedUrl
    }
  }
`);

export function useNoticeDocumentUploadUrl(
  options?: UseMutationOptions<
    UploadNoticeDocumentUrlMutation,
    unknown,
    UploadNoticeDocumentUrlMutationVariables,
    unknown
  >,
) {
  const { data, ...rest } = useGraphQlMutation(uploadNoticeDoc, {
    ...options,
    onSuccess: (data, vars, ctx) => {
      options?.onSuccess?.(data, vars, ctx);
    },
  });
  return { data: data?.uploadNoticeDocument.signedUrl, ...rest };
}
