import * as React from "react";

export default function SearchIcon(): JSX.Element {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon Left">
        <path
          id="Vector (Stroke)"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.33301 2.66536C4.75568 2.66536 2.66634 4.7547 2.66634 7.33203C2.66634 9.90936 4.75568 11.9987 7.33301 11.9987C8.5903 11.9987 9.73147 11.5015 10.5706 10.693C10.5881 10.6701 10.6074 10.6482 10.6283 10.6273C10.6492 10.6064 10.6711 10.5872 10.6939 10.5696C11.5025 9.73049 11.9997 8.58933 11.9997 7.33203C11.9997 4.7547 9.91034 2.66536 7.33301 2.66536ZM12.0209 11.0772C12.842 10.0507 13.333 8.74871 13.333 7.33203C13.333 4.01832 10.6467 1.33203 7.33301 1.33203C4.0193 1.33203 1.33301 4.01832 1.33301 7.33203C1.33301 10.6457 4.0193 13.332 7.33301 13.332C8.74968 13.332 10.0517 12.841 11.0781 12.02L13.5283 14.4701C13.7886 14.7305 14.2107 14.7305 14.4711 14.4701C14.7314 14.2098 14.7314 13.7876 14.4711 13.5273L12.0209 11.0772ZM6.66634 3.9987C6.66634 3.63051 6.96482 3.33203 7.33301 3.33203C9.54215 3.33203 11.333 5.12289 11.333 7.33203C11.333 7.70022 11.0345 7.9987 10.6663 7.9987C10.2982 7.9987 9.99967 7.70022 9.99967 7.33203C9.99967 5.85927 8.80577 4.66536 7.33301 4.66536C6.96482 4.66536 6.66634 4.36689 6.66634 3.9987Z"
          fill="#2569F9"
        />
      </g>
    </svg>
  );
}
