import * as React from "react";
import { Select } from "antd"; // upgrade and verify
import { OptionProps, SelectProps } from "antd/lib/select";

import AutoclosingMultiselect from "components/form_components/AutoclosingMultiselect";
import { langCodes, OptionItem } from "lib/data/optionItems";

const { Option } = Select;

const filterOptionByTitle = (input: string, option: React.ReactElement<OptionProps>): boolean =>
  (option.props.title as string).toLowerCase().startsWith(input.toLowerCase());

type CommonIntlMultiFilterProps = SelectProps<string[]> & {
  values: string[];
  options?: OptionItem[];
};

function IntlMultiFilter(
  props: CommonIntlMultiFilterProps & { children: React.ReactElement<OptionProps>[] },
) {
  const { onChange, children, values, ...rest } = props;

  return (
    <AutoclosingMultiselect<string>
      onChange={onChange}
      placeholder="Select..."
      value={values}
      filterOption={filterOptionByTitle}
      style={{ width: "100%" }}
      {...rest}
    >
      {children}
    </AutoclosingMultiselect>
  );
}

function optionItemToOptionComponent(optionItem: OptionItem) {
  return (
    <Option value={optionItem.code} key={optionItem.code} title={optionItem.name}>
      {optionItem.name}
    </Option>
  );
}

export function LanguagesFilter(props: CommonIntlMultiFilterProps): JSX.Element {
  const { options, ...rest } = props;
  const selectOptions = React.useMemo(
    () => (options || langCodes).map(optionItemToOptionComponent),
    [options],
  );
  return <IntlMultiFilter {...rest}>{selectOptions}</IntlMultiFilter>;
}
