import * as React from "react";
import { hot } from "react-hot-loader/root";
import { LoadingOutlined } from "@ant-design/icons";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { message } from "antd5";
import produce from "immer";

import TextButton from "components/actions/TextButton";
import { withAppLayout } from "components/app_layout/AppLayout";
import { candidatesToSubmittedDecisions } from "components/organisation_clean/adapters";
import FindDuplicatesPage from "components/organisation_clean/FindDuplicatesPage";
import ModifyAnchorPage from "components/organisation_clean/ModifyAnchorPage";
import ReviewOrgCleanPage from "components/organisation_clean/ReviewOrgCleanPage";
import SelectAnchorPage from "components/organisation_clean/SelectAnchorPage";
import {
  CandidateOperation,
  MultipleEntitiesCandidate,
  OrgWithStats,
  ProcessStages,
  QualifiedCandidate,
  UpdatableAttributes,
  UpdateAttributesChange,
} from "components/organisation_clean/types";
import { validateCleaningProgress } from "components/organisation_clean/validation";
import NavigationFooter from "lib/core_components/NavigationFooter";
import { assertCurrentUser } from "lib/currentUser";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { SubmitOrgCleaningTaskRequestTaskTypeEnum } from "lib/generated/app-api";
import { usePreventNavigation } from "lib/hooks/usePreventNavigation";
import { useOpenApi } from "lib/openApiContext";
import { assertDefined, createSetNestedState } from "lib/utils";

import css from "./SupplierCleaningPage.module.scss";

export enum Steps {
  SELECT_ANCHOR,
  MODIFY_ANCHOR,
  FIND_DUPLICATES,
  REVIEW,
}

// Due to typescript's reverse mapping the length of the compiled object is doubled, hence the
// division by 2
const TOTAL_STEPS = Object.values(Steps).length / 2;

type CommonStepState = {
  userGuid: string;
  qualifiedCandidates: Record<string, QualifiedCandidate>;
};

const useHandleSubmit = (
  options?: UseMutationOptions<void, unknown, SupplierFormState, unknown>,
) => {
  const api = useOpenApi();

  return useMutation(async (formState: SupplierFormState) => {
    const { anchorOrg, qualifiedCandidates, step } = formState;
    assertDefined(anchorOrg);

    const cancelLocation = Steps[step];
    const cancelNote = "SUBMITTED_FOR_REVIEW";

    const decisions = candidatesToSubmittedDecisions(qualifiedCandidates);

    await api.submitOrgCleaningTask({
      submitOrgCleaningTaskRequest: {
        decisions,
        organisationId: anchorOrg.guid,
        cancelLocation,
        cancelNote,
        taskType: SubmitOrgCleaningTaskRequestTaskTypeEnum.DeDupeCleaning,
      },
    });
  }, options);
};

const useHandleSubmitAndProcess = (
  options?: UseMutationOptions<void, unknown, SupplierFormState, unknown>,
) => {
  const api = useOpenApi();

  return useMutation(async (formState: SupplierFormState) => {
    const anchor = formState.anchorOrg;
    assertDefined(anchor);

    const decisions = candidatesToSubmittedDecisions(formState.qualifiedCandidates);

    await api.submitAndProcessOrgCleaningTask({
      submitAndProcessOrgCleaningTaskRequest: {
        decisions,
        organisationId: anchor.guid,
        taskType: SubmitOrgCleaningTaskRequestTaskTypeEnum.DeDupeCleaning,
      },
    });
  }, options);
};

type SupplierFormState =
  | ({ step: Steps.SELECT_ANCHOR; anchorOrg: OrgWithStats | undefined } & CommonStepState)
  | ({ step: Exclude<Steps, Steps.SELECT_ANCHOR>; anchorOrg: OrgWithStats } & CommonStepState);

function SupplierCleaningPage() {
  const initialFormState: SupplierFormState = React.useMemo(
    () => ({
      userGuid: assertCurrentUser().guid,
      anchorOrg: undefined,
      step: Steps.SELECT_ANCHOR,
      qualifiedCandidates: {},
    }),
    [],
  );

  const [formState, setFormState] = React.useState<SupplierFormState>(() => initialFormState);
  // This state keeps track of what index of the multiple entity suspects we're on for the cleaning stage
  const [cleaningIndex, setCleaningIndex] = React.useState<number>(0);
  const candidatesRequiringCleaning: string[] = React.useMemo(() => {
    const arr = [];
    for (const [guid, qs] of Object.entries(formState.qualifiedCandidates)) {
      if (qs.qualification === CandidateOperation.MULTIPLE_ENTITIES) {
        arr.push(guid);
      }
    }
    return arr;
  }, [formState.qualifiedCandidates]);

  usePreventNavigation(
    !!formState.anchorOrg,
    "Are you sure you want to leave the page? All changes will go unsaved.",
  );

  const setQualifiedCandidates = React.useMemo(
    () => createSetNestedState("qualifiedCandidates", setFormState),
    [],
  );

  const currentCandidate = React.useMemo(() => {
    const currentGuid = candidatesRequiringCleaning[cleaningIndex];
    return formState.qualifiedCandidates[currentGuid] as MultipleEntitiesCandidate;
  }, [formState.qualifiedCandidates, cleaningIndex, candidatesRequiringCleaning]);

  const handleChangeStep = React.useCallback((step: Steps) => {
    setFormState(
      produce((form) => {
        form.step = step;
      }),
    );
  }, []);

  // TODO: deprecated - everything will soon require human review
  const { mutate: handleSubmitAndProcess, isLoading: submitAndProcessLoading } =
    useHandleSubmitAndProcess({
      onSuccess: () => {
        void message.success("Thanks for submitting the buyer cleaning task");
        setFormState(initialFormState);
      },
      onError: (e) => {
        const msg = e instanceof Error ? e.message : `${e}`;

        void message.error(msg);
      },
    });
  const { mutate: handleSubmit } = useHandleSubmit({
    onSuccess: () => {
      void message.success("Thanks for submitting the buyer cleaning task");
      setFormState(initialFormState);
    },
    onError: (e) => {
      const msg = e instanceof Error ? e.message : `${e}`;

      void message.error(msg);
    },
  });

  const addToQualifiedCandidates = React.useCallback(
    (buyer: OrgWithStats, q: CandidateOperation, note?: string | undefined) => {
      setQualifiedCandidates(
        produce((qualifiedCandidates) => {
          if (q === CandidateOperation.MULTIPLE_ENTITIES) {
            qualifiedCandidates[buyer.guid] = {
              buyer,
              qualification: CandidateOperation.MULTIPLE_ENTITIES,
              processStage: ProcessStages.REQUIRES_CLEAN,
              note,
            };
          } else if (
            // anything else other than anchor updates - they are handled separately
            q !== CandidateOperation.UPDATE_ATTRIBUTES
          ) {
            qualifiedCandidates[buyer.guid] = {
              buyer,
              qualification: q,
              processStage: ProcessStages.DONE,
              note,
            };
          }
        }),
      );
      // If a user goes back to the qualification screen and re-qualifies already qualified candidates
      // After having been through a few multi-entity ones, we need to reset the index. Otherwise it breaks if there
      // are no longer as many buyers requiring cleaning
      setCleaningIndex(0);
    },
    [setQualifiedCandidates],
  );

  const onAnchorUpdate = (newValues: UpdatableAttributes) => {
    const buyer = formState.anchorOrg;
    if (!buyer) return;

    setQualifiedCandidates(
      produce((qualifiedCandidates) => {
        qualifiedCandidates[buyer.guid] = {
          buyer: buyer,
          qualification: CandidateOperation.UPDATE_ATTRIBUTES,
          processStage: ProcessStages.REQUIRES_CLEAN,
          newValues: {
            ...(qualifiedCandidates[buyer.guid] as UpdateAttributesChange | undefined)?.newValues,
            ...newValues,
          },
        };
      }),
    );
  };

  const handleUpdateProcessStage = React.useCallback(
    (guid: string, processStage: ProcessStages) => {
      setQualifiedCandidates(
        produce((qualifiedCandidates) => {
          qualifiedCandidates[guid].processStage = processStage;
        }),
      );
    },
    [setQualifiedCandidates],
  );

  const updateAnchor = React.useCallback((b: OrgWithStats) => {
    setFormState(
      produce((draftState) => {
        draftState.anchorOrg = b;
      }),
    );
  }, []);

  // TODO WORK ON VERIFICATION
  // Verifies if the current suspect is sufficiently done
  React.useEffect(() => {
    if (candidatesRequiringCleaning.length < 1 || !currentCandidate) {
      return;
    } else {
      handleUpdateProcessStage(
        currentCandidate.buyer.guid,
        validateCleaningProgress(currentCandidate),
      );
    }
  }, [currentCandidate, candidatesRequiringCleaning, handleUpdateProcessStage]);

  switch (formState.step) {
    case Steps.SELECT_ANCHOR:
      return (
        <div className={css.buyerCleaningPage}>
          <div className={css.pageContent}>
            <SelectAnchorPage
              onAnchorChange={(o) => {
                if (formState.anchorOrg) {
                  setFormState(initialFormState);
                  setCleaningIndex(0);
                }
                updateAnchor(o);
              }}
              anchorOrg={formState.anchorOrg}
              orgPrimaryRole="Supplier"
            />
          </div>
          <NavigationFooter
            className={css.nav}
            onNext={() => handleChangeStep(Steps.MODIFY_ANCHOR)}
            nextButtonLabel="Next"
            nextDisabled={!formState.anchorOrg}
            currentStep={Steps.SELECT_ANCHOR}
            totalSteps={TOTAL_STEPS}
          />
        </div>
      );
    case Steps.MODIFY_ANCHOR:
      return (
        <div className={css.buyerCleaningPage}>
          <div className={css.pageContent}>
            <ModifyAnchorPage
              anchorOrg={formState.anchorOrg}
              onAnchorUpdate={onAnchorUpdate}
              referenceOrgConnectors={["companies_house"]}
            />
          </div>
          <NavigationFooter
            className={css.nav}
            onNext={() => handleChangeStep(Steps.FIND_DUPLICATES)}
            onBack={() => handleChangeStep(Steps.SELECT_ANCHOR)}
            backButtonLabel="Back"
            nextButtonLabel="Next"
            nextDisabled={!formState.anchorOrg}
            currentStep={Steps.MODIFY_ANCHOR}
            totalSteps={TOTAL_STEPS}
          />
        </div>
      );

    case Steps.FIND_DUPLICATES:
      return (
        <div className={css.buyerCleaningPage}>
          <div className={css.pageContent}>
            <FindDuplicatesPage
              qualifiedCandidates={Object.fromEntries(
                Object.entries(formState.qualifiedCandidates).filter(
                  ([_guid, candidate]) =>
                    candidate.qualification !== CandidateOperation.UPDATE_ATTRIBUTES,
                ),
              )}
              onCandidateChange={addToQualifiedCandidates}
              anchorOrg={formState.anchorOrg}
              orgPrimaryRole="Supplier"
            />
          </div>
          <NavigationFooter
            className={css.nav}
            onNext={() => {
              handleChangeStep(Steps.REVIEW);
            }}
            onBack={() => handleChangeStep(Steps.MODIFY_ANCHOR)}
            nextButtonLabel="Next"
            backButtonLabel="Back"
            // TODO: This should probably check that the qualified candidate isn't just
            // Unsure or Unrelated
            nextDisabled={Object.keys(formState.qualifiedCandidates).length < 1}
            currentStep={Steps.FIND_DUPLICATES}
            totalSteps={TOTAL_STEPS}
          />
        </div>
      );
    case Steps.REVIEW:
      return (
        <div className={css.buyerCleaningPage}>
          <div className={css.pageContent}>
            <ReviewOrgCleanPage
              pageTitle="Step 4. Review and submit"
              anchorOrg={formState.anchorOrg}
              qualifiedCandidates={formState.qualifiedCandidates}
              formStateStringified={JSON.stringify(formState)}
            />
          </div>
          <NavigationFooter
            className={css.nav}
            onNext={async () => {
              await handleSubmit(formState);
            }}
            onBack={() => {
              handleChangeStep(Steps.FIND_DUPLICATES);
            }}
            nextButtonLabel="Submit for review"
            backButtonLabel="Back"
            renderAdditionalCTARight={() => (
              <>
                {FeatureToggles.isEnabled(Feature.SUBMIT_BUYER_CLEANING) && (
                  <TextButton
                    className={css.submitNow}
                    onClick={async () => {
                      await handleSubmitAndProcess(formState);
                    }}
                  >
                    Submit &amp; make changes now {submitAndProcessLoading && <LoadingOutlined />}
                  </TextButton>
                )}
              </>
            )}
            currentStep={Steps.REVIEW}
            totalSteps={TOTAL_STEPS}
          />
        </div>
      );
  }
}

export default hot(
  withAppLayout(SupplierCleaningPage, {
    pageName: "Supplier Cleaning",
  }),
);
