import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Divider } from "antd5";

import { DocumentFilters, documentTypes, FilterSection } from "components/documents/utils";
import { SelectBuyerList } from "components/form_components/BuyerListSelect";
import { BuyerSelect } from "components/form_components/BuyerSelect";
import { BuyerTypeSelect } from "components/form_components/BuyerTypeSelect";
import { CountryRegionSelect } from "components/form_components/CountryRegionSelect";
import { DateRange, Select } from "components/form_components/Inputs";
import { KeywordSelect } from "components/form_components/keyword_select/KeywordSelect";
import { NewRelevanceScoreButtons } from "components/form_components/NewRelevanceScoreButtons";
import { DOCUMENTS_SIGNAL_SCORE } from "lib/featureFlags";
import FilterFormTitle from "../filter_form/filter_form_title/FilterFormTitle";

import css from "./FilterForm.module.scss";

type FilterFormProps = {
  onChange: (filters: DocumentFilters) => void;
  filters: DocumentFilters;
  hiddenSections?: FilterSection[];
  closeDrawer: () => void;
  showTitle: boolean;
};

export function FilterForm({
  onChange,
  filters,
  hiddenSections,
  closeDrawer,
  showTitle,
}: FilterFormProps) {
  const isSignalScoreEnabled = useVariableValue(DOCUMENTS_SIGNAL_SCORE, false);

  const { control, watch, handleSubmit } = useForm<DocumentFilters>({
    defaultValues: filters,
  });

  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((d) => {
        onChange(d);
      })(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, onChange, watch]);

  const data = watch();

  return (
    <>
      {showTitle && <FilterFormTitle close={closeDrawer} />}
      <form>
        <div className={showTitle ? css.formContent : undefined}>
          <div className={css.filterSection}>
            <KeywordSelect name="keywords" label="Keywords" control={control} />
            {isSignalScoreEnabled && (
              <Controller
                name="signalScore"
                control={control}
                render={({ field }) => (
                  <div className={css.inputContainer}>
                    <label htmlFor="relevanceScore" className={css.inputLabel}>
                      <h3>Signal score</h3>
                    </label>
                    <NewRelevanceScoreButtons {...field} />
                  </div>
                )}
              />
            )}
            <Select
              mode="multiple"
              name="categories"
              control={control}
              label="Document type"
              optionFilterProp="label"
              options={documentTypes}
              placeholder="Select document types"
            />
            <DateRange
              name="publishDate.filter"
              control={control}
              label="Published date"
              showFutureDates={false}
            />
            {!hiddenSections?.includes("buyers") && (
              <>
                <Divider className={css.divider} />
                <BuyerSelect
                  name="organisationIds"
                  label="Buyers"
                  control={control}
                  mode="multiple"
                  placeholder="Select buyers"
                  disabled={(data.buyerListIds ?? []).length > 0}
                  allowClear
                />
                <SelectBuyerList
                  name="buyerListIds"
                  label="Buyers saved to"
                  control={control}
                  placeholder="Select buyer list"
                  mode="multiple"
                  disabled={(data.organisationIds ?? []).length > 0}
                  allowClear
                />
                <BuyerTypeSelect
                  name="buyerTypes"
                  control={control}
                  multiple
                  label="Buyer type"
                  placeholder="Select buyer type"
                  allowClear
                />
                <CountryRegionSelect
                  name="buyerCountryRegions"
                  control={control}
                  label="Buyer location"
                  placeholder="Select country or region"
                  allowClear
                />
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
}
