import * as React from "react";
import { Spin } from "antd5";
import { ProfileTypeContext } from "pages/app/SupplierRelationshipPage";

import { labelledRoutes } from "components/relationship_profiles/routingUtils";
import {
  ALL_NOTICES_COLUMNS,
  AWARD_FILTERS,
  AWARDS_COLUMNS,
  DEFAULT_NOTICE_SORTS,
  noticesSecondaryTabs,
  UPCOMING_EXPIRIES_COLUMNS,
  UPCOMING_EXPIRIES_FILTERS,
} from "components/relationship_profiles/tableConfigs";
import {
  NoticeTabsTypes,
  PrimaryTab,
  SecondaryTab,
  SupplierRelationshipFilter,
  TabConfigType,
} from "components/relationship_profiles/types";
import { SecondaryTabs, TabPane } from "lib/core_components/tabs";
import { TrackingProvider } from "lib/tracking";
import NoticesTable from "./NoticesTable";

import css from "./TabStyles.module.scss";

type Props = {
  tabConfig: TabConfigType;
  handleTabNavigation: (loc: PrimaryTab | SecondaryTab) => void;
  filters: SupplierRelationshipFilter;
};

const noticeTypeTabs = Object.keys(noticesSecondaryTabs);

function NoticesTabs({ handleTabNavigation, tabConfig, filters }: Props) {
  // If the secondary isn't set we default it to "all notices" for the notices tab
  // We also check that the current tab is definitely one of the notice tabs, otherwise default to all_notices
  const activeTab: NoticeTabsTypes = React.useMemo(
    () =>
      tabConfig.secondary && noticeTypeTabs.includes(tabConfig.secondary)
        ? (tabConfig.secondary as NoticeTabsTypes)
        : SecondaryTab.ALL_NOTICES,
    [tabConfig],
  );

  const [allCount, setAllCount] = React.useState<number | undefined>(undefined);
  const [awardsCount, setAwardsCount] = React.useState<number | undefined>(undefined);
  const [expiriesCount, setExpiriesCount] = React.useState<number | undefined>(undefined);

  const { signalName, signalCategory } = React.useContext(ProfileTypeContext);

  const { allRecordsFilters, awardsFilters, upcomingExpiriesFilters } = React.useMemo(() => {
    const signalFilter = {
      ...filters,
      ...(signalCategory && signalName ? { [`${signalCategory}s`]: [signalName] } : undefined),
    };
    return {
      allRecordsFilters: {
        signals: signalFilter,
        text: signalFilter.textSearch,
      },
      upcomingExpiriesFilters: {
        ...UPCOMING_EXPIRIES_FILTERS,
        signals: signalFilter,
        text: signalFilter.textSearch,
      },

      awardsFilters: {
        ...AWARD_FILTERS,
        signals: signalFilter,
        text: signalFilter.textSearch,
      },
    };
  }, [signalCategory, signalName, filters]);

  const onAllRecordsLoad = React.useCallback((apiResp) => {
    setAllCount(apiResp.paging_info.total_results);
  }, []);
  const onAwardsLoad = React.useCallback((apiResp) => {
    setAwardsCount(apiResp.paging_info.total_results);
  }, []);
  const onExpiriesLoad = React.useCallback((apiResp) => {
    setExpiriesCount(apiResp.paging_info.total_results);
  }, []);

  return (
    <SecondaryTabs
      activeKey={activeTab}
      onChange={(k) => {
        handleTabNavigation(k as SecondaryTab);
      }}
    >
      <TabPane
        className={css.tabPane}
        key={SecondaryTab.ALL_NOTICES}
        tab={<>All notices {allCount === undefined ? <Spin /> : `(${allCount})`}</>}
        // this forces the tab to be rendered even if it isn't visible, which ensure that all
        // the data is eagerly fetched (so tab counts can be suitably populated)
        forceRender
      >
        <TrackingProvider data={{ "Context source": labelledRoutes[SecondaryTab.ALL_NOTICES] }}>
          <NoticesTable
            defaultSort={DEFAULT_NOTICE_SORTS[SecondaryTab.ALL_NOTICES]}
            onRecordLoad={onAllRecordsLoad}
            columnNames={ALL_NOTICES_COLUMNS}
            filters={allRecordsFilters}
          />
        </TrackingProvider>
      </TabPane>
      <TabPane
        className={css.tabPane}
        key={SecondaryTab.AWARDS}
        tab={<>Awards {awardsCount === undefined ? <Spin /> : `(${awardsCount})`}</>}
        // this forces the tab to be rendered even if it isn't visible, which ensure that all
        // the data is eagerly fetched (so tab counts can be suitably populated)
        forceRender
      >
        <TrackingProvider data={{ "Context source": labelledRoutes[SecondaryTab.AWARDS] }}>
          <NoticesTable
            defaultSort={DEFAULT_NOTICE_SORTS[SecondaryTab.AWARDS]}
            onRecordLoad={onAwardsLoad}
            columnNames={AWARDS_COLUMNS}
            filters={awardsFilters}
          />
        </TrackingProvider>
      </TabPane>
      <TabPane
        className={css.tabPane}
        key={SecondaryTab.UPCOMING_EXPIRIES}
        tab={<>Upcoming expiries {expiriesCount === undefined ? <Spin /> : `(${expiriesCount})`}</>}
        // this forces the tab to be rendered even if it isn't visible, which ensure that all
        // the data is eagerly fetched (so tab counts can be suitably populated)
        forceRender
      >
        <TrackingProvider
          data={{ "Context source": labelledRoutes[SecondaryTab.UPCOMING_EXPIRIES] }}
        >
          <NoticesTable
            defaultSort={DEFAULT_NOTICE_SORTS[SecondaryTab.UPCOMING_EXPIRIES]}
            onRecordLoad={onExpiriesLoad}
            columnNames={UPCOMING_EXPIRIES_COLUMNS}
            filters={upcomingExpiriesFilters}
          />
        </TrackingProvider>
      </TabPane>
    </SecondaryTabs>
  );
}

export default NoticesTabs;
