import { useEffect, useState } from "react";
import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { BuyerListDto, GetBuyerListsPermissionEnum } from "lib/generated/app-api";
import { useOpenApi } from "lib/openApiContext";

type QueryKey = [string, string];

export type AllBuyerLists = { private: BuyerListDto[]; team: BuyerListDto[]; all: BuyerListDto[] };
export function useBuyerLists(
  permissionFilter?: GetBuyerListsPermissionEnum,
  options?: UseQueryOptions<AllBuyerLists, unknown, AllBuyerLists, QueryKey>,
) {
  const api = useOpenApi();
  return useQuery(
    ["buyerLists", permissionFilter || "allLists"],
    async () => {
      const { buyerLists } = await api.getBuyerLists({ permission: permissionFilter });
      return buyerLists.reduce<AllBuyerLists>(
        (lists, list) => {
          if (list.sharingType === "PRIVATE") {
            lists.private.push(list);
            return lists;
          }
          lists.team.push(list);
          return lists;
        },
        { private: [], team: [], all: buyerLists },
      );
    },
    options,
  );
}

type IsBuyerInListState = {
  isSaved: boolean;
  savedLists: BuyerListDto[];
  savedListNames: string[];
};
export function useIsBuyerInList(buyerGuid: string) {
  // When the user is not logged in, checking for buyer lists causes errors
  const { data: lists } = useBuyerLists(undefined, {
    enabled: !!window.currentUser,
  });
  const [isSaved, setIsSaved] = useState<IsBuyerInListState>({
    isSaved: false,
    savedLists: [],
    savedListNames: [],
  });

  useEffect(() => {
    if (lists) {
      const allLists = lists.private.concat(lists.team);
      const listNames: string[] = [];
      const listsWithBuyer = allLists.filter((list) => {
        const buyerEntry = list.entries.find((entry) => entry.id === buyerGuid);
        if (buyerEntry) {
          listNames.push(list.name);
          return true;
        }
        return false;
      });
      setIsSaved({
        isSaved: listsWithBuyer.length > 0,
        savedLists: listsWithBuyer,
        savedListNames: listNames,
      });
    }
  }, [lists, buyerGuid]);

  return isSaved;
}
