import * as React from "react";
import { Spin } from "antd5";

import BuyerTable from "components/relationship_profiles/BuyerTable";
import { labelledRoutes } from "components/relationship_profiles/routingUtils";
import { buyersSecondaryTabs } from "components/relationship_profiles/tableConfigs";
import {
  BuyerTabTypes,
  PrimaryTab,
  SecondaryTab,
  SupplierRelationshipFilter,
  TabConfigType,
} from "components/relationship_profiles/types";
import { SecondaryTabs, TabPane } from "lib/core_components/tabs";
import { TrackingProvider } from "lib/tracking";

import css from "./TabStyles.module.scss";

type Props = {
  tabConfig: TabConfigType;
  handleTabNavigation: (loc: PrimaryTab | SecondaryTab) => void;
  filters: SupplierRelationshipFilter;
};

const buyerTypeTabs = Object.keys(buyersSecondaryTabs);

function BuyerTabs({ tabConfig, handleTabNavigation, filters }: Props) {
  // If the secondary isn't set we default it to "all notices" for the notices tab
  // We also check that the current tab is definitely one of the notice tabs, otherwise default to all_notices
  const activeTab: BuyerTabTypes = React.useMemo(
    () =>
      tabConfig.secondary && buyerTypeTabs.includes(tabConfig.secondary)
        ? (tabConfig.secondary as BuyerTabTypes)
        : SecondaryTab.ALL_BUYERS,
    [tabConfig],
  );
  const [allCount, setAllCount] = React.useState<number | undefined>(undefined);
  const [keyAccountCount, setKeyAccountCount] = React.useState<number | undefined>(undefined);

  const onAllBuyersLoad = React.useCallback((apiResp) => {
    setAllCount(apiResp.pagingInfo.totalResults);
  }, []);
  const onKeyAccountsLoad = React.useCallback((apiResp) => {
    setKeyAccountCount(apiResp.pagingInfo.totalResults);
  }, []);

  return (
    <SecondaryTabs
      activeKey={activeTab}
      onChange={(k) => {
        handleTabNavigation(k as SecondaryTab);
      }}
    >
      <TabPane
        className={css.tabPane}
        key={SecondaryTab.ALL_BUYERS}
        tab={<>All buyers {allCount === undefined ? <Spin /> : `(${allCount})`}</>}
        // this forces the tab to be rendered even if it isn't visible, which ensure that all
        // the data is eagerly fetched (so tab counts can be suitably populated)
        forceRender
      >
        <TrackingProvider data={{ "Context source": labelledRoutes[SecondaryTab.ALL_BUYERS] }}>
          <BuyerTable showKeyAccountsOnly={false} onBuyerLoad={onAllBuyersLoad} filters={filters} />
        </TrackingProvider>
      </TabPane>
      <TabPane
        className={css.tabPane}
        key={SecondaryTab.SAVED_BUYERS}
        tab={<>Saved buyers {keyAccountCount === undefined ? <Spin /> : `(${keyAccountCount})`}</>}
        // this forces the tab to be rendered even if it isn't visible, which ensure that all
        // the data is eagerly fetched (so tab counts can be suitably populated)
        forceRender
      >
        <TrackingProvider data={{ "Context source": labelledRoutes[SecondaryTab.SAVED_BUYERS] }}>
          <BuyerTable
            showKeyAccountsOnly={true}
            onBuyerLoad={onKeyAccountsLoad}
            filters={filters}
          />
        </TrackingProvider>
      </TabPane>
    </SecondaryTabs>
  );
}
export default BuyerTabs;
